import { combineReducers } from "redux";

import reducera from "./alert/reducera";
import reduceras from "./alert/reduceras";
import reducerst from "../components/stock/reducerst";
import reducerg from "../components/group/reducerg";
import reducersc from "../components/secteuractivite/reducersc";
import reducerf from "../components/Fournisseurs/reducerf";
import reducercp from "../components/compte/reducercp";
import auth from "./signin/auth";
import reducerdvc from "../components/colis/reducerdvc";
import reducerc from "../components/colis/reducerc";
import reducerh from "../layout/header/reducerh";
import reducerr from "../components/reclamation/reducerr";
import reducerfin from "../components/finance/reducerfin";
import reducerde from "../components/demandes/reducerde";
import reduceraddr from "../components/adresse/reduceraddr";
import reducernews from "../components/news/reducernews";
const reducers = combineReducers({
  reducerst,
  reducerdvc,
  reduceraddr,
  reducerh,
  reduceras,
  reducerfin,
  reducerr,
  reducersc,
  reducerg,
  reducercp,
  auth,
  reducerc,
  reducera,
  reducerf,
  reducerde,
  reducernews
});

export default reducers;
