import React, {
    Fragment,
    useState,
    useEffect,
    useMemo, useContext,
} from "react";
import Select, {components} from "react-select";
import Alert from "../../redux/Alert";
import {connect} from "react-redux";
import {withRouter, useLocation} from "react-router-dom";
import {
    getVille,
    getCite,
    getDeligation,
    getLivreur,
    getadresseF,
    getVille1,
    getCite1,
    getVilleF,
    getCiteF,
    GenerateColis,
    getGenerated, getLivreurs,
} from "./action";
import {getFinances} from "../finance/action";
import {
    getFournisseurs,
} from "../Fournisseurs/action";
import Breadcrumbs from "../../layout/breadcrumb";

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    CardHeader,
    Table
} from "reactstrap";
import {useDispatch} from "react-redux";
import Spinner from "./spinner.gif";
import {day2digits} from "./gestiondescolis";
import PropTypes from "prop-types";
import {setAlertD} from "../../redux/alert/action";
import Button from "../../utils/Button";
import AppContext from "../../context/Context";

const GenerateOrderPage = ({
                               villeData,
                               citeDataf,
                               villeDataf,
                               financeData,
                               fournisseurData,
                               GeneratedData,
                               GenerateColis,
                               citeData,
                               deligationData,
                           }) => {
    const [selectedfournisseur, setSelectedFournisseur] = useState([]);
    const [statevillef, setVilleF] = useState([
        {
            id: "",
            name: "",
        },
    ]);

    const [statecitef, setCiteF] = useState([
        {
            id: "",
            name: "",
        },
    ]);
    const [statedelegation, setDelegation] = useState([
        {
            id: "",
            name: "",
        },
    ]);
    const [statefournisseur, setFournisseur] = useState([
        {
            id: "",
            responsable: "",
        },
    ]);
    const [statefinance, setFinance] = useState([
        {
            id: "",
        },
    ]);
    const [livreur, setLivreur] = useState(null);
    const [currentPage, setCurrentPage] = useState(1)
    const [livreurDatas, setLivreurData] = useState([])
    const [currentFournisseurPage, setCurrentFournisseurPage] = useState(1)
    const [showLoader, setShowLoader] = useState(false)

    const {
        config: {group},
    } = useContext(AppContext);


    let F = parseInt(localStorage.getItem("fournisseur"));
    const [formData, setFormData] = useState({
        ville: "",
        cite: "",
        deligaiton: "",
        livreur: "",
        fournisseur: "",
        prix: "",
        status: "",
        creation_date_start: "",
        creation_date_finish: ""
    });


    const onChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
        query.set(`${e.target.name}`, e.target.value);
        console.log(query.toString())
    };


    const dispatch = useDispatch();

    const [taptopStyle, setTapTopStyle] = useState("none");

    const handleScroll = () => {
        if (window.scrollY > 600) {
            setTapTopStyle("block");
        } else {
            setTapTopStyle("none");
        }
    };
    useEffect(() => {
        dispatch(getGenerated(query))
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        handleScroll();
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [taptopStyle]);

    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };
    let query = useQuery();

    const getStatus = () => {
        let params = new URLSearchParams(document.location.search);
        let status = params.get("status");
        let service = params.get("service");
        if (status === "supprimer") {
            return "Colis supprimés";
        } else if (status === "retour") {
            return "Colis retour";
        } else if (status === "modifier") {
            return "Colis modifiés";
        } else if (service === "echange") {
            return "Colis echange";
        } else if (status === "payer") {
            return "Colis payés";
        } else {
            return `Colis du jour : ${getDate()}`;
        }
    };


    const getDate = () => {
        let params = new URLSearchParams(document.location.search);
        let date = params.get("created_at");
        if (date === "today") {
            let today = new Date();
            let m = today.getMonth() + 1;
            if (m < 10) {
                m = "0" + m;
            }
            return today.getFullYear() + "-" + m + "-" + day2digits(today.getDate());
        } else {
            return date;
        }
    };


    const onChangeDeligationF = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});

        query.set(`${e.target.name}`, e.target.value)
        let qs = `gouvernat_id=${e.target.value}`
        dispatch(getVilleF(qs));
    };
    const onChangeVilleF = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
        query.set(`${e.target.name}`, e.target.value)
        let qs = `ville_id=${e.target.value}`
        dispatch(getCiteF(qs));
    };

    useEffect(() => {
        setVilleF(villeDataf.villeF);
    }, [villeDataf.villeF, villeDataf.loading, statevillef]);

    let fp = useQuery()
    //get fournisseur
    useEffect(() => {
        fp.set("page_size", "50")
        fp.set("page", currentFournisseurPage)
        dispatch(getFournisseurs(fp))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fp, currentFournisseurPage]);

    useEffect(() => {
        setFournisseur(fournisseurData.fournisseurs);
    }, [fournisseurData.fournisseurs, fournisseurData.loading, statefournisseur]);

    //get deligation
    useEffect(() => {
        dispatch(getDeligation());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setDelegation(deligationData.deligations);
    }, [deligationData.deligations, deligationData.loading, statedelegation]);
    // get cite
    useEffect(() => {
        setCiteF(citeDataf.citesF);
    }, [citeDataf.citesF, citeDataf.loading, statecitef]);
    //get adresse

    const onChangeFournisseur = (e) => {
        setSelectedFournisseur(e);
        query.set('fournisseur_id', e.value)
        setFormData({
            ...formData,
            fournisseur: e.value,
        });


    };

    //get livreur
    useEffect(() => {
        query.set("page_size", "50")
        query.set("page", currentPage)
        getLivreurs(query)
            .then(res => setLivreurData([...livreurDatas, ...res?.results]))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    let user_groups_name = group;

    //get Finance
    useEffect(() => {
        if (F) {
            dispatch(getFinances(query));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setFinance(financeData.finances);
    }, [financeData.finances, financeData.loading, statefinance]);
    const data = [];
    fournisseurData &&
    fournisseurData.fournisseurs.map((F) =>
        data.push({
            value: parseInt(F.id),
            label: F.name,
        })
    );
    const NoOptionsMessage = props => {
        return (
            <components.NoOptionsMessage {...props}>
                <div className="text-center">
                    <img src={Spinner} style={{width: "10%"}} alt=""/>
                </div>
            </components.NoOptionsMessage>
        );
    };
    const onSubmit = (e) => {
        e.preventDefault()
        setShowLoader(true)
        const creation_date_start = new Date(query.get("creation_date_start"));
        const creation_date_finish = new Date(query.get("creation_date_finish"));
        const oneMonthInMillis = 30 * 24 * 60 * 60 * 1000; // milliseconds in one month

        if (creation_date_finish.getTime() - creation_date_start.getTime() > oneMonthInMillis) {
            dispatch(setAlertD("Veuillez exporter au moins un mois de données.", "danger", 3000))
        } else {
            GenerateColis(formData, query);
        }
        setTimeout(() =>
                dispatch(getGenerated(query)),
            3000)
        setShowLoader(false)
    }

    const onChangeLivreur = (e) => {
        setFormData({...formData, livreur: e.value});
        setLivreur(e);
    };

    let livreurOptions;

    livreurOptions = livreurDatas.map((livreur) => ({
        value: livreur.id,
        label: livreur.name
    }));

// Add the reset option
    livreurOptions = [
        {value: "", label: "------------------"},
        ...livreurOptions
    ];

    return (
        <Fragment>
            <Breadcrumbs parent="Colis" title="Exporter des Colis"/>
            <Container fluid={true}>

                <Alert/>

                <Col>

                    {/* eslint-disable-next-line no-mixed-operators */}
                    {user_groups_name && user_groups_name.indexOf("admin") !== -1 || user_groups_name && user_groups_name.indexOf("fournisseur") !== -1 ? (
                        <Card>
                            <CardHeader>
                                <Col>
                                    <form>
                                        {user_groups_name.indexOf("admin") !== -1 ? (
                                            <Row>


                                                <Col>
                                                    <FormGroup>
                                                        <Label>Fournisseur</Label>
                                                        <Select
                                                            value={selectedfournisseur}
                                                            placeholder={"Fournisseur"}
                                                            options={data}
                                                            onInputChange={(e) => {
                                                                setTimeout(() => {
                                                                    fp.set("search", e)
                                                                    dispatch(getFournisseurs(fp))
                                                                }, 1000)
                                                            }}
                                                            onMenuScrollToBottom={(e) => {
                                                                console.log(e)
                                                                setCurrentFournisseurPage(page => page + 1)
                                                            }}
                                                            components={{NoOptionsMessage}}
                                                            onChange={(e) => {
                                                                onChangeFournisseur(e);
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>


                                                <Col>
                                                    <FormGroup>
                                                        {user_groups_name &&
                                                        user_groups_name.indexOf("admin") !== -1 ? (
                                                            <Label>Livreur</Label>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {user_groups_name &&
                                                        user_groups_name.indexOf("admin") !== -1 ? (
                                                            <Select
                                                                options={livreurOptions}
                                                                onChange={(e) => {
                                                                    onChangeLivreur(e)
                                                                }}
                                                                placeholder={"Livreur"}
                                                                value={livreur}
                                                                name={'livreur'}
                                                                onInputChange={(e) => {
                                                                    query.set('search', e);
                                                                    getLivreurs(query);
                                                                }}
                                                                onMenuScrollToBottom={(e) => {
                                                                    console.log(e)
                                                                    setCurrentPage(page => page + 1)
                                                                }}
                                                            />
                                                        ) : (
                                                            ""
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        ) : ""}
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Délégation</Label>
                                                    <select
                                                        className="form-control digits"
                                                        name="deligaiton"
                                                        value={formData.deligaiton}
                                                        onChange={(e) => onChangeDeligationF(e)}
                                                    >
                                                        <option value="" disabled defaultValue hidden>
                                                            ----------
                                                        </option>
                                                        {deligationData &&
                                                            deligationData.deligations.map((delegation) => (
                                                                <option
                                                                    key={delegation.id}
                                                                    value={delegation.id}
                                                                >
                                                                    {delegation.name}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </FormGroup>
                                            </Col>

                                            <Col>
                                                <FormGroup>
                                                    <Label>Ville</Label>

                                                    <select
                                                        className="form-control digits"
                                                        name="ville"
                                                        value={formData.ville}
                                                        onChange={(e) => onChangeVilleF(e)}
                                                    >
                                                        {" "}
                                                        <option value="" disabled defaultValue hidden>
                                                            ----------
                                                        </option>
                                                        {villeData &&
                                                            villeData.villes.map((ville) => (
                                                                <option key={ville.id} value={ville.id}>
                                                                    {ville.name}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Cité</Label>
                                                    <select
                                                        className="form-control digits"
                                                        name="cite"
                                                        value={formData.cite}
                                                        onChange={(e) => onChange(e)}
                                                    >
                                                        <option value="" disabled defaultValue hidden>
                                                            ----------
                                                        </option>
                                                        {citeData &&
                                                            citeData.cites.map((cite) => (
                                                                <option key={cite.id} value={cite.id}>
                                                                    {cite.name}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Date de début</Label>
                                                    <Input
                                                        required
                                                        className="form-control digits"
                                                        type="date"
                                                        name={"creation_date_start"}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col>
                                                <FormGroup>
                                                    <Label>Date de fin</Label>

                                                    <Input
                                                        required
                                                        className="form-control digits"
                                                        type="date"
                                                        name={"creation_date_finish"}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Status</Label>
                                                    <select
                                                        className="form-control"
                                                        name="status"
                                                        placeholder="product"
                                                        value={formData.status}
                                                        onChange={(e) => onChange(e)}
                                                    >
                                                        <option value="" disabled defaultValue hidden>
                                                            ----------
                                                        </option>
                                                        <option value="nouveau">Nouveau</option>
                                                        <option value="payer">Payé</option>
                                                        <option value="livrer">Livré</option>
                                                        <option value="non_ramasse">Non ramassé</option>
                                                        <option value="anomalie">Anomalie</option>
                                                        <option value="retour">Retour</option>
                                                        <option value="en_cours_de_livraison">
                                                            En cours de livraison
                                                        </option>
                                                        <option value="retour_aux_sources">
                                                            Retour aux sources
                                                        </option>
                                                    </select>

                                                </FormGroup>
                                            </Col>
                                        </Row>


                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                        <FormGroup className="mb-0">
                                            <Button
                                                onClick={onSubmit}
                                                disabled={showLoader}
                                                className="btn btn-success mr-3"
                                                color={"success"}
                                                loading={showLoader}
                                                text={"Enregistrer"}
                                            />
                                            <br/>
                                            <br/>
                                        </FormGroup>
                                    </form>
                                </Col>

                            </CardHeader>
                            <Row>
                            </Row>
                            <CardBody>
                                <div className="table-responsive">
                                    <Table>
                                        <thead>
                                        <tr>
                                            <th style={{cursor: "pointer"}}
                                                id="id"
                                                title="id"
                                            >
                                                {"id"}
                                            </th>
                                            <th
                                                style={{cursor: "pointer"}}
                                                id="status"
                                                title="status"
                                                value={`${getStatus()}`}
                                            >
                                                {"status"}
                                            </th>
                                            <th
                                                title="type_de_feuille"
                                                style={{cursor: "pointer"}}
                                                scope="col"
                                                id="type_de_feuille"
                                            >
                                                {"type de feuille"}
                                            </th>
                                            <th
                                                title="demandé_par"
                                                style={{cursor: "pointer"}}
                                                scope="col"
                                                id="demandé_par"
                                            >
                                                {"demandé par"}
                                            </th>
                                            <th
                                                style={{cursor: "pointer"}}
                                                scope="col"
                                                id="client_address"
                                            >
                                                {"demandé à"}
                                            </th>
                                            <th
                                                title=""
                                                style={{cursor: "pointer"}}
                                                scope="col"
                                                id="client_telephone_1"
                                            >
                                                {"durée d'exportation"}
                                            </th>
                                            <th
                                                title=""
                                                style={{cursor: "pointer"}}
                                                scope="col"
                                                id="fournisseur_name"
                                            >
                                                {"Fichier"}
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {GeneratedData && GeneratedData.loading ?
                                            <div className="text-center">
                                                <img src={Spinner} style={{width: "10%"}} alt=""/>
                                            </div> : (
                                                GeneratedData.generated.map((item) => (
                                                    <tr>
                                                        <td>{item.id}</td>
                                                        <td>{item.status}</td>
                                                        <td>Excel</td>
                                                        <td>{item.username}</td>
                                                        <td>{item.started_at}</td>
                                                        <td>{item.duration} s</td>
                                                        <td>
                                                            <button disabled={!item.file}
                                                                    className="btn btn-success mr-3"
                                                                    onClick={() => window.open(`${item.file}`)}><i
                                                                className={"fa fa-download"}></i></button>
                                                        </td>
                                                    </tr>
                                                ))
                                            )
                                        }


                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                            {/*<CardFooter>*/}
                            {/*    <PaginationWrapper*/}
                            {/*        name="Colis"*/}
                            {/*        length={GenerateColis?.generated?.length}*/}
                            {/*        count={count}*/}
                            {/*        currentPage={pagenumber}*/}
                            {/*        onNavigate={Navigate}*/}
                            {/*    />*/}
                            {/*</CardFooter>*/}
                        </Card>
                    ) : (
                        ""
                    )}
                </Col>


            </Container>
        </Fragment>


    );
};
GenerateOrderPage.propTypes = {
    GenerateColis: PropTypes.func.isRequired,
    getGenerated: PropTypes.func.isRequired,
    getCurrentFournisseur: PropTypes.func.isRequired,
};


const mapstatetoprops = (state) => ({
    villeData: state.reducerc,
    villeDataf: state.reducerdvc,
    citeDataf: state.reducerdvc,
    fournisseurData: state.reducerf,
    financeData: state.reducerfin,
    citeData: state.reducerc,
    deligationData: state.reducerc,
    GeneratedData: state.reducerc,
    livreurData: state.reducerc,
    adresseData: state.reducerc,
});

export default connect(mapstatetoprops, {
    getVille,
    getVille1,
    getVilleF,
    getCite,
    GenerateColis,
    getCite1,
    getCiteF,
    getDeligation,
    getGenerated,
    getFournisseurs,
    getFinances,
    getLivreur,
    getadresseF,
})(withRouter(GenerateOrderPage));