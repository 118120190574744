import {
    RIGHT_BAR_RECLAMATION_ERROR,
    RIGHT_BAR_TRACKING_ERROR,
    RIGHT_BAR_TRACKING_LOADING,
    RIGHT_BAR_TRACKING_ACTIVITIES_LOADING,
    RIGHT_BAR_TRACKING_ACTIVITIES_ERROR,
    GET_RIGHT_BAR_TRACKING_ACTIVITIES,
    RIGHT_BAR_RECLAMATION_LOADING,
    GET_RIGHT_BAR_RECLAMATION,
    GET_RIGHT_BAR_TRACKING,
    GET_RIGHT_BAR_COMPTE,
    RIGHT_BAR_COMPTE_ERROR,
    RIGHT_BAR_COMPTE_LOADING,
} from "../../redux/actionTypes";
import {setAuthToken, api} from "../../utils/backend.instance";

export const getTracking = (params) => async (dispatch) => {
    dispatch({type: RIGHT_BAR_TRACKING_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(`/colis/tracking/?shown=False&${params.toString()}`);
        dispatch({
            type: GET_RIGHT_BAR_TRACKING,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: RIGHT_BAR_TRACKING_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getTrackingActivities = (params) => async (dispatch) => {
    dispatch({type: RIGHT_BAR_TRACKING_ACTIVITIES_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(`/colis/trackingactivities/?shown=False&${params.toString()}`);
        dispatch({
            type: GET_RIGHT_BAR_TRACKING_ACTIVITIES,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: RIGHT_BAR_TRACKING_ACTIVITIES_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};


export const updateTracking = (id, formData) => async (dispatch) => {
        if (localStorage.access) {
            setAuthToken(localStorage.access);
        }
        try {
            const res = await api.patch(`/colis/tracking/${id}/`, formData);
            dispatch({
                type: GET_RIGHT_BAR_TRACKING,
                payload: res.data,
            });
        } catch (err) {


            dispatch({
                type: RIGHT_BAR_TRACKING_ERROR,
                payload: {
                    msg: err.response.data,
                    status: err.response.status,
                },
            });
        }
    }
;

export const updateTrackingActivities = (id, formData) => async (dispatch) => {
        if (localStorage.access) {
            setAuthToken(localStorage.access);
        }
        try {
            const res = await api.patch(`/colis/trackingactivities/${id}/`, formData);
            dispatch({
                type: GET_RIGHT_BAR_TRACKING_ACTIVITIES,
                payload: res.data,
            });
        } catch (err) {


            dispatch({
                type: RIGHT_BAR_TRACKING_ACTIVITIES_ERROR,
                payload: {
                    msg: err.response.data,
                    status: err.response.status,
                },
            });
        }
    }
;

export const getReclamation = (query) => async (dispatch) => {
    dispatch({type: RIGHT_BAR_RECLAMATION_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(`/colis/reclamation/?${query.toString()}`);
        dispatch({
            type: GET_RIGHT_BAR_RECLAMATION,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: RIGHT_BAR_RECLAMATION_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getCurrentCompte = (id) => async (dispatch) => {
    dispatch({type: RIGHT_BAR_COMPTE_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(`/colis/user/${id}/`);

        dispatch({
            type: GET_RIGHT_BAR_COMPTE,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: RIGHT_BAR_COMPTE_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};
