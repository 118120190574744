import React, {Fragment, useState, useEffect} from "react";
import {
    Col,
    Row,
} from "reactstrap";
import {Link} from "react-router-dom";
import Nava from "./components/Navbar";
import Footer from "./components/footer";
import step1 from "./images/step-1.png";
import step2 from "./images/step-2.png";
import step3 from "./images/step-3.png";
import step4 from "./images/step-4.png";
import i1 from "./images/i1.png";
import i2 from "./images/i2.png";
import i3 from "./images/i3.png";
import i4 from "./images/i4.png";
import i5 from "./images/i5.png";
import i6 from "./images/i6.png";
import i7 from "./images/i7.png";
import i8 from "./images/i8.png";

const About = (props) => {
    const [slide, setSlide] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const Slide = () => {
        if (!slide) {
            return <h1 style={{color:"#FFC107", fontSize:"50px"}}>Connect</h1>
        } else {
            return <h1 style={{color:"#FFC107", fontSize:"50px"}}>Ship</h1>
        }
    }
    useEffect(() => {
    const timer = setTimeout(() => {
      setSlide(!slide)
    }, 1500);
    return () => clearTimeout(timer);
  }, [Slide, slide]);
    return (
        <Fragment>
            <div style={{backgroundColor: "#F8F9FE", fontFamily: "Raleway" }}>
                <Nava/>
                <br/>
               <br/>
                <Row>
                   <Col sm={2}   ></Col>
                      <Col xs={12} sm={12} lg={4} style={{padding : "50px" , textAlign: "center"}}>
                      
                      <h1 style={{fontWeight : "600" , color : "#F2B707"}}>Connect.</h1>
                      <h6>Dans un premier temps, vous allez connecter votre boutique à notre plateforme</h6>
                      <h3>Envoyez votre inventaire et annoncez les stocks entrants.</h3>
                      <p>Nous nous chargerons de le stocker correctement et efficacement - l'une de nos activités favorites</p>
                      
                      </Col>

                      <Col xs={12} sm={12} lg={4}>
                      <img src={step1} height={"400px"} style={{maxWidth : "100%" , display : "block"}} alt={""}/>
                      </Col>
                      <Col sm={1}></Col>
            
                </Row>
                <br/>
                <br/>
                <br/>
             
               <Row>
                <Col sm={2}></Col>
                <Col sm={8} md={4} >
                <img src={step2} height={"400px"} style={{maxWidth : "100%" , display : "block"}} alt={""}/>
                </Col>

                <Col sm={8} md={4}  style={{paddingTop : "50px" , textAlign: "center"}}>
                    
                <h1 style={{fontWeight : "600" , color : "#F2B707"}} >Store.</h1>
                      <h6>Envoyez votre inventaire et annoncez les stocks entrants.</h6>
                      <h3>Envoyez votre inventaire et annoncez les stocks entrants.</h3>
                      <p>Nous nous chargerons de le stocker correctement et efficacement - l'une de nos activités favorites</p>
                      
                </Col>
                <Col sm={2}></Col>

               </Row>
               <br/>
               <br/>
               <br/>
           
                <Row>
                    <Col sm={2}></Col>
                    <Col sm={8} md={4} style={{paddingTop : "50px" , textAlign: "center"}}     >
                    <h1 style={{fontWeight : "600" , color : "#F2B707"}} >Ship.</h1>
                      <h6>Nous préparons, emballons et expédions toutes les commandes entrantes directement depuis notre propre entrepôt</h6>
                      <h3>On s'en occupe</h3>
                      <p>Nous préparons, emballons et expédions vos commandes jusqu'à 12 heures le jour même, ce qui permet une livraison le lendemain à vos clients</p>
                    </Col>

                    <Col sm={8} md={4}>
                    <img src={step3} height={"400px"} style={{maxWidth : "100%" , display : "block"}} alt={""}/>
                    </Col>

               
                </Row>
                <br/>
                <br/>
                <br/>
                <Row>
                    <Col sm={1}></Col>
                <Col  sm={8} md={4}>
                <img src={step4} height={"400px"} style={{maxWidth : "100%" , display : "block"}} alt={""}/>
                </Col>

                <Col  style={{paddingTop : "60px" , textAlign: "center"}}>
                <h1 style={{fontWeight : "600" , color : "#F2B707"}}>Grow.</h1>
                <h6>Envoyez votre inventaire et annoncez les stocks entrants.</h6>
                      <h3>Obtenez des informations sur le site qui vous intéresse</h3>
                      <p>Aperçu des commandes, prévisions de ruptures de stock et de croissance, et bien plus encore, directement dans notre application web</p>
                    
                </Col>
                <Col sm={2}></Col>
 
            </Row>
                <div className="page-section-heading text-center  jn mt-4 " style={{backgroundColor : "white"}}>
                    <br/>
                    <br/>
                    <h1
                        style={{
                            position: "relative",
                            fontSize: "30px",
                            fontWeight: "bold",
                            color: "#F4D56D",
                        }}
                    >
                        Notre role
                    </h1>
                    <br/>
                    <p
                        style={{
                            position: "relative",
                            fontSize: "15px",
                            fontWeight: "bold",
                        }}
                    >
                        Beezit s'occupe de tous les échanges entre les e-commerçants et les
                        sociétés de livraison afin de vous faire gagner <br/> du temps, de
                        l'argent et de l'énergie
                    </p>
                    <br/>
                    <br/>
                    <br/>
                    <Row>
                        <Col  sm={2} md={0} lg={2}></Col>

                        <Col sm={2} md={3} lg={2}>
                        <img src={i1} height={"100px"} alt={""}/>
                        <h4>Simplicité</h4>
                        </Col>
                        <Col sm={2} md={3} lg={2}>
                        <img src={i2} height={"100px"} alt={""}/>
                        <h4>livraison Express</h4>
                        </Col>
                        <Col sm={2} md={3} lg={2}>
                        <img src={i3} height={"100px"} alt={""}/>
                        <h4>Payement Sécurisé</h4>
                        </Col>
                        <Col sm={2} md={3} lg={2}>
                        <img src={i4} height={"100px"} alt={""}/>
                        <h4>Assistance</h4>
                        </Col>
                        <Col sm={2} md={0} lg={2}>*</Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col  sm={2} md={0} lg={2}></Col>

                        <Col sm={2} md={3} lg={2}>
                        <img src={i5} height={"100px"} alt={""}/>
                        <h4>Stockage et gestion</h4>
                        </Col>
                        <Col sm={2} md={3} lg={2}>
                        <img src={i6} height={"100px"} alt={""}/>
                        <h4>E-payment</h4>
                        </Col>
                        <Col sm={2} md={3} lg={2}>
                        <img src={i7} height={"100px"} alt={""}/>
                        <h4>Meilleur service</h4>
                        </Col>
                        <Col sm={2} md={3} lg={2}>
                        <img src={i8} height={"100px"} alt={""}/>
                        <h4>Support 24H / 7</h4>
                        </Col>
                        <Col sm={2} md={0} lg={2}>*</Col>
                    </Row>
                    <br/>
                    <br/>
                    <h2
                        style={{
                            position: "relative",
                            fontSize: "40px",
                            fontWeight: "bold",
                            color: "#F4D56D",
                        }}
                    >
                        JOIN US
                    </h2>
                    <br/>
                    <Link
                        to={"/partner"}
                        className="btn"
                        style={{
                            fontSize: "25px",
                            fontWeight: "bold",
                            color: "white",
                            borderRadius: "30px",
                            backgroundColor: "#FFC107",
                        }}
                    >
                        Devenir Partenaire <i className="fa fa-arrow-right"></i>
                    </Link>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
                <Footer/>
            </div>
        </Fragment>
    );
};

About.propTypes = {};

export default About;
