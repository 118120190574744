import React, {useState, useContext} from "react";
import {connect, useDispatch} from "react-redux";
import PropTypes from "prop-types";
import Alert from "../redux/Alert";
import {
    Container,
    Row,
    Col,
    FormGroup,
    Label,
} from "reactstrap";
import {Link, Redirect, useHistory} from "react-router-dom";
import {loadUser, login} from "../redux/signin/action";
import {withRouter} from "react-router-dom";
import {
    Password,
} from "../constant";
import AppContext from "../context/Context";
import {setAlert} from "../redux/alert/action";

const Logins = () => {
    const {
        config: {isAuthenticated},
        setConfig
    } = useContext(AppContext);
    const dispatch = useDispatch();
    const [togglePassword, setTogglePassword] = useState(false);
    const [formData, setFormData] = useState({
        username: "",
        password: "",
    });
    const {username, password} = formData;
    const history = useHistory()

    const handleLogin = (e) => {
        e.preventDefault()
        login(username, password)
            .then(res => {
                setConfig('refresh', res?.data?.refresh)
                loadUser(res?.data?.refresh)
                    .then(res => {
                        setConfig('access', res?.data.access)
                        setConfig('user_id', res?.data?.user?.id)
                        setConfig('stock_service', res?.data?.stock_service)
                        setConfig('group', res?.data?.groups_name)
                        setConfig('avatar', res?.data?.user?.fournisseur_image_path)
                        setConfig('fournisseur', res?.data?.user?.fournisseur)
                        setConfig('username', res?.data?.user?.username)
                        setConfig('accept_terms', res?.data?.accept_terms)
                        setConfig('isAuthenticated', true)
                        history.push('/dashboard')
                    })
            })
            .catch(() => dispatch(setAlert("invalid credentials", "danger")))
    }

    const onChange = (e) =>
        setFormData({...formData, [e.target.name]: e.target.value});

    const onSubmit = async (e) => {
        e.preventDefault();
        login(username, password);
    };

    // redirect if logged in

    if (isAuthenticated) {
        return <Redirect to="/dashboard"/>;
    }

    return (
        <Container fluid={true} className="p-0">
            <Alert/>
            <Row>
                <Col xs="12">
                    <div className="login-card">
                        <div>
                            <div>
                                <Link className="logo" to={"/"}>
                                    <img
                                        className="image"
                                        src={require("../assets/images/logo/login.png")}
                                        alt=""
                                    />
                                    <img
                                        className="img-fluid for-dark"
                                        src={require("../assets/images/logo/logo_dark.png")}
                                        alt=""
                                    />
                                </Link>
                            </div>
                            <div className="login-main login-tab">
                                <form className="theme-form" onSubmit={(e) => onSubmit(e)}>
                                    <p>{"Enter your email & password to login"}</p>
                                    <FormGroup>
                                        <Label className="col-form-label">Username</Label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Username"
                                            name="username"
                                            value={username}
                                            onChange={(e) => onChange(e)}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="col-form-label">{Password}</Label>
                                        <input
                                            className="form-control"
                                            type={togglePassword ? "text" : "password"}
                                            name="password"
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) => onChange(e)}
                                        />
                                        <div
                                            className="show-hide"
                                            onClick={() => setTogglePassword(!togglePassword)}
                                        >
                                        </div>
                                    </FormGroup>
                                    <div className="form-group mb-3">
                                        <button
                                            type="submit"
                                            className="btn-block p-2 rounded-lg btn-primary"
                                            onClick={(e) => handleLogin(e)}
                                        >
                                            Sign in
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

login.PropTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.object.isRequired,
    loading: PropTypes.object.isRequired,
};

const mapStateToPorps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    loading: state.auth.loading,
});

export default connect(mapStateToPorps, {login})(withRouter(Logins));
