import {
  GET_RECLAMATION,
  GET_RECLAMATIONS,
  RECLAMATION_LOADING,
  RECLAMATION_ERROR,
  UPDATE_RECLAMATION,
  GET_REPONSE,
  GET_REPONSES,
  REPONSE_LOADING,
  REPONSE_ERROR,
  UPDATE_REPONSE,
} from "../../redux/actionTypes";

const initial_state = {
  reponse: null,
  count: 0,
  reponses: [],
  reclamation: null,
  reclamations: [],
  loading: false,
  error: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initial_state, action) {
  const { type, payload } = action;
  switch (type) {
    case REPONSE_LOADING:
    case RECLAMATION_LOADING:
      return {
        ...state,
        loading: true,
      };
    case REPONSE_ERROR:
    case RECLAMATION_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case UPDATE_RECLAMATION:
      return {
        ...state,
        reclamation: payload,
        loading: false,
      };
    case UPDATE_REPONSE:
      return {
        ...state,
        reponse: payload,
        loading: false,
      };
    case GET_REPONSE:
      return {
        ...state,
        reponse: payload,
        loading: false,
      };
    case GET_RECLAMATION:
      return {
        ...state,
        reclamation: payload,
        loading: false,
      };
    case GET_REPONSES:
      return {
        ...state,
        reponses: payload.results,
        loading: false,
      };
    case GET_RECLAMATIONS:
      return {
        ...state,
        count: payload.count,
        reclamations: payload.results,
        loading: false,
      };

    default:
      return state;
  }
}
