import {forwardRef, Fragment, useImperativeHandle, useState} from "react";
import {Col, Form, Input, Label, Row} from "reactstrap";
import * as XLSX from 'xlsx';
import {api} from "../../../utils/backend.instance";
import {useDispatch} from "react-redux";
import {setAlertD} from "../../../redux/alert/action";

const Upload = forwardRef(({setReportId, file, setFile, setFileColumns, formData, jumpToStep}, ref) => {
        const [valid, setValid] = useState(false);
        const [uiError, setUiError] = useState(false);

        const dispatch = useDispatch()

        const toggleValidState = () => {
            setUiError(false);
            setValid(true);
        }

        const extractColumns = (file) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const data = event.target.result;
                const workbook = XLSX.read(data, {type: 'binary'});
                const firstWorksheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(firstWorksheet, {header: 1});
                const columns = jsonData[0]; // Assuming the first row of your Excel file contains the column names
                setFileColumns(columns);
            };
            reader.readAsBinaryString(file);
        };

        const onChangeHandler = (e) => {
            const selectedFile = e.target.files[0];

            if (selectedFile) {
                setFile(selectedFile);
                extractColumns(selectedFile);
            }

            // set the file's name to parent's formData state
            formData.file = selectedFile.name;
            toggleValidState();
        };

        useImperativeHandle(ref, () => ({
            async isValidated() {
                if (!valid) {
                    setUiError(true);
                    return false;
                } else {
                    const fd = new FormData()
                    fd.append("file", file)
                    fd.append("livreur", formData.livreur)
                    await api.post("/fournisseur/import-frais/", fd)
                        .then(res => setReportId(res?.data?.id))
                        .catch((err) => dispatch(setAlertD("Un erreur est survenue " + err.toString(), "danger", 1000)))
                    return true;
                }
            }
        }));

        return (
            <Fragment>
                <Row>
                    <Col sm="12">
                        <Form className="needs-validation">
                            <div className="form-row">

                                <Col md="12 mb-3">
                                    <Label>Fichier</Label>
                                    <Input
                                        type={"file"}
                                        name="file"
                                        className="form-control"
                                        rows="1"
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                        onChange={onChangeHandler}
                                    />
                                    {!valid && <><br/><br/><span
                                        className="text-danger">Un fichier valide est requis</span></>}
                                </Col>
                                {uiError &&
                                    <div className="val-err-tooltip" style={{marginTop: '1rem', display: 'table'}}></div>}
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Fragment>
        )
    }
)

export default Upload