// dashbaord
import Default from "../components/dashboard/default";

//Colis
import Coli from "../components/colis/coli";
import Newcoli from "../components/colis/newColi";
import Editcoli from "../components/colis/editcolis";
import GestionColi from "../components/colis/gestiondescolis";
import Manifestecolis from "../components/colis/manifestecolis";
import Deletecolis from "../components/colis/deletecolis";
import GenerateOrderPage from "../components/colis/generateOrder"


//profile
import Profile from "../components/profile/profile";

//Commercial
import Fournisseur from "../components/Fournisseurs/fournisseur";
import EditFournisseur from "../components/Fournisseurs/editfournisseur";
import AddFournisseur from "../components/Fournisseurs/addfournisseur";
import Reclamation from "../components/reclamation/reclamation";
import History from "../components/colis/history";
import HistoryDetail from "../components/colis/historydetail";

//Gestion
import Sector from "../components/secteuractivite/sector";
//Finance
import Finance from "../components/finance/finance";
import Financeexport from "../components/finance/financeexport";
import Fournisseurfinance from "../components/finance/fournisseurfinance";
import Ifl from "../components/importFraisLivreur/ifl"

//Stock
import Produit from "../components/stock/produit";
import Stcokdepot from "../components/stock/stcokdepot";
import AddStock from "../components/stock/addStock";
import Editstock from "../components/stock/editstock";
import StockActivity from "../components/stock/history";

//User
import Compte from "../components/compte/compte";
import Newcompte from "../components/compte/newcompte";
import Group from "../components/group/group";
import Editcompte from "../components/compte/editcompte";
import Detailcolis from "../components/colis/detailcolis";
import DetailDemande from "../components/demandes/detail";
import Demandes from "../components/demandes/demandes";

// Adresse
import Adresse from "../components/adresse/adresse";
import NewAdress from "../components/adresse/newAdress";
import EditAdress from "../components/adresse/editAdresse";
import Colilog from "../components/colis/colilog";
import Avis from "../components/avis/avis";
import News from "../components/news/news";
import NewNews from "../components/news/newNews";
import EditNew from "../components/news/editNew";
import newAvis from "../components/avis/newAvis";
import ProcessIfl from "../components/importFraisLivreur/processImport/ProcessIfl";
import {Analytiques} from "../components/analytiques";

//Generate

import Generate from "../components/generate/generate";


export const routes = [
    {path: "/dashboard", Component: Default},
    {path: "/analytics", Component: Analytiques},
    {path: "/colis", Component: Coli},
    {path: "/colis/new", Component: Newcoli},
    {path: "/colis/edit/:id", Component: Editcoli},
    {path: "/colis/detail/:id", Component: Detailcolis},
    {path: "/gestion-colis", Component: GestionColi},
    {path: "/colis/:id/history", Component: Colilog},
    {path: "/manifeste-colis", Component: Manifestecolis},
    {path: "/supprimer-colis", Component: Deletecolis},
    {path: "/fournisseur", Component: Fournisseur},
    {path: "/fournisseur/edit/:id", Component: EditFournisseur},
    {path: "/fournisseur/new", Component: AddFournisseur},
    {path: "/reclamation", Component: Reclamation},
    {path: "/secteur-activite", Component: Sector},
    {path: "/compte", Component: Compte},
    {path: "/compte/new", Component: Newcompte},
    {path: "/group", Component: Group},
    {path: "/finance", Component: Finance},
    {path: "/import-frais-livreurs", Component: Ifl},
    {path: "/import-frais-livreurs/process", Component: ProcessIfl},
    {path: "/finance/:id", Component: Financeexport},
    {path: "/finance_f/:id", Component: Fournisseurfinance},
    {path: "/profile/:id", Component: Profile},
    {path: "/stock_depot", Component: Stcokdepot},
    {path: "/produit", Component: Produit},
    {path: "/stock_depot/new", Component: AddStock},
    {path: "/compte/edit/:id", Component: Editcompte},
    {path: "/stock_depot/edit/:id", Component: Editstock},
    {path: "/demande", Component: Demandes},
    {path: "/adresse", Component: Adresse},
    {path: "/avis", Component: Avis},
    {path: "/avis/new", Component: newAvis},
    {path: "/adresse/new", Component: NewAdress},
    {path: "/adresse/edit/:id", Component: EditAdress},
    {path: "/demande/detail/:id", Component: DetailDemande},
    {path: "/colis/history", Component: History},
    {path: "/colis/history/:id", Component: HistoryDetail},
    {path: "/colis-export", Component: GenerateOrderPage},
    {path: "/stock_depot/activity", Component: StockActivity},
    {path: "/news", Component: News},
    {path: "/news/new", Component: NewNews},
    {path: "/news/edit/:id", Component: EditNew},
    {path: "/generate", Component: Generate},
];
