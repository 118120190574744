import React, { Fragment, useState, useMemo } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import Alert from "../../redux/Alert";
import {useLocation} from "react-router-dom";
import CustomPaginationWrapper from "../pagination/custompagination";
import { Container, FormGroup, Input, Button, Row, Col, Card, Table, CardBody } from "reactstrap";
import Spinner from "../colis/spinner.gif"; // Import the Spinner image
import * as XLSX from 'xlsx';

const Generate = () => {
    const [loading, setLoading] = useState(false);
    // const [pagesize, setPageSize] = useState(null)
    const [nextpage, setNextpage] = useState(null);
    const [selectedFields, setSelectedFields] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [data, setData] = useState(null); // Define 'data' in component state
    const [apiCalled, setApiCalled] = useState(false); // Define 'apiCalled' in component state

    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };


    let query = useQuery();
    // let params = new URLSearchParams(document.location.search);
    // let status = params.get("status");

    // let pagenumber = query.get("page") ? query.get("page") : 1;
    let pagenumber = 1
    if(nextpage){
        const searchParams = new URLSearchParams(nextpage.split('?')[1]);
        pagenumber = searchParams.get("page")-1;
    }

    const formatDateTime = (timestamp) => {
        const date = new Date(timestamp);
        const formattedDate = date.toLocaleDateString(); // Get date in "MM/DD/YYYY" format
        const formattedTime = date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}); // Get time in "HH:MM" format
        return `${formattedDate} ${formattedTime}`;
    }


    // Extract the page number
    
    const Navigate = (page) => {
        handlePagination()
    };

    const handlePagination = () => {
        if(nextpage){
            let decodedNextpage = nextpage ? decodeURIComponent(nextpage) : null;
            if (decodedNextpage && decodedNextpage.startsWith("http://")) {
                decodedNextpage = decodedNextpage.replace("http://", "https://");
            }
            fetch(`${decodedNextpage}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                },
            }).then(response => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then(data => {
                // Handle the API response data here
                console.log(data);
                setNextpage(data.next)
                setData(data); // Set the API response data in state
                setApiCalled(true); // Set the API response data in state
            })
            .catch(error => {
                // Handle errors here
                console.error("There was a problem with the fetch operation:", error);
            })
            .finally(() => {
                setLoading(false);
            });
        }
    }


    const handleGenerate = () => {
        setLoading(true);
        let baseURL = process.env.REACT_APP_API_URL;
        let requestData = "";
        if(toDate !== "" && fromDate !== ""){
            requestData =`fields=${selectedFields.join(',')}&created_at__gte=${fromDate}&created_at__lte=${toDate}`
        } else {
            requestData =`fields=${selectedFields.join(',')}`
        }
        requestData = query.get("page") ? `${requestData}&page=${query.get("page")}` : `${requestData}&page=1`;
        requestData = `${requestData}&page_size=100`
        fetch(`${baseURL}/colis/generate-value/?${requestData}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
        }).then(response => {
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            return response.json();
        })
        .then(data => {
            // Handle the API response data here
            console.log(data);
            setNextpage(data.next)
            setData(data); // Set the API response data in state
            setApiCalled(true); // Set the API response data in state
        })
        .catch(error => {
            // Handle errors here
            console.error("There was a problem with the fetch operation:", error);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const exportToCsv = () => {
        const csvRows = [];
    
        // Header row
        const headerRow = selectedFields.map(field => {
          switch (field) {
            case "code_a_barre":
              return "Code à barre";
            case "created_at":
              return "Date d'ajout";
            case "frais_livraison":
              return "Frais de livraison";
            case "frais_retour":
              return "Frais de retour";
            case "cod":
              return "COD";
            case "service":
              return "Service";
            case "note":
              return "Note";
            case "products":
              return "Produits";
            case "status":
              return "LAST STATUS";
            case "client_prenom":
              return "Nom client";
            case "client_name":
              return "Client name";
            case "client_address_pickup":
              return "Adresse";
            case "client_ville":
              return "Ville";
            case "client_deligaiton":
              return "Délegation";
            case "client_telephone_1":
              return "Téléphone";
            case "client_telephone_2":
              return "Téléphone 2";
            case "client_email":
              return "Email";
            case "client_zipcode":
              return "Code postal";
            case "client_cite":
              return "Cité";
            case "client_address":
              return "Adresse complète";
            case "name":
              return "Name";
            case "email":
              return "Email";
            case "livreur":
              return "SALES AGENT";
            case "rib":
              return "RIB";
            case "mf":
              return "MF";
            case "tel_fournisseur":
              return "Téléphone fournisseur";
            case "adresse_pickup_fournisseur":
              return "Adresse fournisseur";
            case "expiditeur_ville":
              return "Ville expéditeur";
            case "expiditeur_cite":
              return "Cité expéditeur";
            case "expiditeur_deligaiton":
              return "Délegation expéditeur";
            case "expiditeur_zipcode":
              return "Code postal expéditeur";
            case "remarque_echange":
              return "Remarque échange";
            case "prix":
              return "Prix";
            case "volume":
              return "Volume";
            case "related_payment":
              return "Paiement associé";
            default:
              return "";
          }
        });
        csvRows.push(headerRow.join(','));
    
        // Data rows
        data.results.forEach(item => {
          const rowData = selectedFields.map(field => {
            switch (field) {
              case "code_a_barre":
                return item.code_a_barre || "";
              case "created_at":
                return formatDateTime(item.created_at) || "";
              case "frais_livraison":
                return item.fournisseur.grand_livraison || "";
              case "frais_retour":
                return item.fournisseur.grand_retour || "";
              case "cod":
                return item.prix || "";
              case "service":
                return item.service || "";
              case "note":
                return item.remarque || "";
              case "products":
                return item.product_name || "";
              case "status":
                return item.status || "";
              case "client_prenom":
                return item.client_prenom || "";
              case "client_name":
                return item.client_name || "";
              case "client_address_pickup":
                return item.client_address_pickup || "";
              case "client_ville":
                return item.client_ville.name || "";
              case "client_deligaiton":
                return item.client_deligaiton.name || "";
              case "client_telephone_1":
                return item.client_telephone_1 || "";
              case "client_telephone_2":
                return item.client_telephone_2 || "";
              case "client_email":
                return item.client_email || "";
              case "client_zipcode":
                return item.client_zipcode || "";
              case "client_cite":
                return item.client_cite.name || "";
              case "client_address":
                return item.client_address || "";
              case "name":
                return item.fournisseur.name || "";
              case "email":
                return item.fournisseur.email || "";
              case "livreur":
                return item.fournisseur.livreur || "";
              case "rib":
                return item.fournisseur.rib || "";
              case "mf":
                return item.fournisseur.mf || "";
              case "tel_fournisseur":
                return item.fournisseur.tel || "";
              case "adresse_pickup_fournisseur":
                return item.fournisseur.adresse || "";
              case "expiditeur_ville":
                return item.expiditeur_ville.name || "";
              case "expiditeur_cite":
                return item.expiditeur_cite.name || "";
              case "expiditeur_deligaiton":
                return item.expiditeur_deligaiton.name || "";
              case "expiditeur_zipcode":
                return item.expiditeur_zipcode || "";
              case "remarque_echange":
                return item.remarque_echange || "";
              case "prix":
                return item.prix || "";
              case "volume":
                return item.volume || "";
              case "related_payment":
                return item.related_payment || "";
              default:
                return "";
            }
          });
          csvRows.push(rowData.join(','));
        });
    
        // Combine rows into a single CSV content
        const csvContent = csvRows.join('\n');
    
        // Trigger download
        const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "table_data.csv");
        document.body.appendChild(link);
        link.click();
    };

    const exportToExcel = () => {
      // Prepare selected data based on selected rows and fields
      const selectedData = data.results.map(item => {
          return selectedFields.map(field => {
              switch (field) {
                  case "code_a_barre":
                      return item.code_a_barre || "";
                  case "created_at":
                      return formatDateTime(item.created_at) || "";
                  case "frais_livraison":
                      return item.fournisseur.grand_livraison || "";
                  case "frais_retour":
                      return item.fournisseur.grand_retour || "";
                  case "cod":
                      return item.prix || "";
                  case "service":
                      return item.service || "";
                  case "note":
                      return item.remarque || "";
                  case "products":
                      return item.product_name || "";
                  case "status":
                      return item.status || "";
                  case "client_prenom":
                      return item.client_prenom || "";
                  case "client_name":
                      return item.client_name || "";
                  case "client_address_pickup":
                      return item.client_address_pickup || "";
                  case "client_ville":
                      return item.client_ville.name || "";
                  case "client_deligaiton":
                      return item.client_deligaiton.name || "";
                  case "client_telephone_1":
                      return item.client_telephone_1 || "";
                  case "client_telephone_2":
                      return item.client_telephone_2 || "";
                  case "client_email":
                      return item.client_email || "";
                  case "client_zipcode":
                      return item.client_zipcode || "";
                  case "client_cite":
                      return item.client_cite.name || "";
                  case "client_address":
                      return item.client_address || "";
                  case "name":
                      return item.fournisseur.name || "";
                  case "email":
                      return item.fournisseur.email || "";
                  case "livreur":
                      return item.fournisseur.livreur || "";
                  case "rib":
                      return item.fournisseur.rib || "";
                  case "mf":
                      return item.fournisseur.mf || "";
                  case "tel_fournisseur":
                      return item.fournisseur.tel || "";
                  case "adresse_pickup_fournisseur":
                      return item.fournisseur.adresse || "";
                  case "expiditeur_ville":
                      return item.expiditeur_ville.name || "";
                  case "expiditeur_cite":
                      return item.expiditeur_cite.name || "";
                  case "expiditeur_deligaiton":
                      return item.expiditeur_deligaiton.name || "";
                  case "expiditeur_zipcode":
                      return item.expiditeur_zipcode || "";
                  case "remarque_echange":
                      return item.remarque_echange || "";
                  case "prix":
                      return item.prix || "";
                  case "volume":
                      return item.volume || "";
                  case "related_payment":
                      return item.related_payment || "";
                  default:
                      return "";
              }
          });
      });
  
      // Create a new workbook
      const workbook = XLSX.utils.book_new();
  
      // Convert selected data to worksheet
      const worksheet = XLSX.utils.aoa_to_sheet([selectedFields, ...selectedData]);
  
      // Add worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  
      // Save the workbook to an XLSX file
      XLSX.writeFile(workbook, 'table_data.xlsx');
  };

    

    return (
        <Fragment>
            <Breadcrumb parent="Colis" title="Generate"/>
            <div>
                <Container fluid={true}>
                    <Alert/>
                    <div className="d-flex align-items-center">
                        {/* Multi-selectable field */}
                        <FormGroup style={{ width: "20%"}} className="mr-4">
                            <Input type="select" multiple 
                            style={{ height: "700px"}}
                            onChange={(e) => setSelectedFields(Array.from(e.target.selectedOptions, option => option.value))}
                            >
                                <optgroup label="Colis -- " class="text-green-500 font-bold">
                                    <option value="code_a_barre"> Code à barre</option>
                                    <option value="created_at"> Date d'ajout</option>
                                    <option value="frais_livraison"> Frais de livraison</option>
                                    <option value="frais_retour"> Frais de retour</option>
                                    <option value="cod"> COD</option>
                                    <option value="service"> Service</option>
                                    <option value="note"> Note</option>
                                    <option value="products"> Produits</option>
                                    <option value="status"> LAST STATU</option>
                                </optgroup>
                                <optgroup label="Client -- " class="text-green-500 font-bold">
                                    <option value="client_prenom"> Nom client</option>
                                    <option value="client_name"> Client name</option>
                                    <option value="client_address_pickup"> Adresse</option>
                                    <option value="client_ville"> Ville</option>
                                    <option value="client_deligaiton"> Délegation</option>
                                    <option value="client_telephone_1"> Téléphone</option>
                                    <option value="client_telephone_2"> Téléphone 2</option>
                                    <option value="client_email"> Email</option>
                                    <option value="client_zipcode"> Code postal</option>
                                    <option value="client_cite"> Cité</option>
                                    {/* <option value="client_filiere"> Filière</option> */}
                                    <option value="client_address"> Adresse complète</option>
                                </optgroup>
                                <optgroup label="Expiditeur -- " class="text-green-500 font-bold">
                                    {/* <option value="nom_fournisseur"> Nom société</option> */}
                                    <option value="name"> Name</option>
                                    <option value="email"> Email</option>
                                    <option value="livreur"> SALES AGENT</option>
                                    <option value="rib"> RIB</option>
                                    <option value="mf"> MF</option>
                                    <option value="tel_fournisseur"> Téléphone</option>
                                    <option value="adresse_pickup_fournisseur"> Adresse</option>
                                    <option value="expiditeur_ville"> Ville</option>
                                    <option value="expiditeur_cite"> Cité</option>
                                    <option value="expiditeur_deligaiton"> Délegation</option>
                                    <option value="expiditeur_zipcode"> Code postal</option>
                                </optgroup>
                                <optgroup label="Autres -- " class="text-green-500 font-bold">
                                    <option value="remarque_echange"> Remarque échange</option>
                                    <option value="prix"> Prix</option>
                                    <option value="volume"> Volume</option>
                                    <option value="related_payment"> Paiement associé</option>
                                </optgroup>
                            </Input>
                        </FormGroup>

                        {/* From Date */}
                        <FormGroup className="mr-4">
                            <Input
                                type="date"
                                value={fromDate}
                                onChange={(e) => setFromDate(e.target.value)}
                            />
                        </FormGroup>

                        {/* To Date */}
                        <FormGroup className="mr-4">
                            <Input
                                type="date"
                                value={toDate}
                                onChange={(e) => setToDate(e.target.value)}
                            />
                        </FormGroup>

                        {/* Button */}
                        <FormGroup>
                            <Button color="primary" onClick={handleGenerate} disabled={loading}>
                                {loading ? 'Generating...' : 'Generate'}
                            </Button>
                        </FormGroup>
                    </div>
                </Container>
            </div>
            {apiCalled ? (
                <div>
                    <Container fluid={true}>
                        <Alert/>
                        <Row>
                            <Col sm="12">
                                <Card>
                                    {/* {datacolis.colis.length > 0 ? () : ()} */}
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <button
                                                    className="btn text-white m-2"
                                                    style={{backgroundColor: "#C725C7"}}
                                                    title="CSV"
                                                    onClick={exportToCsv}
                                                >
                                                    CSV
                                                </button>
                                                <button
                                                    className="btn text-white m-2"
                                                    style={{backgroundColor: "#C725C7"}}
                                                    title="EXCEL"
                                                    onClick={exportToExcel}
                                                >
                                                    EXCEL
                                                </button>
                                            </Col>
                                        </Row>
                                        <Col sm="12">
                                            <Card>
                                                <div className="card-block row">
                                                    <Col sm="12" lg="12" xl="12">
                                                        {data && data.loading ? (
                                                            <div className="text-center">
                                                                <img src={Spinner} style={{width: "10%"}} alt=""/>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                              <Table className="table table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        {selectedFields.includes("code_a_barre") ?(
                                                                            <th>Code à barre</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("created_at")?(
                                                                            <th>Date d'ajout</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("frais_livraison")?(
                                                                            <th>Frais de livraison</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("frais_retour")?(
                                                                            <th>Frais de retour</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("cod") ?(
                                                                            <th>COD</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("service") ?(
                                                                            <th>Service</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("note") ?(
                                                                            <th>Note</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("products") ?(
                                                                            <th>Produits</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("status") ?(
                                                                            <th>LAST STATUS</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("client_prenom")?(
                                                                            <th>Nom client</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("client_name")?(
                                                                            <th>Client name</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("client_address_pickup")?(
                                                                            <th>Adresse</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("client_ville")?(
                                                                            <th>Ville</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("client_deligaiton")?(
                                                                            <th>Délegation</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("client_telephone_1")?(
                                                                            <th>Téléphone</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("client_telephone_2")?(
                                                                            <th>Téléphone 2</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("client_email")?(
                                                                            <th>Email</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("client_zipcode")?(
                                                                            <th>Code postal</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("client_cite")?(
                                                                            <th>Cité</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {/* {selectedFields.includes("client_filiere")?(
                                                                            <th>Filière</th>
                                                                        ):(<></>)
                                                                        } */}
                                                                        {selectedFields.includes("client_address")?(
                                                                            <th>Adresse complète</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("name")?(
                                                                            <th>Name</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("email")?(
                                                                            <th>Email</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("livreur")?(
                                                                            <th>SALES AGENT</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("rib")?(
                                                                            <th>RIB</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("mf")?(
                                                                            <th>MF</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("tel_fournisseur")?(
                                                                            <th>Téléphone</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("adresse_pickup_fournisseur")?(
                                                                            <th>Adresse</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("expiditeur_ville")?(
                                                                            <th>Ville</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("expiditeur_cite")?(
                                                                            <th>Cité</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("expiditeur_deligaiton")?(
                                                                            <th>Délegation</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("expiditeur_zipcode")?(
                                                                            <th>Code postal</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("remarque_echange")?(
                                                                            <th>Remarque échange</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("prix")?(
                                                                            <th>Prix</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("volume")?(
                                                                            <th>Volume</th>
                                                                        ):(<></>)
                                                                        }
                                                                        {selectedFields.includes("related_payment")?(
                                                                            <th>Paiement associé</th>
                                                                        ):(<></>)
                                                                        }
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                {data && data.results.map((item, index) => (
                                                                    <tr key={index}>
                                                                        {selectedFields.includes("code_a_barre") && (
                                                                            <td>{item.code_a_barre}</td>
                                                                        )}
                                                                        {selectedFields.includes("created_at") && (
                                                                            <td>{formatDateTime(item.created_at)}</td>
                                                                        )}
                                                                        {selectedFields.includes("frais_livraison") && (
                                                                            <td>{item.fournisseur.grand_livraison}</td>
                                                                        )}
                                                                        {selectedFields.includes("frais_retour") && (
                                                                            <td>{item.fournisseur.grand_retour}</td>
                                                                        )}

                                                                        {selectedFields.includes("cod") && (
                                                                            <td>{item.prix}</td>
                                                                        )}

                                                                        {selectedFields.includes("service") && (
                                                                            <td>{item.service}</td>
                                                                        )}
                                                                        
                                                                        {selectedFields.includes("note") && (
                                                                            <td>{item.remarque}</td>
                                                                        )}
                                                                        {selectedFields.includes("products") && (
                                                                            <td>{item.product_name}</td>
                                                                        )}
                                                                        {selectedFields.includes("status") && (
                                                                            <td>{item.status}</td>
                                                                        )}
                                                                        {selectedFields.includes("client_prenom") && (
                                                                            <td>{item.client_prenom}</td>
                                                                        )}
                                                                        {selectedFields.includes("client_name") && (
                                                                            <td>{item.client_name}</td>
                                                                        )}
                                                                        {selectedFields.includes("client_address_pickup") && (
                                                                            <td>{item.client_address_pickup}</td>
                                                                        )}
                                                                        {selectedFields.includes("client_ville") && (
                                                                            <td>{item.client_ville.name}</td>
                                                                        )}
                                                                        {selectedFields.includes("client_deligaiton") && (
                                                                            <td>{item.client_deligaiton.name}</td>
                                                                        )}
                                                                        {selectedFields.includes("client_telephone_1") && (
                                                                            <td>{item.client_telephone_1}</td>
                                                                        )}
                                                                        {selectedFields.includes("client_telephone_2") && (
                                                                            <td>{item.client_telephone_2}</td>
                                                                        )}
                                                                        {selectedFields.includes("client_email") && (
                                                                            <td>{item.client_email}</td>
                                                                        )}
                                                                        {selectedFields.includes("client_zipcode") && (
                                                                            <td>{item.client_zipcode}</td>
                                                                        )}
                                                                        {selectedFields.includes("client_cite") && (
                                                                            <td>{item.client_cite.name}</td>
                                                                        )}
                                                                        {selectedFields.includes("client_address") && (
                                                                            <td>{item.client_address}</td>
                                                                        )}
                                                                        {selectedFields.includes("name") && (
                                                                            <td>{item.fournisseur.name}</td>
                                                                        )}
                                                                        {selectedFields.includes("email") && (
                                                                            <td>{item.fournisseur.email}</td>
                                                                        )}
                                                                        {selectedFields.includes("livreur") && (
                                                                            <td>{item.fournisseur.livreur}</td>
                                                                        )}
                                                                        {selectedFields.includes("rib") && (
                                                                            <td>{item.fournisseur.rib}</td>
                                                                        )}
                                                                        {selectedFields.includes("mf") && (
                                                                            <td>{item.fournisseur.mf}</td>
                                                                        )}
                                                                        {selectedFields.includes("tel_fournisseur") && (
                                                                            <td>{item.fournisseur.tel}</td>
                                                                        )}
                                                                        {selectedFields.includes("adresse_pickup_fournisseur") && (
                                                                            <td>{item.fournisseur.adresse}</td>
                                                                        )}
                                                                        {selectedFields.includes("expiditeur_ville") && (
                                                                            <td>{item.expiditeur_ville.name}</td>
                                                                        )}
                                                                        {selectedFields.includes("expiditeur_cite") && (
                                                                            <td>{item.expiditeur_cite.name}</td>
                                                                        )}
                                                                        {selectedFields.includes("expiditeur_deligaiton") && (
                                                                            <td>{item.expiditeur_deligaiton.name}</td>
                                                                        )}
                                                                        {selectedFields.includes("expiditeur_zipcode") && (
                                                                            <td>{item.expiditeur_zipcode}</td>
                                                                        )}
                                                                        {selectedFields.includes("remarque_echange") && (
                                                                            <td>{item.remarque_echange}</td>
                                                                        )}
                                                                        {selectedFields.includes("prix") && (
                                                                            <td>{item.prix}</td>
                                                                        )}
                                                                        {selectedFields.includes("volume") && (
                                                                            <td>{item.volume}</td>
                                                                        )}
                                                                        {selectedFields.includes("related_payment") && (
                                                                            <td>{item.related_payment}</td>
                                                                        )}
                                                                        
                                                                    </tr>
                                                                ))}
                                                                </tbody>
                                                              </Table>
                                                            </div>
                                                        )}
                                                    </Col>
                                                </div>
                                            </Card>
                                        </Col>{" "}
                                        <Row>
                                            <Col sm="10"></Col>
                                            {/* {data ? "" : */}
                                                <Col>
                                                    <CustomPaginationWrapper
                                                        name="Genarate"
                                                        length={data.results.length}
                                                        count={data.count}
                                                        currentPage={pagenumber}
                                                        onNavigate={Navigate}
                                                    />
                                                </Col>
                                            {/* // } */}
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            ):(<></>)}
            
        </Fragment>
    );
};

export default Generate;