
import {
  GET_COMPTE,
  GET_COMPTES,
  COMPTE_LOADING,
  COMPTE_ERROR,
  UPDATE_COMPTE,
  GET_COMMERCIALS,
    COMMERCIALS_ERROR,
    COMMERCIALS_LOADING
} from "../../redux/actionTypes";

const initial_state = {
  compte: null,
  comptes: [],
  commercials: [],
  count: 0,
  loading: true,
  error: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initial_state, action) {
  const { type, payload } = action;
  switch (type) {
    case COMMERCIALS_LOADING:
    case COMPTE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case COMMERCIALS_ERROR:
    case COMPTE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case UPDATE_COMPTE:
      return {
        ...state,
        compte: payload,
        loading: false,
      };
    case GET_COMPTE:
      return {
        ...state,
        compte: payload,
        loading: false,
      };
    case GET_COMMERCIALS:
      return {
        ...state,
        commercials: payload,
        loading: false
      }
    case GET_COMPTES:
      return {
        ...state,
        count: payload.count,
        comptes: payload.results,
        loading: false,
      };

    default:
      return state;
  }
}
