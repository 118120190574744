import React, {
    Fragment,
    useState,
    useEffect,
    useMemo, useContext,
} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import Error404 from "../../pages/errors/error404";
import Alert from "../../redux/Alert";
import Spinner from "../colis/spinner.gif";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter, useLocation} from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Table,
    Card,
    CardBody, Modal, ModalHeader, ModalBody, ModalFooter,
} from "reactstrap";
import {useDispatch} from "react-redux";
import {getFournisseurs, deleteFournisseur} from "./action";
import PaginationWrapper from "../pagination/pagination";
import {Link} from "react-router-dom";
import AppContext from "../../context/Context";

const Fournisseur = ({fournisseurdata, deleteFournisseur, history}) => {
    const [timer, setTimer] = useState(null)
    const [alldata, searchData] = useState([]);
    // const [modal, setModal] = useState(false)
    const [deleteId, setDeleteId] = useState(null);

    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState(false);

    const toggleModal = (id) => setDeleteId(id ? id : null);

    const [hideOtherElements, setHideOtherElements] = useState(false);

    const handleDelete = (supplierId) => {
        if(password === "delete_supplier"){
            // Here you can perform the delete action
            deleteFournisseur(supplierId, history);
            setDeleteId(null); // Close the modal after deletion
            dispatch(getFournisseurs(query));
            setHideOtherElements(false);
        }else{
            // setDeleteId(null);
            setMsg("Password Wrong!");
            setHideOtherElements(false);
        }
        
    };

    const {
        config: {group}
    } = useContext(AppContext)

    const dispatch = useDispatch();

    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };

    let query = useQuery();

    useEffect(() => {
        dispatch(getFournisseurs(query));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  useEffect(() => {
    if (fournisseurdata.fournisseurs) {
      searchData(fournisseurdata.fournisseurs);
    }
  }, [fournisseurdata.fournisseurs, fournisseurdata.loading, alldata]);
  // useEffect(() => {
  //   if (fournisseurdata.loading)
  //     setDisable(true)
  //   else
  //     setDisable(false)
  // }, [fournisseurdata.loading])
    useEffect(() => {
        if (fournisseurdata.fournisseurs) {
            searchData(fournisseurdata.fournisseurs);
        }
    }, [fournisseurdata.fournisseurs, fournisseurdata.loading, alldata]);
    let pagenumber = query.get("page") ? query.get("page") : 1;
    const Navigate = (page) => {
        query.set("page", `${Number(page)}`)
        window.location.replace(`/fournisseur/?${query}`);
    };

    let count = fournisseurdata.count;

    let user_groups_name = group;
    const getValSearch = () => {
        let params = new URLSearchParams(document.location.search);
        return params.get("q");
    };

    const inputChanged = (e) => {
        clearTimeout(timer)
        query.set("search", e)
        const newTimer = setTimeout(() => {
            dispatch(getFournisseurs(query));
        }, 500)

        setTimer(newTimer)
    }
    return (
        <Fragment>
            <Breadcrumb parent="Gestion" title="Fournisseur"/>
            {user_groups_name && user_groups_name.indexOf("admin") !== -1 ? (
                <div>

                    <Container fluid={true}>
                        <Alert/>
                        <Row>
                            <Col sm="12">
                                <Card>
                                    {/* {datacolis.colis.length > 0 ? () : ()} */}

                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <Link
                                                    className="btn  m-2 "
                                                    style={{backgroundColor: "#FDF203"}}
                                                    to={"/fournisseur/new"}
                                                >
                                                    {"Ajouter"}
                                                </Link>
                                                <Link
                                                    className="btn btn-excel text-white m-2 "
                                                    to={"/fournisseur/export"}
                                                    target={"_blank"}
                                                >
                                                    {"Excel"}
                                                </Link>
                                            </Col>
                                            {!hideOtherElements && (
                                            <div className="search-6">
                                                <h6>Rechercher:</h6>
                                            </div>
                                            )}
                                            {!hideOtherElements && (
                                            <input
                                                className=" border search "
                                                style={{borderRadius: "7px"}}
                                                type="text"
                                                placeholder="Rechercher..."
                                                name="search"
                                                autoComplete="off" // Prevent autofill
                                                onFocus={(e) => { e.target.setAttribute('autocomplete', 'new-password'); }} // Set autocomplete attribute to 'new-password' on focus
                                                defaultValue={getValSearch()}
                                                onChange={(e) => inputChanged(e.target.value)}
                                            />
                                            )}
                                            <br/>
                                        </Row>
                                        <Col sm="12">
                                            <div className="card-block row">
                                                <Col sm="12" lg="12" xl="12">
                                                    <div className="table-responsive">
                                                        {fournisseurdata && fournisseurdata.loading ? (
                                                            <div className="text-center">
                                                                <img src={Spinner} style={{width: "10%"}} alt=""/>
                                                            </div>
                                                        ) : (
                                                            <Table striped>
                                                                <thead>
                                                                <tr>
                                                                    <th id="id">{"id"}</th>
                                                                    <th>{"img"}</th>
                                                                    <th>{"Raison"}</th>
                                                                    <th>{"Adresse"}</th>
                                                                    <th>{"Téléphone"}</th>
                                                                    <th>{"Secteur(s)"}</th>
                                                                    <th>{"Actions"}</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {fournisseurdata &&
                                                                        fournisseurdata.fournisseurs.map((b) => (
                                                                            
                                                                            <tr key={b.id}>
                                                                                <td>
                                                                                    <Link to={`/fournisseur/edit/${b.id}`}>
                                                                                        {b.id}
                                                                                    </Link>
                                                                                </td>
                                                                                <td>
                                                                                    <img
                                                                                        className="image"
                                                                                        src={`${b.url_path}`}
                                                                                        alt=""
                                                                                    />
                                                                                </td>
                                                                                <td>{b.name}</td>
                                                                                <td>{b.absolute_address}</td>
                                                                                <td>{b.tel}</td>
                                                                                <td>
                                                                                    {b.secteur_names.map((e) => e + ", ")}
                                                                                </td>
                                                                                <td>
                                                                                    <button
                                                                                        onClick={() => {
                                                                                            toggleModal(b.id);
                                                                                            setHideOtherElements(true);
                                                                                        }}
                                                                                        className="btn btn-danger m-1"
                                                                                    >
                                                                                        <i className="fa fa-trash"></i>
                                                                                    </button>
                                                                                    <Modal isOpen={deleteId === b.id} centered toggle={() => toggleModal(null)}>
                                                                                        <ModalHeader>Delete Supplier</ModalHeader>
                                                                                        <ModalBody>
                                                                                            <p>Are you sure you want to delete this supplier?</p>
                                                                                            <p className="text-red-900">{msg}</p>
                                                                                            <div className="form-group">
                                                                                                <label>Password:</label>
                                                                                                <input
                                                                                                    type="password"
                                                                                                    className="form-control"
                                                                                                    value={password}
                                                                                                    onChange={(e) => setPassword(e.target.value)}
                                                                                                />
                                                                                            </div>
                                                                                        </ModalBody>
                                                                                        <ModalFooter>
                                                                                            <button className="btn btn-secondary" onClick={() => toggleModal(null)}>
                                                                                                Cancel
                                                                                            </button>
                                                                                            <button
                                                                                                className="btn btn-danger"
                                                                                                onClick={() => handleDelete(b.id)}
                                                                                                disabled={!password.trim()}
                                                                                            >
                                                                                                Delete
                                                                                            </button>
                                                                                        </ModalFooter>
                                                                                    </Modal>
                                                                                </td>
                                                                            </tr>
                                                                            
                                                                        ))}
                                                                </tbody>
                                                            </Table>
                                                        )}
                                                    </div>
                                                </Col>
                                            </div>
                                        </Col>{" "}
                                        <Row>
                                            <Col sm="10"></Col>
                                            {fournisseurdata && fournisseurdata.loading ? "" :
                                                <Col>
                                                    <PaginationWrapper
                                                        name="Fournisseurs"
                                                        length={fournisseurdata.fournisseurs.length}
                                                        count={count}
                                                        currentPage={pagenumber}
                                                        onNavigate={Navigate}
                                                    />
                                                </Col>}
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            ) : (
                <Error404 page="Fournisseur"/>
            )}
        </Fragment>
    );
};

Fournisseur.propTypes = {
    getFournisseurs: PropTypes.func.isRequired,
    deleteFournisseur: PropTypes.func.isRequired,
    fournisseurdata: PropTypes.object.isRequired,
};

const mapstatetoprops = (state) => ({
    fournisseurdata: state.reducerf,
});

export default connect(mapstatetoprops, {getFournisseurs, deleteFournisseur})(
    withRouter(Fournisseur)
);
