import React, {Fragment, useState, useEffect, useCallback, useMemo} from "react";
import { Form, Row } from "reactstrap";
import { X } from "react-feather";
import LeftHeader from "./leftbar";
import RightHeader from "./rightbar";
import { useHistory, useLocation} from "react-router-dom";
import { Loading } from "../../constant";
const Header = () => {
  // eslint-disable-next-line
  const [searchValue, setsearchValue] = useState("");
  const history = useHistory()
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setsearchValue("");
    }
  }, []);
  const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };
    let query = useQuery();

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      query.set("search", searchValue)
      history.push({ pathname: '/colis', search: query.toString() });
    }
  };


  return (
    <Fragment>
      <div className="page-header">
        <Row className="header-wrapper m-0">
          <Form className="form-inline search-full" onSubmit={(e) => e.preventDefault()} method="get">
            <div className="form-group w-100">
              <div className="Typeahead Typeahead--twitterUsers">
                <div className="u-posRelative">
                  <input
                    className="Typeahead-input form-control-plaintext w-100"
                    id="demo-input"
                    type="search"
                    placeholder="Rechercher"
                    onChange={(event) => setsearchValue(event.target.value)}
                    defaultValue={searchValue}
                    value={searchValue}
                    onKeyDown={handleKeyDown}
                  />
                  <div
                    className="spinner-border Typeahead-spinner"
                    role="status"
                  >
                    <span className="sr-only">{Loading}</span>
                  </div>
                  <X
                    className="close-search"
                    onClick={() =>
                      document
                        .querySelector(".search-full")
                        .classList.remove("open")
                    }
                  />
                </div>
                <div
                  className="Typeahead-menu custom-scrollbar"
                  id="search-outer"
                >
                  {/*{searchValue*/}
                  {/*  ? searchValue.map((data, index) => {*/}
                  {/*      return (*/}
                  {/*        <div className="ProfileCard u-cf" key={index}>*/}
                  {/*          <div className="ProfileCard-avatar">*/}
                  {/*            <data.icon />*/}
                  {/*          </div>*/}
                  {/*          <div className="ProfileCard-details">*/}
                  {/*            <div className="ProfileCard-realName">*/}
                  {/*              <Link to={data.path} className="realname">*/}
                  {/*                {data.title}*/}
                  {/*              </Link>*/}
                  {/*            </div>*/}
                  {/*          </div>*/}
                  {/*        </div>*/}
                  {/*      );*/}
                  {/*    })*/}
                  {/*  : ""}*/}
                </div>
                <div className="Typeahead-menu empty-menu">
                  <div className="tt-dataset tt-dataset-0">
                    <div className="EmptyMessage">
                      {"Opps!! There are no result found."}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
          <LeftHeader />
          <RightHeader />
        </Row>
      </div>
    </Fragment>
  );
};

export default Header;
