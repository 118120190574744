import React from 'react'
import { Col, Row } from 'reactstrap'
import lap from "../images/Name_Parcels_v3.png"
import tick from "../images/success.png"
export default function Section2() {
  return (
    <div style={{padding: "50px" , fontFamily : "sans-serif"  }}>
        { window.innerWidth >= 1000 ?(<Row>
            <Col sm={7}>
            <h1 style={{fontWeight : "600" , fontSize : "48px" , marginLeft: "68px" }}>Solution <span style={{color : "#F2B707"}}>rapide et fiable</span> de livraison pour vos ventes</h1>
            <h5 style={{fontSize : "28px" , color: "rgb(83, 86, 88)" , lineHeight : "1.6em" , marginLeft: "68px"}}>L'interface Beezit vous permet de tout maîtriser :</h5>
            <ul style={{fontSize : "22px" , color: "rgb(83, 86, 88)" , lineHeight : "1.6em" , marginLeft: "68px"}}>
                <li><img src={tick} height={'30px'} width={'30px'} alt={""}/>préparation de vos envoie</li>
                <li> <img src={tick} height={'30px'} width={'30px'} alt={""}/>gestion de l'inventaire et du stock</li>
                <li> <img src={tick} height={'30px'} width={'30px'} alt={""}/>picking</li>
                <li> <img src={tick} height={'30px'} width={'30px'} alt={""}/>envoi de commandes</li>
                <li> <img src={tick} height={'30px'} width={'30px'} alt={""}/>gestion des retours</li>
                <li> <img src={tick} height={'30px'} width={'30px'} alt={""}/>service client..</li>
            </ul>
            <br/>
            <h5 style={{fontSize : "28px" , color: "#F2B707" , lineHeight : "1.6em" , marginLeft: "68px"}}>Tout cela en temps réel.</h5>
            {/* <button >book a demo</button> */}
            </Col>
            <Col sm={5}>
            <img src={lap} height={"500px"} alt={""} />
            </Col>
           

        </Row>) : (
            <>
       <Row>
        
       <Col sm={7}>
       <h1 style={{fontWeight : "600" , fontSize : "56px"}}>Solution <span style={{color : "#F2B707"}}>rapide et fiable</span> de livraison pour vos ventes</h1>
       <h5 style={{fontSize : "28px" , color: "rgb(83, 86, 88)" , lineHeight : "1.6em"}}>L'interface Beezit vous permet de tout maîtriser :</h5>
       <ul style={{fontSize : "22px" , color: "rgb(83, 86, 88)" , lineHeight : "1.6em"}}>
           <li><img src={tick} height={'30px'} width={'30px'} alt={""}/>préparation de vos envoie</li>
           <li> <img src={tick} height={'30px'} width={'30px'} alt={""}/>gestion de l'inventaire et du stock</li>
           <li> <img src={tick} height={'30px'} width={'30px'} alt={""}/>picking</li>
           <li> <img src={tick} height={'30px'} width={'30px'} alt={""}/>envoi de commandes</li>
           <li> <img src={tick} height={'30px'} width={'30px'} alt={""}/>gestion des retours</li>
           <li> <img src={tick} height={'30px'} width={'30px'} alt={""}/>service client..</li>
       </ul>
       <h5 style={{fontSize : "28px" , color: "#F2B707" , lineHeight : "1.6em", }}>Tout cela en temps réel.</h5>
       
       </Col>
       
      
   
   
     <Col sm={7}>
       <img src={lap} width= {"400px"} alt={""} />
       </Col>
   </Row> 
   </>  
        )}
        
    </div>
  )
}
