import React, { Fragment, useState} from "react";
import {
  Card,
  CardBody,
  Col,
  Form,
  Row,
  FormGroup,
  CardTitle,
  Tooltip 
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { addNewDemande } from "../demandes/action";
import Alert from "../../redux/Alert";
import Nava from "./components/Navbar";
import Footer from "./components/footer";


const Partner = ({ addNewDemande }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [tooltipOpen1, setTooltipOpen1] = useState(false);
  const toggle1 = () => setTooltipOpen1(!tooltipOpen1);
  const [tooltipOpen2, setTooltipOpen2] = useState(false);
  const toggle2 = () => setTooltipOpen2(!tooltipOpen2);
  const [tooltipOpen3, setTooltipOpen3] = useState(false);
  const toggle3 = () => setTooltipOpen3(!tooltipOpen3);
  const [formData, setFormData] = useState({
    commande: 0 ,
    first_name: "",
    last_name: "",
    type: "Devenir partenaire",
    number: "",
    email: "",
    entreprise: "",
  });
  const onSubmit = (e) => {
    e.preventDefault();
    addNewDemande(formData);
  };
  const onChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <Fragment>
      <div style={{ backgroundColor: "#F8F9FE", fontFamily: "Raleway"}}>
        {/* {window.innerWidth >= 1200 ? (
          <div className="page-header flex " style={{backgroundColor: "#193256"}}>
            <Row className="header-wrapper m-0">
              <Col sm="2"></Col>
              <Col>
                <LeftHeader />
              </Col>
              <Col sm="4">
                <Nave />
              </Col>
              <Col sm="3">
                <RightHeader />
              </Col>
            </Row>
          </div>
        ) : (
          <Row>
            <Col>
              <img
                className="img-fluid for-light"
                style={{
                  width: "200px",
                  height: "80px",
                }}
                src={require("../../assets/images/logo/logo_dark.png")}
                alt=""
              />
            </Col>
            <Row hidden={!menu}>
              <div
                sm="1"
                style={{
                  width: "100%",
                  borderRadius: "15px",

                  backgroundColor: "white",
                }}
              >
                <br />
                <br />
                <Row>
                  <Col sm="1"></Col>
                  <Col>
                    {" "}
                    <Row>
                      <Col></Col>
                      <Link to="/">Home</Link>
                      <Col></Col>
                    </Row>
                    <br />
                    <Row>
                      <Col></Col>
                      <Link to="/about">About</Link>
                      <Col></Col>
                    </Row>
                    <br />
                    <Row>
                      {" "}
                      <Col></Col>
                      <Link to="/services">Privilège</Link>
                      <Col></Col>
                    </Row>
                    <br />
                    <Row>
                      <Col></Col>
                      <Link to="/partner">Contact</Link>
                      <Col></Col>
                    </Row>
                    <br />
                    <Row>
                      <Col></Col>
                      <Link
                        to={`/signin`}
                        style={{
                          color: "#FFC107",
                          padding: "10px",
                          borderRadius: "15px",
                          border: "1px solid #FFC107",
                          fontSize: "16px",
                          textTransform: "uppercase",
                        }}
                      >
                        Login &nbsp;&nbsp;&nbsp;&nbsp;
                        <i className="fa fa-sign-in"></i>
                      </Link>
                      <Col></Col>
                    </Row>
                  </Col>
                  <Col sm="1"></Col>
                </Row>
                <br />
              </div>
            </Row>

            <i
              onClick={() => setMenu(!menu)}
              className="fa fa-bars"
              style={{
                position: "relative",
                top: "20px",
                right: "7%",
                fontSize: "30px",
                color: "#FFC107",
              }}
            ></i>
          </Row>
        )} */}
        <Nava/>
       
        <div className="page-section-heading text-center  jn mt-4">
          
          <br />
          <br />
          <Row>
           
            <Col md={7}>
          <h1>Gagnez plus avec <span style={{ color: "#F2B707" }}>Beez</span> </h1>
         <br/>
        
          
          <Row>
            <Col sm={"1"}></Col>
            <Col>
              <Card style={{ }}>

                <CardBody>
                  <Alert/>
                  <h2 className="text-center" style={{ color: "#F2B707" , fontWeight : "bold" }}>
                    Combien de commandes avez-vous par mois?
                  </h2>
                  <Form onSubmit={(e) => onSubmit(e)}>
                    <FormGroup>
                      <output id="rangevalue1" className="mb-4 bold ">
                        {formData.commande} Commandes / Mois en moyenne
                      </output>
                      <br />
                      <input
                        className="range mb-5 mt-3"
                        type="range"
                        min="0"
                        max="10000"
                        step="10"
                        name="commande"
                        style={{ width: "80%"  }}
                        onChange={(e) => onChange(e)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col sm="1"></Col>
                        <Col>
                          <input
                            style={{ height: "200%"  , border: "1px solid #8A8A8A" }}
                            className="form-control digits"
                            type="text"
                            placeholder="Nom"
                            value={formData.last_name}
                            name="last_name"
                            onChange={(e) => onChange(e)}
                          />
                        </Col>
                        <Col>
                          <input
                            style={{ height: "200%" , border: "1px solid #8A8A8A"  }}
                            className="form-control digits"
                            type="text"
                            placeholder="Prénom"
                            value={formData.first_name}
                            name="first_name"
                            onChange={(e) => onChange(e)}
                          />
                        </Col>

                        <Col sm="1"></Col>
                      </Row>
                    </FormGroup>
                    <br />
                    <br />
                    <FormGroup>
                      <Row>
                        <Col sm="1"></Col>
                        <Col>
                          <input
                            style={{ height: "200%" , border: "1px solid #8A8A8A"  }}
                            className="form-control digits"
                            type="text"
                            placeholder="Nom de l'entreprise"
                            value={formData.entreprise}
                            name="entreprise"
                            onChange={(e) => onChange(e)}
                          />
                        </Col>
                        <Col sm="1"></Col>
                      </Row>
                    </FormGroup>
                    <br />
                    <br />
                    <FormGroup>
                      <Row>
                        <Col sm="1"></Col>
                        <Col>
                          <input
                            style={{ height: "200%" , border: "1px solid #8A8A8A"  }}
                            className="form-control digits"
                            type="number"
                            placeholder="Numéro de téléphone"
                            value={formData.number}
                            name="number"
                            onChange={(e) => onChange(e)}
                          />
                        </Col>
                        <Col sm="1"></Col>
                      </Row>
                    </FormGroup>
                    <br />
                    <br />
                    <FormGroup>
                      <Row>
                        <Col sm="1"></Col>
                        <Col>
                          <input
                            style={{ height: "200%" , border: "1px solid #8A8A8A"  }}
                            className="form-control digits"
                            type="email"
                            placeholder="Email"
                            value={formData.email}
                            name="email"
                            onChange={(e) => onChange(e)}
                          />
                        </Col>
                        <Col sm="1"></Col>
                      </Row>
                    </FormGroup>
                    <br />
                    <br />
                    <button className="btn text-white" type="submit" style={{borderRadius:"30px", backgroundColor: "#F2B707"}}>
                      <h3>Obtenir une estimation de prix</h3>
                    </button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col sm="2"></Col>
          </Row>
          </Col>
          {/* tarif */}
          <Col ms={5} style={{alignItems : "center " , display: "center"}}>
          <h1 style={{color : "#F2B707" , marginRight : "50px"}}>
            Nos Tarifs
          </h1>
          <br />
          <Row>
            
            <Col  id="TooltipExample" sm={5} style={{display: "flex" , alignItems : "center" , justifyContent : "center"}}>
            <Card style={{width: '13rem'}}>
            <img  alt="Sample" height={"120px"} src={require("./images/EP colis.png")}/>
      <CardBody>
            <CardTitle tag="h5">
            Extra petits colis
            </CardTitle>
      </CardBody>
</Card>
<Tooltip
        
        isOpen={tooltipOpen}
        target="TooltipExample"
        toggle={toggle}
      >
     De petites bouteilles d'huile, rouge à lèvre ...
      </Tooltip>
            </Col>
            <Col id="TooltipExample1" sm={5} style={{display: "flex" , alignItems : "center" , justifyContent : "center"}}>
            <Card style={{width: '13rem'}}>
            <img  alt="Sample" height={"140px"} src={require("./images/P colis.png")}/>
      <CardBody>
            <CardTitle tag="h5">
            petits colis
            </CardTitle>
      </CardBody>
</Card> 
<Tooltip
        
        isOpen={tooltipOpen1}
        target="TooltipExample1"
        toggle={toggle1}
      >
   Pantalons, t-shirts, chaussure, sacs...
      </Tooltip>
            </Col>
            <Col sm={2}></Col>
          </Row>
          <Row>
            
            <Col id="TooltipExample2" xs={12} sm={5 } style={{display: "flex" , alignItems : "center" , justifyContent : "center"}}>
            <Card style={{width: '13rem'}}>
            <img  alt="Sample" height={"120px"} src={require("./images/M parcesslespng.png")}/>
      <CardBody>
            <CardTitle tag="h5">
            Moyennes parcelles
            </CardTitle>
      </CardBody>
</Card>
<Tooltip
        
        isOpen={tooltipOpen2}
        target="TooltipExample2"
        toggle={toggle2}
      >
   Livres, Palettes make up...  
      </Tooltip>
            </Col>
            <Col id="TooltipExample3" xs={12} sm={5 } style={{display: "flex" , alignItems : "center" , justifyContent : "center"}}>
            <Card style={{width: '13rem'}}>
            <img  alt="Sample" height={"120px"} src={require("./images/G parcelles.png")}/>
      <CardBody>
            <CardTitle tag="h5">
            Grande parcelles
            </CardTitle>
      </CardBody>
</Card>
<Tooltip
        
        isOpen={tooltipOpen3}
        target="TooltipExample3"
        toggle={toggle3}
      >
   Machine à café, tapis...
      </Tooltip>
            </Col>
            <Col sm={2}></Col>
          </Row>
          </Col>
          </Row>
          <h2
            style={{
              position: "relative",
              fontSize: "40px",
              fontWeight: "bold",
              color: "#F2B707",
            }}
          >
            JOIN US
          </h2>
          <br />
          <Link
            to={"/services"}
            className="btn"
            style={{
              fontSize: "25px",
              fontWeight: "bold",
              color: "white",
                borderRadius:"30px",
              backgroundColor: "#F2B707",
            }}
          >
            More About us
          </Link>

          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
          <Footer/>

      </div>
    </Fragment>
  );
};

Partner.propTypes = {
  addNewDemande: PropTypes.func.isRequired,
};

export default connect(null, { addNewDemande })(Partner);
