import {
  VILLEF_LOADING,
  VILLEF_ERROR,
  GET_VILLEF,
  DELIGATIONF_LOADING,
  DELIGATIONF_ERROR,
  GET_DELIGATIONF,
  CITEF_LOADING,
  CITEF_ERROR,
  GET_CITEF,
} from "../../redux/actionTypes";
const initial_state = {
  citesF: [],
  deligationsF: [],
  count: 0,
  villeF: [],
  loading: false,
  error: {},
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initial_state, action) {
  const { type, payload } = action;
  switch (type) {
    case CITEF_ERROR:
    case VILLEF_ERROR:
    case DELIGATIONF_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case VILLEF_LOADING:
    case DELIGATIONF_LOADING:
    case CITEF_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_CITEF:
      return {
        ...state,
        citesF: payload,
        loading: false,
      };
    case GET_DELIGATIONF:
      return {
        ...state,
        deligationsF: payload,
        loading: false,
      };
    case GET_VILLEF:
      return {
        ...state,
        villeF: payload,
        loading: false,
      };
    default:
      return state;
  }
}
