import React from "react";
import { ReactComponent as Loader } from '../assets/images/loader.svg'
import "./Loader.css"
import {Button as B} from "reactstrap";

function Button({onSubmit, text, disabled, loading = false, ...rest}) {

  return (
    <B className="submit-btn" onClick={onSubmit} disabled={disabled} {...rest}>
      {!loading ? text : <Loader className="spinner" />}
    </B>
  )
}

export default Button;
