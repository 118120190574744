import {
  GET_STOCK,
  GET_STOCKS,
  STOCK_LOADING,
  STOCK_ERROR,
  GET_CATEGORIE,
  GET_CATEGORIES,
  CATEGORIE_LOADING,
  CATEGORIE_ERROR,
  GET_SOUS_CATEGORIE,
  GET_SOUS_CATEGORIES,
  SOUS_CATEGORIE_LOADING,
  SOUS_CATEGORIE_ERROR,
  GET_PRODUIT,
  GET_PRODUITS,
  PRODUIT_LOADING,
  PRODUIT_ERROR, STOCK_ACTIVITIES_LOADING, STOCK_ACTIVITIES_ERROR, GET_STOCK_ACTIVITIES,
} from "../../redux/actionTypes";

const initial_state = {
  stock: null,
  produit: null,
  count: 0,
  activities: [],
  categorie: null,
  categories: [],
  souscategorie: null,
  souscategories: [],
  stocks: [],
  loading: true,
  error: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initial_state, action) {
  const { type, payload } = action;
  switch (type) {
    case STOCK_ACTIVITIES_LOADING:
    case STOCK_LOADING:
      return {
        ...state,
        loading: true,
      };
    case STOCK_ACTIVITIES_ERROR:
    case STOCK_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case GET_STOCK:
      return {
        ...state,
        stock: payload,
        loading: false,
      };
    case GET_STOCKS:
      return {
        ...state,
        count: payload.count,
        stocks: payload.results,
        loading: false,
      };
    case GET_STOCK_ACTIVITIES:
      return {
        ...state,
        count: payload.count,
        activities: payload.results,
        loading: false,
      };
    case CATEGORIE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CATEGORIE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case GET_CATEGORIE:
      return {
        ...state,
        categorie: payload,
        loading: false,
      };
    case GET_CATEGORIES:
      return {
        ...state,
        categories: payload.results,
        loading: false,
      };
    case SOUS_CATEGORIE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SOUS_CATEGORIE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case GET_SOUS_CATEGORIE:
      return {
        ...state,
        souscategorie: payload,
        loading: false,
      };
    case GET_SOUS_CATEGORIES:
      return {
        ...state,
        souscategories: payload.results,
        loading: false,
      };
    case PRODUIT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case PRODUIT_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case GET_PRODUIT:
      return {
        ...state,
        produit: payload,
        loading: false,
      };
    case GET_PRODUITS:
      return {
        ...state,
        produits: payload.results,
        loading: false,
      };
    default:
      return state;
  }
}
