import React, {Fragment, useState, useEffect, useMemo, useContext} from "react";
import man from "../../assets/images/dashboard/profile.jpg";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import {Logout} from "../../redux/signin/action";
import {getReclamation, getTracking, updateTracking, updateTrackingActivities} from "./action";
import {getCurrentCompte} from "./action";
import {withRouter, useLocation, Redirect} from "react-router-dom";
import {LogIn, Mail, User, Bell, Search, Star} from "react-feather";
import {useHistory} from "react-router-dom";
import {Link} from "react-router-dom";
import Profile from "./profile.png"
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
} from "react-switch-lang";

import en from "../../assets/i18n/en.json";
import es from "../../assets/i18n/es.json";
import pt from "../../assets/i18n/pt.json";
import fr from "../../assets/i18n/fr.json";
import du from "../../assets/i18n/du.json";
import cn from "../../assets/i18n/cn.json";
import ae from "../../assets/i18n/ae.json";
import {FormGroup, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {day2digits} from "../../components/colis/gestiondescolis";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import {getCurrentcolis} from "../../components/colis/action";
import {Rating} from "react-simple-star-rating";
import {Form, FormLabel} from "react-bootstrap";
import {AddAvis} from "../../components/avis/actions";
import {setAlertD} from "../../redux/alert/action";
import AppContext from "../../context/Context";

setTranslations({en, es, pt, fr, du, cn, ae});
setDefaultLanguage("en");
setLanguageCookie();

const Rightbar = ({
                      Logout,
                      RightbarData,
                      getCurrentcolis,
                      coli: {coli, loading},
                  }) => {
    const {
        config: {user_id, username, group, avatar},
        setConfig
    } = useContext(AppContext);
    const history = useHistory();
    const [profile, setProfile] = useState("");
    const [data, setData] = useState(null);
    const [name, setName] = useState("");
    const [notificationDropDown, setNotificationDropDown] = useState(false);
    const [reclamation, setReclamation] = useState([]);
    const [tracking, setTracking] = useState([]);
    const [menu, setMenu] = useState(false);
    const [scannerModal, setScannerModal] = useState(false)
    const [avisModal, setAvisModal] = useState(false)
    const [, setSearchresponsive] = useState(false)
    const [rating, setRating] = useState(0) // initial rating value
    const [formData, setFormData] = useState({
        "rating": rating,
        "description": ""
    })

    // Catch Rating value
    const handleRating = (rate) => {
        setRating(rate)
        // Some logic
    }


    useEffect(() => {
        setProfile(localStorage.getItem("profileURL") || man);
        setName(localStorage.getItem("Name"));
    }, [profile, name]);

    useEffect(() => {
        if (coli) {
            setScannerModal(false);
            setData("")
        }
    }, [coli]);
    const dispatch = useDispatch();
    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };
    let query = useQuery();
    let params = useQuery();
    query.set("status", "new")
    useEffect(() => {
        dispatch(getReclamation(query));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const today = () => {
        let today = new Date();
        let m = today.getMonth() + 1;
        if (m < 10) {
            m = "0" + m;
        }
        return today.getFullYear() + "-" + m + "-" + day2digits(today.getDate());
    }

    const SearchResponsive = () => {
        setSearchresponsive(true)
        document.querySelector(".search-full").classList.add("open");
    }

    useEffect(() => {
        if (RightbarData.reclamation) {
            setReclamation(RightbarData.reclamation);
        }
    }, [RightbarData.reclamation, RightbarData.loading, reclamation]);
    params.set("created_at", today())
    useEffect(() => {
        dispatch(getTracking(params));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    useEffect(() => {
        setTracking(RightbarData.tracking);
    }, [RightbarData.tracking, RightbarData.loading, tracking]);

    useEffect(() => {
        if (data)
            getCurrentcolis(data)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    let count = RightbarData.t_count;
    let r_count = RightbarData.r_count;
    let id = user_id;
    let user = username;
    let user_groups_name = group;

    const handleFieldChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const handleAvisSubmit = (e) => {
        e.preventDefault()
        AddAvis({...formData, rating: rating})
            .then(() => setRating(0))
        setAvisModal(false)
        setFormData({...formData, description: ""})
        dispatch(setAlertD("Avis Ajoutée", "success", 1000))
    }

    return (
        <Fragment>
            <div className="nav-right col-8 pull-right right-header p-0">
                <ul className="nav-menus">
                    <li>
                        <span className="header-search">
                            <Search
                                onClick={() => {
                                    SearchResponsive()
                                }}/>
                        </span>
                    </li>
                    {user_groups_name.indexOf("fournisseur") !== -1 &&
                        <li>
                            <Star style={{cursor: "pointer"}} onClick={() => setAvisModal(true)}/>
                            <Modal isOpen={avisModal} toggle={() => setAvisModal(!avisModal)}>
                                <ModalHeader>Ajouter un avis</ModalHeader>
                                <ModalBody>
                                    <Form>
                                        <div className='App d-flex justify-content-center align-items-center'>
                                            <Rating
                                                onClick={handleRating}
                                                onPointerMove={handleRating}
                                                ratingValue={rating}
                                                size={50}
                                                label
                                                transition
                                                fillColor='orange'
                                                emptyColor='gray'
                                                className='foo' // Will remove the inline style if applied
                                            />
                                            {/* Use rating value */}
                                            {rating}
                                        </div>
                                        <FormGroup>
                                            <FormLabel>Avis:</FormLabel>
                                            <textarea className={"form-control"} name={"description"}
                                                      onChange={handleFieldChange}>

                                        </textarea>
                                        </FormGroup>
                                    </Form>
                                </ModalBody>
                                <ModalFooter>
                                    <button
                                        className="btn  btn-danger m-1"
                                        onClick={(e) => {
                                            handleAvisSubmit(e)
                                        }}>Ajouter
                                    </button>
                                </ModalFooter>
                            </Modal>
                        </li>
                    }
                    <li>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             cursor={"pointer"}
                             className="bi bi-upc-scan" viewBox="0 0 16 16" onClick={() => setScannerModal(true)}>
                            <path
                                d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"/>
                        </svg>
                        <Modal isOpen={scannerModal} toggle={() => setScannerModal(!scannerModal)}>
                            <ModalHeader>Lecteur de code-barres</ModalHeader>
                            <ModalBody>
                                <div style={{position: "relative"}}>
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "40%",
                                            left: "10%",
                                            width: "80%",
                                            height: "20%",
                                            backgroundSize: "cover",
                                            border: "2px solid white",
                                            pointerEvents: "none", // make sure the child element doesn't interfere with the scanner
                                        }}
                                    />

                                    <BarcodeScannerComponent
                                        height={500}
                                        onUpdate={(err, result) => {
                                            if (result) setData(result.text);
                                        }}
                                    />

                                    {coli ? (
                                        <>
                                            Coli trouvé. redirecting...
                                            <Redirect to={`/colis/detail/${coli.id}`}/>
                                        </>
                                    ) : (
                                        data && "Erreur. Coli pas trouvé ou code à barre incorrect"
                                    )}
                                </div>
                            </ModalBody>
                        </Modal>
                    </li>
                    <li className="onhover-dropdown">
                        <div
                            className="notification-box"
                            onClick={() => setNotificationDropDown(!notificationDropDown)}
                        >
                            <Mail/>
                            <span className="badge badge-pill badge-primary">{r_count}</span>
                        </div>
                        <ul
                            className={`onhover-show-div ${
                                notificationDropDown ? "active" : ""
                            }`}
                        >
                            <li>
                                <Mail/>
                                <h6 className="f-18 mb-0">Reclamations</h6>
                            </li>
                            <div
                                style={{
                                    height: 400,
                                    overflowY: "scroll",
                                    scrollbarWidth: "initial",
                                }}
                            >
                                {RightbarData &&
                                    RightbarData.reclamation &&
                                    RightbarData.reclamation.map((r) => (
                                        <li key={r.id}>
                                            <div>
                                                <i className="fa fa-circle-o mr-3 font-primary"></i>
                                                <Link to={`/colis/detail/${r.colis}`}>
                                                    {r.description}
                                                </Link>{" "}
                                                <br/>
                                                {r.created_at}
                                            </div>
                                        </li>
                                    ))}
                            </div>
                            <Link to={"/reclamation"} className="btn btn-primary m-2">
                                Voir tous
                            </Link>
                        </ul>
                    </li>

                    <li className="onhover-dropdown">
                        <div
                            className="notification-box"
                            onClick={() => setNotificationDropDown(!notificationDropDown)}
                        >
                            <Bell/>
                            <span className="badge badge-pill badge-secondary">
                            {count}
                         </span>
                        </div>
                        <ul
                            className={`onhover-show-div ${
                                notificationDropDown ? "active" : ""
                            }`}
                        >
                            <li>
                                <Bell/>
                                <h6 className="f-18 mb-0">{false ? "FP" : "BEEZ"} Suivi</h6>

                            </li>
                            <div
                                style={{
                                    height: 400,
                                    overflowY: "scroll",
                                    scrollbarWidth: "initial",
                                }}
                            >

                                {RightbarData &&
                                    RightbarData.tracking.map((r) => (
                                        <Link to={`/colis/detail/${r.order_id}`}>
                                            <div onClick={updateTracking(r.id, {shown: "True"})}>
                                                <li key={r.id}>
                                                    <div style={{position: "relative", left: "0"}}>
                                                        <i className="fa fa-circle-o mr-3 font-danger"></i>
                                                        {r.description} <br/>{r.code_a_barre} <br/> {r.status_display}
                                                        <br/> {r.client_name}
                                                        <br/> {r.created_at}
                                                    </div>
                                                </li>
                                            </div>
                                        </Link>
                                    ))}
                            </div>
                            <Link to={"/gestion-colis"} className="btn btn-danger m-2">
                                Voir tous
                            </Link>
                        </ul>
                    </li>
                    <li className=" onhover-dropdown p-0">
                        <div className="media profile-media">
                            <img
                                className="b-r-10"
                                onClick={() => setMenu(!menu)}
                                src={avatar || Profile}
                                alt=""
                            />
                            <div className="media-body">
                                <span>{user}</span>
                                <p className="mb-0 font-roboto">
                                    {user_groups_name} <i className="middle fa fa-angle-down"></i>
                                </p>
                            </div>
                        </div>


                        <ul style={{width: 200}}
                            className={`profile-dropdown onhover-show-div ${menu ? "active" : ""}`}>
                            {window.innerWidth <= 1200 ?
                                <li>
                                    <div className="media-body list-inline"
                                         style={{display: "flex", justifyContent: "space-between"}}>
                                        <p>{user}</p>
                                        <span
                                            className="ml-10 font-roboto">{user_groups_name}</span>
                                    </div>
                                </li> : ""
                            }
                            {user_groups_name.indexOf("personnel") === -1 ? (
                                <li>
                                    <Link to={`/profile/${id}`}>
                                        <User/>
                                        <span>Profile</span>
                                    </Link>
                                </li>
                            ) : (
                                ""
                            )}

                            <li>
                                <Link onClick={() => {
                                    setConfig('isAuthenticated', false)
                                    history.push("/signin");
                                    localStorage.clear()
                                    Logout();
                                }}>
                                    <LogIn/>
                                    <span
                                    >
                                        Déconnexion
                                        </span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </Fragment>
    );
};

Rightbar.propTypes = {
    compte: PropTypes.object.isRequired,
    getCurrentCompte: PropTypes.func.isRequired,
    updateTracking: PropTypes.func.isRequired,
    updateTrackingActivities: PropTypes.func.isRequired,
    Logout: PropTypes.func.isRequired,
    getReclamation: PropTypes.func.isRequired,
    getTracking: PropTypes.func.isRequired,
    RightbarData: PropTypes.object.isRequired,
    authData: PropTypes.object.isRequired,
    getCurrentcolis: PropTypes.func.isRequired,
    coli: PropTypes.object.isRequired,
};
const mapStateToPorps = (state) => ({
    compte: state.reducerh,
    authData: state.auth,
    RightbarData: state.reducerh,
    coli: state.reducerc,
});
export default connect(mapStateToPorps, {
    getCurrentCompte,
    updateTracking,
    updateTrackingActivities,
    Logout,
    getReclamation,
    getTracking,
    getCurrentcolis,
})(withRouter(Rightbar));
