import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {getFinancesP} from "./action";
import {connect, useDispatch} from "react-redux";
import {Link, useLocation} from "react-router-dom";
import PropTypes from "prop-types";
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import Spinner from "../colis/spinner.gif";
import {Table} from "reactstrap";


const Exportfinance = ({financeData}) => {
    const [alldata, searchData] = useState([]);
    const dispatch = useDispatch();

    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };


    let query = useQuery();
    useEffect(() => {
        if (financeData.financesP) {
            searchData(financeData.financesP);
        }
    }, [financeData.financesP, financeData.loading, alldata]);


    ReactHTMLTableToExcel.format = (s, c) => {
        if (c && c["table"]) {
            const html = c.table;
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, "text/html");
            const rows = doc.querySelectorAll("thead tr th");
            for (const row of rows) {
                row.setAttribute(
                    "style",
                    "background: #B8CCE4"
                );
            }


            c.table = doc.querySelector("table").outerHTML;
        }

        return s.replace(/{(\w+)}/g, (m, p) => c[p]);
    };
    let length = financeData.financesP.length
    console.log("financeData Data ", financeData)
    console.log("length Data ", length)
    useEffect(() => {
        dispatch(getFinancesP(query))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (length > 0) {
            setTimeout(() => {
                window.close()
            }, 500)
        }
    }, [length])

    useEffect(() => {
        if (length > 0) {
            document.getElementById("excelexport").click()
        }
    }, [length])
    return (
        <Fragment>
            {financeData && financeData.loading ? <div className="text-center">
                <img src={Spinner} style={{width: "10%"}} alt=""/>
            </div> : ""
            }
            <div hidden>
                <ReactHTMLTableToExcel
                    table="example"
                    buttonText="EXCEL"
                    className="btn  m-2 btn-excel text-white"
                    filename={`Beezit - Finance`}
                    sheet={"Finance"}
                    id="excelexport"
                />
                <Table striped id="example">
                    <thead>
                    <tr>
                        <th>{"Id"}</th>
                        <th>{"User ID"}</th>
                        <th>{"Date"}</th>
                        <th>{"Nombre de colis"}</th>
                        <th>{"Fournisseur"}</th>
                        <th>{"Username"}</th>
                        <th>{"Total COD"}</th>
                        <th>{"Cout total de livraison"}</th>
                        <th>{"Total HFL"}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {financeData &&
                        financeData.financesP.map((b) => (
                            <tr key={b.id}>
                                <td>
                                    <Link to={`/finance/${b.id}`}>
                                        {b.id}
                                    </Link>
                                </td>
                                <td>
                                    <Link to={`/fournisseur/edit/${b.fournisseur}`}>
                                        {b.fournisseur}
                                    </Link>
                                </td>
                                <td>{b.created_at}</td>
                                <td>{b.nombre_de_colis}</td>
                                <td>{b.name_fournisseur}</td>
                                <td>{b.username}</td>
                                <td>{b.total_cod}</td>
                                <td>{b.total_hfl}</td>
                                <td>{b.cous_livraison}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </Fragment>
    )
}
Exportfinance.propTypes = {
    getFinancesP: PropTypes.func.isRequired,
    financeData: PropTypes.object.isRequired,
}
const mapstatetoprops = (state) => ({
    financeData: state.reducerfin,
});

export default connect(mapstatetoprops, {getFinancesP})(Exportfinance)