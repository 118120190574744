import React, {Fragment, useContext, useEffect, useState} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {Row, Col, Card} from "reactstrap";
import 'echarts/extension/bmap/bmap'
import IconAnamolie from "./anomalie.svg"
import Livree from "./livred.svg"
import All from "./all.svg"
import Process from "./process.svg"
import Back from "./return.svg"
import {currentMonthStartDate} from "../colis/gestiondescolis";
import Table from 'react-bootstrap/Table';
import {Link, Redirect, useHistory, withRouter} from "react-router-dom";
import 'echarts/extension/bmap/bmap'
import geoJson from "./geo.json";
import 'react-circular-progressbar/dist/styles.css';
import Spinner from './icons/spinner.gif';
import "./default.css"
import {
    deleteColisG,
    getVilleVente,
    getSecteursPercents,
    getColis,
    getDashboard,
    getChart,
} from "../colis/action";
import Exp from './export.svg'
import {loadUser} from "../../redux/signin/action";
import {connect, useDispatch} from "react-redux";
import PropTypes from "prop-types";
import * as echarts from 'echarts/core';
import ReactEcharts from "echarts-for-react"
import 'echarts/extension/bmap/bmap'
import {
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent
} from 'echarts/components';
import {BarChart} from 'echarts/charts';
import {CanvasRenderer} from 'echarts/renderers';
import {MapChart} from 'echarts/charts';
import ExportExcel from "../../Excelexport";
import {getUnseenNews} from "../news/actions";
import NewsPopup from "../../layout/popUp/popUp";
import Alert from "../../redux/Alert";
import CountUp from "react-countup";
import AppContext from "../../context/Context";


const Default = ({gestionData, unseenNews}) => {
    const [daytimes, setDayTimes] = useState();
    const today = new Date();
    const curHr = today.getHours();
    const [, setMeridiem] = useState("AM");
    const [ville, setVille] = useState("created_at=today")
    const [dashboard, setDashboard] = useState(`created_at__gte=${currentMonthStartDate()}`)
    const [chart, setChart] = useState('range=this_year')
    const [secteur, setSecteur] = useState("created_at=today")
    const [gestion, setGestion] = useState([]);
    const dispatch = useDispatch();
    const [viewAll, setviewAll] = useState(false);
    const [viewAllS, setviewAllS] = useState(false);
    const {
        config: {group, refresh, access},
        setConfig
    } = useContext(AppContext);
    const history = useHistory()
    echarts.use([
        TitleComponent,
        ToolboxComponent,
        TooltipComponent,
        GridComponent,
        LegendComponent,
        BarChart,
        CanvasRenderer,
        MapChart,
    ]);

    useEffect(() => {
        loadUser(refresh).then(res => setConfig('access', res?.data?.access))
            .catch(err => {
                if (err.response.status === 401 || err.response.status === 400){
                    setConfig('isAuthenticated', false)
                    localStorage.clear()
                    history.push('/signin')
                }
            })
        // eslint-disable-next-line
    }, [])

    var colorPalette = ["#cc770a", "#eb9a70", "#2f72be", "#7e0328", "#c53460", "#dbcaea", "#45ceba", "#eaefa2", "#fed7b5", "#9199f4", "#c8cc01", "#03e6d5"]
    useEffect(() => {
        dispatch(getDashboard(dashboard));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboard, access]);

    useEffect(() => {
        dispatch(getUnseenNews())
    }, [dispatch, access])

    useEffect(() => {
        if (gestionData.dashboard) {
            setGestion(gestionData.dashboard);

        }
    }, [gestionData.dashboard, gestionData.loading, gestion]);
    useEffect(() => {
        dispatch(getVilleVente(ville));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ville, access]);

    useEffect(() => {
        if (gestionData.villevente) {
            setGestion(gestionData.villevente);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gestionData.loading]);

    useEffect(() => {
        dispatch(getSecteursPercents(secteur));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secteur, access]);

    useEffect(() => {
        dispatch(getChart(chart))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chart, access])

    useEffect(() => {
        if (gestionData.secteurpercents) {
            setGestion(gestionData.secteurpercents);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gestionData.loading]);
    const headers = [
        {label: 'Ville', key: 'delegation'},
        {label: 'nombre de vente', key: 'nbr_vente'},
        {label: 'chiffre d"Affaire', key: 'chiffre_aff'},
        {label: 'coordonnée', key: 'nbr_fournisseur'},
    ];
    const testheaders = [
        {label: 'Month', key: 'month'},
        {label: 'Crée', key: 'cree'},
        {label: 'Livrée', key: 'paye'},
        {label: 'Retour', key: 'retour'},
    ];

    let resultCree = gestionData.chart && gestionData.chart.chart_bars ? gestionData.chart.chart_bars.payer : "";
    let resultPaye = gestionData.chart && gestionData.chart.chart_bars ? gestionData.chart.chart_bars.livrer : "";
    let resultRetour = gestionData.chart && gestionData.chart.chart_bars ? gestionData.chart.chart_bars.retour : "";
    const BarList = gestionData.chart && gestionData.chart.chart ? gestionData.chart.chart.map((item) => (
        {
            "Month": item.month,
            "Payé": item.payer,
            "Retour": item.retour,
            "Livrée": item.livrer
        }
    )) : []


    const listSections = gestionData && gestionData.secteurpercents.map((S) => (
        {
            name: S.secteur,
            value: S.percents
        }
    ))
    const listSection = gestionData && gestionData.secteurpercents.map((S) => (
        {
            "Secteur": S.secteur,
            "Pourcentage": S.percents
        }
    ))
    const BarOptions = {

        tooltip: {
            trigger: 'axis',
            axisPointer: {
                // Use axis to trigger tooltip
                type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            }
        },
        toolbox: {
            show: true,

        },
        legend: {
            orient: 'vertical',
            right: "left"
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'value'
        },
        yAxis: {
            type: 'category',
            data: gestionData && gestionData.chart && gestionData.chart.chart_bars.months.map((item) => (
                item
            ))
        },
        series: [
            {
                name: 'Payé',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                    formatter: function (param) {
                        return param.data === 0 ? '' : param.data;
                    }
                },
                emphasis: {
                    focus: 'series'
                },
                data: resultCree,
                color: " #56d0d6",
            },
            {
                name: 'Livrée',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                    formatter: function (param) {
                        return param.data === 0 ? '' : param.data;
                    }
                },
                emphasis: {
                    focus: 'series'
                },
                data: resultPaye,
                color: "#edae48",
            },

            {
                name: 'Retour',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                    formatter: function (param) {
                        return param.data === 0 ? '' : param.data;
                    }
                },
                emphasis: {
                    focus: 'series'
                },
                data: resultRetour,
                color: "#193256",
            },


        ]
    };
    const PieOptions = {

        tooltip: {
            trigger: 'item'
        },
        toolbox: {
            show: true,
        },

        series: [
            {
                name: 'Access From',
                type: 'pie',
                radius: '50%',
                data: listSections,

                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)',

                    }
                },
                color: colorPalette,

            },

        ]
    };

    const listMapT = gestionData && gestionData.villevente.map((V) => (
        {
            delegation: V.delegation,
            nbr_vente: V.nbr_vente,
            nbr_fournisseur: V.nbr_fournisseur,
            chiffre_aff: Math.round(V.chiffre_aff * 100) / 100,
        }
    ))
    const listMapF = gestionData && gestionData.villevente.map((V) => (
        {
            delegation: V.delegation,
            nbr_vente: V.nbr_vente,
            chiffre_aff: Math.round(V.chiffre_aff * 100) / 100,
        }
    ))

    const Foptions = {

        tooltip: {
            trigger: "item",
            showDelay: 0,
            transitionDuration: 0.2,
            formatter: function (params) {
                let value = (params.value + "").split(".");
                value[0].replace(/(\d{1,3})(?=(?:\d{3})+(?!\d))/g, "$1,");
                return `${params.name}<br />
              ${params.data.name1}: ${params.data.chiff}<br />
              ${params.data.name2}: ${params.data.value} <br/>`;
            }
        },
        visualMap: {
            left: "right",
            min: gestionData.villeMin,
            max: gestionData.villeMax,
            inRange: {
                color: [
                    "#dddddd",
                    "#3b496a",
                    "#193256"
                ]
            },
            text: ["High", "Low"], // 文本，默认为数值文本
            calculable: true
        },
        toolbox: {
            show: true,
            //orient: 'vertical',
            left: "left",
            top: "top"

        },
        series: [
            {
                name: "tunisia",
                type: "map",
                roam: true,
                map: "tunisia",
                emphasis: {
                    label: {
                        show: true
                    }
                },
                data: gestionData && gestionData.villevente.map((V) => (
                    {
                        name: V.delegation && V.delegation === "Kef" ? "Le Kef" : V.delegation === "Sidi Bouzid" ? "Sidi Bouziid" : V.delegation,
                        name1: "chiffred'affaire",
                        chiff: Math.round(V.chiffre_aff * 100) / 100,
                        name2: 'nombre des colis',
                        value: V.nbr_vente
                    }
                ))
            }
        ]
    };


    const Aoptions = {

        tooltip: {
            trigger: "item",
            showDelay: 0,
            transitionDuration: 0.2,
            formatter: function (params) {
                return `${params.name}<br />
          ${params.data.name1}: ${params.data.value}<br />
          ${params.data.name2}: ${params.data.chiff} <br/>
          ${params.data.name3}: ${params.data.nbrF} <br/>`;
            }
        },
        visualMap: {
            left: "right",
            min: gestionData.villeMin,
            max: gestionData.villeMax,
            inRange: {
                color: [
                    "#dddddd",


                    "#3b496a",
                    "#193256"
                ]
            },
            text: ["High", "Low"], // 文本，默认为数值文本
            calculable: true
        },
        toolbox: {
            show: true,
            //orient: 'vertical',
            left: "left",
            top: "top"

        },
        series: [
            {
                name: "tunisia",
                type: "map",
                roam: !true,
                map: "tunisia",
                emphasis: {
                    label: {
                        show: true
                    }
                },
                data: gestionData && gestionData.villevente.map((V) => (
                    {
                        name: V.delegation && V.delegation === "Kef" ? "Le Kef" : V.delegation === "Sidi Bouzid" ? "Sidi Bouziid" : V.delegation,
                        name2: "chiffred'affaire",
                        chiff: Math.round(V.chiffre_aff * 100) / 100,
                        name1: 'nombre des colis',
                        value: V.nbr_vente,
                        name3: "nombre des fournisseurs",
                        nbrF: V.nbr_fournisseur,
                    }
                ))
            }
        ]
    };
    useEffect(() => {
        echarts.registerMap("tunisia", geoJson);
    }, []);
    useEffect(() => {
        if (curHr < 12) {
            setDayTimes("Good Morning");
        } else if (curHr < 18) {
            setDayTimes("Good Afternoon");
        } else {
            setDayTimes("Good Evening");
        }

        if (curHr >= 12) {
            setMeridiem("PM");
        } else {
            setMeridiem("AM");
        }

        // eslint-disable-next-line
    }, [daytimes]);


    let user_groups_name = group;
    if (user_groups_name.indexOf('livreur') !== -1) {
        return <Redirect to={"/colis?created_at=today"}/>
    } else return (
        <Fragment>
            <Alert/>
            <Breadcrumb parent="Dashboard"/>
            {user_groups_name && user_groups_name.indexOf("admin") === -1 && <NewsPopup unseenNews={unseenNews}/>}


            {/* Dashboard content goes here */}
            {/* admin */}
            {/* 5 cards  */}
            <Row className={"mb-2"}>
                <Col md={1}></Col>
                <Col>
                    <select onChange={(e) => setDashboard(e.target.value)} className="sel" style={{
                        border: "3px solid #FFF",
                        borderRadius: "3px",
                        backgroundColor: "white",
                        padding: "5px",
                        textDecoration: "none"
                    }}>
                        {/*<option id="1" value="created_at=today">Aujourd'hui</option>
                                <option id="2" value="created_at=week">Cette semaine</option>*/}
                        <option id="3" value={`created_at__gte=${currentMonthStartDate()}`}>Ce mois</option>
                        <option id="4" value="created_at=year">Cette année</option>
                        <option id="5" value="">Depuis premier jour</option>
                    </select>
                </Col>
                <Col md={1}></Col>
            </Row>
            <Row style={{textAlign: "center"}}>

                <Col md={1}></Col>

                <Col style={{paddingLeft: "0px"}} md={2}>
                    <Link to={"/gestion-colis"}>
                        <Card style={{padding: "15px", margin: "0px", borderRadius: "10px"}}>
                            {gestionData && gestionData.dashboardLoading
                                ? <Col md={12}>
                                    <div className="text-center">
                                        <img src={Spinner} style={{width: "10%"}} alt=""/>
                                    </div>
                                </Col>
                                : <>
                                    <Row>
                                        <Col xl={4}><img src={All} width={"50"} alt={""}/></Col>
                                        <Col xl={8}><Row>
                                        </Row><label
                                            style={{fontWeight: "800"}}>Tous</label><br/>{gestionData && gestionData.dashboard ? Math.round(gestionData.dashboard.tous_flous * 100) / 100 : 0} DT <br/> {gestionData && gestionData.dashboard ? gestionData.dashboard.tous : ""} Colis
                                        </Col>
                                    </Row>
                                </>
                            }
                        </Card>
                    </Link>
                    <br/>
                </Col>
                <Col md={2}>
                    <Link to={"/gestion-colis?status=anomalie"}>
                        <Card style={{padding: "15px", margin: "0px", borderRadius: "10px"}}>
                            {gestionData && gestionData.dashboardLoading
                                ? <Col md={12}>
                                    <div className="text-center">
                                        <img src={Spinner} style={{width: "10%"}} alt=""/>
                                    </div>
                                </Col>
                                : <>
                                    <Row>
                                        <Col xl={4}><img src={IconAnamolie} width={"50"} alt={""}/></Col>
                                        <Col xl={8}><label
                                            style={{fontWeight: "800"}}>Anomalie</label><br/>{gestionData && gestionData.dashboard ? Math.round(gestionData.dashboard.anomalie_flous * 100) / 100 : 0} DT<br/> {gestionData && gestionData.dashboard ? gestionData.dashboard.anomalie : ""} Colis</Col>
                                    </Row>
                                </>
                            }
                        </Card></Link>
                    <br/>
                </Col>
                <Col md={2}>
                    <Link to={"/gestion-colis?status=livrer"}>
                        <Card style={{padding: "15px", margin: "0px", borderRadius: "10px"}}>
                            {gestionData && gestionData.dashboardLoading
                                ? <Col md={12}>
                                    <div className="text-center">
                                        <img src={Spinner} style={{width: "10%"}} alt=""/>
                                    </div>
                                </Col>
                                : <>
                                    <Row>
                                        <Col xl={4}><img src={Livree} width={"50"} alt={""}/></Col>
                                        <Col xl={8}><label style={{fontWeight: "800"}}>Livrée</label>
                                            <br/>{gestionData && gestionData.dashboard ? Math.round(gestionData.dashboard.livrer_flous * 100) / 100 : 0} DT <br/> {gestionData && gestionData.dashboard ? gestionData.dashboard.livrer : ""} Colis</Col>
                                    </Row>
                                </>
                            }
                        </Card>
                    </Link>
                    <br/>
                </Col>
                <Col md={2}>
                    <Link to={"/gestion-colis?status=en_cours_de_livraison"}>
                        <Card style={{padding: "15px", margin: "0px", borderRadius: "10px"}}>
                            {gestionData && gestionData.dashboardLoading
                                ? <Col md={12}>
                                    <div className="text-center">
                                        <img src={Spinner} style={{width: "10%"}} alt=""/>
                                    </div>
                                </Col>
                                : <>
                                    <Row>
                                        <Col xl={4}><img src={Process} width={"50"} alt={""}/></Col>
                                        <Col xl={8}><label
                                            style={{fontWeight: "800"}}>Encours</label><br/>{gestionData && gestionData.dashboard ? Math.round(gestionData.dashboard.en_cours_flous * 100) / 100 : 0} DT <br/> {gestionData && gestionData.dashboard ? gestionData.dashboard.en_cours : ""} Colis</Col>
                                    </Row>
                                </>
                            }
                        </Card>
                    </Link>
                    <br/>
                </Col>
                <Col style={{paddingRight: "0px"}} md={2}>
                    <Link to={"/gestion-colis?status=retour"}>
                        <Card style={{padding: "15px", margin: "0px", borderRadius: "10px"}}>
                            {gestionData && gestionData.dashboardLoading
                                ? <Col md={12}>
                                    <div className="text-center">
                                        <img src={Spinner} style={{width: "10%"}} alt=""/>
                                    </div>
                                </Col>
                                : <>
                                    <Row>
                                        <Col xl={4}><img src={Back} width={"50"} alt={""}/></Col>
                                        <Col xl={8}><label
                                            style={{fontWeight: "bold"}}>Retour</label><br/> {gestionData && gestionData.dashboard ? Math.round(gestionData.dashboard.retour_flous * 100) / 100 : 0} DT <br/> {gestionData && gestionData.dashboard ? gestionData.dashboard.retour : ""} Colis</Col>

                                    </Row>
                                </>
                            }
                        </Card>
                    </Link>
                </Col>

                <Col md={1}></Col>
            </Row>

            {/* stats des colis */}
            {user_groups_name.indexOf("admin") !== -1 ? (
                    <>
                        <Row>
                            <>
                                <Col md={1}></Col>
                                <Col style={{padding: "0px"}} md={10}>
                                    <Card style={{margin: "20px 0px", overflowX: "hidden", borderRadius: "10px"}}>
                                        <Row style={{padding: "15px", backgroundColor: "#eaeaea"}}>

                                            <Col md={8}>
                                                <h5 style={{color: "#193256", margin: "0px"}}> Stats des colis<label
                                                    style={{
                                                        fontSize: "15px",
                                                        color: "#193256",
                                                        marginLeft: "5px",
                                                        marginBottom: "0px"
                                                    }}>(Cette année)</label></h5>
                                            </Col>

                                            <Col md={4} style={{textAlign: "right"}}>

                                                <ExportExcel
                                                    fileName={`stats-des-colis-${new Date().toISOString()}.xls`}
                                                    data={BarList} headers={testheaders} style={{
                                                    color: "#f2b707 ",
                                                    backgroundColor: "white",
                                                    padding: "7px",
                                                    borderRadius: "3px"
                                                }} children={<img src={Exp} height={"15px"}
                                                                  style={{marginRight: "5px"}} alt={""}/>}/>

                                            </Col>
                                        </Row>
                                        {gestionData && gestionData.chartloading
                                            ? <Col md={12}>
                                                <div className="text-center">
                                                    <img src={Spinner} style={{width: "10%"}} alt=""/>
                                                </div>
                                            </Col>
                                            :
                                            BarOptions && <ReactEcharts
                                                option={BarOptions}
                                                style={{width: "100%", height: "300px"}}
                                            ></ReactEcharts>
                                        }
                                        <Row style={{padding: "20px", backgroundColor: "#eaeaea"}}>
                                            <Col md={4}>
                                                <select onChange={(e) => setChart(e.target.value)} className="sel"
                                                        style={{
                                                            border: "3px solid #FFF",
                                                            borderRadius: "3px",
                                                            backgroundColor: "white",
                                                            padding: "5px",
                                                            textDecoration: "none"
                                                        }}>
                                                    <option id="1" value="range=this_year">Cette année</option>
                                                    <option id="2" value="range=last_year">Derniers 12 mois</option>
                                                    <option id="3" value="range=all">tous</option>
                                                </select>
                                            </Col>
                                            <Col md={4}></Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col md={1}></Col>
                            </>
                        </Row>
                    </>
                ) :
                (
                    <Row>
                        <Col md={1}></Col>
                        {/* map + tableau */}
                        <Col
                            style={{paddingLeft: "0px"}}
                            md={5}>
                            <>

                                <Card style={{
                                    overflowX: "hidden",
                                    borderRadius: "10px",
                                    maxHeight: "605px"
                                }}>
                                    <Row style={{
                                        padding: "15px",
                                        backgroundColor: "#eaeaea",
                                        margin: "0px"
                                    }}>

                                        <Col md={7}
                                             style={{padding: "0px"}}>
                                            <h5 style={{
                                                color: "#193256",
                                                margin: "0px"
                                            }}> Stats
                                                des
                                                produits</h5>
                                        </Col>

                                        <Col
                                            style={{
                                                padding: "0px",
                                                textAlign: "right"
                                            }}
                                            md={5}>
                                            <ExportExcel
                                                fileName={`stats-des-ventes-${new Date().toISOString()}.xls`}
                                                data={listMapT} headers={headers}/>


                                        </Col>
                                    </Row>


                                    {Foptions && <ReactEcharts
                                        option={Foptions}
                                        notMerge={true}
                                        lazyUpdate={true}
                                        style={{
                                            height: "275px",
                                            padding: "10px",
                                            minHeight: "250px"
                                        }}
                                    ></ReactEcharts>}
                                    {/* table des villes */}
                                    <Table
                                        responsive
                                        style={{
                                            width: "100%",
                                            textAlign: "center",
                                            overflowX: "scroll"
                                        }}>
                                        <thead
                                            style={{backgroundColor: "#eaeaea"}}>
                                        <th style={{color: "#193256"}}>
                                            <label>Ville</label>
                                        </th>
                                        <th style={{color: "#193256"}}>Nombre
                                            de <br/> vente
                                        </th>
                                        <th style={{color: "#193256"}}>Chiffre<br/> d'affaire
                                        </th>
                                        </thead>
                                        {gestionData && gestionData.villeventeloading
                                            ?
                                            <div className="text-center">
                                                <img src={Spinner}
                                                     style={{width: "10%"}}
                                                     alt=""/>
                                            </div>
                                            :
                                            <tbody>
                                            {listMapF.slice(0, 3).map(({
                                                                           delegation,
                                                                           nbr_vente,
                                                                           chiffre_aff
                                                                       }) => {
                                                return (
                                                    <tr>
                                                        <td>{delegation}</td>
                                                        <td>{nbr_vente}</td>
                                                        <td>{chiffre_aff}</td>
                                                    </tr>
                                                )
                                            })}
                                            {viewAll ? (
                                                listMapF.slice(3).map(({
                                                                           delegation,
                                                                           nbr_vente,
                                                                           chiffre_aff
                                                                       }) => {
                                                    return (
                                                        <tr>
                                                            <td>{delegation}</td>
                                                            <td>{nbr_vente}</td>
                                                            <td>{chiffre_aff}</td>
                                                        </tr>
                                                    )
                                                })

                                            ) : (
                                                <></>
                                            )
                                            }
                                            </tbody>}
                                    </Table>


                                    <Row style={{
                                        padding: "18px",
                                        backgroundColor: "#eaeaea"
                                    }}>
                                        <Col md={4}>
                                            <select
                                                onChange={(e) => setVille(e.target.value)}
                                                className="sel"
                                                style={{
                                                    border: "3px solid #FFF",
                                                    borderRadius: "3px",
                                                    backgroundColor: "white",
                                                    padding: "5px",
                                                    textDecoration: "none"
                                                }}>
                                                <option
                                                    id="1"
                                                    value="created_at=today">Aujourd'hui
                                                </option>
                                                <option
                                                    id="2"
                                                    value="created_at=week">Cette
                                                    semaine
                                                </option>
                                                <option
                                                    id="3"
                                                    value="created_at=month">Ce
                                                    mois
                                                </option>
                                                <option
                                                    id="4"
                                                    value="created_at=year">Cette
                                                    année
                                                </option>
                                                <option
                                                    id="5"
                                                    value="">Depuis
                                                    premier
                                                    jour
                                                </option>
                                            </select>
                                        </Col>
                                        <Col
                                            md={3}></Col>
                                        <Col
                                            style={{textAlign: "right"}}
                                            md={5}>
                                            <button
                                                onClick={() => setviewAll(!viewAll)}
                                                style={{
                                                    border: "none",
                                                    color: "#193256",
                                                    background: "none",
                                                    padding: "5px"
                                                }}>{viewAll ? "View Less" : "View All"}</button>
                                        </Col>
                                    </Row>

                                </Card>
                            </>

                        </Col>
                        <Col
                            style={{paddingRight: "0px"}}
                            md={5}>
                            <>
                                <Card style={{
                                    overflowX: "hidden",
                                    borderRadius: "10px",
                                    maxHeight: "605px"
                                }}>
                                    <Row style={{
                                        padding: "15px",
                                        backgroundColor: "#eaeaea",
                                        margin: "0px"
                                    }}>

                                        <Col md={8}
                                             style={{padding: "0px"}}>
                                            <h5 style={{
                                                color: "#193256",
                                                margin: "0px"
                                            }}> Stats
                                                des
                                                colis<span
                                                    style={{
                                                        fontSize: "12px",
                                                        marginLeft: "5px",
                                                        marginBottom: "0px"
                                                    }}>(Cette année)</span>
                                            </h5>
                                        </Col>

                                        <Col md={4}
                                             style={{
                                                 padding: "0px",
                                                 textAlign: "right"
                                             }}>
                                            <ExportExcel
                                                fileName={`stats-des-colis-${new Date().toISOString()}.xls`}
                                                data={BarList} headers={testheaders}
                                                style={{marginRight: "5px"}}/>

                                        </Col>
                                    </Row>
                                    {gestionData && gestionData.chartloading
                                        ?
                                        <div className="text-center">
                                            <img src={Spinner}
                                                 style={{width: "10%"}}
                                                 alt=""/>
                                        </div>
                                        :
                                        BarOptions && <ReactEcharts
                                            option={BarOptions}
                                            style={{
                                                width: "100%",
                                                height: "600px"
                                            }}
                                        ></ReactEcharts>}
                                    <Row style={{
                                        padding: "20px",
                                        backgroundColor: "#eaeaea"
                                    }}>
                                        <Col md={4}>
                                            <select
                                                onChange={(e) => setChart(e.target.value)}
                                                className="sel"
                                                style={{
                                                    border: "3px solid #FFF",
                                                    borderRadius: "3px",
                                                    backgroundColor: "white",
                                                    padding: "5px",
                                                    textDecoration: "none"
                                                }}>
                                                <option
                                                    id="1"
                                                    value="range=this_year">Cette
                                                    année
                                                </option>
                                                <option
                                                    id="2"
                                                    value="range=last_year">Derniers
                                                    12
                                                    mois
                                                </option>
                                                <option
                                                    id="3"
                                                    value="range=all">tous
                                                </option>
                                            </select>
                                        </Col>
                                        <Col>
                                        </Col>
                                        <Col
                                            md={4}></Col>
                                    </Row>
                                </Card>
                            </>

                        </Col>
                        <Col md={1}></Col>
                    </Row>
                )}
            {user_groups_name.indexOf("admin") !== -1 && (
                <Row>
                    <>
                        <Col md={1}></Col>
                        {/* ville a fort traffic(admin) */}
                        <Col style={{paddingLeft: "0px"}} md={5}>

                            <Card style={{overflowX: "hidden", borderRadius: "10px", maxHeight: "650px"}}>

                                <Row style={{padding: "15px", backgroundColor: "#eaeaea"}}>

                                    <Col md={7}>
                                        <h5 style={{margin: "0px", color: "#193256"}}> Stats des ventes</h5>
                                    </Col>

                                    <Col style={{textAlign: "right"}} md={5}>
                                        <ExportExcel
                                            fileName={`stats-des-ventes-${new Date().toISOString()}.xls`}
                                            data={listMapT} headers={headers} style={{
                                            color: "#f2b707 ",
                                            backgroundColor: "white",
                                            padding: "7px",
                                            borderRadius: "3px"
                                        }} children={<img src={Exp} height={"15px"}
                                                          style={{marginRight: "5px"}} alt={""}/>}/>
                                    </Col>

                                </Row>


                                {Aoptions && <ReactEcharts
                                    option={Aoptions}
                                    notMerge={true}
                                    lazyUpdate={true}
                                    style={{height: "300px", padding: "10px"}}
                                ></ReactEcharts>}


                                {/* table des villes */}

                                <Table responsive style={{width: "100%", textAlign: "center"}}>
                                    < thead style={{backgroundColor: "#eaeaea"}}>
                                    <th style={{color: "#193256"}}><label>Ville</label></th>
                                    <th style={{color: "#193256"}}>Nombre des <br/>fournisseur</th>
                                    <th style={{color: "#193256"}}>Nombre de <br/>vente</th>
                                    <th style={{color: "#193256"}}>Chiffre<br/> d'affaire</th>
                                    </ thead>
                                    {gestionData && gestionData.villeventeloading
                                        ?
                                        <div className="text-center">
                                            <img src={Spinner}
                                                 style={{width: "10%"}}
                                                 alt=""/>
                                        </div>
                                        :
                                        <tbody>
                                        {listMapT.slice(0, 3).map(({
                                                                       delegation,
                                                                       nbr_fournisseur,
                                                                       nbr_vente,
                                                                       chiffre_aff
                                                                   }) => {
                                            return (
                                                <tr>
                                                    <td>{delegation}</td>
                                                    <td>{nbr_fournisseur}</td>
                                                    <td>{nbr_vente}</td>
                                                    <td>{chiffre_aff}</td>
                                                </tr>
                                            )
                                        })}
                                        {viewAll ? (
                                            listMapT.slice(3).map(({
                                                                       delegation,
                                                                       nbr_fournisseur,
                                                                       nbr_vente,
                                                                       chiffre_aff
                                                                   }) => {
                                                return (
                                                    <tr>
                                                        <td>{delegation}</td>
                                                        <td>{nbr_fournisseur}</td>
                                                        <td>{nbr_vente}</td>
                                                        <td>{chiffre_aff}</td>
                                                    </tr>
                                                )
                                            })
                                        ) : (
                                            <></>
                                        )
                                        }
                                        </tbody>}
                                </Table>
                                <Row style={{padding: "20px", backgroundColor: "#eaeaea"}}>
                                    <Col md={4}>
                                        <select onChange={(e) => setVille(e.target.value)} className="sel"
                                                style={{
                                                    border: "3px solid #FFF",
                                                    borderRadius: "3px",
                                                    backgroundColor: "white",
                                                    padding: "5px",
                                                    textDecoration: "none"
                                                }}>
                                            <option id="1" value="created_at=today">Aujourd'hui</option>
                                            <option id="2" value="created_at=week">Cette semaine</option>
                                            <option id="3" value="created_at=month">Ce mois</option>
                                            <option id="4" value="created_at=year">Cette année</option>
                                            <option id="5" value="">Depuis premier jour</option>
                                        </select>
                                    </Col>
                                    <Col md={4}></Col>
                                    <Col style={{textAlign: "right"}} md={4}>
                                        <button onClick={() => setviewAll(!viewAll)} style={{
                                            border: "none",
                                            color: "#193256",
                                            background: "none",
                                            padding: "5px"
                                        }}>{viewAll ? "View Less" : "View All"}</button>
                                    </Col>


                                </Row>

                            </Card>
                        </Col>

                        {/* secteur/pourcentage */}
                        <Col style={{paddingRight: "0px "}} md={5}>
                            <Card style={{overflowX: "hidden", borderRadius: "10px", maxHeight: "662px"}}>
                                <Row style={{padding: "15px", backgroundColor: "#eaeaea"}}>
                                    <Col md={7}><h5 style={{color: "#193256", margin: "0px"}}>Secteurs en %</h5>
                                    </Col>

                                    <Col style={{textAlign: "right"}} md={5}>
                                        <ExportExcel
                                            fileName={`stats-des-secteurs-${new Date().toISOString()}.xls`}
                                            data={listSection} style={{
                                            color: "#f2b707 ",
                                            backgroundColor: "white",
                                            padding: "7px",
                                            borderRadius: "3px"
                                        }} children={<img src={Exp} height={"15px"}
                                                          style={{marginRight: "5px"}} alt={""}/>}/>
                                    </Col>
                                </Row>


                                {PieOptions && <ReactEcharts
                                    option={PieOptions}
                                    style={{width: "100%", height: "300px"}}
                                ></ReactEcharts>}

                                <Table responsive style={{textAlign: "center", overflowX: "scroll"}}>
                                    <thead style={{backgroundColor: "#EAEAEA"}}>
                                    <th style={{color: "#193256"}}>Secteur <br/> d'activité</th>
                                    <th style={{color: "#193256"}}><label>Pourcentage</label></th>
                                    </thead>
                                    {gestionData && gestionData.secteurloading
                                        ? <Col md={12}>
                                            <div className="text-center">
                                                <img src={Spinner} style={{width: "10%"}} alt=""/>
                                            </div>
                                        </Col>
                                        :
                                        <tbody>
                                        {listSections.slice(0, 3).map(({name, value}) => {
                                            return (
                                                <tr>
                                                    <td>{name}</td>
                                                    <td>{value}</td>
                                                </tr>
                                            )
                                        })}
                                        {viewAllS ? (
                                            listSections.slice(3).map(({name, value}) => {
                                                return (
                                                    <tr>
                                                        <td>{name}</td>
                                                        <td>{value}</td>
                                                    </tr>
                                                )
                                            })
                                        ) : (
                                            <></>
                                        )}
                                        </tbody>}
                                </Table>

                                <Row style={{backgroundColor: "#eaeaea", padding: "20px"}}>
                                    <Col md={4}>
                                        <select onChange={(e) => setSecteur(e.target.value)} className="sel"
                                                style={{
                                                    border: "3px solid #FFF",
                                                    borderRadius: "3px",
                                                    backgroundColor: "white",
                                                    padding: "5px",
                                                    textDecoration: "none"
                                                }}>

                                            <option id="1" value="created_at=today">Aujourd'hui</option>
                                            <option id="2" value="created_at=week">Cette semaine</option>
                                            <option id="3" value="created_at=month">Ce mois</option>
                                            <option id="4" value="created_at=year">Cette année</option>
                                            <option id="5" value="">Depuis premier jour</option>

                                        </select>
                                    </Col>
                                    <Col md={4}></Col>
                                    <Col style={{textAlign: "right"}} md={4}>
                                        <button onClick={() => setviewAllS(!viewAllS)} style={{
                                            border: "none",
                                            color: "#193256",
                                            background: "none",
                                            padding: "5px"
                                        }}>{viewAllS ? "View Less" : "View All"}</button>
                                    </Col>
                                </Row>


                            </Card>
                        </Col>
                        <Col md={1}></Col>

                        <Col style={{
                            padding: "0px",
                            marginLeft: "10%",
                            marginBottom: "5%",
                            marginRight: "1.5%"
                        }}>

                            <Row style={{padding: "0", backgroundColor: "#eaeaea"}}>

                                <Table responsive style={{textAlign: "center"}}>
                                    <thead>
                                    <tr>
                                        <th>Nombre des colis</th>
                                        <th>Status</th>
                                        <th>Montant</th>
                                    </tr>
                                    </thead>
                                    <tbody style={{
                                        color: "#f2b707 ",
                                        backgroundColor: "white",
                                        padding: "7px",
                                        borderRadius: "3px"
                                    }}>
                                    <tr>
                                        <td><CountUp end={gestionData.dashboard.en_cours}/>
                                        </td>
                                        <td>En attente de paiement</td>
                                        <td>{gestionData && gestionData.dashboard ? Math.round(gestionData.dashboard.en_cours_flous * 100) / 100 : 0} DT
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>

                                            <CountUp end={gestionData.dashboard.payer}/>

                                        </td>
                                        <td>Payée</td>
                                        <td>{gestionData && gestionData.dashboard ? Math.round(gestionData.dashboard.payer_flous * 100) / 100 : 0} DT
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>

                            </Row>


                        </Col>

                        <Col md={1}></Col>
                    </>
                </Row>
            )}
        </Fragment>
    );
};

Default.propTypes = {
    gestionData: PropTypes.object.isRequired,
    unseenNews: PropTypes.object.isRequired,
};

const mapstatetoprops = (state) => ({
    gestionData: state.reducerc,
    unseenNews: state.reducernews
});

export default connect(mapstatetoprops, {
    getColis,
    getUnseenNews, deleteColisG, getVilleVente, getSecteursPercents
})(
    withRouter(Default)
);