import React, {
    Fragment,
    useState,
    useEffect,
    useMemo, useContext,
} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import Alert from "../../redux/Alert";
import PropTypes from "prop-types";
import Spinner from "../colis/spinner.gif";
import {connect, useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {withRouter, useLocation} from "react-router-dom";
import {
    getStocks,
    deleteStock,
    addNewCategorie,
    addNewSousCategorie,
    getCategories,
    getProduits,

} from "./action";
import {
    Container,
    Row,
    Col,
    Table,
    Card,
    CardHeader,
    CardBody,
    InputGroup,
    Modal,
    ModalHeader,
    ModalBody,
    InputGroupAddon,
    ModalFooter,
    Form,
    Label,
    Input,

} from "reactstrap";
import {getColis} from "../colis/action";
import Error404 from "../../pages/errors/error404";
import PaginationWrapper from "../pagination/pagination";
import AppContext from "../../context/Context";

const Stcokdepot = ({
                        addNewCategorie,
                        addNewSousCategorie,
                        stockData,
                        categorieData,
                        deleteStock,
                        history,
                    }) => {
    const [stock, setStock] = useState([]);
    const [ascId, setAscSortId] = useState(true);
    const [categori, setCategori] = useState([]);
    const [modal, setModal] = useState(false);
    const [modal1, setModal1] = useState(false);
    const {
        config: {group, stock_service}
    } = useContext(AppContext)

    const dispatch = useDispatch();
    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };
    let query = useQuery();
    //get stock
    useEffect(() => {
        dispatch(getStocks(query));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setStock(stockData.stocks);
    }, [stockData.stocks, stockData.loading]);

    // get categories
    useEffect(() => {
        dispatch(getCategories(query));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setCategori(categorieData.categories);
    }, [categorieData.categories, categorieData.loading, categori]);
    const [formDatacategorie, setFormDataCategorie] = useState({
        name: "",
    });

    const onChangecategorie = (e) =>
        setFormDataCategorie({
            ...formDatacategorie,
            [e.target.name]: e.target.value,
        });

    const onSubmitcategorie = (e) => {
        e.preventDefault();
        addNewCategorie(formDatacategorie, history, true);
        dispatch(getCategories(query));
    };
    const [formDatasouscategorie, setFormDatasouscategorie] = useState({
        category: "",
        name: "",
    });

    const onChangesouscategorie = (e) =>
        setFormDatasouscategorie({
            ...formDatasouscategorie,
            [e.target.name]: e.target.value,
        });

    const onSubmitsouscategorie = (e) => {
        e.preventDefault();
        addNewSousCategorie(formDatasouscategorie, history, true);
    };

    const onSearchQuery = (e) => {
        query.set('search', e.target.value)
        dispatch(getStocks(query));
    };
    const getValSearch = () => {
        let params = new URLSearchParams(document.location.search);
        return params.get("q");
    };

    const onAscSort = (e) => {
        const qr = query.toString() + `&ordering=${e.target.id}`;
        setAscSortId(false);
        dispatch(getColis(qr));
        setStock(stock);
    };

    const onDescSort = (e) => {
        const qr = query.toString() + `&ordering=-${e.target.id}`;
        setAscSortId(true);
        dispatch(getColis(qr));
        setStock(stock);
    };
    let user_groups_name = group;
    let st = stock_service;
    let count = stockData.count;
    let pagenumber = query.get("page") ? query.get("page") : 1;
    const Navigate = (page) => {
        query.set("page", Number(page));

        window.location.replace(`/stock_depot/?${query}`);
    };
    return (
        <Fragment>
            <Breadcrumb parent="Stock" title="Stock dépôt"/>
            {(user_groups_name && user_groups_name.indexOf("admin") !== -1) || st ? (
                    <div>

                        <Container fluid={true}>
                            <Alert/>
                            <Row>
                                <Col sm="12">
                                    <Card>
                                        <CardHeader>
                                            <Row>
                                                <Col sm="10">
                                                    <button
                                                        onClick={() => setModal(true)}
                                                        className="btn btn-primary"
                                                    >
                                                        Ajouter catégorie
                                                    </button>
                                                    <button
                                                        onClick={() => setModal1(true)}
                                                        className="btn btn-primary ml-2"
                                                    >
                                                        Ajouter sous catégorie
                                                    </button>
                                                </Col>
                                                <Col>
                                                    <Link
                                                        to={"/stock_depot/new"}
                                                        className="btn  text-dark"
                                                        style={{backgroundColor: "#FDF203"}}
                                                    >
                                                        Ajouter nouveau
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        {/* {datacolis.colis.length > 0 ? () : ()} */}

                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <CardHeader sm="12">
                                                        <InputGroup>
                                                            <Form className="theme-form">
                                                                <InputGroupAddon addonType="append">
                                                                    <Col>
                                                                        <div>
                                                                            <input
                                                                                className="border search"
                                                                                style={{borderRadius: "7px"}}
                                                                                type="search"
                                                                                placeholder="Rechercher..."
                                                                                name="q"
                                                                                defaultValue={getValSearch()}
                                                                                onChange={(e) => onSearchQuery(e)}
                                                                            />
                                                                            <button
                                                                                type="submit"
                                                                                className="btn text-white ml-2"
                                                                                style={{backgroundColor: "#27B3B0"}}
                                                                            >
                                                                                Valider
                                                                            </button>
                                                                        </div>
                                                                    </Col>
                                                                </InputGroupAddon>
                                                            </Form>
                                                        </InputGroup>
                                                    </CardHeader>
                                                </Col>
                                            </Row>
                                            <Col sm="12">
                                                <Card>
                                                    <div className="card-block row">
                                                        <Col sm="12" lg="12" xl="12">
                                                            <div className="table-responsive">
                                                                {stockData && stockData.loading ? (
                                                                    <div className="text-center">
                                                                        <img src={Spinner} style={{width: "10%"}} alt=""/>
                                                                    </div>
                                                                ) : (
                                                                    <Table striped>
                                                                        <thead>
                                                                        <tr>
                                                                            <th
                                                                                scope="col"
                                                                                id="reference"
                                                                                onClick={(e) =>
                                                                                    ascId ? onAscSort(e) : onDescSort(e)
                                                                                }
                                                                            >
                                                                                {"Ref"}
                                                                            </th>
                                                                            <th scope="col">{"Image"}</th>
                                                                            {user_groups_name && user_groups_name.indexOf("admin") !== -1 &&
                                                                                <th
                                                                                    scope="col"
                                                                                    id="fournisseur_name"
                                                                                    onClick={(e) =>
                                                                                        ascId ? onAscSort(e) : onDescSort(e)
                                                                                    }
                                                                                >
                                                                                    {"Fournisseur"}
                                                                                </th>
                                                                            }
                                                                            <th
                                                                                scope="col"
                                                                                id="category_name"
                                                                                onClick={(e) =>
                                                                                    ascId ? onAscSort(e) : onDescSort(e)
                                                                                }
                                                                            >
                                                                                {"Catégorie"}
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                                id="sub_cat_name"
                                                                                onClick={(e) =>
                                                                                    ascId ? onAscSort(e) : onDescSort(e)
                                                                                }
                                                                            >
                                                                                {"Sous Catégorie"}
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                                id="description"
                                                                                onClick={(e) =>
                                                                                    ascId ? onAscSort(e) : onDescSort(e)
                                                                                }
                                                                            >
                                                                                {"Nom"}
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                                id="etage"
                                                                                onClick={(e) =>
                                                                                    ascId ? onAscSort(e) : onDescSort(e)
                                                                                }
                                                                            >
                                                                                {"Poids (Kg)"}
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                                id="quantity"
                                                                                onClick={(e) =>
                                                                                    ascId ? onAscSort(e) : onDescSort(e)
                                                                                }
                                                                            >
                                                                                {"Qte"}
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                                id="cod"
                                                                                onClick={(e) =>
                                                                                    ascId ? onAscSort(e) : onDescSort(e)
                                                                                }
                                                                            >
                                                                                {"Cod"}
                                                                            </th>
                                                                            <th scope="col">{"Actions"}</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {stockData &&
                                                                            stockData.stocks.map((b) => (
                                                                                <tr key={b.id}>
                                                                                    <td>
                                                                                        <Link
                                                                                            to={`/stock_depot/edit/${b.id}`}
                                                                                        >
                                                                                            {b.reference}
                                                                                        </Link>
                                                                                    </td>
                                                                                    <td>
                                                                                        <img
                                                                                            className="image"
                                                                                            src={`${b.url_path}`}
                                                                                            alt=""
                                                                                        />
                                                                                    </td>
                                                                                    {user_groups_name && user_groups_name.indexOf("admin") !== -1 &&
                                                                                        <td>{b.fournisseur_name}</td>
                                                                                    }
                                                                                    <td>{b._category_name}</td>
                                                                                    <td>{b.sub_cat_name}</td>
                                                                                    <td>{b.name}</td>
                                                                                    <td>{b.weight}</td>
                                                                                    <td>{b.stock}</td>
                                                                                    <td>{b.cod}</td>

                                                                                    <td>
                                                                                        <div>
                                                                                            <button
                                                                                                onClick={() => {
                                                                                                    deleteStock(b.id);
                                                                                                    dispatch(getStocks(query));
                                                                                                    dispatch(getStocks(query));
                                                                                                }}
                                                                                                className="btn btn-danger m-1"
                                                                                            >
                                                                                                {" "}
                                                                                                <i className="fa fa-trash"></i>
                                                                                            </button>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </Table>)}
                                                            </div>
                                                        </Col>
                                                    </div>
                                                </Card>
                                            </Col>{" "}
                                            <Row>
                                                <Col sm="10"></Col>
                                                {stockData && stockData.loading ? "" :
                                                    <Col>
                                                        <PaginationWrapper
                                                            name="Produits"
                                                            length={stockData.stocks.length}
                                                            count={count}
                                                            currentPage={pagenumber}
                                                            onNavigate={Navigate}
                                                        />
                                                    </Col>}
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <div>
                                <Modal
                                    centered
                                    fullscreen="sm"
                                    size="md"
                                    className="relative min-h-screen flex flex-col sm:justify-center items-center"
                                    isOpen={modal}
                                    toggle={() => setModal(!modal)}
                                >
                                    <ModalHeader
                                        style={{
                                            backgroundImage:
                                                "linear-gradient(to right,#FFD166, #133A4A  )",
                                        }}
                                        className="p-3 text-white"
                                        toggle={() => setModal(!modal)}>
                                        Ajouter
                                    </ModalHeader>
                                    <ModalBody>
                                        <Row>
                                            <Col sm="12">
                                                <Form
                                                    className="theme-form"
                                                    onSubmit={(e) => {
                                                        onSubmitcategorie(e);
                                                        dispatch(getCategories(query));
                                                    }}
                                                >
                                                    <Label>Catégorie</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        name="name"
                                                        placeholder="catégorie"
                                                        value={formDatacategorie.category}
                                                        onChange={(e) => onChangecategorie(e)}
                                                    />
                                                    <ModalFooter>
                                                        <button
                                                            onClick={() => setModal(!modal)}
                                                            type="submit "
                                                            className="p-2 btn rounded text-white"
                                                            style={{backgroundColor: "#133A4A"}}
                                                        >
                                                            Confirm
                                                        </button>
                                                    </ModalFooter>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </ModalBody>
                                </Modal>
                            </div>
                            <div>
                                <Modal
                                    centered
                                    fullscreen="sm"
                                    size="md"
                                    className="relative min-h-screen flex flex-col sm:justify-center items-center"
                                    isOpen={modal1}
                                    toggle={() => setModal1(!modal1)}
                                >
                                    <ModalHeader
                                        style={{
                                            backgroundImage:
                                                "linear-gradient(to right,#FFD166, #133A4A  )",
                                        }}
                                        className="p-3 text-white"
                                        toggle={() => setModal1(!modal1)}>
                                        Ajouter sous Catégorie
                                    </ModalHeader>
                                    <ModalBody>
                                        <Row>
                                            <Col sm="12">
                                                <Form
                                                    className="theme-form"
                                                    onSubmit={(e) => onSubmitsouscategorie(e)}
                                                >
                                                    <Label>Catégorie</Label>
                                                    <select
                                                        className="form-control digits"
                                                        name="category"
                                                        value={formDatasouscategorie.category}
                                                        onChange={(e) => onChangesouscategorie(e)}
                                                    >
                                                        {" "}
                                                        <option value="" disabled defaultValue hidden>
                                                            ----------
                                                        </option>
                                                        {categorieData &&
                                                            categorieData.categories.map((C) => (
                                                                <option key={C.id} value={C.id}>
                                                                    {C.name}
                                                                </option>
                                                            ))}
                                                    </select>
                                                    <Label className="mt-2">Sous Catégorie</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        name="name"
                                                        placeholder="sous catégorie"
                                                        value={formDatasouscategorie.name}
                                                        onChange={(e) => onChangesouscategorie(e)}
                                                    />
                                                    <ModalFooter>
                                                        <button
                                                            onClick={() => setModal1(!modal1)}
                                                            type="submit "
                                                            className="p-2 btn-info rounded"
                                                        >
                                                            Confirm
                                                        </button>
                                                    </ModalFooter>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </ModalBody>
                                </Modal>
                            </div>
                            <div>
                            </div>
                        </Container>
                    </div>
                ) :
                (
                    <Error404 page="Stock dépôt"/>
                )
            }
        </Fragment>
    )
        ;
};

Stcokdepot.propTypes = {
    addNewCategorie: PropTypes.func.isRequired,
    addNewSousCategorie: PropTypes.func.isRequired,
    deleteStock: PropTypes.func.isRequired,
    getStocks: PropTypes.func.isRequired,
    stockData: PropTypes.object.isRequired,
    categorieData: PropTypes.object.isRequired,
    produitsData: PropTypes.object.isRequired,
};

const mapstatetoprops = (state) => ({
    stockData: state.reducerst,
    categorieData: state.reducerst,
});

export default connect(mapstatetoprops, {
    getStocks,
    deleteStock,
    getCategories,
    getProduits,
    addNewCategorie,
    addNewSousCategorie,
})(withRouter(Stcokdepot));