import {setAlert, setAlertD} from "../../redux/alert/action";
import {setAuthToken, api} from "../../utils/backend.instance";
import {
    GET_COMPTE,
    GET_COMPTES,
    COMPTE_LOADING,
    COMPTE_ERROR, COMMERCIALS_LOADING, GET_COMMERCIALS, COMMERCIALS_ERROR,
} from "../../redux/actionTypes";

export const addNewCompte = (fd, history) => async (dispatch) => {
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const config = {
            headers: {
                "Content-Type":
                    "multipart/form-data; charset=utf-8; boundary=" +
                    Math.random().toString().substr(2),
            },
        };
        const res = await api.post("/accounts/user/", fd, config);
        dispatch({
            type: GET_COMPTE,
            payload: res.data,
        });
        dispatch(setAlert("User Successfully Created", "success"));

        history.push("/compte");
    } catch (err) {
        const errors = err.response.data;
        if (errors) {
            dispatch(
                setAlertD(
                    (errors.username ? "Username: " + errors.username + " " : "") +
                    (errors.email ? "Email: " + errors.email + "\n" : "") +
                    (errors.is_active ? "Active: " + errors.is_active + " " : "") +
                    (errors.password ? "Password: " + errors.password + " " : ""),
                    "danger"
                )
            );
        }
        dispatch({
            type: COMPTE_ERROR,
            payload: {msg: err.response.data, status: err.response.status},
        });
    }
};

export const getComptes = (params) => async (dispatch) => {
    dispatch({type: COMPTE_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(`/accounts/user/?${params.toString()}`);

        dispatch({
            type: GET_COMPTES,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: COMPTE_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};


export const getCommercial = () => async (dispatch) => {
    dispatch({type: COMMERCIALS_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(`/accounts/commercial-list/`);

        dispatch({
            type: GET_COMMERCIALS,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: COMMERCIALS_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};


export const getCurrentCompte = (id) => async (dispatch) => {
    dispatch({type: COMPTE_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(`/accounts/user/${id}/`);

        dispatch({
            type: GET_COMPTE,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: COMPTE_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const updatecompte = (fd, id) => async (dispatch) => {
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.patch(`/accounts/user/${id}/`, fd);
        dispatch({
            type: GET_COMPTE,
            payload: res.data,
        });
        dispatch(setAlert("User Successfully Updated", "success"));
    } catch (err) {
        const errors = err.response.data;
        if (errors) {
            dispatch(
                setAlertD(
                    (errors.email ? "Email: " + errors.email + " " : "") +
                    (errors.last_name ? "Nom: " + errors.last_name + " " : "") +
                    (errors.first_name
                        ? "Prénom: " + errors.first_name + " "
                        : ""),
                    "danger"
                )
            );
        }

        dispatch({
            type: COMPTE_ERROR,
            payload: {
                msg: err.response.data,
                status: err.response.status,
            },
        });
    }
};


export const termsServices = () => async (dispatch) => {
    const res = await api.get('/accounts/terms_services/?terms_service=True')
    return res
}


export const updatecomptep = (fd, id) => async (dispatch) => {
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.patch(`/accounts/user/${id}/`, fd);
        dispatch({
            type: GET_COMPTE,
            payload: res.data,
        });
        dispatch(setAlert("User Successfully Updated", "success"));
    } catch (err) {
        const errors = err.response.data;
        if (errors) {
            dispatch(
                setAlertD(
                    (errors.email ? "Email: " + errors.email + " " : "") +
                    (errors.last_name ? "Nom: " + errors.last_name + " " : "") +
                    (errors.first_name
                        ? "Prénom: " + errors.first_name + " "
                        : ""),
                    "danger"
                )
            );
        }

        dispatch({
            type: COMPTE_ERROR,
            payload: {
                msg: err.response.data,
                status: err.response.status,
            },
        });
    }
};


export const impersonate = async (user_id)  => {
    const res = await api.get(`/accounts/impersonate/${user_id}/`);
    return res
};
