import React, {Fragment, useContext, useEffect, useMemo} from "react";
import {Link, useHistory, useLocation, useParams, withRouter} from "react-router-dom";
import {
    getLogs
} from "./action";
import {connect, useDispatch} from "react-redux";
import {Card, CardBody, Col, Form, InputGroupAddon, Row, Table} from "reactstrap";
import PaginationWrapper from "../pagination/pagination";
import PropTypes from "prop-types";
import Breadcrumb from "../../layout/breadcrumb";
import Spinner from "./spinner.gif";
import AppContext from "../../context/Context";

const Colilog = ({logsData}) => {
    const dispatch = useDispatch();
    const history = useHistory()

    const {
        config: {group},
    } = useContext(AppContext);

    const {id} = useParams();
    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };
    let query = useQuery();
    let pagenumber = query.get("page") ? query.get("page") : 1;
    const Navigate = (page) => {
        query.set("page", Number(page));

        window.location.replace(`/colis/edit/${id}/?${query}`);
    };
    let user_groups_name = group;
    useEffect(() => {
        query.set("object_id", id)
        dispatch(getLogs(query));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    let count = logsData.count;

    const GetChangesRepresentation = ({changes}) => {
        console.log("The Changes is ", changes)
        // const parsedChanges = JSON.parse(changes);
        if (!changes) {
            return <p>No changes provided</p>;
        }
    
        let parsedChanges;
        try {
            parsedChanges = JSON.parse(changes);
        } catch (error) {
            console.error('Failed to parse changes:', error);
            return <p>Invalid changes format</p>;
        }
        return Object.entries(parsedChanges).map(([key, [oldVal, newVal]]) => (
            <li key={key}>{`${key}: ${oldVal} => ${newVal}`}</li>
        ));
    }

    return (
        <Fragment>
            <Breadcrumb parent="Colis" title={`Données d'historique`}/>
            <Card>
                <CardBody>
                    <Col sm="12">
                        <Row>
                            <Col sm={4}></Col>
                            <Col>
                                <Form className="theme-form">
                                    <InputGroupAddon addonType="append">
                                        <Col>
                                            <div>
                                                <input
                                                    className="btn btn-light"
                                                    type="date"
                                                    name="created_at"
                                                    defaultValue={query.get("created_at")}
                                                    onChange={(e) => {
                                                        query.set("created_at", e.target.value)
                                                    }}
                                                />
                                                <button
                                                    type="button"
                                                    className="btn text-white  ml-2"
                                                    style={{backgroundColor: "#27B3B0"}}
                                                    onClick={(e) => {
                                                        history.push({search: query.toString()})
                                                    }}
                                                >
                                                    Valider
                                                </button>
                                            </div>

                                        </Col>
                                    </InputGroupAddon>
                                </Form>
                            </Col>
                        </Row>
                        <Card>
                            <div className="card-block row">
                                <Col sm="12" lg="12" xl="12">
                                    <div className="table-responsive">
                                        {logsData && logsData.loading ? (
                                                <div className="text-center">
                                                    <img src={Spinner} style={{width: "10%"}} alt=""/>
                                                </div>
                                            ) :
                                            (
                                                <><Table striped>
                                                    <thead>
                                                    <tr>
                                                        <th>{"Date"}</th>
                                                        <th>{"Coli"}</th>
                                                        <th>{"Changements"}</th>
                                                        {user_groups_name &&
                                                        user_groups_name.indexOf("admin") !== -1 ? (
                                                            <th>{"Acteur"}</th>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <th>{"Action"}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {logsData &&
                                                        logsData.logs.map((b) => (
                                                            <tr key={b.id}>
                                                                <td>{b.timestamp}</td>
                                                                <td><Link
                                                                    to={`/colis/detail/${b.object_pk}`}>{b.object_repr}</Link>
                                                                </td>
                                                                <td>{<GetChangesRepresentation
                                                                    changes={b.changes}/>}</td>
                                                                {user_groups_name.indexOf("admin") !== -1 && <td>
                                                                    {b.actor_username}</td>}
                                                                <td>{b.action_display}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                                </>
                                            )}
                                    </div>
                                </Col>
                            </div>
                        </Card>
                    </Col>{" "}
                    <Row>
                        <Col sm="10"></Col>
                        <Col>
                            <PaginationWrapper
                                name="Historiques"
                                length={logsData.logs.length}
                                count={count}
                                currentPage={pagenumber}
                                onNavigate={Navigate}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Fragment>
    )

}

Colilog.protoTypes = {
    getLogs: PropTypes.func.isRequired,
    logsData: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    logsData: state.reducerc
});

export default connect(mapStateToProps, {
    getLogs
})(withRouter(Colilog));
