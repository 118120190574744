import React from 'react'
import { useState } from 'react';
import lel from "../images/book-a-demo.png";
import {
  Card,
  CardBody,
  Col,
  Form,
  Row,
  Modal,
  ModalBody,
  Input,
  FormGroup,
} from "reactstrap";
import pp from "../images/une-performance-de-livraison.png"
export default function Section1({addNewDemande}) {
  const [modal, setModal] = useState(false);
  const [formData, setFormData] = useState({
      commande: 0, first_name: "", last_name: "", type: "Demo", demo: "", number: "", email: "", entreprise: "",
  });
  const onSubmit = (e) => {
    e.preventDefault();
    addNewDemande(formData);
};
const onChange = (e) => {
    e.preventDefault();
    setFormData({...formData, [e.target.name]: e.target.value});
};
  return (
    <>
    <Row style={{backgroundColor: "#f3f6f4"}}>
    

      <Col md={6} sm={12}>
      <img src={pp} style={{display : "block", width : "100%" ,}} alt={""}/>
      </Col>
      <Col md={5} sm={12} style={{paddingTop : "20px" , fontFamily : "Raleway" , padding : "100px 50px" , textAlign : "center" }} >

  <h1>Une performance de livraison</h1>
  <h6 style={{MarginBottom: "10px"}}>
Nous nous engageons à suivre les performances de notre réseau,<br/> de la commande de vos clients à la livraison.</h6>
  <button onClick={() => setModal(true)} style={{border: "none", position: "relative", fontSize: "25px", backgroundColor: "#F2B707", padding: "10px 40px", borderRadius: "30px", color: "white"}}>Book a demo</button>

      </Col>
      <Col md={1} ></Col>
    </Row>
    <Modal
                centered
                size="xl"
                className="relative flex flex-col sm:justify-center items-center"
                isOpen={modal}
                toggle={() => setModal(!modal)}
            >
                <ModalBody  style={{fontFamily: "typo" , backgroundImage : `url("${lel}")` }}>
                    <Row>
                        <Col sm="5" style={{backgroundImage : `url("${lel}")` , backgroundSize : "cover" , color:"white" }}>
                            <p className="m-2" style={{fontSize: "30px"}}>
                                Réserver une démo
                            </p>
                            <br/>
                            <p className="m-2" style={{fontSize: "15px"}}>
                                Beezit est la solution logistique tunisienne garantissant la
                                meilleure experience clients et augmentant vos ventes
                            </p>
                            <br/>
                            <li className="m-2">Accédez à une tarification simple</li>

                            <li className="m-2">
                                Accédez à une solution de livraison premier ordre
                            </li>
                            <li className="m-2"> Augmentez vos ventes</li>
                        </Col>

                        <Col>
                            <Card>
                                <CardBody>

                                    <Form onSubmit={(e) => onSubmit(e)}>
                                        <Row>
                                            <Col>
                                                {" "}
                                                <FormGroup>
                                                    <Input
                                                        style={{height: "50px", borderRadius: "10px"}}
                                                        type="text"
                                                        name="last_name"
                                                        placeholder="Nom*"
                                                        value={formData.last_name}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                {" "}
                                                <FormGroup>
                                                    <Input
                                                        style={{height: "50px", borderRadius: "10px"}}
                                                        type="text"
                                                        name="first_name"
                                                        placeholder="Prénom*"
                                                        value={formData.first_name}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {" "}
                                                <FormGroup>
                                                    <Input
                                                        style={{height: "50px", borderRadius: "10px"}}
                                                        type="number"
                                                        name="number"
                                                        placeholder="Numéro de téléphone*"
                                                        value={formData.number}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                {" "}
                                                <FormGroup>
                                                    <Input
                                                        style={{height: "50px", borderRadius: "10px"}}
                                                        type="emails"
                                                        name="email"
                                                        placeholder="Email*"
                                                        value={formData.email}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <FormGroup>
                                            <select
                                                style={{height: "50px", borderRadius: "10px"}}
                                                className="form-control digits "
                                                name="type"
                                                value={formData.type}
                                                onChange={(e) => onChange(e)}
                                            >
                                                <option value="" disabled selected hidden>
                                                    Vous vendez principalemens vos produits sur*
                                                </option>
                                                <option hidden> vous vendez sur ?</option>
                                                <option value={"website"}>Site web</option>
                                                <option value={"social_media"}>
                                                    Résaux socieaux{" "}
                                                </option>
                                            </select>
                                        </FormGroup>
                                        <p>
                                            Beezit a besoin des cordonnées que vous nous fournisser
                                            pour vous contacter au sujet de nos produits et
                                            services. Vous pouvez vous désabonner de ces
                                            communications à tout moment. Pour plus d'informations,
                                            veuillez consulter notre politique de confidentalité
                                        </p>
                                        <Row>
                                            <Col>
                                                <button
                                                    type="submit"
                                                    onClick={() => setModal(!modal)}
                                                    className="p-2 text-white ml-1 btn"
                                                    style={{
                                                        backgroundColor: "#193256",
                                                        width: "100%",
                                                        borderRadius: "20px",
                                                    }}
                                                >
                                                    Soumettre
                                                </button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="1"></Col>
                    </Row>
                </ModalBody>
            </Modal>
            </>
  )
}
