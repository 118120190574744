import React, {Fragment, useState, useEffect, useMemo, useContext} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link, withRouter, useLocation} from "react-router-dom";
import {Multiselect} from "multiselect-react-dropdown";
import {addNewFournisseur} from "./action";
import {useDispatch} from "react-redux";
import AlertS from "../../redux/AlertS";
import {getSecteurs} from "../secteuractivite/action";
import {getadresse, getCite, getDeligation, getLivreurs, getVille} from "../colis/action";
import Breadcrumb from "../../layout/breadcrumb";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button as B,
    CardHeader,
} from "reactstrap";
import Error404 from "../../pages/errors/error404";
import {getCommercial} from "../compte/action";
import {getLivreur} from "../colis/action";
import Select from "react-select";
import Button from "../../utils/Button";
import AppContext from "../../context/Context";

const AddFournisseur = ({
                            addNewFournisseur,
                            adresseData,
                            secteurData,
                            citeData,
                            deligationData,
                            commercialData,
                            livreurData,
                            villeData,
                            history,
                        }) => {
    const [statesecteur, setsecteur] = useState([
        {
            id: "",
            activiter: "",
        },
    ]);
    const [stateville, setVille] = useState([
        {
            id: "",
            name: "",
        },
    ]);

    const [commercial, setCommercial] = useState([
        {
            id: "",
            name: "",
        },
    ]);

    const [livreur, setLivreur] = useState(null);

    const [currentPage, setCurrentPage] = useState(1)
    const [livreurDatas, setLivreurData] = useState([])

    const [statecite, setCite] = useState([
        {
            id: "",
            name: "",
        },
    ]);
    const [statedelegation, setDelegation] = useState([
        {
            id: "",
            name: "",
        },
    ]);
    const [stateselectedsecteur, setSelectedSecteur] = useState([]);
    const [formData, setFormData] = useState({
        responsable: "",
        tel: "",
        email: "",
        name: "",
        nb_colis: 3,
        adresse: "",
        code_postale: "",
        petit_livraison: "",
        grand_livraison: "",
        petit_retour: "",
        grand_retour: "",
        stock_service: "",
        img: null,
        rib: "",
        mf: "",
        tentative: "",
        filiere: "",
        ville: "",
        delegation: "",
        cite: "",
        commercial: "",
        livreur: "",
        secteur: [],
    });

    const {
        config: {group}
    } = useContext(AppContext)

    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };
    const [showLoader, setShowLoader] = useState(false)

    let query = useQuery();
    const onChange = (e) =>
        setFormData({...formData, [e.target.name]: e.target.value});
    const onChangestock = (e) =>
        setFormData({...formData, [e.target.name]: e.target.checked});
    const onChangeimg = (e) => {
        setFormData({...formData, img: e.target.files[0]});
    };

    const onSubmit = async (e) => {
        setShowLoader(true)
        e.preventDefault();
        const fd = new FormData();
        fd.append("adresse", formData.adresse);
        fd.append("email", formData.email);
        fd.append("petit_retour", formData.petit_retour);
        fd.append("grand_livraison", formData.grand_livraison);
        fd.append("delegation", formData.delegation);
        fd.append("ville", formData.ville);
        fd.append("cite", formData.cite);
        fd.append("code_postale", formData.code_postale);
        fd.append("img", formData.img);
        fd.append("name", formData.name);
        fd.append("mf", formData.mf);
        fd.append("nb_colis", formData.nb_colis);
        fd.append("grand_retour", formData.grand_retour);
        fd.append("petit_livraison", formData.petit_livraison);
        fd.append("responsable", formData.responsable);
        fd.append("stock_service", formData.stock_service);
        fd.append("commercial", formData.commercial);
        fd.append("livreur", formData.livreur);
        fd.append("rib", formData.rib);
        formData.secteur.forEach((item) => {
            fd.append("secteur", item);
        });
        fd.append("tel", formData.tel);
        fd.append("tentative", formData.tentative);
        await addNewFournisseur(fd, history);
        setShowLoader(false)
    };
    const onSelectSecteur = (e) => {
        setSelectedSecteur(e);
        const Secteurid = e.map((s) => s.value);
        setFormData({...formData, secteur: Secteurid});
    };

    const dispatch = useDispatch();
    //get adresse

    const onChangeDeligation = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
        query.set("gouvernat_id", e.target.value);
        dispatch(getVille(query));
    };

    const onChangeVille = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
        query.set("ville_id", e.target.value);
        dispatch(getCite(query));
    };

    //get secteurs
    useEffect(() => {
        dispatch(getSecteurs(query));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setsecteur(secteurData.secteurs);
    }, [secteurData.secteurs, secteurData.loading, statesecteur]);

    useEffect(() => {
        dispatch(getCommercial());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        query.set("page_size", "50")
        query.set("page", currentPage)
        getLivreurs(query)
            .then(res => setLivreurData([...livreurDatas, ...res?.results]))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);


    useEffect(() => {
        setCommercial(commercialData.commercials);
    }, [commercialData.commercials, commercialData.loading, commercial]);

    const datasecteur = [];
    secteurData &&
    secteurData.secteurs.map((secteur) =>
        datasecteur.push({
            activiter: secteur.activiter,
            value: parseInt(secteur.id),
        })
    );
    const dataadresse = [];
    adresseData &&
    adresseData.adresses.map((address) =>
        dataadresse.push({
            address: address.address,
            value: parseInt(address.id),
        })
    );

    useEffect(() => {
        setVille(villeData.villes);
    }, [villeData.villes, villeData.loading, stateville]);

    useEffect(() => {
        dispatch(getDeligation());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setDelegation(deligationData.deligations);
    }, [deligationData.deligations, deligationData.loading, statedelegation]);
    // get cite
    useEffect(() => {
        setCite(citeData.cites);
    }, [citeData.cites, citeData.loading, statecite]);

    const [taptopStyle, setTapTopStyle] = useState("none");

    const handleScroll = () => {
        if (window.scrollY > 600) {
            setTapTopStyle("block");
        } else {
            setTapTopStyle("none");
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        handleScroll();
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [taptopStyle]);
    let user_groups_name = group;
    console.log(formData.stock_service)
    const onChangeCommercial = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };
    const onChangeLivreur = (e) => {
        setFormData({...formData, livreur: e.value});
        setLivreur(e);
    };

    let livreurOptions;

    livreurOptions = livreurDatas.map((livreur) => ({
        value: livreur.id,
        label: livreur.name
    }));

// Add the reset option
    livreurOptions = [
        {value: "", label: "------------------"},
        ...livreurOptions
    ];
    return (
        <Fragment>
            <Breadcrumb parent="Fournisseur" title="Ajouter un fournisseur"/>
            {user_groups_name && user_groups_name.indexOf("admin") !== -1 ? (
                <Container fluid={true}>
                    <AlertS/>{" "}
                    <Form
                        className="theme-form"
                        encType="multipart/form-data"
                        onSubmit={(e) => onSubmit(e)}
                    >
                        <Row>
                            <Col sm="8">
                                <Card>
                                    <CardHeader>
                                        <h5>Données personnelles</h5>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Résponsable (*)</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        required
                                                        name="responsable"
                                                        placeholder="Résponsable"
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Raison sociale (*)</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        required
                                                        name="name"
                                                        placeholder="Raison sociale"
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Email (*)</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        name="email"
                                                        required
                                                        placeholder="Email"
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>{" "}
                                            <Col>
                                                <FormGroup>
                                                    <Label>Téléphone (*)</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="tel"
                                                        name="tel"
                                                        required
                                                        placeholder="Téléphone"
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Image </Label>
                                                    <Input
                                                        className="form-control"
                                                        type="file"
                                                        name="img"
                                                        required
                                                        accept="image/jpeg,image/png,image/gif"
                                                        onChange={(e) => {
                                                            onChangeimg(e);
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="ml-4">
                                                <FormGroup>
                                                    <Row>
                                                        <Col>
                                                            <Input
                                                                className="ml-2"
                                                                key={"active"}
                                                                type="checkbox"
                                                                name="stock_service"
                                                                onChange={(e) => onChangestock(e)}
                                                            />
                                                            <Label className="ml-4">Stock service</Label>
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardHeader>
                                        <h5>Confidentalités</h5>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>RIB </Label>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        name="rib"
                                                        placeholder="RIB"
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>MF</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        name="mf"
                                                        placeholder="MF"
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    {" "}
                                                    <Label>Petit</Label>
                                                    <Row>
                                                        <Col>
                                                            <Input
                                                                className="form-control"
                                                                type="number"
                                                                name="petit_livraison"
                                                                placeholder="0"
                                                                value={formData.petit_livraison}
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Input
                                                                className="form-control"
                                                                type="number"
                                                                placeholder="0"
                                                                name="petit_retour"
                                                                value={formData.petit_retour}
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Tentative (*)</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="number"
                                                        required
                                                        name="tentative"
                                                        placeholder="Tentative"
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Grand</Label>
                                                    <Row>
                                                        <Col>
                                                            <Input
                                                                className="form-control"
                                                                type="number"
                                                                placeholder="0"
                                                                name="grand_livraison"
                                                                value={formData.grand_livraison}
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Input
                                                                className="form-control"
                                                                type="number"
                                                                placeholder="0"
                                                                name="grand_retour"
                                                                value={formData.grand_retour}
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="4">
                                <Card>
                                    <CardHeader>
                                        <h5>Cordonnées</h5>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Livreur</Label>
                                                    <Select
                                                        options={livreurOptions}
                                                        onChange={onChangeLivreur}
                                                        value={livreur}
                                                        placeholder={"Livreur"}
                                                        name={'customer'}
                                                        className="m-2"
                                                        onInputChange={(e) => {
                                                            query.set('search', e);
                                                            getLivreurs(query);
                                                        }}
                                                        onMenuScrollToBottom={(e) => {
                                                            console.log(e)
                                                            setCurrentPage(page => page + 1)
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Commercial</Label>
                                                    <select
                                                        className="form-control digits"
                                                        name="commercial"
                                                        onChange={(e) => onChangeCommercial(e)}
                                                    >
                                                        <option
                                                            value=""
                                                            disabled
                                                            defaultValue
                                                            selected
                                                            hidden
                                                        >
                                                            ----------
                                                        </option>
                                                        {commercialData &&
                                                            commercialData.commercials.map((compte) => (
                                                                <option
                                                                    key={compte.id}
                                                                    value={compte.id}
                                                                >
                                                                    {compte.username}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Adresse (*)</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        required
                                                        name="adresse"
                                                        placeholder="Adresse"
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Code Postale </Label>
                                                    <Input
                                                        className="form-control"
                                                        type="number"
                                                        name="code_postale"
                                                        placeholder="Code Postale"
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Délégation (*)</Label>
                                                    <select
                                                        className="form-control digits"
                                                        name="delegation"
                                                        onChange={(e) => onChangeDeligation(e)}
                                                    >
                                                        <option
                                                            value=""
                                                            disabled
                                                            defaultValue
                                                            selected
                                                            hidden
                                                        >
                                                            ----------
                                                        </option>
                                                        {deligationData &&
                                                            deligationData.deligations.map((delegation) => (
                                                                <option
                                                                    key={delegation.id}
                                                                    value={delegation.id}
                                                                >
                                                                    {delegation.name}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Ville</Label>

                                                    <select
                                                        className="form-control digits"
                                                        name="ville"
                                                        onChange={(e) => onChangeVille(e)}
                                                    >
                                                        {" "}
                                                        <option
                                                            value=""
                                                            disabled
                                                            defaultValue
                                                            selected
                                                            hidden
                                                        >
                                                            ----------
                                                        </option>
                                                        {villeData &&
                                                            villeData.villeF.map((ville) => (
                                                                <option key={ville.id} value={ville.id}>
                                                                    {ville.name}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Cité</Label>
                                                    <select
                                                        className="form-control digits"
                                                        name="cite"
                                                        onChange={(e) => onChange(e)}
                                                    >
                                                        <option
                                                            value=""
                                                            disabled
                                                            defaultValue
                                                            selected
                                                            hidden
                                                        >
                                                            ----------
                                                        </option>
                                                        {citeData &&
                                                            citeData.citesF.map((cite) => (
                                                                <option key={cite.id} value={cite.id}>
                                                                    {cite.name}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Secteur (*)</Label>
                                                    <Multiselect
                                                        name="secteur"
                                                        value={stateselectedsecteur}
                                                        options={datasecteur}
                                                        displayValue="activiter"
                                                        placeholder={"Secteurs"}
                                                        onSelect={(e) => {
                                                            onSelectSecteur(e);
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="mb-6">
                                    <Button
                                        onClick={onSubmit}
                                        disabled={showLoader}
                                        className="btn btn-success mr-3"
                                        color={"success"}
                                        loading={showLoader}
                                        text={"Enregistrer"}
                                    />
                                    <Link to={`/fournisseur`}>
                                        <B color="danger">Annuler</B>
                                    </Link>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            ) : (
                <Error404 page="Ajouter Un Fournisseur"/>
            )}
        </Fragment>
    );
};
AddFournisseur.propTypes = {
    addNewFournisseur: PropTypes.func.isRequired,
    getadresse: PropTypes.func.isRequired,
    getCommercial: PropTypes.func.isRequired,
    getLivreur: PropTypes.func.isRequired,
    getSecteurs: PropTypes.func.isRequired,
    getVille: PropTypes.func.isRequired,
    getCite: PropTypes.func.isRequired,
    getDeligation: PropTypes.func.isRequired,
};

const mapstatetoprops = (state) => ({
    adresseData: state.reducerc,
    secteurData: state.reducersc,
    villeData: state.reducerdvc,
    citeData: state.reducerdvc,
    deligationData: state.reducerc,
    commercialData: state.reducercp,
    livreurData: state.reducerc,
});

export default connect(mapstatetoprops, {
    addNewFournisseur,
    getVille,
    getCite,
    getDeligation,
    getCommercial,
    getLivreur,
    getadresse,
    getSecteurs,
})(withRouter(AddFournisseur));
