import React, {Fragment} from 'react';
import Loader from "../layout/loader";
import Taptop from "../layout/tap-top";
import Header from '../layout/header'
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {termsServices} from "./compte/action";
import Sidebar from '../layout/sidebar'
import Footer from '../layout/footer'
import {ToastContainer} from 'react-toastify'
import {withRouter} from 'react-router-dom'

const App = ({children}) => {

    return (
        <Fragment>
            <Loader/>
            <Taptop/>
            <div className="page-wrapper compact-wrapper" id="pageWrapper">
                <Header/>
                {/*<Modal*/}
                {/*    centered*/}
                {/*    className="relative min-h-screen flex flex-col sm:justify-center items-center"*/}
                {/*    isOpen={false}*/}
                {/*>*/}
                {/*    <ModalBody>*/}
                {/*        <Row>*/}
                {/*            <Col sm={"1"}></Col>*/}
                {/*            <Col>*/}
                {/*                <Row><input type={"checkbox"} onChange={(e) =>*/}
                {/*                    setCheck(e.target.checked)*/}
                {/*                }/></Row></Col>*/}
                {/*            <Col></Col>*/}

                {/*        </Row>*/}
                {/*        <Row>*/}
                {/*            <Col></Col>*/}
                {/*            <Col></Col>*/}
                {/*            <Col><Button disabled={!check} onClick={() => {*/}
                {/*                dispatch(termsServices()); window.location.reload()*/}
                {/*            }} >Confirmer</Button></Col>*/}
                {/*        </Row>*/}

                {/*    </ModalBody>*/}
                {/*</Modal>*/}
                <div className="page-body-wrapper">

                    <Sidebar/>
                    <div className="page-body">
                        {children}
                    </div>
                    <Footer/>
                </div>
            </div>
            {/* <ThemeCustomize/> */}
            <ToastContainer/>
        </Fragment>
    );
}
App.protoTypes = {
    termsServices: PropTypes.func.isRequired,
}
export default connect(null, {termsServices})(withRouter(App));