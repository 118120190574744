import {
  GET_NEWS,
  GET_NEW,
  NEWS_LOADING,
  NEWS_ERROR,
  NEWS_UPDATE, UNSEEN_NEWS_LOADING, UNSEEN_NEWS_ERROR, UNSEEN_NEWS
} from "../../redux/actionTypes";

const initial_state = {
  neww: null,
  count: 0,
  news: [],
  loading: true,
  error: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initial_state, action) {
  const { type, payload } = action;
  switch (type) {
    case NEWS_LOADING:
    case UNSEEN_NEWS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case NEWS_ERROR:
    case UNSEEN_NEWS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case GET_NEW:
      return {
        ...state,
        neww: payload,
        loading: false,
      };
    case GET_NEWS:
      return {
        ...state,
        count: payload.count,
        news: payload.results,
        loading: false,
      };
    case UNSEEN_NEWS:
      return {
        ...state,
        count: payload.count,
        news: payload,
        loading: false,
      };
    case NEWS_UPDATE:
      return {
        ...state,
        count: payload.count,
        neww: payload.results,
        loading: false,
      };
    default:
      return state;
  }
}
