import React, {Fragment, useState, useEffect, useMemo, useContext} from "react";
import PropTypes from "prop-types";
import AlertS from "../../redux/AlertS";
import axios from "axios";
import {
    getReclamationsByIds,
    getReponses,
    deleteReclamation,
    addNewReclamation,
    addNewReponse,
} from "../reclamation/action";
import {connect} from "react-redux";
import PaginationWrapper from "../pagination/pagination";
import {Link, withRouter, useParams, useLocation} from "react-router-dom";
import {
    getCurrentcolis,
    updatecolis,
    deleteColis,
    deleteTracking,
    getTracking,
    getTracking2,
    getVille,
    getCite,
    getDeligation,
    updateStatuscolis,
    getfiliere,
    getLivreur,
    addNewTracking
} from "./action";
import Breadcrumb from "../../layout/breadcrumb";
import {getFournisseurs} from "../Fournisseurs/action";

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Table, CardHeader,
} from "reactstrap";
import {useDispatch} from "react-redux";
import Spinner from "./spinner.gif";
import AppContext from "../../context/Context";

const Detailcoli = ({
                        reclamationData,
                        addNewReclamation,
                        addNewTracking,
                        getCurrentcolis,
                        updateStatuscolis,
                        deleteColis,
                        deleteTracking,
                        addNewReponse,
                        coli: {coli, loading},
                        getTracking2,
                        history,
                        reponseData,
                        trackingData
                    }) => {
    const [reclamationid, setReclamationId] = useState(0);
    const {id} = useParams();
    const [modal, setModal] = useState(false);
    const [notes, setNotesRes] = useState({});
    const [modaltracking, setModalTracking] = useState(false);
    const [modalreclamation, setModalReclamation] = useState(false);
    const [modalreponse, setModalReponse] = useState(false);
    // const [usercomment, setUserComment] = useState("");
    const [note_t_s, setNotesTyNo] = useState({ note_type: '', note: '' });
    const [commentmodal, setCommentModal] = useState(false);

    const setAuthToken = (token) => {
        if (token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        } else {
            delete axios.defaults.headers.common['Authorization'];
        }
    };
    
    if (localStorage.getItem('access')) {
        setAuthToken(localStorage.getItem('access'));
    }

    const getIdFromUrl = () => {
        const urlObj = new URL(window.location.href);
        const pathSegments = urlObj.pathname.split('/');
        const id = pathSegments[pathSegments.length - 1];  // Get the last segment which is the ID
        return id;
    };
    
    // Function to perform an Axios GET request
    const getData = async () => {
        const coli_id = getIdFromUrl();
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/colis/colis-notes/${coli_id}/`); // Replace with your actual API endpoint
            console.log('Data:', response.data);
            setNotesRes(response.data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleNoteChange = (value) => {
        setNotesTyNo((prevNotes) => ({ ...prevNotes, note: value }));
    };

    const NotesSaved = async () => {
        const c_id = getIdFromUrl();
        try {
            // Define the data you want to send in the POST request
            const postData = {
                ...note_t_s,
                coli: c_id,
            };
        
            // Make a POST request instead of a GET request
            await axios.post(
                `${process.env.REACT_APP_API_URL}/colis/colis-notes/`, 
                postData // Send the data with the POST request
            );
        
            toggle()
        } catch (error) {
            console.error('Error sending data:', error);
        }
        

    };


    const toggle = () => {
        setCommentModal(!commentmodal);
        getData();
    };
    const [formDatareclamation, setFormDataReclamation] = useState({
        description: "",
        status: "new",
        colis: id,
        type: "",
    });
    const [formDatatracking, setFormDataTracking] = useState({
        activity_type: "",
        client_name: "",
        description: "",
        code_a_barre: "",
        shown: false,
        location: "",
        status: "",
        shipment_id: id,
        problem_description: "",
    });
    const [status, setStatus] = useState({
        status: ""
    })
    const [formDatareponse, setFormDataReponse] = useState({
        description: "",
        reclamation: {reclamationid},
    });

    const {
        config: {group},
    } = useContext(AppContext);

    const dispatch = useDispatch();
    useEffect(() => {
        getCurrentcolis(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const onChangeReclamation = (e) =>
        setFormDataReclamation({
            ...formDatareclamation,
            [e.target.name]: e.target.value,
        });
    const onChangeTracking = (e) =>
        setFormDataTracking({
            ...formDatatracking,
            [e.target.name]: e.target.value,
        });
    const onChangeReponse = (e) =>
        setFormDataReponse({...formDatareponse, [e.target.name]: e.target.value});
    const onChangeStatus = (e) => {
        setStatus({...status, [e.target.name]: e.target.value});
        setFormDataTracking({...formDatatracking, status: e.target.value})
    }

    const onSubmitReclamation = (e) => {
        e.preventDefault();
        addNewReclamation(formDatareclamation, true);
        dispatch(getReclamationsByIds(query, id));
        dispatch(getReclamationsByIds(query, id));
    };
    const onSubmitTracking = (e) => {
        e.preventDefault();
        updateStatuscolis(status, id)
        if (formDatatracking.description) {
            addNewTracking(formDatatracking)
        }
        dispatch(getTracking(id));
        dispatch(getTracking(id));
    };
    const onSubmitReponse = (e) => {
        e.preventDefault();
        addNewReponse(formDatareponse, reclamationid, history, true);
        dispatch(getReponses(reclamationid));
    };
    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };
    let query = useQuery();
    let pagenumber = query.get("page") ? query.get("page") : 1;
    const Navigate = (page) => {
        query.set("page", Number(page));

        window.location.replace(`/colis/edit/${id}/?${query}`);
    };
    useEffect(() => {
        if (formDatatracking.description === "Montant collecté" || formDatatracking.description === "Colis livré" || formDatatracking.description === "Position d'échange livrée") {
            setFormDataTracking({...formDatatracking, status: "livrer"})
            setStatus({...status, status: "livrer"})
        } else if (formDatatracking.description === "Colis enlevé" || formDatatracking.description === "Réception HUB" || formDatatracking.description === "Scan Inventaire" || formDatatracking.description === "Planification Livraison" || formDatatracking.description === "Colis validé") {
            setFormDataTracking({...formDatatracking, status: "valider"})
            setStatus({...status, status: "valider"})
        } else if (formDatatracking.description === "Planification Livraison" || formDatatracking.description === "Echange planifié en cours de tournée" || formDatatracking.description === "Livraison planifiée en cours de tournée" || formDatatracking.description === "Tentative de livraison" || formDatatracking.description === "Tentative d'échange" || formDatatracking.description === "Anomalie d'échange") {
            setFormDataTracking({...formDatatracking, status: "en_cours_de_livraison"})
            setStatus({...status, status: "en_cours_de_livraison"})
        } else if (formDatatracking.description === "Anomalie enlevement" || formDatatracking.description === "Anomalie réception" || formDatatracking.description === "Anomalie livraison" || formDatatracking.description === "Livraison annulée" || formDatatracking.description === "Planification enlevement annulée" || formDatatracking.description === "Planification livraison annulée" || formDatatracking.description === "Anomalie de retour") {
            setFormDataTracking({...formDatatracking, status: "anomalie"})
            setStatus({...status, status: "anomalie"})
        } else if (formDatatracking.description === "Création étiquette position de retour" || formDatatracking.description === "Cloture pour retour" || formDatatracking.description === "Planification retour" || formDatatracking.description === "Retour planifié en cours de tournée" || formDatatracking.description === "Position de retour livrée" || formDatatracking.description === "Tentative de retour") {
            setFormDataTracking({...formDatatracking, status: "retour"})
            setStatus({...status, status: "retour"})
        } else {
            setFormDataTracking({
                ...formDatatracking,
                code_a_barre: coli && coli.code_a_barre ? coli.code_a_barre : "",
                client_name: coli && coli.client_name ? coli.client_name : "",
                status: coli && coli.status ? coli.status : ""
            })
            setStatus({...status, status: coli && coli.status ? coli.status : ""})
        }
        // eslint-disable-next-line
    }, [formDatatracking.description, coli])
    //get reclamation
    useEffect(() => {
        dispatch(getReclamationsByIds(query, id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //get tracking
    useEffect(() => {
        dispatch(getTracking(id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let count = reclamationData.count;
    let id_fournisseur = "";
    try {
        id_fournisseur = coli.related_payment;
    } catch (e) {
        id_fournisseur = "";
    }
    let user_groups_name = group;
    return (
        <Fragment>
            <Breadcrumb parent="Colis" title="Détail colis"/>
            {loading || !coli ?
                <img src={Spinner} style={{width: "10%", position: "relative", left: "45%"}} alt=""/>
                :
                <Container fluid={true}>
                    <AlertS/>
                    <Row>
                        <Col sm="8">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col sm="4">
                                            <h4>{coli ? coli.code_a_barre : ""}</h4>
                                        </Col>
                                        <Col>
                                            <Row>
                                                {user_groups_name &&
                                                user_groups_name.indexOf("admin") !== -1 ? (
                                                    <Link
                                                        to={`/colis/edit/${id}`}
                                                        className="btn btn-primary m-2"
                                                        title="Edit colis"
                                                    >
                                                        <i className="fa fa-edit"></i>
                                                    </Link>
                                                ) : (
                                                    ""
                                                )}
                                                {user_groups_name &&
                                                user_groups_name.indexOf("admin") !== -1 ? (
                                                    <button
                                                        className="btn btn-danger m-2"
                                                        onClick={() => deleteColis(id, history)}
                                                        title="Supprimer colis"
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </button>
                                                ) : (
                                                    ""
                                                )}

                                                <Button
                                                    hidden={user_groups_name && user_groups_name.indexOf("livreur") !== -1}
                                                    className="m-2"
                                                    color="info"
                                                    onClick={() => setModalReclamation(true)}
                                                    title="Réclamer"
                                                >
                                                    Réclamer
                                                </Button>
                                                <Link
                                                    className=" btn  m-2"
                                                    style={{
                                                        backgroundColor: "#FDF203",
                                                    }}
                                                    to={`/colis/print/?colis_id=${id}`}
                                                    target="_blank"
                                                    title="Imprimer Colis"
                                                >
                                                    <i className="fa fa-print"></i>
                                                </Link>
                                                <a
                                                    href={`/colis/${id}/history`}
                                                    className="btn btn-primary m-2"
                                                    title="Historique"
                                                >
                                                    <i className="fa fa-history"></i>
                                                </a>
                                                {(user_groups_name && user_groups_name.indexOf("admin") !== -1)? (
                                                    <>
                                                        <Button className="m-2" color="primary" onClick={toggle}>
                                                            Ajouter des notes
                                                        </Button>

                                                        <Modal isOpen={commentmodal} toggle={toggle}>
                                                            <ModalHeader toggle={toggle}>Notes d'utilisateur</ModalHeader>
                                                            <ModalBody>
                                                                <Label>Type de notes : </Label>
                                                                <FormGroup>
                                                                    <select
                                                                        className="form-control digits"
                                                                        defaultValue={notes.note_type}
                                                                        onChange={(e) => setNotesTyNo({ ...notes, note_type: e.target.value })}
                                                                    >
                                                                        <option>Sélectionner les types de notes : </option>
                                                                        <option value="a- problématiques client">a- problématiques client</option>
                                                                        <option value="b- cause de retour">b- cause de retour</option>
                                                                    </select>
                                                                </FormGroup>
                                                                <FormGroup>
                                                                    <Label>Écrire des notes : </Label>
                                                                    <Input
                                                                        id="comment"
                                                                        maxLength={100}
                                                                        type="textarea"
                                                                        placeholder={"Notes d'utilisateur"}
                                                                        defaultValue={notes.note}
                                                                        onChange={(e) => handleNoteChange(e.target.value)}
                                                                    />
                                                                </FormGroup>
                                                            </ModalBody>
                                                            <ModalFooter>
                                                                <Button color="secondary" onClick={toggle}>
                                                                    Close
                                                                </Button>
                                                                <Button color="primary" onClick={NotesSaved}>
                                                                    Saved
                                                                </Button>
                                                            </ModalFooter>
                                                        </Modal>
                                                    </>
                                                ):(
                                                    <></>
                                                )}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr/>
                                    <Row>
                                        <Col>
                                            <h4>Livreur</h4>
                                            {user_groups_name &&
                                            user_groups_name.indexOf("fournisseur") !== -1 ? (
                                                ""
                                            ) : (
                                                <Label><strong>Nom:</strong> {coli && coli.livreur_name}</Label>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label>
                                                <strong>Code à barre:</strong> {coli && coli.tracking_code}
                                            </Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label>
                                                <strong>Reference:</strong> {coli && coli.reference_am}
                                            </Label>
                                        </Col>
                                    </Row>
                                    <hr/>
                                    <Row>
                                        <Col>
                                            <h4>Expéditeur</h4>
                                            <Label><strong>Type:</strong> Coli fournisseur</Label>
                                        </Col>
                                        <Col>
                                            <h4>Destinataire</h4>
                                            <Label><strong>Nom:</strong> {coli && coli.client_name}</Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {user_groups_name &&
                                            user_groups_name.indexOf("admin") !== -1 ? (
                                                <Label><strong>Raison:</strong> {coli && coli.fournisseur_name}</Label>
                                            ) : (
                                                ""
                                            )}
                                        </Col>
                                        <Col>
                                            <Label>
                                                <strong>Téléphone:</strong> {coli && coli.client_telephone_1}
                                            </Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label><strong>Téléphone:</strong> {coli && coli.fournisseur_tel}</Label>
                                        </Col>
                                        <Col>
                                            <Label><strong>Ville:</strong> {coli && coli.ville_name}</Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label>
                                                <strong>Adresse:</strong> {coli && coli.client_address_pickup_name}
                                            </Label>
                                        </Col>
                                        <Col>
                                            <Label>
                                                <strong>Délégation:</strong> {coli && coli.delegation_name}
                                            </Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col></Col>
                                        <Col>
                                            <Label><status>Adresse:</status> {coli && coli.client_address}</Label>
                                        </Col>
                                    </Row>
                                    <hr/>
                                    <Row>
                                        <Col sm="12" style={{textAlign: "center"}}>
                                            <Label>
                                                <strong>Libellé de marchandise:</strong> {coli && coli.product_name}
                                            </Label>
                                            <p></p>
                                        </Col>
                                    </Row>
                                    <hr/>
                                    <Row>
                                        <Col sm="12" style={{textAlign: "center"}}>
                                            <Label><strong>Service:</strong> {coli && coli.service}</Label>
                                            <p></p>
                                        </Col>
                                    </Row>
                                    <hr/>
                                    <Row>
                                        <Col sm="12" style={{textAlign: "center"}}>
                                            <Label><strong>Remarque:</strong> {coli && coli.remarque}</Label>
                                            <p></p>
                                        </Col>
                                    </Row>
                                    <hr/>
                                    <Row>
                                        <Col sm="12" style={{textAlign: "center"}}>
                                            <Label><strong>COD:</strong> {coli && coli.prix}</Label>
                                            <p></p>
                                        </Col>
                                    </Row>
                                    <hr/>
                                    <Row>
                                        <Col sm="12" style={{textAlign: "center"}}>
                                            <Label>
                                                <strong>Dernier status:</strong> {coli && coli.status_display}
                                            </Label>
                                            <p></p>
                                        </Col>
                                    </Row>
                                    <hr/>
                                    <Row>
                                        <Col sm="12" style={{textAlign: "center"}}>
                                            <Label>
                                                <strong>Date dernier status:</strong> {coli && coli.updated_at}
                                            </Label>
                                            <p></p>
                                        </Col>
                                    </Row>
                                    <hr/>
                                    <Row>
                                        <Col sm="12" style={{textAlign: "center"}}>
                                            <Label>
                                                <strong>Date de création:</strong> {coli && coli.created_at}
                                            </Label>
                                            <p></p>
                                        </Col>
                                    </Row>
                                    <hr/>
                                </CardBody>
                            </Card>

                            <Card>
                                <div>
                                    <Modal
                                        centered
                                        className="relative min-h-screen flex flex-col sm:justify-center items-center"
                                        isOpen={modalreponse}
                                        toggle={() => setModalReponse(!modalreponse)}
                                    >
                                        <ModalHeader
                                            className="p-3"
                                            toggle={() => setModalReponse(!modalreponse)}
                                        >
                                            Details
                                        </ModalHeader>
                                        <ModalBody>
                                            <Row>
                                                <Col sm="12">
                                                    <Col>
                                                        <FormGroup>
                                                            <Table striped>
                                                                <thead>
                                                                <tr>
                                                                    <th>{"Reponses"}</th>
                                                                    <th>{"Par"}</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {reponseData &&
                                                                    reponseData.reponses.map((b) => (
                                                                        <tr key={b.id}>
                                                                            <td>{b.description}</td>
                                                                            <td>{b.username}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                        </FormGroup>
                                                    </Col>
                                                    <ModalFooter>
                                                        <button
                                                            onClick={() => setModalReponse(!modalreponse)}
                                                            className="p-2 btn-info rounded"
                                                        >
                                                            Close
                                                        </button>
                                                    </ModalFooter>
                                                </Col>
                                            </Row>
                                        </ModalBody>
                                    </Modal>
                                </div>
                                <div>
                                    <Modal
                                        centered
                                        className="relative min-h-screen flex flex-col sm:justify-center items-center"
                                        isOpen={modal}
                                        toggle={() => setModal(!modal)}
                                    >
                                        <ModalHeader className="p-3" toggle={() => setModal(!modal)}>
                                            Ajouter
                                        </ModalHeader>
                                        <ModalBody>
                                            <Row>
                                                <Col sm="12">
                                                    <Form
                                                        className="theme-form"
                                                        onSubmit={(e) => {
                                                            onSubmitReponse(e);
                                                            dispatch(getReclamationsByIds(query, id));
                                                            dispatch(getReclamationsByIds(query, id));
                                                        }}
                                                    >
                                                        <Col>
                                                            <FormGroup>
                                                                <Label>Réponse</Label>
                                                                <Input
                                                                    className="form-control"
                                                                    type="textarea"
                                                                    name="description"
                                                                    value={formDatareponse.description}
                                                                    placeholder="Réponse"
                                                                    onChange={(e) => onChangeReponse(e)}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <ModalFooter>
                                                            <button
                                                                onClick={() => {
                                                                    setModal(!modal);
                                                                }}
                                                                type="submit"
                                                                className="p-2 btn-info rounded"
                                                            >
                                                                Confirm
                                                            </button>
                                                        </ModalFooter>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        </ModalBody>
                                    </Modal>
                                </div>
                                <div>
                                    <Modal
                                        centered
                                        className="relative min-h-screen flex flex-col sm:justify-center items-center"
                                        isOpen={modalreclamation}
                                        toggle={() => setModalReclamation(!modalreclamation)}
                                    >
                                        <ModalHeader
                                            style={{
                                                backgroundImage:
                                                    "linear-gradient(to right,#FFD166, #133A4A  )",
                                            }}
                                            className="p-3 text-white"
                                            toggle={() => setModalReclamation(!modalreclamation)}
                                        >
                                            Ajouter réclamtion
                                        </ModalHeader>
                                        <ModalBody>
                                            <Form onSubmit={(e) => onSubmitReclamation(e)}>
                                                <FormGroup>
                                                    <Label>Type de réclamtion</Label>
                                                    <select
                                                        className="form-control digits"
                                                        name="type"
                                                        value={formDatareclamation.type}
                                                        onChange={(e) => onChangeReclamation(e)}
                                                    >
                                                        <option value="" disabled defaultValue hidden>
                                                            ----------
                                                        </option>
                                                        <option value={"modification"}>
                                                            Modification colis
                                                        </option>
                                                        <option value={"retard"}>Retard</option>
                                                        <option value={"comportement_incorrect"}>
                                                            Comportement non correcte
                                                        </option>
                                                        <option value={"retour"}>Retour colis</option>
                                                        <option value={"endommage"}>Colis Endomagé</option>
                                                        <option value={"perdu"}>Colis perdu</option>
                                                        <option value={"Other"}>Autres</option>
                                                    </select>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>Description</Label>
                                                    <Input
                                                        type="textarea"
                                                        name="description"
                                                        placeholder="description"
                                                        value={formDatareclamation.description}
                                                        onChange={(e) => onChangeReclamation(e)}
                                                    />
                                                </FormGroup>
                                                <ModalFooter>
                                                    <Row>
                                                        <button
                                                            onClick={() =>
                                                                setModalReclamation(!modalreclamation)
                                                            }
                                                            className="p-2 btn text-white  rounded"
                                                            style={{backgroundColor: "#133A4A"}}
                                                        >
                                                            Anuuler
                                                        </button>
                                                        <button
                                                            type="submit"
                                                            onClick={() =>
                                                                setModalReclamation(!modalreclamation)
                                                            }
                                                            className="p-2 ml-1 btn  rounded"
                                                            style={{backgroundColor: "#FFD166"}}
                                                        >
                                                            Sauvegarder
                                                        </button>
                                                    </Row>
                                                </ModalFooter>
                                            </Form>
                                        </ModalBody>
                                    </Modal>
                                </div>
                                <div>
                                    <Modal
                                        centered
                                        className="relative min-h-screen flex flex-col sm:justify-center items-center"
                                        isOpen={modaltracking}
                                        toggle={() => setModalTracking(!modaltracking)}
                                    >
                                        <ModalHeader
                                            style={{
                                                backgroundImage:
                                                    "linear-gradient(to right,#FFD166, #133A4A  )",
                                            }}
                                            className="p-3 text-white"
                                            toggle={() => setModalTracking(!modaltracking)}
                                        >
                                            Ajouter tracking
                                        </ModalHeader>
                                        <ModalBody>
                                            <Form onSubmit={(e) => onSubmitTracking(e)}>
                                                <FormGroup>
                                                    <Label>Type d'activité</Label>
                                                    <select
                                                        className="form-control digits"
                                                        name="description"
                                                        value={formDatatracking.description}
                                                        onChange={(e) => onChangeTracking(e)}
                                                    >
                                                        <option value="" disabled defaultValue hidden>
                                                            ----------
                                                        </option>
                                                        <option value={"Création étiquette position"}> Création
                                                            étiquette position
                                                        </option>
                                                        <option value={"Planification enlevement"}>Planification
                                                            enlevement
                                                        </option>
                                                        <option value={"Enlevement en cours de tournée"}>Enlevement en
                                                            cours de tournée
                                                        </option>
                                                        <option value={"Colis enlevé"}> Colis enlevé</option>
                                                        <option value={"Anomalie enlevement"}>Anomalie enlevement
                                                        </option>
                                                        <option value={"Tentative enlevement"}> Tentative enlevement
                                                        </option>
                                                        <option value={"Enlevement annulé"}>Enlevement annulé</option>
                                                        <option value={"Réception HUB"}> Réception HUB</option>
                                                        <option value={"Scan Inventaire"}> Scan Inventaire</option>
                                                        <option value={"Anomalie réception"}> Anomalie réception
                                                        </option>
                                                        <option value={"Commentaire"}> Commentaire</option>
                                                        <option value={"Clôture position"}> Clôture position</option>
                                                        <option value={"Planification Livraison"}>Planification
                                                            Livraison
                                                        </option>
                                                        <option
                                                            value={"Livraison planifiée en cours de tournée"}> Livraison
                                                            planifiée en cours de tournée
                                                        </option>
                                                        <option value={"Tentative de livraison"}> Tentative de
                                                            livraison
                                                        </option>
                                                        <option value={"Anomalie livraison"}> Anomalie livraison
                                                        </option>
                                                        <option value={"Montant collecté"}>Montant collecté</option>
                                                        <option value={"Livraison annulée"}> Livraison annulée</option>
                                                        <option value={"Colis livré"}> Colis livré</option>
                                                        <option value={"Colis validé"}> Colis validé</option>
                                                        <option
                                                            value={"Planification enlevement annulée"}> Planification
                                                            enlevement annulée
                                                        </option>
                                                        <option value={"Planification livraison annulée"}> Planification
                                                            livraison annulée
                                                        </option>
                                                        <option
                                                            value={"Création étiquette position de retour"}> Création
                                                            étiquette position de retour
                                                        </option>
                                                        <option value={"Cloture pour retour"}> Cloture pour retour
                                                        </option>
                                                        <option value={"Planification retour"}> Planification retour
                                                        </option>
                                                        <option value={"Retour planifié en cours de tournée"}>Retour
                                                            planifié en cours de tournée
                                                        </option>
                                                        <option value={"Position de retour livrée"}> Position de retour
                                                            livrée
                                                        </option>
                                                        <option value={"Anomalie de retour"}> Anomalie de retour
                                                        </option>
                                                        <option value={"Tentative de retour"}> Tentative de retour
                                                        </option>
                                                        <option
                                                            value={"Création étiquette position d'échange"}> Création
                                                            étiquette position d'échange
                                                        </option>
                                                        <option value={"Echange planifié en cours de tournée"}> Echange
                                                            planifié en cours de tournée
                                                        </option>
                                                        <option value={"Tentative d'échange"}> Tentative d'échange
                                                        </option>
                                                        <option value={"Position d'échange livrée"}>Position d'échange
                                                            livrée
                                                        </option>
                                                        <option value={"Anomalie d'échange"}> Anomalie d'échange
                                                        </option>
                                                    </select>
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label>Description du Problème</Label>
                                                    <Input
                                                        type="textarea"
                                                        name="problem_description"
                                                        value={formDatatracking.problem_description}
                                                        onChange={(e) => onChangeTracking(e)}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>Localisation</Label>
                                                    <Input
                                                        type="text"
                                                        name="location"
                                                        value={formDatatracking.location}
                                                        onChange={(e) => onChangeTracking(e)}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>Status Colis</Label>
                                                    <select
                                                        className="form-control"
                                                        name="status"
                                                        placeholder="product"
                                                        value={status.status}
                                                        onChange={(e) => onChangeStatus(e)}
                                                    >
                                                        <option value="" disabled defaultValue hidden>
                                                            ----------
                                                        </option>
                                                        <option value="nouveau">Nouveau</option>
                                                        <option value="payer">Payé</option>
                                                        <option value="livrer">Livré</option>
                                                        <option value="non_ramasse">Non ramassé</option>
                                                        <option value="anomalie">Anomalie</option>
                                                        <option value="retour">Retour</option>
                                                        <option value="en_cours_de_livraison">
                                                            En cours de livraison
                                                        </option>
                                                        <option value="retour_aux_sources">
                                                            Retour aux sources
                                                        </option>
                                                    </select>
                                                </FormGroup>
                                                <ModalFooter>
                                                    <Row>
                                                        <button
                                                            type="submit"
                                                            onClick={() =>
                                                                setModalTracking(!modaltracking)
                                                            }
                                                            className="p-2 ml-1 btn  rounded"
                                                            style={{backgroundColor: "#FFD166"}}
                                                        >
                                                            Sauvegarder
                                                        </button>
                                                    </Row>
                                                </ModalFooter>
                                            </Form>
                                        </ModalBody>
                                    </Modal>
                                </div>
                                {count !== 0 ? (
                                    <CardBody>
                                        <Col sm="12">
                                            <Card>
                                                <div className="card-block row">
                                                    <Col sm="12" lg="12" xl="12">
                                                        <div className="table-responsive">
                                                            <Table striped>
                                                                <thead>
                                                                <tr>
                                                                    <th>{"Date"}</th>
                                                                    <th>{"Type"}</th>
                                                                    <th>{"Colis"}</th>
                                                                    <th>{"Description"}</th>
                                                                    <th>{"Réponse"}</th>
                                                                    {user_groups_name &&
                                                                    user_groups_name.indexOf("admin") !== -1 ? (
                                                                        <th>{"Action"}</th>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {reclamationData &&
                                                                    reclamationData.reclamations.map((b) => (
                                                                        <tr key={b.id}>
                                                                            <td>{b.created_at}</td>
                                                                            <td>{b.type}</td>
                                                                            <td>{b.code}</td>
                                                                            <td>{b.description}</td>
                                                                            <td>{b.last_reponse}</td>
                                                                            {user_groups_name &&
                                                                            user_groups_name.indexOf("admin") !==
                                                                            -1 ? (
                                                                                <td>
                                                                                    <button
                                                                                        onClick={() => {
                                                                                            setModalReponse(true);
                                                                                            setReclamationId(b.id);
                                                                                            dispatch(getReponses(b.id));
                                                                                        }}
                                                                                        className="btn btn-primary  m-1"
                                                                                    >
                                                                                        <i className="fa fa-eye"></i>
                                                                                    </button>
                                                                                </td>
                                                                            ) : (
                                                                                <td>
                                                                                    <button
                                                                                        onClick={() => {
                                                                                            setModalReponse(true);
                                                                                            setReclamationId(b.id);
                                                                                            dispatch(getReponses(b.id));
                                                                                        }}
                                                                                        className="btn btn-primary  m-1"
                                                                                    >
                                                                                        <i className="fa fa-eye"></i>
                                                                                    </button>

                                                                                    <button
                                                                                        onClick={() => {
                                                                                            setModal(true);
                                                                                            setReclamationId(b.id);
                                                                                        }}
                                                                                        className="btn btn-success  m-1"
                                                                                        data-id={b.id}
                                                                                    >
                                                                                        <i className="fa fa-reply"></i>
                                                                                    </button>
                                                                                </td>
                                                                            )}
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </Col>
                                                </div>
                                            </Card>
                                        </Col>{" "}
                                        <Row>
                                            <Col sm="10"></Col>
                                            <Col>
                                                <PaginationWrapper
                                                    name="Reclamations"
                                                    length={reclamationData.reclamations.length}
                                                    count={count}
                                                    currentPage={pagenumber}
                                                    onNavigate={Navigate}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                ) : (
                                    ""
                                )}
                            </Card>
                        </Col>
                        <Col sm="4">
                            <Card>
                                {(user_groups_name && user_groups_name.indexOf("admin") !== -1) || (user_groups_name && user_groups_name.indexOf("livreur") !== -1) ?
                                    <CardHeader>

                                        <button className={"btn btn-primary"} onClick={() => setModalTracking(true)}>
                                            Ajouter
                                        </button>
                                    </CardHeader> : ""}
                                <CardBody>
                                    <CardBody>

                                        {window.innerWidth < 750 ? (
                                            <Row>
                                                {coli?.colies_notes_type && coli?.colies_notes ? (
                                                    <>
                                                    <Col sm="10">
                                                        <Label>
                                                            <strong>Notes Type: </strong> {coli && coli?.colies_notes_type}
                                                        </Label>
                                                    </Col>
                                                    <Col sm="10">
                                                        <Label>
                                                            <strong>Notes: </strong> {coli && coli?.colies_notes}
                                                        </Label>
                                                    </Col>
                                                    </>
                                                    
                                                ):(
                                                    <></>
                                                )}
                                                <Col sm="10">
                                                    <h5>Status du colis</h5>
                                                </Col>
                                                <button
                                                    type="submit"
                                                    onClick={() => getTracking2(id)}
                                                    className=" btn btn-primary btn-xs trackrefm "
                                                >
                                                    {" "}
                                                    <i className="fa fa-refresh text-lg"></i>
                                                </button>
                                            </Row>
                                        ) : (
                                            <Row>
                                                {coli?.colies_notes_type && coli?.colies_notes ? (
                                                    <>
                                                    <Col sm="10">
                                                        <Label>
                                                            <strong>Notes Type: </strong> {coli && coli?.colies_notes_type}
                                                        </Label>
                                                    </Col>
                                                    <Col sm="10">
                                                        <Label>
                                                            <strong>Notes: </strong> {coli && coli?.colies_notes}
                                                        </Label>
                                                    </Col>
                                                    </>
                                                ):(
                                                    <></>
                                                )}
                                                <Col sm="10">
                                                    <h5>Status du colis</h5>
                                                </Col>
                                                <Col>
                                                    <button
                                                        type="submit"
                                                        onClick={() => getTracking2(id)}
                                                        className=" btn btn-primary btn-xs "
                                                    >
                                                        {" "}
                                                        <i className="fa fa-refresh text-lg"></i>
                                                    </button>
                                                </Col>
                                            </Row>
                                        )}

                                        <Row>
                                            {" "}
                                            <br/>
                                        </Row>
                                        <div className="activity-timeline">
                                            <Row>
                                                <Col> {trackingData && trackingData.tracking &&
                                                    trackingData.tracking.map((t) => (
                                                        <div className="media " key={t.id}>
                                                            <button
                                                                className="btn btn-danger btn-xs"
                                                                title="Delete Tracking"
                                                                onClick={() => {
                                                                    deleteTracking(t.id)
                                                                    dispatch(getTracking(id))
                                                                }}
                                                            >
                                                                x
                                                            </button>
                                                            <div className="activity-line"></div>
                                                            {t.activity_type &&
                                                            t.activity_type === "in_transit" ? (
                                                                <div className="activity-dot-primary"></div>
                                                            ) : t.activity_type === "delivery" ? (
                                                                <div className="activity-dot-success"></div>
                                                            ) : (
                                                                <div className="activity-dot-danger"></div>
                                                            )}
                                                            <div className="media-body">
                                                                <span>
                                                                  {t.description + " "}
                                                                    <Link
                                                                        to={`/finance/${id_fournisseur}`}
                                                                        className="form-label"
                                                                        data-original-title=""
                                                                        title=""
                                                                    >
                                                                    {t.activity_type}
                                                                  </Link>
                                                                </span>
                                                                {t.problem_description && t.problem_description ?
                                                                    <p>Note: {t.problem_description} </p> : ""
                                                                }
                                                                <p>{t.location && t.location}</p>
                                                                <p className="font-roboto">{t.happened_at ? t.happened_at : t.created_at}</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col></Col>
                    </Row>
                </Container>
            }
        </Fragment>
    );
};

Detailcoli.protoTypes = {
    deleteReclamation: PropTypes.func.isRequired,
    getReponses: PropTypes.func.isRequired,
    updateStatuscolis: PropTypes.func.isRequired,
    updatecolis: PropTypes.func.isRequired,
    fournisseurData: PropTypes.object.isRequired,
    getFournisseurs: PropTypes.func.isRequired,
    addNewReclamation: PropTypes.func.isRequired,
    addNewTracking: PropTypes.func.isRequired,
    deleteTracking: PropTypes.func.isRequired,
    getTracking2: PropTypes.func.isRequired,
    getCurrentcolis: PropTypes.func.isRequired,
    coli: PropTypes.object.isRequired,
    deleteColis: PropTypes.func.isRequired,
    reclamationData: PropTypes.object.isRequired,
    addNewReponse: PropTypes.func.isRequired,
    reponseData: PropTypes.object.isRequired,
    getVille: PropTypes.func.isRequired,
    getCite: PropTypes.func.isRequired,
    getDeligation: PropTypes.func.isRequired,
    getfiliere: PropTypes.func.isRequired,
    getLivreur: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    coli: state.reducerc,
    fournisseurData: state.reducerf,
    villeData: state.reducerc,
    citeData: state.reducerc,
    deligationData: state.reducerc,
    filiereData: state.reducerc,
    livreurData: state.reducerc,
    reclamationData: state.reducerr,
    reponseData: state.reducerr,
    trackingData: state.reducerc
});

export default connect(mapStateToProps, {
    deleteReclamation,
    getCurrentcolis,
    updatecolis,
    deleteTracking,
    addNewReclamation,
    addNewReponse,
    getReponses,
    getVille,
    updateStatuscolis,
    deleteColis,
    getCite,
    getFournisseurs,
    getDeligation,
    getfiliere,
    getLivreur,
    addNewTracking,
    getTracking2
})(withRouter(Detailcoli));
