import React, {Fragment, useContext, useEffect, useMemo, useState} from "react";
import {Button as B, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Row} from "reactstrap";
import Error404 from "../../pages/errors/error404";
import Breadcrumb from "../../layout/breadcrumb";
import Spinner from "../colis/spinner.gif";
import Alert from "../../redux/Alert";
import {getFournisseurs} from "../Fournisseurs/action";
import Select, {components} from "react-select";
import {Link, useHistory, useLocation, withRouter} from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import PropTypes from "prop-types";
import {Rating} from "react-simple-star-rating";
import {FormLabel} from "react-bootstrap";
import Button from "../../utils/Button";
import {api} from "../../utils/backend.instance";
import AppContext from "../../context/Context";

const newAvis = ({fournisseurData}) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loading] = useState(false)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [showLoader, setShowLoader] = useState(false)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [selectedfournisseur, setSelectedFournisseur] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [rating, setRating] = useState(0) // initial rating value
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [currentFournisseurPage, setCurrentFournisseurPage] = useState(1)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [formData, setFormData] = useState({
        fournisseur: "",
        rating: "",
        description: ""
    })
    const {
        config: {group}
        // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useContext(AppContext)

    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };
    // eslint-disable-next-line react-hooks/rules-of-hooks
    let query = useQuery();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const history = useHistory()
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dispatch = useDispatch()

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        query.set("page_size", "50")
        query.set("page", currentFournisseurPage)
        dispatch(getFournisseurs(query))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFournisseurPage]);

    const data = [];
    fournisseurData &&
    fournisseurData.fournisseurs.map((F) =>
        data.push({
            value: parseInt(F.id),
            label: F.name,
        })
    );

    const NoOptionsMessage = props => {
        return (
            <components.NoOptionsMessage {...props}>
                <div className="text-center">
                    <img src={Spinner} style={{width: "10%"}} alt=""/>
                </div>
            </components.NoOptionsMessage>
        );
    };

    const onChangeFournisseur = (e) => {
        setSelectedFournisseur(e);
        const fournisseurid = e.value;

        setFormData({
            ...formData,
            fournisseur: fournisseurid,
        });
    };

    const handleRating = (rate) => {
        setRating(rate)
        setFormData({...formData, rating: rate})
    }

    let user_groups_name = group
    const handleFieldChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        setShowLoader(true)
        await api.post("/avis/avis/", formData)
            .then(() => history.push('/avis'))
        setShowLoader(false)
    }
    return (
        <Fragment>
            {user_groups_name.indexOf("admin") !== -1 ?
                <Row>
                    <Breadcrumb parent="Avis" title="Ajouter un avis"/>
                    {loading ?
                        <img src={Spinner} style={{width: "10%", position: "relative", left: "45%"}} alt=""/> :
                        <Container fluid={true}>
                            <Form className="theme-form">
                                <Alert/>
                                <Card>
                                    <CardHeader>
                                        <h5> Details Avis</h5>
                                    </CardHeader>
                                    <CardBody>
                                        <Select
                                            options={data}
                                            placeholder={"Fournisseur"}
                                            components={{NoOptionsMessage}}
                                            onInputChange={(e) => {
                                                query.set('search', e);
                                                dispatch(getFournisseurs(query))
                                            }}
                                            onMenuScrollToBottom={(e) => {
                                                console.log(e)
                                                setCurrentFournisseurPage(page => page + 1)
                                            }}
                                            onChange={(e) => {
                                                onChangeFournisseur(e);
                                            }}
                                            value={selectedfournisseur}
                                        />
                                        <div className='App mt-4 d-flex justify-content-center align-items-center'>
                                            <Rating
                                                onClick={handleRating}
                                                onPointerMove={handleRating}
                                                ratingValue={rating}
                                                size={50}
                                                label
                                                transition
                                                fillColor='orange'
                                                emptyColor='gray'
                                                className='foo' // Will remove the inline style if applied
                                            />
                                            {/* Use rating value */}
                                            {rating}
                                        </div>
                                        <FormGroup>
                                            <FormLabel>Avis:</FormLabel>
                                            <textarea className={"form-control"} name={"description"}
                                                      onChange={handleFieldChange}>

                                        </textarea>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                                <Row>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Col>
                                        <FormGroup className="mb-0">
                                            <Button
                                                onClick={(e) => handleSubmit(e)}
                                                disabled={showLoader}
                                                className="btn btn-success mr-3"
                                                color={"success"}
                                                loading={showLoader}
                                                text={"Enregistrer"}
                                            />
                                            <Link to={`/avis`}>
                                                <B color="danger">Annuler</B>
                                            </Link>
                                            <br/>
                                            <br/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </Container>
                    }
                </Row>
                :
                <Error404 page={"Avis"}/>
            }
        </Fragment>
    )
}

newAvis.propTypes = {
    getFournisseurs: PropTypes.func.isRequired,
    fournisseurData: PropTypes.object.isRequired,
};

const mapstatetoprops = (state) => ({
    fournisseurData: state.reducerf,
});

export default connect(mapstatetoprops, {
    getFournisseurs,
})(withRouter(newAvis));