import {
    RIGHT_BAR_RECLAMATION_ERROR,
    RIGHT_BAR_TRACKING_ERROR,
    RIGHT_BAR_TRACKING_LOADING,
    RIGHT_BAR_TRACKING_ACTIVITIES_LOADING,
    RIGHT_BAR_TRACKING_ACTIVITIES_ERROR,
    GET_RIGHT_BAR_TRACKING_ACTIVITIES,
    RIGHT_BAR_RECLAMATION_LOADING,
    GET_RIGHT_BAR_RECLAMATION,
    GET_RIGHT_BAR_TRACKING,
    GET_RIGHT_BAR_COMPTE,
    RIGHT_BAR_COMPTE_ERROR,
    RIGHT_BAR_COMPTE_LOADING,
} from "../../redux/actionTypes";

const initial_state = {
    t_count: 0,
    r_count: 0,
    ta_count: 0,
    tracking: [],
    trackingactivity:[],
    compte: null,
    reclamation: [],
    loading: false,
    error: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initial_state, action) {
    const {type, payload} = action;
    switch (type) {
        case RIGHT_BAR_TRACKING_ACTIVITIES_LOADING:
        case RIGHT_BAR_TRACKING_LOADING:
        case RIGHT_BAR_RECLAMATION_LOADING:
            return {
                ...state,
                loading: true,
            };
        case RIGHT_BAR_TRACKING_ACTIVITIES_ERROR:
        case RIGHT_BAR_RECLAMATION_ERROR:
        case RIGHT_BAR_TRACKING_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        case GET_RIGHT_BAR_RECLAMATION:
            return {
                ...state,
                r_count: payload.count,
                reclamation: payload.results,
                loading: false,
            };
        case GET_RIGHT_BAR_TRACKING:
            return {
                ...state,
                t_count: payload.count,
                tracking: payload.results,
                loading: false,
            };
        case GET_RIGHT_BAR_TRACKING_ACTIVITIES:
            return {
                ...state,
                ta_count: payload.count,
                trackingactivity: payload.results,
                loading: false,
            }
        case RIGHT_BAR_COMPTE_LOADING:
            return {
                ...state,
                loading: true,
            };
        case RIGHT_BAR_COMPTE_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        case GET_RIGHT_BAR_COMPTE:
            return {
                ...state,
                compte: payload,
                loading: false,
            };
        default:
            return state;
    }
}
