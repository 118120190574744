import React, {
  Fragment,
  useState,
  useEffect,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import Alert from "../../redux/Alert";
import { connect } from "react-redux";
import {Link, withRouter, useLocation, useParams} from "react-router-dom";
import {
  updateAdresse,
    getCurrentadresse,
} from "./actions";
import {
  getVille,
  getCite,
  getDeligation,
} from "../colis/action";
import Breadcrumb from "../../layout/breadcrumb";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CardHeader,
} from "reactstrap";
import { useDispatch } from "react-redux";

const EditAdress = ({
  updateAdresse,
  adresse: { adresse, loading },
  history,
  villeData,
  citeData,
  deligationData,
}) => {
  const [stateville, setVille] = useState([
    {
      id: "",
      name: "",
    },
  ]);
  const [statecite, setCite] = useState([
    {
      id: "",
      name: "",
    },
  ]);
  const [statedelegation, setDelegation] = useState([
    {
      id: "",
      name: "",
    },
  ]);

  const [formData, setFormData] = useState({
    email: "",
    telephone_1: "",
    telephone_2: "",
    first_name: ".",
    last_name: "",
    zipcode: "",
    address: "",
    ville: "",
    cite: "",
    deligation: "",
  });

  const { id } = useParams();

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    updateAdresse(formData, id, history);
  };
  const dispatch = useDispatch();

  const [taptopStyle, setTapTopStyle] = useState("none");
  const executeScroll = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleScroll = () => {
    if (window.scrollY > 600) {
      setTapTopStyle("block");
    } else {
      setTapTopStyle("none");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [taptopStyle]);

  const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  };
  let query = useQuery();

  const onChangeDeligation = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    query.set("gouvernat_id", e.target.value);
    dispatch(getVille(query));
  };

  const onChangeVille = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    query.set("ville_id", e.target.value);
    dispatch(getCite(query));
  };

//get ville
  useEffect(() => {
    dispatch(getVille(query));
  }, [dispatch,query]);
  useEffect(() => {
    setVille(villeData.villes);
  }, [villeData.villes,villeData.loading, stateville]);

  //get deligation
  useEffect(() => {
    dispatch(getDeligation());
  }, [dispatch]);
  useEffect(() => {
    setDelegation(deligationData.deligations);
  }, [deligationData.deligations,deligationData.loading, statedelegation]);
  // get cite
  useEffect(() => {
    dispatch(getCite(query));
  }, [dispatch,query]);
  useEffect(() => {
    setCite(citeData.cites);
  }, [citeData.cites,citeData.loading, statecite]);

  useEffect(() => {
    getCurrentadresse(id)
  }, [id])

  useEffect(() => {
    setFormData({
      first_name:
        loading || !adresse?.first_name ? "" : adresse.first_name,
      last_name:
        loading || !adresse?.last_name ? "" : adresse.last_name,
      email: loading || !adresse?.email ? "" : adresse.email,
      telephone_1: loading || !adresse?.telephone_1 ? "" : adresse.telephone_1,
      telephone_2:
        loading || !adresse?.telephone_2 ? "" : adresse.telephone_2,
      address: loading || !adresse?.address ? "" : adresse.address,
      deligation: loading || !adresse?.deligation ? "" : adresse.deligation,
      ville:
        loading || !adresse?.ville ? "" : adresse.ville,
      cite: loading || !adresse?.cite ? "" : adresse.cite,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  let user_groups_name = localStorage.getItem("user_groups_name");

  return (
    <Fragment>
      <Breadcrumb parent="Adresse" title="Modifier une adresse" />
      <Container fluid={true}>
        <Form className="theme-form" onSubmit={(e) => onSubmit(e)}>
          <Alert />
          <Row>
            <Col sm="6">
              {user_groups_name.indexOf("admin") !== -1 ||
                  user_groups_name.indexOf("fournisseur") !== -1? (
                <Card>
                  <CardHeader>
                    <h5> Details Utilisateur</h5>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Nom</Label>
                            <Input
                                className="form-control digits"
                                name="first_name"
                                id="first_name"
                                type="text"
                                value={formData.first_name}
                                onChange={(e) => onChange(e)}
                            />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Prénom</Label>
                          <Input
                            className="form-control digits"
                            name="last_name"
                            id="last_name"
                            type="text"
                            value={formData.last_name}
                            onChange={(e) => onChange(e)}
                          />
                        </FormGroup>
                      </Col>

                      <Col>
                        <FormGroup>
                          <Label>Email</Label>
                          <Input
                            className="form-control"
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={(e) => onChange(e)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                      <Row>
                          <Col>
                              <FormGroup>
                                <Label>Téléphone 1 (*)</Label>
                                <Input
                                  className="form-control"
                                  type="text"
                                  required
                                  name="telephone_1"
                                  placeholder="Téléphone 1"
                                  value={formData.telephone_1}
                                  onChange={(e) => onChange(e)}
                                />
                              </FormGroup>
                            </Col>
                          <Col>
                              <FormGroup>
                                <Label>Téléphone 2</Label>
                                <Input
                                  className="form-control"
                                  type="text"
                                  name="telephone_2"
                                  placeholder="Téléphone 2"
                                  value={formData.telephone_2}
                                  onChange={(e) => onChange(e)}
                                />
                              </FormGroup>
                            </Col>
                      </Row>
                  </CardBody>
                </Card>
              ) : (
                ""
              )}
              </Col>
              <Col sm="6">
              <Card>
                <CardHeader>
                  <h5>Détails Adresse</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Adresse (*)</Label>
                        <Input
                          className="form-control"
                          type="text"
                          required
                          name="address"
                          placeholder="adresse"
                          value={formData.address}
                          onChange={(e) => onChange(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Délégation (*)</Label>
                        <select
                          className="form-control digits"
                          name="deligation"
                          id="delegation"
                          required
                          value={formData.deligation}
                          onChange={(e) => onChangeDeligation(e)}
                        >
                          <option value="" disabled defaultValue hidden>
                            ----------
                          </option>
                          {deligationData &&
                            deligationData.deligations.map((delegation) => (
                              <option key={delegation.id} value={delegation.id}>
                                {delegation.name}
                              </option>
                            ))}
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>

                    <Col>
                      <FormGroup>
                        <Label>Ville (*)</Label>

                        <select
                          className="form-control digits"
                          name="ville"
                          id="ville"
                          required
                          value={formData.ville}
                          onChange={(e) => onChangeVille(e)}
                        >
                          {" "}
                          <option value="" disabled defaultValue hidden>
                            ----------
                          </option>
                          {villeData &&
                            villeData.villeF.map((ville) => (
                              <option key={ville.id} value={ville.id}>
                                {ville.name}
                              </option>
                            ))}
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Cité (*)</Label>
                        <select
                          className="form-control digits"
                          name="cite"
                          id="cite"
                          required
                          value={formData.cite}
                          onChange={(e) => onChange(e)}
                        >
                          <option value="" disabled defaultValue hidden>
                            ----------
                          </option>
                          {citeData &&
                            citeData.citesF.map((cite) => (
                              <option key={cite.id} value={cite.id}>
                                {cite.name}
                              </option>
                            ))}
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
                </Col>


            <Row>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Col>
                <FormGroup className="mb-0">
                  <button
                    type="submit"
                    onClick={() => executeScroll()}
                    className="btn btn-success mr-3"
                  >
                    Save
                  </button>
                  <Link to={`/colis?created_at=today`}>
                    <Button color="danger">Cancel</Button>
                  </Link>
                  <br />
                  <br />
                </FormGroup>
              </Col>
            </Row>
          </Row>
        </Form>
      </Container>
    </Fragment>
  );
};
EditAdress.propTypes = {
  getVille: PropTypes.func.isRequired,
  getCite: PropTypes.func.isRequired,
  getDeligation: PropTypes.func.isRequired,
  updateAdresse: PropTypes.func.isRequired,
  getCurrentadresse: PropTypes.func.isRequired,
  adresse: PropTypes.object.isRequired,
};

const mapstatetoprops = (state) => ({
  villeData: state.reducerdvc,
  citeData: state.reducerdvc,
  deligationData: state.reducerc,
  adresse: state.reduceraddr,
});

export default connect(mapstatetoprops, {
  updateAdresse,
  getVille,
  getCite,
  getDeligation,
  getCurrentadresse,
})(withRouter(EditAdress));
