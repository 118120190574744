import { setAlert, setAlertD } from "../../redux/alert/action";
import { setAuthToken, api } from "../../utils/backend.instance";
import {
  GET_NEW, NEWS_ERROR, NEWS_LOADING, GET_NEWS, UNSEEN_NEWS_LOADING, UNSEEN_NEWS, UNSEEN_NEWS_ERROR,
} from "../../redux/actionTypes";

export const addNew = (formData, history) => async (dispatch) => {
  if (localStorage.access) {
    setAuthToken(localStorage.access);
  }
  try {
    const res = await api.post("/news/new/", formData);
    dispatch({
      type: GET_NEW,
      payload: res.data,
    });
    dispatch(setAlert("New Successfully Created", "success"));
    history.push("/news");
  } catch (err) {
    const errors = err.response.data;
    if (errors) {
      dispatch(
        setAlertD(
          (errors.user ? "Utilisateur: " + errors.user + " " : "") +
            (errors.title ? "Titre: " + errors.title + " " : "") +
            (errors.description ? " Description: " + errors.description + " " : ""),
          "danger"
        )
      );
    }
    dispatch({
      type: NEWS_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
  }
}

export const updateNew = (formData, id, history) => async (dispatch) => {
  if (localStorage.access) {
    setAuthToken(localStorage.access);
  }
  try {
    const res = await api.put(`/news/new/${id}/`, formData);
    dispatch({
      type: GET_NEW,
      payload: res.data,
    });
    dispatch(setAlert("New Successfully Updated", "success"));
    history.push('/news')
  } catch (err) {
    const errors = err.response.data;
    if (errors) {
      dispatch(
        setAlertD(
          (errors.user ? "Utilisateur: " + errors.user + " " : "") +
            (errors.title ? "Titre: " + errors.title + " " : "") +
            (errors.description ? " Description: " + errors.description + " " : ""),
          "danger"
        )
      );
    }
    dispatch({
      type: NEWS_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
  }
};

export const getCurrentNew = (id) => async (dispatch) => {
  dispatch({ type: NEWS_LOADING });
  if (localStorage.access) {
    setAuthToken(localStorage.access);
  }

  try {
    const res = await api.get(`/news/new/${id}/`);

    dispatch({
      type: GET_NEW,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: NEWS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const getNews = (params) => async (dispatch) => {
  dispatch({ type: NEWS_LOADING });
  if (localStorage.access) {
    setAuthToken(localStorage.access);
  }

  try {
    const res = await api.get(`/news/new/?${params.toString()}`);

    dispatch({
      type: GET_NEWS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: NEWS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const getUnseenNews = () => async (dispatch) => {
  dispatch({ type: UNSEEN_NEWS_LOADING });
  if (localStorage.access) {
    setAuthToken(localStorage.access);
  }

  try {
    const res = await api.get(`/news/unseen_news/`);

    dispatch({
      type: UNSEEN_NEWS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: UNSEEN_NEWS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const deleteNew = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/news/new/${id}/`);

    dispatch({
      type: GET_NEW,
      payload: res.data,
    });

    dispatch(setAlertD("New Successfully Deleted", "success"));
  } catch (err) {
    dispatch({
      type: NEWS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
