import React from 'react'
import {
    Col,
    Row,
} from "reactstrap";
import mirror from "../images/voulez-vous-expédier-des-marchandise.png"
import {Link} from "react-router-dom"
export default function Section4() {
  return (
    <Row style={{backgroundColor : "white"}} >
        <Col md={2}></Col>
        <Col sm={8} md={4} style={{display: "flex" , alignItems: "center" , justifyContent: "center"}}>
        <img src={mirror} style={{height : "450px"}} alt={""}/>
        </Col>
        <Col sm={8} md={4}>
        <div style={{marginTop : "100px" , backgroundColor : "white" , textAlign : "center" }}>
        <h1 style={{ fontWeight : "500"}}>VOUS VOULEZ EXPÉDIER DES MARCHANDISES ?</h1><br/>
        <Link  to={"/partner"}
                        className="btn"
                        style={{
                            
                            fontSize: "25px",
                            fontWeight: "bold",
                            color: "white",
                            borderRadius: "30px",
                            backgroundColor: "#FFC107",}} >Devenir partenaire</Link>
        </div>
        </Col>
        <Col md={2}></Col>

    </Row>
  )
}
