import React, {Fragment, useState, useMemo, useEffect, useContext} from "react";
import {connect, useDispatch} from "react-redux";
import {Link, useHistory, useLocation, withRouter} from "react-router-dom";
import {deleteDemande, getDemandes} from "./action";
import Spinner from "../colis/spinner.gif";
import PropTypes from "prop-types";
import Breadcrumb from "../../layout/breadcrumb";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    InputGroupAddon,
    Row,
    Table,
} from "reactstrap";
import Alert from "../../redux/Alert";
import PaginationWrapper from "../pagination/pagination";
import Error404 from "../../pages/errors/error404";
import {day2digits} from "../colis/gestiondescolis";
import AppContext from "../../context/Context";

const Demandes = ({demandeData, deleteDemande}) => {
    const [alldata, searchData] = useState([]);
    const dispatch = useDispatch();
    const [timer, setTimer] = useState(null)
    const {
        config: {group}
    } = useContext(AppContext)
    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };

    let query = useQuery();
    const history = useHistory()

    useEffect(() => {
        dispatch(getDemandes(query));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (demandeData.colis) {
            searchData(demandeData.colis);
        }
    }, [demandeData.colis, demandeData.loading, alldata]);
    let pagenumber = query.get("page") ? query.get("page") : 1;
    const Navigate = (page) => {
        query.set("page", `${Number(page)}`)

        window.location.replace(`/demande?${query}`);
    };

    let user_groups_name = group;
    let count = demandeData.count;
    const getValSearch = () => {
        let params = new URLSearchParams(document.location.search);
        return params.get("search");
    };

    const inputChanged = (e) => {
        clearTimeout(timer)
        query.set("search", e)
        const newTimer = setTimeout(() => {
            dispatch(getDemandes(query));
        }, 500)

        setTimer(newTimer)
    }
    const getDate = () => {
        let params = new URLSearchParams(document.location.search);
        let date = params.get("created_at");
        if (date === "today") {
            let today = new Date();
            let m = today.getMonth() + 1;
            if (m < 10) {
                m = "0" + m;
            }
            return today.getFullYear() + "-" + m + "-" + day2digits(today.getDate());
        } else {
            return date;
        }
    };
    return (
        <Fragment>
            <Breadcrumb parent="Demandes" title={`Demandes`}/>
            {user_groups_name && user_groups_name.indexOf("admin") !== -1 ? (
                <Container fluid={true}>
                    <Alert/>
                    <Row>
                        <Col sm="12">
                            <Card>
                                {/* {datacolis.colis.length > 0 ? () : ()} */}

                                <CardBody>
                                    <Col sm="12">
                                        <Row>
                                            <Col sm="4"></Col>
                                        </Row>
                                        <br/>
                                        <br/>
                                        <Row>
                                            <Col>
                                                <Form className="theme-form">
                                                    <InputGroupAddon addonType="append">
                                                        <Col>
                                                            <div>
                                                                Du
                                                                <input
                                                                    className="btn btn-light"
                                                                    type="date"
                                                                    name="created_at__gte"
                                                                    onChange={(e) => query.set("created_at__gte", e.target.value)}
                                                                    defaultValue={getDate()}
                                                                />
                                                                À
                                                                <input
                                                                    className="btn btn-light"
                                                                    type="date"
                                                                    name="created_at__lte"
                                                                    onChange={(e) => query.set("created_at__lte", e.target.value)}
                                                                    defaultValue={getDate()}
                                                                />
                                                                <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        history.push({search: query.toString()})
                                                                        dispatch(getDemandes(query))
                                                                    }}
                                                                    className="btn text-white  ml-2"
                                                                    style={{backgroundColor: "#27B3B0"}}
                                                                >
                                                                    Valider
                                                                </button>
                                                            </div>
                                                        </Col>
                                                    </InputGroupAddon>
                                                </Form>
                                            </Col>
                                            <div className="search-5">
                                                <h6>Rechercher:</h6>
                                            </div>
                                                <input
                                                    className=" border search "
                                                    style={{borderRadius: "7px"}}
                                                    type="search"
                                                    placeholder="Rechercher..."
                                                    name="search"
                                                    defaultValue={getValSearch()}
                                                    onChange={(e) => inputChanged(e.target.value)}
                                                />
                                            </Row>
                                        <Card>
                                            <div className="card-block row">
                                                <Col sm="12">

                                                    <div className="table-responsive">
                                                        {demandeData && demandeData.loading ? (
                                                            <div className="text-center">
                                                                <img src={Spinner} style={{width: "10%"}} alt=""/>
                                                            </div>
                                                        ) : (
                                                            <Table striped>
                                                                <thead>
                                                                <tr>
                                                                    <th scope="col"></th>
                                                                    <th>{"Id"}</th>
                                                                    <th>{"Nom"}</th>
                                                                    <th>{"Prénom"}</th>
                                                                    <th>{"Entreprise"}</th>
                                                                    <th>{"Colis"}</th>
                                                                    <th>{"Téléphone"}</th>
                                                                    <th>{"Date"}</th>
                                                                    <th>{"Type"}</th>
                                                                    <th>{"Approuvé"} </th>
                                                                    <th>{"Action"} </th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {demandeData &&
                                                                    demandeData.demandes.map((b) => (
                                                                        <tr key={b.id}>
                                                                            <td></td>
                                                                            <td>
                                                                                <Link
                                                                                    to={`/demande/detail/${b.id}?key=${b.key}`}
                                                                                >
                                                                                    {b.id}
                                                                                </Link>
                                                                            </td>
                                                                            <td>{b.first_name}</td>
                                                                            <td>{b.last_name}</td>
                                                                            <td>{b.entreprise}</td>
                                                                            <td>{b.commande}</td>
                                                                            <td>{b.number}</td>
                                                                            <td>{b.created_at}</td>
                                                                            <td>{b.type}</td>
                                                                            {b.valid && b.valid === true ?
                                                                                <td className="text-success">
                                                                                    Oui
                                                                                </td> : <td className="text-danger">
                                                                                    Non
                                                                                </td>}
                                                                            <td>
                                                                                <button className="btn btn-danger"
                                                                                        onClick={() => {
                                                                                            deleteDemande(b.id);
                                                                                            dispatch(getDemandes(query));
                                                                                            dispatch(getDemandes(query))
                                                                                        }}>
                                                                                    <i className="fa fa-trash"></i>
                                                                                </button>
                                                                            </td>

                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                        )}
                                                    </div>
                                                </Col>
                                            </div>
                                        </Card>
                                    </Col>{" "}
                                    <Row>
                                        <Col sm="10"></Col>
                                        {demandeData && demandeData.loading ? "" :
                                            <Col>
                                                <PaginationWrapper
                                                    name="Demandes"
                                                    length={demandeData.demandes.length}
                                                    count={count}
                                                    currentPage={pagenumber}
                                                    onNavigate={Navigate}
                                                />
                                            </Col>}
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            ) : (
                <Error404 page="Demandes"/>
            )}
        </Fragment>
    );
};

Demandes.propTypes = {
    deleteDemande: PropTypes.func.isRequired,
    getDemandes: PropTypes.func.isRequired,
    demandeData: PropTypes.object.isRequired,
};

const mapstatetoprops = (state) => ({
    demandeData: state.reducerde,
});

export default connect(mapstatetoprops, {
    getDemandes,
    deleteDemande,
})(withRouter(Demandes));
