import {
  GET_FOURNISSEUR,
  GET_FOURNISSEURS,
  FOURNISSEUR_LOADING,
  FOURNISSEUR_ERROR,
  UPDATE_FOURNISSEUR,
  GET_FOURNISSEURSP,
  FOURNISSEURP_LOADING,
  FOURNISSEURP_ERROR,
} from "../../redux/actionTypes";

const initial_state = {
  fournisseur: null,
  count: 0,
  fournisseurs: [],
  fournisseursP: [],
  secteurs: [],
  loading: true,
  error: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initial_state, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_FOURNISSEURSP:
      return {
        ...state,
        loading: false,
        fournisseursP: payload,
      };
    case FOURNISSEURP_LOADING:
      return {
        ...state,
        loading: true,
      };
    case FOURNISSEURP_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case FOURNISSEUR_LOADING:
      return {
        ...state,
        loading: true,
      };
    case FOURNISSEUR_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case GET_FOURNISSEUR:
      return {
        ...state,
        fournisseur: payload,
        loading: false,
      };
    case UPDATE_FOURNISSEUR:
      return {
        ...state,
        fournisseur: payload,
        loading: false,
      };
    case GET_FOURNISSEURS:
      return {
        ...state,
        fournisseurs: payload.results,
        count: payload.count,
        loading: false,
      };

    default:
      return state;
  }
}
