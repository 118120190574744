import React, {Fragment, useState, useEffect, useCallback} from "react";
import {
    Card,
    CardBody,
    Col,
    Form,
    Row,
    Modal,
    ModalBody,
    Input,
    FormGroup,
} from "reactstrap";
import {connect} from "react-redux";
import Alert from '../../redux/Alert'
import PropTypes from "prop-types";
import {addNewDemande} from "../demandes/action";
import Section1 from "./components/Section1";
import Testimonials from "./components/testimonials";
import Footer from "./components/footer";
import Nava from "./components/Navbar"
import Section2 from "./components/Section2";
import Threecards from "./components/threecards";
import Section4 from "./components/MarchandiseP";
import Cards from "./components/Cards";

const Landing = ({addNewDemande}) => {
    // eslint-disable-next-linef
    const [, setsearchValue] = useState("");
    // eslint-disable-next-line
    const [, setSearchResult] = useState(false);
    // eslint-disable-next-line
    const [modal, setModal] = useState(false);
    const [formData, setFormData] = useState({
        commande: 0, first_name: "", last_name: "", type: "Demo", demo: "", number: "", email: "", entreprise: "",
    });

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            setsearchValue("");
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    const onSubmit = (e) => {
        e.preventDefault();
        addNewDemande(formData);
    };
    const onChange = (e) => {
        e.preventDefault();
        setFormData({...formData, [e.target.name]: e.target.value});
    };
    return (<Fragment>
        <div style={{backgroundColor: "#fff", fontFamily: "Raleway"}}>

            <Nava addNewDemande={addNewDemande}/>

            <Row>
                <Col></Col>
                <Col><Alert/></Col>
                <Col></Col>
            </Row>
            <Section2/>
            <br/>
            <br/>
            <br/>
            <br/>
            <Threecards/>
            <br/>
            <br/>
            <Section4/>
            <br/>
            <br/>
            <Cards/>

            <br/>
            <br/>
            <br/>
            <Section1/>

            <br/>
            <br/>
            <br/>


            <Row>
                <Col>
                    <h1 className={"text-center"}
                        style={{
                            fontSize: "25px ", fontWeight: "bold",
                        }}
                    >
                        CE QUE NOS PARTENAIRES DISENT DE NOUS
                    </h1>
                    <br/>
                </Col>
            </Row>
            <Testimonials/>
            <br/>
            <br/>
            <br/>


            <Footer/>


            <Modal
                centered
                size="xl"
                className="relative flex flex-col sm:justify-center items-center"
                isOpen={modal}
                toggle={() => setModal(!modal)}
            >
                <ModalBody style={{backgroundColor: "#F5F8FA"}} Style={{fontFamily: "typo"}}>
                    <Row>
                        <Col sm="5">
                            <p className="m-2" style={{fontSize: "30px"}}>
                                Réserver une démo
                            </p>
                            <br/>
                            <p className="m-2" style={{fontSize: "15px"}}>
                                Beezit est la solution logistique tunisienne garantissant la
                                meilleure experience clients et augmentant vos ventes
                            </p>
                            <br/>
                            <li className="m-2">Accédez à une tarification simple</li>

                            <li className="m-2">
                                Accédez à une solution de livraison premier ordre
                            </li>
                            <li className="m-2"> Augmentez vos ventes</li>
                        </Col>

                        <Col>
                            <Card>
                                <CardBody>

                                    <Form onSubmit={(e) => onSubmit(e)}>
                                        <Row>
                                            <Col>
                                                {" "}
                                                <FormGroup>
                                                    <Input
                                                        style={{height: "50px", borderRadius: "10px"}}
                                                        type="text"
                                                        name="last_name"
                                                        placeholder="Nom*"
                                                        value={formData.last_name}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                {" "}
                                                <FormGroup>
                                                    <Input
                                                        style={{height: "50px", borderRadius: "10px"}}
                                                        type="text"
                                                        name="first_name"
                                                        placeholder="Prénom*"
                                                        value={formData.first_name}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {" "}
                                                <FormGroup>
                                                    <Input
                                                        style={{height: "50px", borderRadius: "10px"}}
                                                        type="number"
                                                        name="number"
                                                        placeholder="Numéro de téléphone*"
                                                        value={formData.number}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                {" "}
                                                <FormGroup>
                                                    <Input
                                                        style={{height: "50px", borderRadius: "10px"}}
                                                        type="emails"
                                                        name="email"
                                                        placeholder="Email*"
                                                        value={formData.email}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <FormGroup>
                                            <select
                                                style={{height: "50px", borderRadius: "10px"}}
                                                className="form-control digits "
                                                name="type"
                                                value={formData.type}
                                                onChange={(e) => onChange(e)}
                                            >
                                                <option value="" disabled selected hidden>
                                                    Vous vendez principalemens vos produits sur*
                                                </option>
                                                <option hidden> vous vendez sur ?</option>
                                                <option value={"website"}>Site web</option>
                                                <option value={"social_media"}>
                                                    Résaux socieaux{" "}
                                                </option>
                                            </select>
                                        </FormGroup>
                                        <p>
                                            Beezit a besoin des cordonnées que vous nous fournisser
                                            pour vous contacter au sujet de nos produits et
                                            services. Vous pouvez vous désabonner de ces
                                            communications à tout moment. Pour plus d'informations,
                                            veuillez consulter notre politique de confidentalité
                                        </p>
                                        <Row>
                                            <Col>
                                                <button
                                                    type="submit"
                                                    onClick={() => setModal(!modal)}
                                                    className="p-2 text-white ml-1 btn"
                                                    style={{
                                                        backgroundColor: "#193256",
                                                        width: "100%",
                                                        borderRadius: "20px",
                                                    }}
                                                >
                                                    Soumettre
                                                </button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="1"></Col>
                    </Row>
                </ModalBody>
            </Modal>
        </div>


    </Fragment>);
};
Landing.propTypes = {
    addNewDemande: PropTypes.func.isRequired,
};

export default connect(null, {addNewDemande})(Landing);
