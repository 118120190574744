import * as FileSaver from "file-saver"
import XLSX from "sheetjs-style"
import {Button} from "reactstrap";
import React from "react";

const ExportExcel = ({headers, data, fileName}) => {

    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
    const fileExtension = ".xlsx"

    const ExportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet([...data]);
        const wb = {Sheets: {'data': ws}, SheetNames: ['data']}
        const excelBuffer = XLSX.write(wb, {bookType: "xlsx", type: "array"})
        const excelData = new Blob([excelBuffer], {type: fileType})
        FileSaver.saveAs(excelData, fileName + fileExtension)
    }

    return (
        <>
            <Button variant={"contained"} onClick={() => ExportToExcel()} color={"link"}>
                <i className={"fa fa-file"}></i> Export
            </Button>
        </>
    )

}

export default ExportExcel
