import { setAlert } from "../../redux/alert/action";
import { setAuthToken, api } from "../../utils/backend.instance";
import {
  GET_RECLAMATION,
  GET_RECLAMATIONS,
  RECLAMATION_LOADING,
  RECLAMATION_ERROR,
  UPDATE_RECLAMATION,
  GET_REPONSE,
  REPONSE_ERROR,
  REPONSE_LOADING,
  GET_REPONSES,
} from "../../redux/actionTypes";
export const addNewReclamation =
  (formDatareclamation, history) => async (dispatch) => {
    if (localStorage.access) {
      setAuthToken(localStorage.access);
    }
    try {
      const res = await api.post(
        `/colis/reclamation/`,
        formDatareclamation
      );
      dispatch({
        type: GET_RECLAMATION,
        payload: res.data,
      });
      dispatch(setAlert("Reclamation Successfully Created", "success"));
      history.push("/reclamation");
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }
      dispatch({
        type: RECLAMATION_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  };


export const getReclamations = (params) => async (dispatch) => {
  dispatch({ type: RECLAMATION_LOADING });
  if (localStorage.access) {
    setAuthToken(localStorage.access);
  }
  try {
    const res = await api.get(
      `/colis/reclamation/?${params.toString()}`
    );

    dispatch({
      type: GET_RECLAMATIONS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: RECLAMATION_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};


export const getReclamationsByIds = (params, id) => async (dispatch) => {
  dispatch({ type: RECLAMATION_LOADING });
  if (localStorage.access) {
    setAuthToken(localStorage.access);
  }
  params.set("colis_id", id);

  try {
    const res = await api.get(`/colis/reclamation/?${params.toString()}`);

    dispatch({
      type: GET_RECLAMATIONS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: RECLAMATION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const deleteReclamation = (id, history) => async (dispatch) => {
  try {
    const res = await api.delete(`/colis/reclamation/${id}/`);

    dispatch({
      type: UPDATE_RECLAMATION,
      payload: res.data.results,
    });

    dispatch(setAlert("Réclamation Successfully Deleted", "success"));
    history.push("/reclamation");
  } catch (err) {
    dispatch({
      type: RECLAMATION_ERROR,
      payload: { msg: err.response, status: err.response },
    });
  }
};
export const addNewReponse =
  (formDatareponse, id) => async (dispatch) => {
    if (localStorage.access) {
      setAuthToken(localStorage.access);
    }
    try {
      formDatareponse = { ...formDatareponse, reclamation: id };
      const res = await api.post(`/colis/reponse/`, formDatareponse);

      dispatch({
        type: GET_REPONSE,
        payload: res.data,
      });
      dispatch(setAlert("Reponse Successfully Created", "success"));
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }
      dispatch({
        type: REPONSE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  };

export const getReponses = (id) => async (dispatch) => {
  dispatch({ type: REPONSE_LOADING });
  if (localStorage.access) {
    setAuthToken(localStorage.access);
  }
  try {
    const res = await api.get(`/colis/reponse/?reclamation_id=${id}`);

    dispatch({
      type: GET_REPONSES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: REPONSE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
