import React, {
    Fragment,
    useState,
    useEffect,
    useMemo, useContext,
} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import Alert from "../../redux/Alert";
import PaginationWrapper from "../pagination/pagination";
import PropTypes from "prop-types";
import Spinner from "../colis/spinner.gif";
import {connect} from "react-redux";
import {withRouter, useLocation, useHistory} from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Table,
    Card,
    CardBody, ModalHeader, ModalBody, ModalFooter, Modal,
} from "reactstrap";
import {useDispatch} from "react-redux";
import {getComptes} from "./action";
import {Link} from "react-router-dom";
import {getColis} from "../colis/action";
import Error404 from "../../pages/errors/error404";
import {impersonate} from "./action";
import Button from "../../utils/Button";
import AppContext from "../../context/Context";
import {loadUser} from "../../redux/signin/action";
import {setAlert} from "../../redux/alert/action";

const Compte = ({comptedata}) => {
    const [data, setData] = useState([]);
    const [ascId, setAscSortId] = useState(true);
    const [timer, setTimer] = useState(null)
    const [alldata, searchData] = useState([]);
    const [modal, setModal] = useState(false)
    const [id, setID] = useState("")
    const [disable, setDisable] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const dispatch = useDispatch();
    const {
        config: {group}
    } = useContext(AppContext)

    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };

    const history = useHistory()


    let query = useQuery();

    useEffect(() => {
        dispatch(getComptes(query));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    useEffect(() => {
        if (comptedata.comptes) {
            searchData(comptedata.comptes);
        }
    }, [comptedata.comptes, searchData.loading, alldata]);
    // useEffect(() => {
    //     if (comptedata.loading)
    //         setDisable(true)
    //     else
    //         setDisable(false)
    // }, [comptedata.loading])
    let pagenumber = query.get("page") ? query.get("page") : 1;
    const Navigate = (page) => {
        query.set("page", `${Number(page)}`)
        window.location.replace(`/compte/?${query}`);
    };
    let count = comptedata.count;
    const getValSearch = () => {
        let params = new URLSearchParams(document.location.search);
        return params.get("q");
    };
    const inputChanged = (e) => {
        clearTimeout(timer)
        query.set("search", e)
        const newTimer = setTimeout(() => {
            dispatch(getComptes(query));
        }, 500)

        setTimer(newTimer)
    }

    const onAscSort = (e) => {
        const qr = query.toString() + `&ordering=${e.target.id}`;
        setAscSortId(false);
        dispatch(getColis(qr));
        setData(data);
    };

    const onDescSort = (e) => {
        const qr = query.toString() + `&ordering=-${e.target.id}`;
        setAscSortId(true);
        dispatch(getColis(qr));
        setData(data);
    };

    const {
        setConfig
    } = useContext(AppContext);

    const onSubmit = (e) => {
        setShowLoader(true)
        e.preventDefault()
        impersonate(id)
            .then(res => {
                setConfig('refresh', res?.data?.refresh)
                loadUser(res?.data?.refresh)
                    .then(res => {
                        setConfig('access', res?.data.access)
                        setConfig('user_id', res?.data?.user?.id)
                        setConfig('stock_service', res?.data?.stock_service)
                        setConfig('group', res?.data?.user?.groups_name)
                        setConfig('avatar', res?.data?.user?.fournisseur_image_path)
                        setConfig('fournisseur', res?.data?.user?.fournisseur)
                        setConfig('username', res?.data?.user?.username)
                        setConfig('accept_terms', res?.data?.user?.terms_service)
                        history.push('/dashboard')
                    })
                    .catch(() => dispatch(setAlert("invalid credentials", "danger")))
                setConfig('isAuthenticated', true)
                setModal(false)
                setID("")
                setTimeout(() => setShowLoader(false), 1000)
            })
    }

    let user_groups_name = group;
    return (
        <Fragment>
            <Breadcrumb parent="User" title="Compte"/>
            {disable ? (
                <div className="text-center">
                    <img src={Spinner} style={{width: "10%", position: "relative", left: "45%"}} alt=""/>
                </div>
            ) : (
                user_groups_name.indexOf("personnel") === -1 ? (
                    <div>
                        <Container fluid={true}>
                            <Alert/>
                            <Row>
                                <Col sm="12">
                                    <Card>
                                        {/* {datacolis.colis.length > 0 ? () : ()} */}

                                        <CardBody>
                                            <Col sm="12">
                                                <Row>
                                                    <Col>
                                                        <Link
                                                            className="btn  m-2 "
                                                            style={{backgroundColor: "#FDF203"}}
                                                            to="/compte/new"
                                                        >
                                                            {"Ajouter"}
                                                        </Link>
                                                    </Col>
                                                    <div className="search-5">
                                                        <h6>Rechercher:</h6>
                                                    </div>

                                                    <input
                                                        className=" border search "
                                                        style={{borderRadius: "7px"}}
                                                        type="search"
                                                        placeholder="Rechercher..."
                                                        name="search"
                                                        defaultValue={getValSearch()}
                                                        onChange={(e) => inputChanged(e.target.value)}
                                                    />
                                                </Row>
                                                <Card>
                                                    <div className="card-block row">
                                                        <Col sm="12" lg="12" xl="12">
                                                            <div className="table-responsive">
                                                                {comptedata && comptedata.loading ? (
                                                                    <div className="text-center">
                                                                        <img src={Spinner} style={{width: "10%"}}
                                                                             alt=""/>
                                                                    </div>
                                                                ) : (
                                                                    <Table striped>
                                                                        <thead>
                                                                        <tr>
                                                                            <th>{"Photo"}</th>
                                                                            <th
                                                                                id="username"
                                                                                onClick={(e) =>
                                                                                    ascId ? onAscSort(e) : onDescSort(e)
                                                                                }
                                                                            >
                                                                                {"Nom d'utilisateur"}
                                                                            </th>
                                                                            <th
                                                                                id="email"
                                                                                onClick={(e) =>
                                                                                    ascId ? onAscSort(e) : onDescSort(e)
                                                                                }
                                                                            >
                                                                                {"Email"}
                                                                            </th>
                                                                            <th
                                                                                id="first_name"
                                                                                onClick={(e) =>
                                                                                    ascId ? onAscSort(e) : onDescSort(e)
                                                                                }
                                                                            >
                                                                                {"Nom"}
                                                                            </th>
                                                                            <th
                                                                                id="last_name"
                                                                                onClick={(e) =>
                                                                                    ascId ? onAscSort(e) : onDescSort(e)
                                                                                }
                                                                            >
                                                                                {"Prénom"}
                                                                            </th>
                                                                            <th
                                                                                id="code_a_barre"
                                                                                onClick={(e) =>
                                                                                    ascId ? onAscSort(e) : onDescSort(e)
                                                                                }
                                                                            >
                                                                                {"Groupe"}
                                                                            </th>
                                                                            {user_groups_name && user_groups_name.indexOf("admin") !== -1 ?
                                                                                <th></th> : ""}
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {comptedata &&
                                                                            comptedata.comptes.map((b) => (
                                                                                <tr key={b.id}>
                                                                                    <td>
                                                                                        <Link
                                                                                            to={`/compte/edit/${b.id}`}>
                                                                                            <div>
                                                                                                <img
                                                                                                    className="image"
                                                                                                    src={`${b.fournisseur_image_path}`}
                                                                                                    alt=""
                                                                                                />{" "}
                                                                                            </div>
                                                                                        </Link>
                                                                                    </td>
                                                                                    <td>{b.username}</td>
                                                                                    <td>{b.email}</td>
                                                                                    <td>{b.last_name}</td>
                                                                                    <td>{b.first_name}</td>
                                                                                    <td>{b.groups_name} </td>
                                                                                    {user_groups_name && user_groups_name.indexOf("admin") !== -1 ?
                                                                                        <td>
                                                                                            {!b.groups_name.includes("admin") &&
                                                                                                <button
                                                                                                    className="btn  btn-danger m-1"
                                                                                                    key={b.id}
                                                                                                    disabled={disable}
                                                                                                    onClick={() => {
                                                                                                        setModal(true)
                                                                                                        setID(b.id)
                                                                                                        setDisable(false)
                                                                                                    }}>Imiter</button>}
                                                                                        </td> : ""}
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </Table>
                                                                )}
                                                                <Modal isOpen={modal} centered
                                                                       className="relative min-h-screen flex flex-col sm:justify-center items-center"
                                                                       toggle={() => setModal(!modal)}>
                                                                    <ModalHeader style={{
                                                                        backgroundImage:
                                                                            "linear-gradient(to right,#FFD166, #133A4A  )",
                                                                    }}
                                                                                 className="p-3 text-white">Imiter</ModalHeader>
                                                                    <ModalBody>
                                                                        Êtes-vous certain d'imiter
                                                                        ce fournisseur?
                                                                    </ModalBody>
                                                                    <ModalFooter>
                                                                        <button
                                                                            onClick={() => {
                                                                                setModal(false)
                                                                            }}
                                                                            className="btn  btn-danger m-1"
                                                                        >
                                                                            Annuler
                                                                        </button>
                                                                        <Button
                                                                            key={id}
                                                                            onClick={onSubmit}
                                                                            disabled={showLoader}
                                                                            loading={showLoader}
                                                                            text={"Imiter"}
                                                                        />
                                                                    </ModalFooter>
                                                                </Modal>
                                                            </div>
                                                        </Col>
                                                    </div>
                                                </Card>
                                            </Col>{" "}
                                            <Row>
                                                <Col sm="10"></Col>
                                                {comptedata && comptedata.loading ? "" :
                                                    <Col>
                                                        <PaginationWrapper
                                                            name="Comptes"
                                                            length={comptedata.comptes.length}
                                                            count={count}
                                                            currentPage={pagenumber}
                                                            onNavigate={Navigate}
                                                        />
                                                    </Col>}
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                ) : (
                    <Error404 page="Compte"/>
                )
            )}
        </Fragment>
    );
};

Compte.propTypes = {
    getComptes: PropTypes.func.isRequired,
    comptedata: PropTypes.object.isRequired,
};

const mapstatetoprops = (state) => ({
    comptedata: state.reducercp,
});

export default connect(mapstatetoprops, {getComptes})(withRouter(Compte));
