import React, {
    Fragment,
    useState,
    useEffect,
} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Barcode from "react-barcode";
import {withRouter,  useParams} from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Card,
    CardFooter,
    CardBody,
} from "reactstrap";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {getColisH} from "./action";
import {getFournisseurcolis} from "../Fournisseurs/action";
import Spinner from "./spinner.gif";

const HistoryDetail = ({
                           colisData,
                       }) => {
    const [data, setData] = useState([]);

    const dispatch = useDispatch();
    const {id} = useParams()

    useEffect(() => {
        dispatch(getColisH(id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (colisData.colish) {
            setData(colisData.colish);
        }
    }, [colisData.colish, colisData.loading, data]);
    return (
        <Fragment>
            <Breadcrumb parent="Colis" title="Détail Historique"/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col></Col>
                                    <Link to={`/colis/decharge?related_decharge=${id}`} className={"btn btn-info"} target={"_blank"}>
                                        Imprimer
                                    </Link>
                                </Row>
                                <Row>
                                    <div className="col-md-5 offset-md-1">
                                        <img
                                            className="imagedecharge"
                                            src={require("../decharge/decharge.png")}
                                            alt=""
                                        />
                                        <h6>MF: 1736403 M/A/M/000</h6>
                                        <h6>TEL: 29812970</h6>
                                        <h6>Adresse: Zaraani kalaa kbira est, Sousse, Tunisie </h6>
                                    </div>
                                    {colisData && colisData.colish[0] ? (
                                        <div className="col-md-5  m-auto text-center">
                                            <h6>
                                                Fournisseur: {colisData.colish[0].fournisseur_name}{" "}
                                            </h6>
                                            <h6>Tél: {colisData.colish[0].fournisseur_tel} </h6>
                                        </div>
                                    ) : (
                                        ""
                                    )}

                                    <div className="col-lg-12 table-responsive">
                                        <div
                                            id="example_wrapper"
                                            className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
                                        >
                                            {colisData && colisData.loading ? (
                                                <div className="text-center">
                                                    <img src={Spinner} style={{width: "10%"}} alt=""/>
                                                </div>
                                            ) : (
                                                <table
                                                    className="table align-items-center table-bordered dataTable no-footer"
                                                    id="example"
                                                    role="grid"
                                                >
                                                    <thead className="thead-light">
                                                    <tr role="row">
                                                        <th
                                                            className="sorting_disabled"
                                                            rowSpan="1"
                                                            colSpan="1"
                                                        >
                                                            Code à barre
                                                        </th>
                                                        <th
                                                            className="sorting_disabled"
                                                            rowSpan="1"
                                                            colSpan="1"
                                                        >
                                                            Client
                                                        </th>
                                                        <th
                                                            className="sorting_disabled"
                                                            rowSpan="1"
                                                            colSpan="1"
                                                        >
                                                            Ville
                                                        </th>
                                                        <th
                                                            className="sorting_disabled"
                                                            rowSpan="1"
                                                            colSpan="1"
                                                        >
                                                            Service
                                                        </th>
                                                        <th
                                                            className="sorting_disabled"
                                                            rowSpan="1"
                                                            colSpan="1"
                                                        >
                                                            Statut
                                                        </th>
                                                        <th
                                                            className="text-center sorting_disabled"
                                                            rowSpan="1"
                                                            colSpan="1"
                                                        >
                                                            COD
                                                        </th>
                                                        <th
                                                            className="text-center sorting_disabled"
                                                            rowSpan="1"
                                                            colSpan="1"
                                                        >
                                                            Pointage
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {colisData &&
                                                        colisData.colish.map((c) => (
                                                            <tr key={c.id}>
                                                                <td>
                                                                    <Barcode
                                                                        width="1"
                                                                        height="30"
                                                                        value={c.code_a_barre}
                                                                    />
                                                                </td>
                                                                <td>{c.client_name}</td>
                                                                <td>{c.ville_name}</td>
                                                                <td>{c.service}</td>
                                                                <td>{c.status}</td>
                                                                <td>{c.prix}</td>
                                                                <td></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>)}
                                        </div>
                                    </div>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <Col sm="1"></Col>
                                    <Col sm="2">
                                        <Row>Date collecte</Row>
                                        <Row>.......................</Row>
                                    </Col>
                                    <Col sm="1"></Col>
                                    <Col sm="2">
                                        <Row>Nom chauffeur</Row>
                                        <Row>...........................</Row>
                                    </Col>
                                    <Col sm="1"></Col>
                                    <Col sm="2">
                                        <Row>Signature client</Row>
                                        <Row>.............................</Row>
                                    </Col>
                                    <Col sm="1"></Col>
                                    <Col sm="2">
                                        <Row>Signature chauffeur</Row>
                                        <Row>...................................</Row>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

HistoryDetail.propTypes = {
    getColisH: PropTypes.func.isRequired,
    getFournisseurcolis: PropTypes.func.isRequired,
    colisData: PropTypes.object.isRequired,
    fournisseurData: PropTypes.object.isRequired,
};
const mapStateToPorps = (state) => ({
    colisData: state.reducerc,
    financeData: state.reducerfin,
    fournisseurData: state.reducerf,
});

export default connect(mapStateToPorps, {
    getFournisseurcolis,
    getColisH,
})(withRouter(HistoryDetail));
