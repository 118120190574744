import React, {
    Fragment, useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import Alert from "../../redux/Alert";

import PropTypes from "prop-types";

import {connect, useDispatch} from "react-redux";
import {Link, useHistory, useLocation, withRouter} from "react-router-dom";
import ReactHTMLTableToExcel from "react-html-table-to-excel"

import {
    Card,
    CardBody,
    Col,
    Label,
    Container,
    Form,
    InputGroupAddon,
    Row,
    Table
} from "reactstrap";
import {
    deleteColis,
    getColis,
    getColisPY,
    getColisMO,
    getColisR,
    getColisS,
    getColisE,
    getColisP,
    updatecolisPrint,
    getColisLivreur
} from "./action";
import PaginationWrapper from "../pagination/pagination";
import Spinner from "./spinner.gif";
import {day2digits} from "./gestiondescolis";
import AppContext from "../../context/Context";

export const reformatStatus = (status) => {
    if (status === "nouveau") status = "Nouveau";
    else if (status === "livrer") status = "Livré";
    else if (status === "supprimer") status = "Supprimé";
    else if (status === "en_cours_de_livraison") status = "En cours de livraison";
    else if (status === "retour_aux_sources") status = "Retour aux sources";
    else if (status === "payer") status = "Payé";
    else if (status === "retour") status = "Retour";
    else if (status === "anomalie") status = "Anomalie";
    else if (status === "non_ramasser") status = "Non ramassé";
    else if (status === "valider") status = "Validé";

    return status;
};

const Coli = ({colisData}) => {
    const [data, setData] = useState([]);
    const [ascId, setAscSortId] = useState(true);
    const [asctr, setAscSorttr] = useState(true);
    const [ascst, setAscSortst] = useState(true);
    const [asctel, setAscSorttel] = useState(true);
    const [ascser, setAscSortser] = useState(true);
    const [ascpr, setAscSortpr] = useState(true);
    const [check, setCheck] = useState(false);
    const [ascFullname, setAscSortFullname] = useState(true);
    const [id] = useState([]);
    const [alldata, searchData] = useState([]);
    const [timer, setTimer] = useState(null)
    const dispatch = useDispatch();
    const [gteDate, setGteDate] = useState(null)
    const [lteDate, setLteDate] = useState(null)
    const [date, setDate] = useState(null)

    const {
        config: {group}
    } = useContext(AppContext);

    const history = useHistory()

    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };


    let query = useQuery();
    let params = new URLSearchParams(document.location.search);
    let status = params.get("status");
    let cancelTokenSource = null;
    useEffect(() => {
        dispatch(getColis(query, cancelTokenSource));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    useEffect(() => {
        return () => {
            if (cancelTokenSource) {
                cancelTokenSource.cancel("Component unmounted");
            }
        };
    }, [cancelTokenSource]);

    useEffect(() => {
        if (colisData.colis) {
            searchData(colisData.colis);
        }
    }, [colisData.colis, colisData.loading, alldata]);
    let pagenumber = query.get("page") ? query.get("page") : 1;
    const Navigate = (page) => {
        query.set("page", `${Number(page)}`)
        window.location.replace(`/colis?${query}`);
    };


    const inputChanged = (e) => {
        clearTimeout(timer)
        query.set("search", e)
        const newTimer = setTimeout(() => {
            dispatch(getColis(query));
        }, 500)

        setTimer(newTimer)
    }

    const [state, setState] = useState({
        MasterChecked: false,
        SelectedList: [],
    });

    ReactHTMLTableToExcel.format = (s, c) => {
        if (c && c["table"]) {
            const html = c.table;
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, "text/html");
            const rows = doc.querySelectorAll("thead tr th");
            for (const row of rows) {
                row.setAttribute(
                    "style",
                    "background: #B8CCE4"
                );
            }


            c.table = doc.querySelector("table").outerHTML;
        }

        return s.replace(/{(\w+)}/g, (m, p) => c[p]);
    };


    let count = colisData.count;

    const getDate = () => {
        let params = new URLSearchParams(document.location.search);
        let date = params.get("created_at");
        if (date === "today") {
            let today = new Date();
            let m = today.getMonth() + 1;
            if (m < 10) {
                m = "0" + m;
            }
            return today.getFullYear() + "-" + m + "-" + day2digits(today.getDate());
        } else {
            return date;
        }
    };


    const getValSearch = () => {
        let params = new URLSearchParams(document.location.search);
        return params.get("q");
    };
    const getStatus = () => {
        let params = new URLSearchParams(document.location.search);
        let status = params.get("status");
        let service = params.get("service");
        if (status === "supprimer") {
            return "Colis supprimés";
        } else if (status === "retour") {
            return "Colis retour";
        } else if (status === "modifier") {
            return "Colis modifiés";
        } else if (service === "echange") {
            return "Colis echange";
        } else if (status === "payer") {
            return "Colis payés";
        } else {
            return `Colis du jour : ${getDate()}`;
        }
    };


    const onAscSort = () => {
        query.set("ordering", "code_a_barre")
        setAscSortId(false);
        dispatch(getColis(query));
        setData(data);
    };

    const onDescSort = () => {
        query.set("ordering", "-code_a_barre")
        setAscSortId(true);
        dispatch(getColis(query));
        setData(data);
    };

    const onAscSorttr = () => {
        query.set("ordering", "shipment__tracking_code")
        setAscSorttr(false);
        dispatch(getColis(query));
        setData(data);
    };

    const onDescSorttr = () => {
        query.set("ordering", "-shipment__tracking_code")
        setAscSorttr(true);
        dispatch(getColis(query));
        setData(data);
    };

    const onAscSortst = () => {
        query.set("ordering", "status")
        setAscSortst(false);
        dispatch(getColis(query));
        setData(data);
    };

    const onDescSortst = () => {
        query.set("ordering", "-status")
        setAscSortst(true);
        dispatch(getColis(query));
        setData(data);
    };

    const onAscSorttel = () => {
        query.set("ordering", "client_telephone_1")
        setAscSorttel(false);
        dispatch(getColis(query));
        setData(data);
    };

    const onDescSorttel = () => {
        query.set("ordering", "-client_telephone_1")
        setAscSorttel(true);
        dispatch(getColis(query));
        setData(data);
    };

    const onAscSortpr = () => {
        query.set("ordering", "prix")
        setAscSortpr(false);
        dispatch(getColis(query));
        setData(data);
    };

    const onDescSortpr = () => {
        query.set("ordering", "-prix")
        setAscSortpr(true);
        dispatch(getColis(query));
        setData(data);
    };

    const onAscSortser = () => {
        query.set("ordering", "service")
        setAscSortser(false);
        dispatch(getColis(query));
        setData(data);
    };

    const onDescSortser = () => {
        query.set("ordering", "-service")
        setAscSortser(true);
        dispatch(getColis(query));
        setData(data);
    };

    const onAscSortexp = () => {
        query.set("ordering", "fournisseur__name")
        setAscSortFullname(false);
        dispatch(getColis(query));
        setData(data);
    };

    const onDescSortexp = () => {
        query.set("ordering", "-fournisseur__name")
        setAscSortFullname(true);
        dispatch(getColis(query));
        setData(data);
    };
    const onChangeCheck = (e) => {
        let tempList = colisData.colis;
        // eslint-disable-next-line array-callback-return
        tempList.map(l => {
            l.selected = e.target.checked;
            if (e.target.checked) id.push(l.id);
            else id.length = 0;
        });
        setState({
            MasterChecked: e.target.checked,
            SelectedList: colisData.colis.filter((e) => e.selected),
        });
    };

    const onItemCheck = (e, item) => {
        let tempList = colisData.colis;
        // eslint-disable-next-line array-callback-return
        tempList.map(u => {
            if (u.id === item) {
                u.selected = e.target.checked;
                if (e.target.checked) id.push(u.id);
                else id.splice(id.indexOf(u.id), 1);
            }
        });

        //To Control Master Checkbox State
        const totalItems = colisData.colis.length;
        const totalCheckedItems = id.length;

        // Update State
        setState({
            MasterChecked: totalItems !== totalCheckedItems,
            SelectedList: colisData.colis.filter((e) => e.selected),
        })
    };


    const getPrint = () => {
        let params = new URLSearchParams(document.location.search);
        let status = params.get("status");
        let service = params.get("service");
        if (status === "supprimer") {
            return true
        } else if (status === "retour") {
            return true
        } else if (status === "modifier") {
            return true
        } else if (service === "echange") {
            return false
        } else if (status === "payer") {
            return true
        } else {
            return false
        }
    };

    const colisImprimer = () => {
        if (check) {
            return "False"
        } else {
            return "True"
        }
    }

    useEffect(() => {
        if (check) {
            query.set("colis_imprimer", colisImprimer())
        } else {
            query.delete('colis_imprimer')
        }
        dispatch(getColis(query))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [check])

    // useEffect(() =>  {
    //     if (colisData.loading)
    //         setDisable(true)
    //     else
    //         setDisable(false)
    // }, [colisData.loading])

    const onChangeLivreur = (e) => {
        query.set('livreur', e)
        dispatch(getColis(query))
    }
    let user_groups_name = group;

    const getgteDate = () => {
        let params = new URLSearchParams(document.location.search);
        return params.get("created_at__gte");
    };

    const getlteDate = () => {
        let params = new URLSearchParams(document.location.search);
        return params.get("created_at__lte");
    };

    return (
        <Fragment>
            <Breadcrumb parent="Colis" title={`${getStatus()}`}/>
            <Container fluid={true}>
                <Alert/>
                <Row>
                    <Col sm="12">
                        <Card>
                            {/* {datacolis.colis.length > 0 ? () : ()} */}
                            <CardBody>
                                <Col sm="12">
                                    <Row>
                                        <Col sm="4">
                                            <Row hidden={getPrint()}>
                                                <Col sm={"1"}></Col>
                                                <Col>
                                                    <input
                                                        disabled={false}
                                                        type={"checkbox"}
                                                        checked={check}
                                                        onChange={(e) => setCheck(e.target.checked)}/>
                                                    <Label className={"m-2"}>
                                                        Colis non imprimés
                                                    </Label>
                                                </Col>
                                            </Row>
                                            {user_groups_name && user_groups_name.indexOf('admin') !== -1 ?
                                                <Row>
                                                    <Col sm={"1"}></Col>
                                                    <Col sm={"5"}>
                                                        <select className="form-control digits" disabled={false}
                                                                onChange={(e) => onChangeLivreur(e.target.value)}>
                                                            <option value={""}>All</option>
                                                            <option value={"beez"}>Beezit</option>
                                                            <option value={"fp"}>Fparcel</option>
                                                            <option value={"AM"}>Aramex</option>
                                                        </select>
                                                    </Col>
                                                    <Col></Col>
                                                </Row> : ""}


                                        </Col>
                                        {status === "supprimer" && (
                                            <>
                                                <div className=" row m-4">
                                                    <h4>Du</h4>&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <input
                                                        className="btn btn-light"
                                                        type="date"
                                                        name="created_at__gte"
                                                        defaultValue={getgteDate()}
                                                        style={{padding: "5px 5px"}}
                                                        onChange={(e) => {
                                                            setGteDate(e.target.value)
                                                        }}
                                                    />
                                                </div>
                                                <div className="row m-4">
                                                    <h4>À </h4>&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <input
                                                        className="btn btn-light"
                                                        type="date"
                                                        name="created_at__lte"
                                                        defaultValue={getlteDate()}
                                                        style={{padding: "5px 5px"}}
                                                        onChange={(e) => {
                                                            setLteDate(e.target.value)
                                                        }}
                                                    />
                                                    <button
                                                        className="btn text-white  ml-2"
                                                        style={{backgroundColor: "#27B3B0"}}
                                                        type="submit"
                                                        disabled={false}
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            if (gteDate)
                                                                query.set('created_at__gte', gteDate)
                                                            if (lteDate)
                                                                query.set('created_at__lte', lteDate)
                                                            history.push({search: query.toString()})
                                                            dispatch(getColis(query))
                                                        }}
                                                    >
                                                        Valider
                                                    </button>
                                                </div>
                                            </>)}
                                        {status !== "supprimer" &&
                                            <Form className="theme-form">
                                                <InputGroupAddon addonType="append">
                                                    <div>
                                                        <>
                                                            <input
                                                                className="btn btn-light"
                                                                type="date"
                                                                name="created_at"
                                                                defaultValue={getDate()}
                                                                style={{padding: "10px 20px"}}
                                                                onChange={(e) => {
                                                                    setDate(e.target.value)
                                                                }}
                                                            />
                                                            <button
                                                                disabled={false}
                                                                type="button"
                                                                className="btn text-white  ml-2"
                                                                style={{
                                                                    backgroundColor: "#27B3B0",
                                                                    padding: "10px 20px"
                                                                }}
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    if (date)
                                                                        query.set("created_at", date)
                                                                    history.push({search: query.toString()})
                                                                    dispatch(getColis(query))
                                                                }}
                                                            >
                                                                Valider
                                                            </button>
                                                        </>
                                                        {(user_groups_name && user_groups_name.indexOf("livreur") !== -1) || (status && status === "supprimer") ? "" :
                                                            <Link
                                                                className="btn  m-2 "
                                                                style={{
                                                                    backgroundColor: "#FDF203",
                                                                    padding: "10px 20px"
                                                                }}
                                                                to="/colis/new"
                                                            >
                                                                {"Ajouter"}
                                                            </Link>}
                                                        {(user_groups_name && user_groups_name.indexOf("livreur") !== -1) || (status && status === "supprimer") ? "" :
                                                            <Link
                                                                style={{padding: "10px 20px"}}
                                                                to={"/colis/history"} className={"btn btn-primary"}
                                                            >
                                                                Historique
                                                            </Link>}

                                                    </div>
                                                </InputGroupAddon>
                                            </Form>
                                        }


                                    </Row>
                                    <br/>
                                    <br/>
                                    <Row>
                                        <Col>
                                            <button
                                                disabled={id.length === 0}
                                                className="btn text-white m-2"
                                                style={{backgroundColor: "#C725C7"}}
                                                onClick={() => {
                                                    let q = new URLSearchParams();
                                                    q.set("colis_id", id.toString())
                                                    setTimeout(() => {
                                                        dispatch(getColis(query))
                                                    }, 3000)
                                                    window.open(`/colis/print/?${q}`);
                                                }}
                                                title="Imprimer"
                                            >
                                                IMPRIMER
                                            </button>
                                            <button className="btn m-2 text-white "
                                                    onClick={() => {
                                                        window.open(`/colis/printp?${query.toString()}`);
                                                        setTimeout(() => {
                                                            dispatch(getColis(query))
                                                        }, 3000)
                                                    }}
                                                    style={{backgroundColor: "#C725C7"}} title="Imprimer tous">
                                                IMPRIMER TOUS
                                            </button>
                                            {user_groups_name && user_groups_name.indexOf("livreur") !== -1 ? "" :
                                                <Link className="btn  m-2 btn-excel text-white"
                                                      to={`/colis/csv?${query}&page=colis_du_jours`}
                                                      target={"_blank"}>
                                                    EXCEL
                                                </Link>}
                                            {user_groups_name && user_groups_name.indexOf("livreur") !== -1 ? "" :
                                                <Link className="btn  m-2 btn-excel text-white"
                                                      to={`/colis/csv?${query}`}
                                                      target={"_blank"}>
                                                    CSV
                                                </Link>}
                                        </Col>
                                        <div className="search-5">
                                            <h6>Rechercher:</h6>
                                        </div>

                                        <input
                                            className=" border search "
                                            style={{borderRadius: "7px"}}
                                            type="search"
                                            placeholder="Rechercher..."
                                            name="q"
                                            defaultValue={getValSearch()}
                                            onChange={(e) => inputChanged(e.target.value)}
                                        />
                                    </Row>
                                    <Card>
                                        <div className="card-block row">
                                            <Col sm="12">
                                                <div className="table-responsive">
                                                    {colisData && colisData.loading ? (
                                                        <div className="text-center">
                                                            <img src={Spinner} style={{width: "10%"}} alt=""/>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <Table striped>
                                                                <thead>
                                                                <tr>
                                                                    <th scope="col">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={state.MasterChecked}
                                                                            onChange={(e) => {
                                                                                onChangeCheck(e);
                                                                            }}
                                                                        />
                                                                    </th>
                                                                    <th
                                                                        style={{cursor: "pointer"}}
                                                                        id="code_a_barre"
                                                                        title={`Trier ${
                                                                            ascId ? "Ascendant" : "Descendant"
                                                                        }`}
                                                                        onClick={(e) =>
                                                                            ascId ? onAscSort(e) : onDescSort(e)
                                                                        }
                                                                        className={`bi ${
                                                                            ascId ? "bi-sort-down" : "bi-sort-up"
                                                                        }`}
                                                                    >
                                                                        {"Code à barre"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {ascId ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-sort-up"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className={`bi bi-sort-down`}
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        title={`Trier ${
                                                                            asctr ? "Ascendant" : "Descendant"
                                                                        }`}
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="tracking_code"
                                                                        onClick={(e) =>
                                                                            asctr ? onAscSorttr(e) : onDescSorttr(e)
                                                                        }
                                                                    >
                                                                        {"Code de suivi"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {asctr ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-sort-up"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className={`bi bi-sort-down`}
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        style={{cursor: "Partenaire"}}
                                                                        scope="col"
                                                                        id="partenaire"
                                                                    >
                                                                        {"Partenaire"}
                                                                    </th>
                                                                    <th
                                                                        title={`Trier ${
                                                                            ascst ? "Ascendant" : "Descendant"
                                                                        }`}
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="status"
                                                                        onClick={(e) =>
                                                                            ascst ? onAscSortst(e) : onDescSortst(e)
                                                                        }
                                                                    >
                                                                        {"Status"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {ascst ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-sort-up"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className={`bi bi-sort-down`}
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        title={`Trier ${
                                                                            ascFullname ? "Ascendant" : "Descendant"
                                                                        }`}
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="fournisseur_name"
                                                                        onClick={(e) =>
                                                                            ascFullname
                                                                                ? onAscSortexp(e)
                                                                                : onDescSortexp(e)
                                                                        }
                                                                    >
                                                                        {"Expéditeur"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {ascFullname ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-sort-up"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className={`bi bi-sort-down`}
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="client_address"
                                                                    >
                                                                        {"Destinataire"}
                                                                    </th>
                                                                    <th
                                                                        title={`Trier ${
                                                                            asctel ? "Ascendant" : "Descendant"
                                                                        }`}
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="client_telephone_1"
                                                                        onClick={(e) =>
                                                                            asctel
                                                                                ? onAscSorttel(e)
                                                                                : onDescSorttel(e)
                                                                        }
                                                                    >
                                                                        {"Tél 1 / Tél 2"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {asctel ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-sort-up"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className={`bi bi-sort-down`}
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="client_ville"
                                                                    >
                                                                        {"Ville"}
                                                                    </th>
                                                                    <th
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="client_delegation"
                                                                    >
                                                                        {"Délégation"}
                                                                    </th>
                                                                    <th
                                                                        title={`Trier ${
                                                                            ascser ? "Ascendant" : "Descendant"
                                                                        }`}
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="service"
                                                                        onClick={(e) =>
                                                                            ascser
                                                                                ? onAscSortser(e)
                                                                                : onDescSortser(e)
                                                                        }
                                                                    >
                                                                        {"Service"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {ascser ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-sort-up"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className={`bi bi-sort-down`}
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        title={`Trier ${
                                                                            ascpr ? "Ascendant" : "Descendant"
                                                                        }`}
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="prix"
                                                                        onClick={(e) =>
                                                                            ascpr ? onAscSortpr(e) : onDescSortpr(e)
                                                                        }
                                                                    >
                                                                        {"COD"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {ascpr ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-sort-up"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className={`bi bi-sort-down`}
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        )}
                                                                    </th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {colisData &&
                                                                    colisData.colis.map((b) => (
                                                                        <tr
                                                                            key={b.id}
                                                                            className={b.selected ? "selected" : ""}
                                                                        >
                                                                            <td>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    value={b.id}
                                                                                    checked={b.selected}
                                                                                    id={`rowcheck${b.id}`}
                                                                                    onChange={(e) => onItemCheck(e, b.id)}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Link
                                                                                    to={`/colis/detail/${b.id}`}>
                                                                                    {b.code_a_barre}
                                                                                </Link>
                                                                            </td>
                                                                            <td>{b.tracking_code}</td>
                                                                            <td>{b.livreur_name}</td>
                                                                            <td>{reformatStatus(b.status)}</td>
                                                                            <td>{b.fournisseur_name}</td>
                                                                            <td>
                                                                                {b.client_name}
                                                                            </td>
                                                                            <td>
                                                                                {b.client_telephone_1} /
                                                                                {b.client_telephone_2}
                                                                            </td>
                                                                            <td>{b.ville_name}</td>
                                                                            <td>{b.delegation_name}</td>
                                                                            <td>{b.service}</td>
                                                                            <td>{b.prix}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                            <Table striped hidden={true} id="example">
                                                                <thead>
                                                                <tr>
                                                                    <th scope="col">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={state.MasterChecked}
                                                                            onChange={(e) => {
                                                                                onChangeCheck(e);
                                                                            }}
                                                                        />
                                                                    </th>
                                                                    <th
                                                                        style={{cursor: "pointer"}}
                                                                        id="code_a_barre"
                                                                        title={`Trier ${
                                                                            ascId ? "Ascendant" : "Descendant"
                                                                        }`}
                                                                        onClick={(e) =>
                                                                            ascId ? onAscSort(e) : onDescSort(e)
                                                                        }
                                                                        className={`bi ${
                                                                            ascId ? "bi-sort-down" : "bi-sort-up"
                                                                        }`}
                                                                    >
                                                                        {"Code à barre"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {ascId ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-sort-up"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className={`bi bi-sort-down`}
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        title={`Trier ${
                                                                            asctr ? "Ascendant" : "Descendant"
                                                                        }`}
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="tracking_code"
                                                                        onClick={(e) =>
                                                                            asctr ? onAscSorttr(e) : onDescSorttr(e)
                                                                        }
                                                                    >
                                                                        {"Code de suivi"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {asctr ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-sort-up"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className={`bi bi-sort-down`}
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        title={`Trier ${
                                                                            ascst ? "Ascendant" : "Descendant"
                                                                        }`}
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="status"
                                                                        onClick={(e) =>
                                                                            ascst ? onAscSortst(e) : onDescSortst(e)
                                                                        }
                                                                    >
                                                                        {"Status"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {ascst ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-sort-up"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className={`bi bi-sort-down`}
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        title={`Trier ${
                                                                            ascFullname ? "Ascendant" : "Descendant"
                                                                        }`}
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="fournisseur_name"
                                                                        onClick={(e) =>
                                                                            ascFullname
                                                                                ? onAscSortexp(e)
                                                                                : onDescSortexp(e)
                                                                        }
                                                                    >
                                                                        {"Expéditeur"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {ascFullname ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-sort-up"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className={`bi bi-sort-down`}
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="client_address"
                                                                    >
                                                                        {"Destinataire"}
                                                                    </th>
                                                                    <th
                                                                        title={`Trier ${
                                                                            asctel ? "Ascendant" : "Descendant"
                                                                        }`}
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="client_telephone_1"
                                                                        onClick={(e) =>
                                                                            asctel
                                                                                ? onAscSorttel(e)
                                                                                : onDescSorttel(e)
                                                                        }
                                                                    >
                                                                        {"Tél 1 / Tél 2"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {asctel ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-sort-up"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className={`bi bi-sort-down`}
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="client_ville"
                                                                    >
                                                                        {"Ville"}
                                                                    </th>
                                                                    <th
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="client_delegation"
                                                                    >
                                                                        {"Délégation"}
                                                                    </th>
                                                                    <th
                                                                        title={`Trier ${
                                                                            ascser ? "Ascendant" : "Descendant"
                                                                        }`}
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="service"
                                                                        onClick={(e) =>
                                                                            ascser
                                                                                ? onAscSortser(e)
                                                                                : onDescSortser(e)
                                                                        }
                                                                    >
                                                                        {"Service"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {ascser ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-sort-up"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className={`bi bi-sort-down`}
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        title={`Trier ${
                                                                            ascpr ? "Ascendant" : "Descendant"
                                                                        }`}
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="prix"
                                                                        onClick={(e) =>
                                                                            ascpr ? onAscSortpr(e) : onDescSortpr(e)
                                                                        }
                                                                    >
                                                                        {"COD"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {ascpr ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-sort-up"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className={`bi bi-sort-down`}
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        )}
                                                                    </th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {colisData &&
                                                                    colisData.colis.map((b) => (
                                                                        <tr
                                                                            key={b.id}
                                                                            className={b.selected ? "selected" : ""}
                                                                        >
                                                                            <td>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    value={b.id}
                                                                                    checked={b.selected}
                                                                                    id={`rowcheck${b.id}`}
                                                                                    onChange={(e) => onItemCheck(e, b.id)}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Link
                                                                                    to={`/colis/detail/${b.id}`}>
                                                                                    {b.code_a_barre}
                                                                                </Link>
                                                                            </td>
                                                                            <td>{b.tracking_code}</td>
                                                                            <td>{reformatStatus(b.status)}</td>
                                                                            <td>{b.fournisseur_name}</td>
                                                                            <td>
                                                                                {b.client_name}
                                                                            </td>
                                                                            <td>
                                                                                {b.client_telephone_1} /
                                                                                {b.client_telephone_2}
                                                                            </td>
                                                                            <td>{b.ville_name}</td>
                                                                            <td>{b.delegation_name}</td>
                                                                            <td>{b.service}</td>
                                                                            <td>{b.prix}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                        </>
                                                    )}
                                                </div>
                                            </Col>
                                        </div>
                                    </Card>
                                </Col>{" "}
                                <Row>
                                    <Col sm="10"></Col>
                                    {colisData && colisData.loading ? "" :
                                        <Col>
                                            <PaginationWrapper
                                                name="Colis"
                                                length={colisData.colis.length}
                                                count={count}
                                                currentPage={pagenumber}
                                                onNavigate={Navigate}
                                            />
                                        </Col>
                                    }
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

Coli.propTypes = {
    getColisMO: PropTypes.func.isRequired,
    getColisS: PropTypes.func.isRequired,
    getColisLivreur: PropTypes.func.isRequired,
    getColisR: PropTypes.func.isRequired,
    getColisPY: PropTypes.func.isRequired,
    getColisE: PropTypes.func.isRequired,
    deleteColis: PropTypes.func.isRequired,
    getColisP: PropTypes.func.isRequired,
    updatecolisPrint: PropTypes.func.isRequired,
    getColis: PropTypes.func.isRequired,
    colisData: PropTypes.object.isRequired,
};

const mapstatetoprops = (state) => ({
    colisData: state.reducerc,
});

export default connect(mapstatetoprops, {
    getColis,
    getColisPY,
    getColisMO,
    getColisLivreur,
    getColisR,
    getColisS,
    getColisE,
    deleteColis,
    getColisP,
    updatecolisPrint,
})(withRouter(Coli));