import {Pagination} from "react-bootstrap";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
// import {useLocation} from "react-router-dom";

const CustomPaginationWrapper = ({count = 1, pageSize = 100, currentPage, onNavigate, name, length = 1}) => {
    const [pagesize, setPageSize] = useState(pageSize)
    // const useQuery = () => {
    //     const {search} = useLocation();

    //     return useMemo(() => new URLSearchParams(search), [search]);
    // };

    // let query = useQuery();
    let params = new URLSearchParams(window.location.search)
    let page_size = params.get('page_size')
    useEffect(()=> {
        if(page_size !== null) {
            setPageSize(page_size)
        }
    },[page_size])

    if (count === 0) return null;

    // const pageSizeOptions = [25, 50, 100, 200];
    const numPagesInt = parseInt(count / pagesize);
    const numPages =
        numPagesInt === count / pagesize ? count / pagesize : numPagesInt + 1;

    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages;
    const hasPrevious = currentPage > 1;
    const hasNext = currentPage < numPages;

    const delta = 3;

    const firstPage = parseInt(Math.max(1, currentPage - delta));
    const lastPage = parseInt(Math.min(numPages, currentPage + delta + 1));

    const pageRange = new Array(lastPage - firstPage + 1).fill(1);
    const handlePagination = (page) => onNavigate(page);
    // const handlePageSizeChange = (e) => {
    //     setPageSize(e.target.value)
    // }

    return (
        <div className="d-flex flex-wrap align-items-center justify-content-between table-responsive">
            <h5 className="text-muted">
                {/*(currentPage - 1) * pageSize + 1}{" "}
        {pageSize > count ? count : Math.min(currentPage * pageSize, count)}{" "*/}
                {length + " de " + count + " " + name}
            </h5>
            <Pagination>
                {/* <Form.Control value={pagesize} as="select" className="ml-3" onChange={(e) => handlePageSizeChange(e)}
                              style={{height: "35px", width: "100px"}}>
                    {pageSizeOptions.map(option => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </Form.Control> */}
                <Pagination.First
                    disabled={isFirst}
                    onClick={() => handlePagination(1)}
                />
                <Pagination.Prev
                    disabled={!hasPrevious}
                    onClick={() => handlePagination(hasPrevious ? parseInt(currentPage) - 1 : 1)}
                />
                {pageRange.slice(0, 5).map((item, index) => {
                    const pageNumber = firstPage + index;
                    const isCurrent = pageNumber === parseInt(currentPage);
                    return (
                        <Pagination.Item
                            lg
                            onClick={
                                isCurrent ? () => {
                                } : () => handlePagination(pageNumber)
                            }
                            key={pageNumber}
                            active={isCurrent}
                        >
                            {pageNumber}
                        </Pagination.Item>
                    );
                })}
                <Pagination.Next
                    disabled={!hasNext}
                    onClick={() => handlePagination(hasNext ? parseInt(currentPage) + 1 : numPages)}
                />
                <Pagination.Last
                    disabled={isLast}
                    onClick={() => handlePagination(numPages)}
                />
            </Pagination>
        </div>
    );
};

CustomPaginationWrapper.propTypes = {
    onNavigate: PropTypes.func,
    response: PropTypes.any,
};

export default CustomPaginationWrapper;