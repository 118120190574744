import React from 'react'
import "../css/cards.css"
import {Col , Row} from "reactstrap"
import mm from '../images/transparency.png'
export default function Cards  () {
  return (
	<Row>
<Col  md={2} ></Col>
		<Col sm={12} md={4}>
		<div style={{marginTop: "60px",fontFamily : "Raleway" , fontWeight: "600" , fontSize : "10px"  }}>
			<h2>TRANSPARENCE</h2>
			<h6>Pour que vous puissiez gérer votre entreprise et livrer les bonnes promesses à vos clients.</h6><br/>
			<h2>SUIVI DES TRANSPORTEURS</h2>
			<h6>Nous suivons le stockage, la préparation des commandes mais aussi les performances de tous nos partenaires transporteurs.</h6><br/>
			<h2>SOLUTION UNIQUE</h2>
			<h6>Du clic à la livraison, aucune autre solution ne propose un tel suivi pour répondre à vos besoins : il était temps d'y remédier !

</h6>

		</div>
		</Col >
		<Col sm={12} md={4} >
		<img src={mm} height={"500px"} alt=""/>
		</Col>
		<Col  md={2} ></Col>
	</Row>
		
  )
}
