import { setAlert, setAlertD } from "../../redux/alert/action";
import { setAuthToken, api } from "../../utils/backend.instance";
import {
  GET_FOURNISSEUR,
  GET_FOURNISSEURS,
  FOURNISSEUR_LOADING,
  FOURNISSEUR_ERROR,
  FOURNISSEURP_ERROR,
  FOURNISSEURP_LOADING,
  GET_FOURNISSEURSP,
  UPDATE_FOURNISSEUR,
} from "../../redux/actionTypes";
export const addNewFournisseur = (fd, history) => async (dispatch) => {
  if (localStorage.access) {
    setAuthToken(localStorage.access);
  }
  try {
    const config = {
      headers: {
        "Content-Type":
          "multipart/form-data; charset=utf-8; boundary=" +
          Math.random().toString().substr(2),
      },
    };
    const res = await api.post("/fournisseur/fournisseur/", fd, config);
    dispatch({
      type: GET_FOURNISSEUR,
      payload: res.data,
    });
    dispatch(setAlert("Fournisseur Successfully Created", "success"));
    history.push("/fournisseur");
  } catch (err) {
    const errors = err.response.data;
    if (errors) {
      dispatch(
        setAlertD(
          (errors.adresse ? "Adresse: " + errors.adresse + " " : "") +
            (errors.adresse_pickup
              ? "Adresse Pickup: " + errors.adresse_pickup + " "
              : "") + (errors.name ? 'Raison sociale: ' + errors.name + " " : "") +
            (errors.adresse ? 'Addresse: ' + errors.adresse + " " : "") +
            (errors.rib ? 'RIB: ' + errors.rib + " " : "") +
            (errors.tentative ? 'Tentative: ' + errors.tentative + " " : "") +
            (errors.mf ? 'MF: ' + errors.mf + " " : "") +
            (errors.email ? "Email: " + errors.email + " " : "") +
            (errors.nb_colis
              ? "Nombre de Colis: " + errors.nb_colis + " "
              : "") +
            (errors.responsable
              ? "Responsable: " + errors.responsable + " "
              : "") +
            (errors.secteur ? "Secteur: " + errors.secteur + " " : "") +
            (errors.tel ? "Téléphone: " + errors.tel + " " : "") +
            (errors.tentative ? "Tentative: " + errors.tentative : ""),
          "danger"
        )
      );
    }
    dispatch({
      type: FOURNISSEUR_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
  }
};

export const addNewFournisseur1 = (fd, history, key) => async (dispatch) => {
  if (localStorage.access) {
    setAuthToken(localStorage.access);
  }
  try {
    const config = {
      headers: {
        "Content-Type":
          "multipart/form-data; charset=utf-8; boundary=" +
          Math.random().toString().substr(2),
      },
    };
    const res = await api.post(
      `/fournisseur/fournisseur/?key=${key}`,
      fd,
      config
    );
    dispatch({
      type: GET_FOURNISSEUR,
      payload: res.data,
    });
    dispatch(setAlert("Fournisseur Successfully Created", "success"));
    history.push("/signin");
  } catch (err) {
    const errors = err.response.data;
    if (errors) {
      dispatch(
        setAlertD(
          (errors.adresse ? "Adresse: " + errors.adresse + " " : "") +
            (errors.adresse_pickup
              ? "Adresse Pickup: " + errors.adresse_pickup + " "
              : "") + (errors.name ? 'Raison sociale: ' + errors.name + " " : "") +
            (errors.adresse ? 'Addresse: ' + errors.adresse + " " : "") +
            (errors.rib ? 'RIB: ' + errors.rib + " " : "") +
            (errors.tentative ? 'Tentative: ' + errors.tentative + " " : "") +
            (errors.mf ? 'MF: ' + errors.mf + " " : "") +
            (errors.email ? "Email: " + errors.email + " " : "") +
            (errors.nb_colis
              ? "Nombre de Colis: " + errors.nb_colis + " "
              : "") +
            (errors.responsable
              ? "Responsable: " + errors.responsable + " "
              : "") +
            (errors.secteur ? "Secteur: " + errors.secteur + " " : "") +
            (errors.tel ? "Téléphone: " + errors.tel + " " : "") +
            (errors.tentative ? "Tentative: " + errors.tentative : ""),
          "danger"
        )
      );
    }
    dispatch({
      type: FOURNISSEUR_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
  }
};
export const updateFournisseur = (formData, id) => async (dispatch) => {
  if (localStorage.access) {
    setAuthToken(localStorage.access);
  }
  try {
    const config = {
      headers: {
        "Content-Type":
          "multipart/form-data; charset=utf-8; boundary=" +
          Math.random().toString().substr(2),
      },
    };
    const res = await api.patch(`/fournisseur/fournisseur/${id}/`, formData, config);
    dispatch({
      type: GET_FOURNISSEUR,
      payload: res.data,
    });
    dispatch(setAlert("Fournisseur Successfully Updated", "success"));
  } catch (err) {
    const errors = err.response.data;
    if (errors) {
      dispatch(
        setAlertD(
          (errors.adresse ? "Adresse: " + errors.adresse + " " : "") +
            (errors.adresse_pickup
              ? "Adresse Pickup: " + errors.adresse_pickup + " "
              : "") +
            (errors.code_postale
              ? " Code Postale: " + errors.code_postale + " "
              : "") +
            (errors.email ? "Email: " + errors.email + " " : "") +
            (errors.nb_colis
              ? "Nombre de Colis: " + errors.nb_colis + " "
              : "") +
            (errors.responsable
              ? "Responsable: " + errors.responsable + " "
              : "") +
            (errors.secteur ? "Secteur: " + errors.secteur + " " : "") +
            (errors.tel ? "Téléphone: " + errors.tel + " " : "") +
            (errors.tentative ? "Tentative: " + errors.tentative : ""),
          "danger"
        )
      );
    }
    dispatch({
      type: FOURNISSEUR_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
  }
};

export const getCurrentFournisseur = (id) => async (dispatch) => {
  dispatch({ type: FOURNISSEUR_LOADING });
  if (localStorage.access) {
    setAuthToken(localStorage.access);
  }
  try {
    const res = await api.get(`/fournisseur/fournisseur/${id}/`);

    dispatch({
      type: GET_FOURNISSEUR,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: FOURNISSEUR_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const getFournisseurcolis = (id) => async (dispatch) => {
  dispatch({ type: FOURNISSEUR_LOADING });
  if (localStorage.access) {
    setAuthToken(localStorage.access);
  }

  try {
    const res = await api.get(`/fournisseur/fournisseur/?colis_id=${id}/`);

    dispatch({
      type: GET_FOURNISSEUR,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: FOURNISSEUR_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const getFournisseurs = (params) => async (dispatch) => {
  dispatch({ type: FOURNISSEUR_LOADING });
  if (localStorage.access) {
    setAuthToken(localStorage.access);
  }
  try {
    const res = await api.get(
      `/fournisseur/fournisseur/?${params.toString()}`
    );

    dispatch({
      type: GET_FOURNISSEURS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: FOURNISSEUR_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
export const getFournisseursP = (params) => async (dispatch) => {
  dispatch({ type: FOURNISSEURP_LOADING });
  if (localStorage.access) {
    setAuthToken(localStorage.access);
  }
  try {
    const res = await api.get(
      `/fournisseur/fournisseur/?${params.toString()}`
    );

    dispatch({
      type: GET_FOURNISSEURSP,
      payload: res.data.results,
    });
  } catch (err) {
    dispatch({
      type: FOURNISSEURP_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const deleteFournisseur = (id, history) => async (dispatch) => {
  try {
    const res = await api.delete(`/fournisseur/fournisseur/${id}`);

    dispatch({
      type: UPDATE_FOURNISSEUR,
      payload: res.data.results,
    });

    dispatch(setAlert("Fournisseur Successfully Deleted", "success"));
    history.push(getFournisseurs());
  } catch (err) {
    dispatch({
      type: FOURNISSEUR_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
