import React, {
    Fragment,
    useState,
    useEffect,
    useMemo, useContext,
} from "react";
import PropTypes from "prop-types";
import Alert from "../../redux/Alert";
import {connect} from "react-redux";
import {Link, useHistory, useLocation, useParams} from "react-router-dom";
import {getFournisseurs} from "../Fournisseurs/action";
import {
    getCategories,
    getSousCategories,
    updateStock,
    getCurrentstock, addNewActivity, patchStock,
} from "./action";

import Require from "./product.png"

import Breadcrumb from "../../layout/breadcrumb";

import {
    Container,
    Row,
    CardHeader,
    Col,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button as B, ModalHeader, ModalBody, ModalFooter, Modal,
} from "reactstrap";
import {useDispatch} from "react-redux";
import Error404 from "../../pages/errors/error404";
import Select, {components} from "react-select";
import Button from "../../utils/Button";
import AppContext from "../../context/Context";
import Spinner from "../colis/spinner.gif";

const Editstock = ({
                       history,
                       stockData,
                       fournisseurData,
                       stock: {stock, loading},
                       updateStock,
                       addNewActivity
                   }) => {

    const dispatch = useDispatch();
    const [fournisseurs, setFournisseurs] = useState([]);
    const [currentFournisseurPage, setCurrentFournisseurPage] = useState(1)
    const [selectedfournisseur, setSelectedFournisseur] = useState([]);
    const [modal2, setModal2] = useState(false);
    const [boite, setBoite] = useState(false)
    const [, setCase] = useState(1)
    const [, setQuantity] = useState(1)
    const [action, setAction] = useState("")
    const [showFormDelete, setShowFormDelete] = useState(true);
    const [formActivity, setFormActivity] = useState({
        notes: "",
        case: 1,
        quantity: 0,
        reason: "",
    })
    const [showLoader, setShowLoader] = useState(false)
    const [categoryValue, setCategoryValue] = useState()
    const [categoryFormData, setCategoryFormData] = useState({name: ""})
    const [categoryMenuIsOpen, setCategoryMenuIsOpen] = useState(false);
    const [subCategoryValue, setSubCategoryValue] = useState()
    const [subCategoryFormData, setSubCategoryFormData] = useState({name: ""})
    const [subCategoryMenuIsOpen, setSubCategoryMenuIsOpen] = useState(false);
    const [categoryForm, setCategoryForm] = useState(false);
    const [subCategoryForm, setSubCategoryForm] = useState(false);

    const {
        config: {group, stock_service}
    } = useContext(AppContext)

    const onChangeActivity = (e) => {
        setFormActivity({...formActivity, [e.target.name]: e.target.value})
    }
    const onAddProductClick = () => {
        setShowFormDelete(false);
        setAction("add")
    };

    const onRemoveProductClick = () => {
        setShowFormDelete(true);
        setAction("delete")
    };
    const [formData, setFormData] = useState({
        reference: "",
        name: "",
        stock: "",
        cod: "",
        weight: "",
        etage: "",
        image: null,
        fournisseur: "",
        category: "",
        sub_category: "",
    });
    const {id} = useParams();
    const onChangeCategorie = (e) => {
        setFormData({...formData, category: e});
        query.set("category_id", e);
        dispatch(getSousCategories(query));
    };

    useEffect(() => {
        setCase(0)
        setQuantity(0)
        setAction("add")
    }, [modal2]);

    const onSubmitStock = async (e) => {
        e.preventDefault();
        const fd = new FormData();
        fd.append("action", action)
        if (boite) {
            fd.append("type", "case")
            fd.append("case", formActivity.case)
        } else fd.append("type", "single")
        fd.append("reason", formActivity.reason)
        fd.append("quantity", formActivity.quantity)
        fd.append("notes", formActivity.notes)
        fd.append("product", id)
        addNewActivity(fd)
        setModal2(!modal2)
    }

    useEffect(() => {
        if (!boite)
            setCase(1)
    }, [boite])

    const onChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };
    const onChangeimg = (e) => {
        setFormData({...formData, image: e.target.files[0]});
    };

    const onSubmit = async (e) => {
        setShowLoader(true)
        e.preventDefault();
        const fd = new FormData();
        fd.append("reference", formData.reference);
        fd.append("name", formData.name);
        fd.append("cod", formData.cod);
        fd.append("weight", formData.weight);
        fd.append("etage", formData.etage);
        if (formData.image) {
            fd.append("image", formData.image);
        }
        fd.append("category_name", categoryFormData?.name ? categoryFormData?.name : "")
        fd.append("sub_category_name", subCategoryFormData?.name ? subCategoryFormData?.name : "");
        fd.append("fournisseur", formData.fournisseur);
        if (categoryValue)
            fd.append("category", categoryValue.value);
        if (subCategoryValue)
            fd.append("sub_category", subCategoryValue.value);
        await updateStock(fd, id, history);
        setShowLoader(false)
        history.push('/stock_depot')
    };
    useEffect(() => {
        dispatch(getCurrentstock(id));
        setSelectedFournisseur({
            value: loading || !stock?.fournisseur ? "" : stock.fournisseur,
            label: loading || !stock?.fournisseur_name ? "" : stock.fournisseur_name,
        })
        setCategoryValue({
            value: loading || !stock?.category ? "" : stock.category,
            label: loading || !stock?._category_name ? "" : stock._category_name,
        })
        setSubCategoryValue({
            value: loading || !stock?.sub_category ? "" : stock.sub_category,
            label: loading || !stock?.sub_cat_name ? "" : stock.sub_cat_name,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setFormData({
            reference: loading || !stock?.reference ? "" : stock.reference,
            name: loading || !stock?.name ? "" : stock.name,
            stock: loading || !stock?.stock ? "" : stock.stock,
            cod: loading || !stock?.cod ? "" : stock.cod,
            weight: loading || !stock?.weight ? "" : stock.weight,
            etage: loading || !stock?.etage ? "" : stock.etage,
            fournisseur: loading || !stock?.fournisseur ? "" : stock.fournisseur,
            category: loading || !stock?.category ? "" : stock.category,
            sub_category: loading || !stock?.sub_category ? "" : stock.sub_category,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);
    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };

    let query = useQuery();

    // getfournisseur
    useEffect(() => {
        query.set("page_size", "50")
        query.set("stock_service", "True")
        query.set("page", currentFournisseurPage)
        dispatch(getFournisseurs(query));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, currentFournisseurPage]);

    useEffect(() => {
        setFournisseurs(fournisseurData.fournisseurs);
    }, [fournisseurData.fournisseurs, fournisseurData.loading, fournisseurs]);

    // getCategories
    useEffect(() => {
        dispatch(getCategories());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // getSousCategories
    useEffect(() => {
        dispatch(getSousCategories(query));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    let user_groups_name = group;
    const h = useHistory()

    const handleCategoryInputChange = (e, actionMeta) => {
        if (actionMeta.action === 'input-change') {
            setCategoryFormData(prevState =>
                ({...prevState, name: e})
            );
        }
        query.set("search", e)
        dispatch(getCategories(query))
    };

    const handleSubCategoryInputChange = (e, actionMeta) => {
        if (actionMeta.action === 'input-change') {
            setSubCategoryFormData(prevState =>
                ({...prevState, name: e})
            );
        }
        query.set("search", e)
        dispatch(getSousCategories(query))
    };
    const handleCategoryAddNew = (e) => {
        setCategoryForm(true);
        setCategoryValue("")
        setFormData({...formData, category: ""})
        setCategoryMenuIsOpen(false);
    };

    const handleSubCategoryAddNew = (e) => {
        setSubCategoryForm(true);
        setSubCategoryValue("")
        setFormData({...formData, sub_category: ""})
        setSubCategoryMenuIsOpen(false);
    };

    const handleCategoryChange = (e) => {
        setCategoryFormData({...categoryFormData, [e.target.name]: e.target.value})
    };

    const handleSubCategoryChange = (e) => {
        setSubCategoryFormData({...subCategoryFormData, [e.target.name]: e.target.value})
    };

    const CategoryMenu = (props) => {
        const menuHeaderStyle = {
            padding: '6px 12px',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            cursor: "pointer"
        };
        return (
            <Fragment>
                <components.Menu {...props}>
                    <div>
                        {props.selectProps.fetchingData ? (
                            <span className="fetching">Fetching data...</span>
                        ) : (
                            <div>{props.children}</div>
                        )}
                        <div className="option" aria-disabled="false"
                             tabIndex="-1" style={menuHeaderStyle} onClick={handleCategoryAddNew}>
                            <p className={"bol"} style={{color: "#9da9bb"}}>Add new</p>

                        </div>
                    </div>
                </components.Menu>
            </Fragment>
        );
    };

    const SubCategoryMenu = (props) => {
        const menuHeaderStyle = {
            padding: '6px 12px',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            cursor: "pointer"
        };
        return (
            <Fragment>
                <components.Menu {...props}>
                    <div>
                        {props.selectProps.fetchingData ? (
                            <span className="fetching">Fetching data...</span>
                        ) : (
                            <div>{props.children}</div>
                        )}
                        <div className="option" aria-disabled="false"
                             tabIndex="-1" style={menuHeaderStyle} onClick={handleSubCategoryAddNew}>
                            <p className={"bol"} style={{color: "#9da9bb"}}>Add new</p>

                        </div>
                    </div>
                </components.Menu>
            </Fragment>
        );
    };

    const NoOptionsMessage = props => {
        return (
            <components.NoOptionsMessage {...props}>
                <div className="text-center">
                    <img src={Spinner} style={{width: "10%"}} alt=""/>
                </div>
            </components.NoOptionsMessage>
        );
    };

    let categoryOptions = stockData &&
        stockData.categories.map((C) => (
            {label: C.name, value: C.id}
        ))
    categoryOptions = [
        {label: "-------------------------", value: ""},
        ...categoryOptions
    ]
    let subCategoryOptions = stockData &&
        stockData.souscategories.map((C) => (
            {label: C.name, value: C.id}
        ))
    subCategoryOptions = [
        {label: "-------------------------", value: ""},
        ...subCategoryOptions
    ]

    return (
        <Fragment>
            <Breadcrumb parent="Stock" title="Modifier Stock"/>
            {/* eslint-disable-next-line no-mixed-operators */}
            {user_groups_name && user_groups_name.indexOf("admin") !== -1 || stock_service ? (
                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <Alert/>
                            <Form className="theme-form" onSubmit={(e) => onSubmit(e)}>
                                <Row>
                                    <Col lg="7">
                                        <Card>
                                            <CardHeader>
                                                <Row>
                                                    <Col sm={8}>
                                                        <h4>Détails du produit</h4>
                                                    </Col>
                                                    <Col sm={4}>
                                                        <button
                                                            onClick={() => {
                                                                setModal2(true)
                                                                setShowFormDelete(false)
                                                                setBoite(false)
                                                                setAction("add")
                                                            }}
                                                            type={"button"}
                                                            className="btn btn-primary"
                                                        >
                                                            Ajouter aux stock
                                                        </button>
                                                    </Col>
                                                </Row>
                                            </CardHeader>
                                            <CardBody>
                                                <div className={"d-flex flex-wrap"}>
                                                    <div>
                                                        <Label>
                                                            <h6>Image</h6>
                                                        </Label>
                                                        <br/>

                                                        {stock && stock.image ? (
                                                            <img
                                                                className="product"
                                                                src={`${stock.url_path}`}
                                                                alt=""
                                                            />
                                                        ) : (
                                                            <img
                                                                className="product"
                                                                src={Require}
                                                                alt=""
                                                            />
                                                        )}
                                                        <br/>
                                                        <br/>
                                                    </div>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Nom du produit (*)</Label>
                                                            <Input
                                                                className="form-control"
                                                                type="text"
                                                                name="name"
                                                                placeholder="Nom du produit"
                                                                value={formData.name}
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>COD (*)</Label>
                                                            <Input
                                                                className="form-control"
                                                                type="number"
                                                                name="cod"
                                                                placeholder="COD"
                                                                value={formData.cod}
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </FormGroup>
                                                        <Row>
                                                            <Col lg={12} xl={6}>
                                                                <FormGroup>
                                                                    <Label>Etage(*)</Label>
                                                                    <Input
                                                                        className="form-control"
                                                                        type="text"
                                                                        name="etage"
                                                                        placeholder="0  "
                                                                        value={formData.etage}
                                                                        onChange={(e) => onChange(e)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg={12} xl={6}>
                                                                <FormGroup>
                                                                    <Label>Poids (Kg)</Label>
                                                                    <Input
                                                                        className="form-control"
                                                                        type="text"
                                                                        name="weight"
                                                                        placeholder="Poids"
                                                                        value={formData.weight}
                                                                        onChange={(e) => onChange(e)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </div>
                                                <Row>
                                                    <Input
                                                        className="form-control"
                                                        type="file"
                                                        name="image"
                                                        accept="image/jpeg,image/png,image/gif"
                                                        onChange={(e) => {
                                                            onChangeimg(e);
                                                        }}
                                                    />
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg="5">
                                        <Card>
                                            <CardHeader>
                                                <h4>Informations du produit</h4>
                                            </CardHeader>
                                            <CardBody>
                                                {user_groups_name && user_groups_name.indexOf("admin") !== -1 &&
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label>Fournisseur (*)</Label>
                                                                <Select
                                                                    defaultValue={formData.fournisseur}
                                                                    value={selectedfournisseur}
                                                                    options={fournisseurData &&
                                                                        fournisseurData.fournisseurs.map(item => (
                                                                            {label: item.responsable, value: item.id}
                                                                        ))}
                                                                    onInputChange={(e) => {
                                                                        query.set("search", e)
                                                                        h.push({search: query.toString()})
                                                                    }}
                                                                    onMenuScrollToBottom={(e) => {
                                                                        console.log(e)
                                                                        setCurrentFournisseurPage(page => page + 1)
                                                                    }}
                                                                    onChange={(e) => {
                                                                        setSelectedFournisseur(e)
                                                                        setFormData({...formData, fournisseur: e.value})
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                }
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Catégorie (*)</Label>
                                                            <Select
                                                                onInputChange={(e, actionMeta) => handleCategoryInputChange(e, actionMeta)}
                                                                name={"category"}
                                                                options={categoryOptions}
                                                                value={categoryValue}
                                                                components={{Menu: CategoryMenu, NoOptionsMessage}}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        handleCategoryAddNew();
                                                                    }
                                                                }}
                                                                menuIsOpen={categoryMenuIsOpen}
                                                                onMenuOpen={() => setCategoryMenuIsOpen(true)} // Add this prop to open the menu
                                                                onMenuClose={() => setCategoryMenuIsOpen(false)}
                                                                onChange={(value) => {
                                                                    setCategoryValue(value)
                                                                    onChangeCategorie(value.value)
                                                                    setCategoryForm(false)
                                                                }}
                                                            />
                                                        </FormGroup>
                                                        {categoryForm &&
                                                            <FormGroup>
                                                                <Label>Nom (*)</Label>
                                                                <Input
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="name"
                                                                    placeholder="Nom"
                                                                    value={categoryFormData.name}
                                                                    onChange={(e) => handleCategoryChange(e)}
                                                                />
                                                            </FormGroup>
                                                        }
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Sous Catégorie (*)</Label>
                                                            <Select
                                                                onInputChange={(e, actionMeta) => handleSubCategoryInputChange(e, actionMeta)}
                                                                name={"sub_category"}
                                                                options={subCategoryOptions}
                                                                value={subCategoryValue}
                                                                components={{Menu: SubCategoryMenu, NoOptionsMessage}}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        handleSubCategoryAddNew();
                                                                    }
                                                                }}
                                                                menuIsOpen={subCategoryMenuIsOpen}
                                                                onMenuOpen={() => setSubCategoryMenuIsOpen(true)} // Add this prop to open the menu
                                                                onMenuClose={() => setSubCategoryMenuIsOpen(false)}
                                                                onChange={(value) => {
                                                                    setSubCategoryValue(value)
                                                                    setFormData({...formData, sub_category: value.value})
                                                                    setSubCategoryForm(false)
                                                                }}
                                                            />
                                                        </FormGroup>
                                                        {subCategoryForm &&
                                                            <FormGroup>
                                                                <Label>Nom (*)</Label>
                                                                <Input
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="name"
                                                                    placeholder="Nom"
                                                                    value={subCategoryFormData.name}
                                                                    onChange={(e) => handleSubCategoryChange(e)}
                                                                />
                                                            </FormGroup>
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Reference (*)</Label>
                                                            <Input
                                                                className="form-control"
                                                                type="text"
                                                                name="reference"
                                                                placeholder="Reference du produit"
                                                                value={formData.reference}
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Col>
                                    <FormGroup className="mb-0">
                                        <Button
                                            onClick={onSubmit}
                                            disabled={showLoader}
                                            className="btn btn-success mr-3"
                                            color={"success"}
                                            loading={showLoader}
                                            text={"Save"}
                                        />
                                        <Link to={`/stock_depot`}>
                                            <B color="danger">Cancel</B>
                                        </Link>
                                    </FormGroup>
                                </Col>
                            </Form>
                        </Col>
                    </Row>
                    <Modal
                        centered
                        fullscreen="sm"
                        size="md"
                        className="relative min-h-screen flex flex-col sm:justify-center items-center"
                        isOpen={modal2}
                        toggle={() => setModal2(!modal2)}
                    >
                        <ModalHeader
                            style={{
                                backgroundImage:
                                    "linear-gradient(to right,#FFD166, #133A4A  )",
                            }}
                            className="p-3 text-white"
                            toggle={() => setModal2(!modal2)}>
                            Ajouter
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col sm="12">
                                    <Form
                                        className="theme-form"
                                    >
                                        <Row>
                                            <Col>
                                                <Alert/>
                                                <Row>
                                                    <Col sm={6}>
                                                        <button onClick={onAddProductClick}
                                                                type={"button"}
                                                                className={`mr-2  w-100 btn ${!showFormDelete ? "btn-primary" : "btn-secondary"}`}
                                                        >Ajouter Produit
                                                        </button>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <button onClick={onRemoveProductClick}
                                                                type={"button"}
                                                                className={`w-100 btn ${showFormDelete ? "btn-primary" : "btn-secondary"}`}
                                                        >Remove Produit
                                                        </button>
                                                    </Col>
                                                </Row>
                                                <Form>
                                                    {showFormDelete &&
                                                        <Row>
                                                            <Col>
                                                                <Label>Raison du retrait</Label>
                                                                <select
                                                                    className="form-control"
                                                                    name="reason"
                                                                    onChange={(e) =>
                                                                        onChangeActivity(e)
                                                                    }
                                                                >
                                                                    <option value="" selected>----------</option>
                                                                    <option value="cassée">Cassée</option>
                                                                    <option
                                                                        value="pas_encore_disponible">Pas
                                                                        encore
                                                                        disponible
                                                                    </option>
                                                                    <option value="expirée">Expirée</option>
                                                                    <option value="other">Autres</option>
                                                                </select>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    <Row style={{marginTop: 20}}>
                                                        <Col sm={4}>
                                                            <Label>Seul / Boîte</Label>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <button
                                                                className={`mr-2  w-100 btn ${!boite ? "btn-primary" : "btn-secondary"}`}
                                                                onClick={() => setBoite(false)} type={"button"}
                                                            >Seul
                                                            </button>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <button
                                                                className={`w-100 btn ${boite ? "btn-primary" : "btn-secondary"}`}
                                                                onClick={() => setBoite(true)} type={"button"}
                                                            >Boite
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                    {boite &&
                                                        <Row>
                                                            <Col>
                                                                <Label>Taille</Label>
                                                                <Input
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="case"
                                                                    placeholder="Taille"
                                                                    onChange={(e) => onChangeActivity(e)}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    }
                                                    <Row>
                                                        <Col>
                                                            <Label>Quantité</Label>
                                                            <Input
                                                                className="form-control"
                                                                type="text"
                                                                name="quantity"
                                                                placeholder="Quantité"
                                                                onChange={(e) => onChangeActivity(e)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Label>Message</Label>
                                                    <textarea
                                                        className="form-control"
                                                        name="notes"
                                                        placeholder="Message"
                                                        onChange={(e) => onChangeActivity(e)}
                                                    />
                                                </Form>
                                            </Col>
                                        </Row>
                                        <br/>

                                        <ModalFooter>
                                            <button
                                                onClick={e => onSubmitStock(e)}
                                                type="submit "
                                                className="p-2 btn rounded text-white"
                                                style={{backgroundColor: "#133A4A"}}
                                            >
                                                {showFormDelete ? "Supprimer" : "Ajouter"} {showFormDelete ? "du" : "au"} stock
                                            </button>
                                        </ModalFooter>
                                    </Form>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>

                    <br/>
                    <br/>
                </Container>
            ) : (
                <Error404 page="Modifier Stock"/>
            )}
        </Fragment>
    );
};

Editstock.propTypes = {
    fournisseurData: PropTypes.object.isRequired,
    stockData: PropTypes.object.isRequired,
    stock: PropTypes.object.isRequired,
    updateStock: PropTypes.func.isRequired,
    patchStock: PropTypes.func.isRequired,
    getFournisseurs: PropTypes.func.isRequired,
    addNewActivity: PropTypes.func.isRequired,
};

const mapStateToPorps = (state) => ({
    fournisseurData: state.reducerf,
    stock: state.reducerst,
    stockData: state.reducerst,
});

export default connect(mapStateToPorps, {
    getFournisseurs,
    getCategories,
    getSousCategories,
    updateStock,
    patchStock,
    getCurrentstock,
    addNewActivity,
})(Editstock);
