import React, {Fragment, useContext, useState} from "react";
import { setAlert, setAlertD } from "../../redux/alert/action";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Form } from "reactstrap";
import Alert from "../../redux/Alert";
import { setAuthToken, api } from "../../utils/backend.instance";
import {  useDispatch } from "react-redux";
import Error404 from "../../pages/errors/error404";
import AppContext from "../../context/Context";

const Deletecolis = () => {
  const {
    config: {group}
  } =  useContext(AppContext)
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    code_or_tracking: "",
  });
  const deleteColisByIds = async (formData) => {
    if (localStorage.access) {
      setAuthToken(localStorage.access);
    }
    try {
      let list_id = formData.code_or_tracking.split("\n");
      let url_params = new URLSearchParams();

      for (let id = 0; id < list_id.length; id++) {
        url_params.append("colis_cab", list_id[id]);
      }
      await api.post(`/colis/delete_multiple/colis/`, {colis_cab: list_id});
      dispatch(setAlert("Colis successfully deleted", "success"));
    } catch (err) {
      const errors = err.response.data;
      if (errors) {
        dispatch(setAlertD("Colis not found", "danger"));
      }
    }
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    deleteColisByIds(formData);
  };
  let user_groups_name = group;
  return (
    <Fragment>
      <Breadcrumb parent="Colis" title="Supprimer colis " />
      {user_groups_name && user_groups_name.indexOf("admin") !== -1 ? (
        <Container fluid={true}>
          <Alert />
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <Form className="row" onSubmit={(e) => onSubmit(e)}>
                      <div className="col-sm-4">
                        <div className="form-group text-right">
                          <label className="form-label text-right">
                            Entrez le numéro de référence,
                          </label>
                          <br />
                          <label className="form-label text-right">
                             chaque un sur une ligne :
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div className="form-group">
                        <textarea
                          name="code_or_tracking"
                          value={formData.code_or_tracking}
                          className="form-control"
                          onChange={(e) => onChange(e)}
                        />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <button type="submit" className="btn btn-primary">
                            Supprimer
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12" id="content"></div>
            </div>
          </div>
        </Container>
      ) : (
        <Error404 page="Supprimer colis" />
      )}
    </Fragment>
  );
};

export default Deletecolis;
