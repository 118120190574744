import {setAlert, setAlertD} from "../../redux/alert/action";
import {setAuthToken, api} from "../../utils/backend.instance";
import {
    GET_STOCK,
    GET_STOCKS,
    STOCK_LOADING,
    STOCK_ERROR,
    GET_CATEGORIE,
    GET_CATEGORIES,
    CATEGORIE_LOADING,
    CATEGORIE_ERROR,
    GET_SOUS_CATEGORIE,
    GET_SOUS_CATEGORIES,
    SOUS_CATEGORIE_LOADING,
    SOUS_CATEGORIE_ERROR,
    GET_PRODUIT,
    PRODUIT_ERROR, GET_STOCK_ACTIVITIES, STOCK_ACTIVITIES_ERROR, STOCK_ACTIVITIES_LOADING,
} from "../../redux/actionTypes";

export const addNewStock = (formData, history) => async (dispatch) => {
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.post("/stock/product/", formData);
        dispatch({
            type: GET_STOCK,
            payload: res.data,
        });
        dispatch(setAlert("Product Successfully Created", "success"));
        history.push("/stock_depot");
    } catch (err) {
        const errors = err.response.data;
        if (errors) {
            dispatch(
                setAlertD(
                    (errors.case ? "Case: " + errors.case + " " : "") +
                    (errors.category ? "Catégorie: " + errors.category + " " : "") +
                    (errors.cod ? " COD: " + errors.cod + " " : "") +
                    (errors.etage ? "Etage: " + errors.etage + " " : "") +
                    (errors.fournisseur
                        ? "Fournisseur: " + errors.fournisseur + " "
                        : "") +
                    (errors.responsable
                        ? "Responsable: " + errors.responsable + " "
                        : "") +
                    (errors.quantity ? "QTE: " + errors.quantity + " " : "") +
                    (errors.reference ? "Reference: " + errors.reference + " " : "") +
                    (errors.sub_category
                        ? "Sous Catégorie: " + errors.sub_category + " "
                        : ""),
                    "danger"
                )
            );
        }
        dispatch({
            type: STOCK_ERROR,
            payload: {msg: err.response.data, status: err.response.status},
        });
    }
};


export const addNewActivity = (formData) => async (dispatch) => {
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.post("/stock/stock/history/", formData);
        dispatch({
            type: GET_STOCK_ACTIVITIES,
            payload: res.data,
        });
        dispatch(setAlert("Product Successfully Added to Stock", "success"));
    } catch (err) {
        const errors = err.response.data;
        if (errors) {
            dispatch(
                setAlertD(
                    (errors.quantity ? "QTE: " + errors.quantity + " " : ""),
                    "danger"
                )
            );
        }
        dispatch({
            type: STOCK_ACTIVITIES_ERROR,
            payload: {msg: err.response.data, status: err.response.status},
        });
    }
};


export const updateStock = (formData, id) => async (dispatch) => {
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.put(`/stock/product/${id}/`, formData);
        dispatch({
            type: GET_STOCK,
            payload: res.data,
        });
        dispatch(setAlert("Stock Successfully Updated", "success"));
    } catch (err) {
        const errors = err.response.data;
        if (errors) {
            dispatch(
                setAlertD(
                    (errors.case ? "Case: " + errors.case + " " : "") +
                    (errors.category ? "Catégorie: " + errors.category + " " : "") +
                    (errors.cod ? " COD: " + errors.cod + " " : "") +
                    (errors.etage ? "Etage: " + errors.etage + " " : "") +
                    (errors.fournisseur
                        ? "Fournisseur: " + errors.fournisseur + " "
                        : "") +
                    (errors.responsable
                        ? "Responsable: " + errors.responsable + " "
                        : "") +
                    (errors.quantity ? "QTE: " + errors.quantity + " " : "") +
                    (errors.reference ? "Reference: " + errors.reference + " " : "") +
                    (errors.sub_category
                        ? "Sous Catégorie: " + errors.sub_category + " "
                        : ""),
                    "danger"
                )
            );
        }
        dispatch({
            type: STOCK_ERROR,
            payload: {msg: err.response.data, status: err.response.status},
        });
    }
};


export const patchStock = (formData, id) => async (dispatch) => {
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.patch(`/stock/product/${id}/`, formData);
        dispatch({
            type: GET_STOCK,
            payload: res.data,
        });
        dispatch(setAlert("Stock Successfully Updated", "success"));
    } catch (err) {
        dispatch({
            type: STOCK_ERROR,
            payload: {msg: err.response.data, status: err.response.status},
        });
    }
};

export const getCurrentstock = (id) => async (dispatch) => {
    dispatch({type: STOCK_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }

    try {
        const res = await api.get(`/stock/product/${id}/`);

        dispatch({
            type: GET_STOCK,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: STOCK_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getStocks = (params) => async (dispatch) => {
    dispatch({type: STOCK_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }

    try {
        const res = await api.get(`/stock/product/?${params.toString()}`);

        dispatch({
            type: GET_STOCKS,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: STOCK_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getActivities = (params) => async (dispatch) => {
    dispatch({type: STOCK_ACTIVITIES_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }

    try {
        const res = await api.get(`/stock/stock/history/?${params.toString()}`);

        dispatch({
            type: GET_STOCK_ACTIVITIES,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: STOCK_ACTIVITIES_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const deleteStock = (id, history) => async (dispatch) => {
    try {
        const res = await api.delete(`/stock/product/${id}/`);

        dispatch({
            type: GET_STOCK,
            payload: res.data.results,
        });

        dispatch(setAlert("Product Successfully Deleted", "success"));
        history.push("/colis?created_at=today");
    } catch (err) {
        dispatch({
            type: STOCK_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const addNewCategorie =
    (formDatacategorie) =>
        async (dispatch) => {
            if (localStorage.access) {
                setAuthToken(localStorage.access);
            }
            try {
                const res = await api.post("/stock/category/", formDatacategorie);
                dispatch({
                    type: GET_CATEGORIE,
                    payload: res.data,
                });
                dispatch(setAlert("Category Successfully Created", "success"));
            } catch (err) {
                const errors = err.response.data.errors;
                if (errors) {
                    errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
                }
                dispatch({
                    type: CATEGORIE_ERROR,
                    payload: {msg: err.response.statusText, status: err.response.status},
                });
            }
        };

export const addNewSousCategorie =
    (formDatasouscategorie) =>
        async (dispatch) => {
            if (localStorage.access) {
                setAuthToken(localStorage.access);
            }
            try {
                const res = await api.post("/stock/subcat/", formDatasouscategorie);
                dispatch({
                    type: GET_SOUS_CATEGORIE,
                    payload: res.data,
                });
                dispatch(setAlert("Sub category Successfully Created", "success"));
            } catch (err) {
                const errors = err.response.data.errors;
                if (errors) {
                    errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
                }
                dispatch({
                    type: SOUS_CATEGORIE_ERROR,
                    payload: {msg: err.response.statusText, status: err.response.status},
                });
            }
        };
export const getCategories = (query) => async (dispatch) => {
    dispatch({type: CATEGORIE_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }

    try {
        const res = await api.get(`/stock/category/?${query.toString()}`);

        dispatch({
            type: GET_CATEGORIES,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: CATEGORIE_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getSousCategories = (params) => async (dispatch) => {
    dispatch({type: SOUS_CATEGORIE_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }

    try {
        const res = await api.get(`/stock/subcat/?${params.toString()}`);

        dispatch({
            type: GET_SOUS_CATEGORIES,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: SOUS_CATEGORIE_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const addNewProduit =
    (formDatasouscategorie) =>
        async (dispatch) => {
            if (localStorage.access) {
                setAuthToken(localStorage.access);
            }
            try {
                const res = await api.post("/stock/product/", formDatasouscategorie);
                dispatch({
                    type: GET_PRODUIT,
                    payload: res.data,
                });
                dispatch(setAlert("Product Successfully Created", "success"));
            } catch (err) {
                const errors = err.response.data.errors;
                if (errors) {
                    errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
                }
                dispatch({
                    type: PRODUIT_ERROR,
                    payload: {msg: err.response.statusText, status: err.response.status},
                });
            }
        };

export const getProduits = async (query) => {
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    const res = await api.get(`/stock/product/?${query.toString()}`);
    return res?.data
};
