import {
  GET_SECTEUR,
  GET_SECTEURS,
  SECTEUR_LOADING,
  SECTEUR_ERROR,
  UPDATE_SECTEUR,
} from "../../redux/actionTypes";

const initial_state = {
  secteur: null,
  secteurs: [],
  count: 0,
  loading: true,
  error: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initial_state, action) {
  const { type, payload } = action;
  switch (type) {
    case SECTEUR_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SECTEUR_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case GET_SECTEUR:
      return {
        ...state,
        secteur: payload.SECTEUR,
        loading: false,
      };
    case UPDATE_SECTEUR:
      return {
        ...state,
        secteur: payload,
        loading: false,
      };
    case GET_SECTEURS:
      return {
        ...state,
        count: payload.count,
        secteurs: payload.results,
        loading: false,
      };

    default:
      return state;
  }
}
