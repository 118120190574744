import { setAlert, setAlertD } from "../../redux/alert/action";
import { setAuthToken, api } from "../../utils/backend.instance";
import {
  GET_DEMANDE,
  GET_DEMANDES,
  UPDATE_DEMANDE,
  DEMANDE_LOADING,
  DEMANDE_ERROR,
} from "../../redux/actionTypes";

export const addNewDemande = (formData) => async (dispatch) => {
  if (localStorage.access) {
    setAuthToken(localStorage.access);
  }
  try {
    const res = await api.post("/join/join/", formData);
    dispatch({
      type: GET_DEMANDE,
      payload: res.data,
    });
    dispatch(setAlert("Demande Successfully Created", "success"));
  } catch (err) {
    const errors = err.response.data;
    if (errors) {
      dispatch(
        setAlertD(
          (errors.first_name ? "Nom: " + errors.first_name + " " : "") +
            (errors.email ? "Email: " + errors.email + "\n" : "") +
            (errors.last_name ? "Prénom: " + errors.last_name + " " : "") +
            (errors.entreprise
              ? "Entreprise: " + errors.entreprise + " "
              : "") +
            (errors.number ? "Numéro: " + errors.number + " " : ""),
          "danger"
        )
      );
    }
    dispatch({
      type: DEMANDE_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
  }
};

export const getDemandes = (params) => async (dispatch) => {
  dispatch({ type: DEMANDE_LOADING });
  if (localStorage.access) {
    setAuthToken(localStorage.access);
  }

  try {
    const res = await api.get(`/join/join/?${params.toString()}`);

    dispatch({
      type: GET_DEMANDES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: DEMANDE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const deleteDemande = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/join/join/${id}/`);

    dispatch({
      type: UPDATE_DEMANDE,
      payload: res.data.results,
    });

    dispatch(setAlert("Demande Successfully Deleted", "success"));
  } catch (err) {
    dispatch({
      type: DEMANDE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const getCurrentDemande = (id) => async (dispatch) => {
  dispatch({ type: DEMANDE_LOADING });
  if (localStorage.access) {
    setAuthToken(localStorage.access);
  }
  try {
    const res = await api.get(`/join/join/${id}/`);

    dispatch({
      type: GET_DEMANDE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: DEMANDE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
export const getCurrentDemandeF= (key) => async (dispatch) => {
  dispatch({ type: DEMANDE_LOADING });
  if (localStorage.access) {
    setAuthToken(localStorage.access);
  }
  try {
    const res = await api.get(`/join/join/?key=${key}`);

    dispatch({
      type: GET_DEMANDES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: DEMANDE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const updateDemande = (fd, id) => async (dispatch) => {
  if (localStorage.access) {
    setAuthToken(localStorage.access);
  }
  try {
    const res = await api.patch(`/join/join/${id}/`, fd);
    dispatch({
      type: GET_DEMANDE,
      payload: res.data,
    });
    dispatch(setAlert("Demande Successfully Updated", "success"));
  } catch (err) {
    const errors = err.response.data;
    if (errors) {
      dispatch(
        setAlertD(
          (errors.first_name ? "Nom: " + errors.first_name + " " : "") +
            (errors.email ? "Email: " + errors.email + "\n" : "") +
            (errors.last_name ? "Prénom: " + errors.last_name + " " : "") +
            (errors.entreprise
              ? "Entreprise: " + errors.entreprise + " "
              : "") +
            (errors.number ? "Numéro: " + errors.number + " " : ""),
          "danger"
        )
      );
    }

    dispatch({
      type: DEMANDE_ERROR,
      payload: {
        msg: err.response.data,
        status: err.response.status,
      },
    });
  }
};
