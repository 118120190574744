import React, {Fragment, useState, useMemo, useEffect} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {useLocation, Link} from "react-router-dom";
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import {connect} from "react-redux";
import {Card, CardBody, Col, Row, Table} from "reactstrap";
import PaginationWrapper from "../pagination/pagination";
import { GetColisByIds} from "./action";
import {reformatStatus} from "./coli";

const Manifestecolis = ({GetColisByIds, colisData}) => {
    const [formData, setFormData] = useState({
        code_a_barre: "",
    });
    const [colis, setColis] = useState([]);

    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };
    const {code_a_barre} = formData;
    const dispatch = useDispatch();

    const onChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    let q = new URLSearchParams()
    console.log(q.toString())
    let query = useQuery();
    let pagenumber = query.get("page") ? query.get("page") : 1;
    const Navigate = (page) => {
        query.set("page", Number(page));

        window.location.replace(`/manifeste-colis/?${query}`);
    };
    useEffect(() => {
        if (colisData.colism) {
            setColis(colisData.colism);
        }
    }, [colisData.colism, colisData.loading, colis]);
    let count = colisData.countm;

    const onSubmit = (e) => {
        e.preventDefault();
        query.set('page_size', "200")
        dispatch(GetColisByIds(formData, query));
    };

    return (
        <Fragment>
            <Breadcrumb parent="Colis" title="Manifeste de colis "/>
                <div className="container-fluid">
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardBody>
                                    <form className="row" onSubmit={(e) => onSubmit(e)}>
                                        <div className="col-sm-4">
                                            <div className="form-group text-right">
                                                <label className="form-label text-right">
                                                    Entrez le numéro de référence,
                                                </label>
                                                <br/>
                                                <label className="form-label text-right">
                                                    chaque un sur une ligne :
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="form-group">
                        <textarea
                            name="code_a_barre"
                            value={code_a_barre}
                            className="form-control"
                            placeholder=""
                            onChange={(e) => onChange(e)}
                        ></textarea>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <button
                                                    type="submit"
                                                    className="btn"
                                                    style={{backgroundColor: "#FDF203"}}
                                                >
                                                    obtenir
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {count > 0 && (
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <CardBody>
                                        <Col sm="12">
                                            <button
                                                className="btn m-2"
                                                style={{backgroundColor: "#FDF203"}}
                                                onClick={() => {
                                                    let q = new URLSearchParams();
                                                    q.set("colis_cab", colisData.colism.map(c => c.code_a_barre + ","))
                                                    q.set("page_size", "200")
                                                    window.open(`/colis/excel?${q}`);
                                                }}
                                                title="excel"
                                            >
                                                Excel
                                            </button>
                                            <button className="btn m-2 text-white "
                                                    onClick={() => {
                                                        let q = new URLSearchParams();
                                                        q.set("colis_cab", colisData.colism.map(c => c.code_a_barre + ","))
                                                        q.set("page_size", "200")
                                                        window.open(`/colis/printp?${q}`);
                                                    }}
                                                    style={{backgroundColor: "#C725C7"}} title="Imprimer tous">
                                                IMPRIMER TOUS
                                            </button>
                                            <Card>
                                                <div className="card-block row">
                                                    <Col sm="12" lg="12" xl="12">
                                                        <div className="table-responsive">
                                                            <Table striped>
                                                                <thead>
                                                                <tr>
                                                                    <th scope="col" id="code_a_barre">
                                                                        {"Code à barre"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                    </th>
                                                                    <th scope="col">
                                                                        {"Code à barre livreur"}{" "}
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                                    </th>
                                                                    <th scope="col" id="status">
                                                                        {"Status"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                    </th>
                                                                    <th scope="col" id="fournisseur_name">
                                                                        {"Expéditeur"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                    </th>
                                                                    <th scope="col" id="client_email">
                                                                        {"Destinataire"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                    </th>
                                                                    <th scope="col" id="client_telephone_1">
                                                                        {"Téléphone 1"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                    </th>

                                                                    <th scope="col" id="service">
                                                                        {"Service"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                    </th>
                                                                    <th scope="col" id="prix">
                                                                        {"Prix"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                    </th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {colisData &&
                                                                    colisData.colism.map((b) => (
                                                                        <tr key={b.code_a_barre}>
                                                                            <td>
                                                                                <Link to={`/colis/detail/${b.id}`}>
                                                                                    {b.code_a_barre}
                                                                                </Link>
                                                                            </td>
                                                                            <td>{b.tracking_code}</td>
                                                                            <td>{reformatStatus(b.status)}</td>
                                                                            <td>{b.fournisseur_name}</td>
                                                                            <td>{b.client_name}</td>
                                                                            <td>{b.client_telephone_1}</td>
                                                                            <td>{b.service}</td>
                                                                            <td>{b.prix}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </Col>
                                                </div>
                                            </Card>
                                        </Col>{" "}
                                        <Row>
                                            <Col sm="10"></Col>
                                            <Col>
                                                <PaginationWrapper
                                                    pageSize={200}
                                                    name="Colis"
                                                    length={colisData.colism.length}
                                                    count={count}
                                                    currentPage={pagenumber}
                                                    onNavigate={Navigate}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                </div>
        </Fragment>
    );
};

Manifestecolis.propTypes = {
    GetColisByIds: PropTypes.func.isRequired,
};
const mapStateToPorps = (state) => ({
    colisData: state.reducerc,
});

export default connect(mapStateToPorps, {
    GetColisByIds,
})(Manifestecolis);
