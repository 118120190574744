import React, {Fragment, useState, useEffect, useMemo, useContext} from "react";
import PropTypes from "prop-types";
import Alert from "../../redux/Alert";
import {connect} from "react-redux";
import {Link, useHistory, useLocation, useParams} from "react-router-dom";
import {getCurrentCompte, updatecompte} from "./action";
import {getFournisseurs, getFournisseursP} from "../Fournisseurs/action";
import {getGroups} from "../group/action";
import Breadcrumb from "../../layout/breadcrumb";
import {useDispatch} from "react-redux";
import Error404 from "../../pages/errors/error404";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button as B,
    CardHeader,
} from "reactstrap";
import Spinner from "../colis/spinner.gif";
import Select, {components} from "react-select";
import Button from "../../utils/Button";
import AppContext from "../../context/Context";

const Editcompte = ({
                        getCurrentCompte,
                        updatecompte,
                        fournisseurData,
                        compte: {compte, loading},
                    }) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        username: "",
        email: "",
        first_name: "",
        last_name: "",
        avatar: null,
        fournisseur: "",
        is_active: "",
        number: "",
        password: "",
    });

    const {id} = useParams();
    useEffect(() => {
        getCurrentCompte(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const {
        config: {group}
    } = useContext(AppContext)

    const [taptopStyle, setTapTopStyle] = useState("none");
    const [selectedfournisseur, setSelectedFournisseur] = useState([]);
    const [currentFournisseurPage, setCurrentFournisseurPage] = useState(1)
    const [showLoader, setShowLoader] = useState(false)

    const handleScroll = () => {
        if (window.scrollY > 600) {
            setTapTopStyle("block");
        } else {
            setTapTopStyle("none");
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        handleScroll();
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [taptopStyle]);
    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };
    let query = useQuery();
    const history = useHistory()

    useEffect(() => {
        query.set("page_size", "50")
        query.set("page", currentFournisseurPage)
        dispatch(getFournisseurs(query))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFournisseurPage]);

    const onChange = (e) =>
        setFormData({...formData, [e.target.name]: e.target.value});
    const onChangeimg = (e) => {
        setFormData({...formData, avatar: e.target.files[0]});
    };

    const onChangeFournisseur = (e) => {
        setSelectedFournisseur(e);
        const fournisseurid = e.value;

        setFormData({
            ...formData,
            fournisseur: fournisseurid,
        });
    };

    useEffect(() => {
        setFormData({
            username: loading || !compte?.username ? "" : compte.username,
            email: loading || !compte?.email ? "" : compte.email,
            first_name: loading || !compte?.first_name ? "" : compte.first_name,
            last_name: loading || !compte?.last_name ? "" : compte.last_name,
            fournisseur: loading || !compte?.fournisseur ? "" : compte.fournisseur,
            is_active: loading || !compte?.is_active ? "" : compte.is_active,
            number: loading || !compte?.number ? "" : compte.number,
            password: loading || !compte?.password ? "" : compte.password,
        });
        setSelectedFournisseur({
            value: loading || !compte?.fournisseur ? "" : compte.fournisseur,
            label: loading || !compte?.fournisseur_name ? "" : compte.fournisseur_name,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);
    const onSubmit = async (e) => {
        setShowLoader(true)
        e.preventDefault();
        const fd = new FormData();
        fd.append("email", formData.email);
        fd.append("username", formData.username);
        fd.append("first_name", formData.first_name);
        fd.append("last_name", formData.last_name);
        if (formData.password) {
            fd.append("password", formData.password);
        }
        fd.append("fournisseur", formData.fournisseur);
        fd.append("is_active", formData.is_active);
        fd.append("number", formData.number);
        if (formData.avatar) {
            fd.append("avatar", formData.avatar);
        }
        await updatecompte(fd, id);
        setShowLoader(false)
        history.push('/compte')
    };
    let user_groups_name = group;

    const onChangeCheck = (e) => {
        if (e.target.checked) {
            document.getElementById("password").style.display = "block";
        } else {
            document.getElementById("password").style.display = "none";
        }
    };

    let data = [];
    fournisseurData &&
    fournisseurData.fournisseurs.map((F) =>
        data.push({
            value: parseInt(F.id),
            label: F.name,
        })
    );
    data = [
        {label: "---------------------", value: ""},
        ...data
    ]

    const NoOptionsMessage = props => {
        return (
            <components.NoOptionsMessage {...props}>
                <div className="text-center">
                    <img src={Spinner} style={{width: "10%"}} alt=""/>
                </div>
            </components.NoOptionsMessage>
        );
    };

    return (
        <Fragment>
            <Breadcrumb parent="Compte" title="Modifier Utilisateur"/>
            {user_groups_name.indexOf("personnel") === -1 ? (
                <div>
                    {loading && loading ?
                        <img src={Spinner} style={{width: "10%", position: "relative", left: "45%"}} alt=""/> :
                        <Container fluid={true}>
                            <Alert/>

                            <Form className="theme-form" onSubmit={(e) => onSubmit(e)}>
                                <Row>
                                    <Col sm="7">
                                        <Card>
                                            <CardHeader>
                                                <Row>
                                                    <Col sm="10">
                                                        <h5>Confidentalité</h5>
                                                    </Col>
                                                </Row>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col sm="4">
                                                        <Label>
                                                            <h6>Avatar</h6>
                                                        </Label>
                                                        <br/>
                                                        {compte && compte.avatar ? (
                                                            <img
                                                                className="product"
                                                                src={`${compte.url_path}`}
                                                                alt=""
                                                            />
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Col>
                                                    <Col>
                                                        {" "}
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label>
                                                                        {compte && compte.username ? (
                                                                            <h6>Utilisateur: {compte.username}</h6>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm="7">
                                                                <FormGroup>
                                                                    <Input
                                                                        className="ml-2"
                                                                        type="checkbox"
                                                                        id="checkPass"
                                                                        onChange={(e) => onChangeCheck(e)}
                                                                    />
                                                                    <label className="ml-4">
                                                                        Modifier le mot de passe ?{" "}
                                                                    </label>
                                                                </FormGroup>
                                                            </Col>
                                                            {user_groups_name &&
                                                            user_groups_name.indexOf("admin") !== -1 ? (
                                                                <Col className="ml-4">
                                                                    <FormGroup>
                                                                        <Row>
                                                                            <Col>
                                                                                <Input
                                                                                    className="ml-2"
                                                                                    key={"active"}
                                                                                    checked={formData.is_active}
                                                                                    type="checkbox"
                                                                                    name="is_active"
                                                                                    value={formData.is_active}
                                                                                    onChange={(e) => onChange(e)}
                                                                                />
                                                                                <Label className="ml-4">Active</Label>
                                                                            </Col>
                                                                        </Row>
                                                                    </FormGroup>
                                                                </Col>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Row>
                                                        <Row id="password" style={{display: "none"}}>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label>Mot de passe</Label>
                                                                    <Input
                                                                        className="form-control"
                                                                        type="password"
                                                                        name="password"
                                                                        placeholder="********"
                                                                        value={formData.password}
                                                                        onChange={(e) => onChange(e)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    {user_groups_name &&
                                                                    user_groups_name.indexOf("admin") !== -1 ? (
                                                                        <Label>Fournisseur</Label>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    {user_groups_name &&
                                                                    user_groups_name.indexOf("admin") !== -1 ? (
                                                                        <Select
                                                                            value={selectedfournisseur}
                                                                            options={data}
                                                                            placeholder={"Fournisseur"}
                                                                            components={{NoOptionsMessage}}
                                                                            onChange={(e) => {
                                                                                onChangeFournisseur(e);
                                                                            }}
                                                                            onInputChange={(e) => {
                                                                                query.set('search', e);
                                                                                dispatch(getFournisseurs(query))
                                                                            }}
                                                                            onMenuScrollToBottom={(e) => {
                                                                                console.log(e)
                                                                                setCurrentFournisseurPage(page => page + 1)
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Input
                                                                className="form-control"
                                                                type="file"
                                                                name="avatar"
                                                                accept="image/jpeg,image/png,image/gif"
                                                                onChange={(e) => {
                                                                    onChangeimg(e);
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col sm="5">
                                        <Card>
                                            <CardHeader>
                                                <h5>Données personnelles</h5>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Nom</Label>
                                                            <Input
                                                                className="form-control"
                                                                type="text"
                                                                placeholder="Nom"
                                                                name="last_name"
                                                                value={formData.last_name}
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Prénom</Label>
                                                            <Input
                                                                className="form-control"
                                                                type="text"
                                                                name="first_name"
                                                                placeholder="Prénom"
                                                                value={formData.first_name}
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>{" "}
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Email</Label>
                                                            <Input
                                                                className="form-control"
                                                                type="text"
                                                                placeholder="Email"
                                                                name="email"
                                                                value={formData.email}
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>{" "}
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Téléphone</Label>
                                                            <Input
                                                                className="form-control"
                                                                type="number"
                                                                name="number"
                                                                placeholder="Téléphone"
                                                                value={formData.number}
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup className="mb-6">
                                            <Button
                                                onClick={onSubmit}
                                                disabled={showLoader}
                                                className="btn btn-success mr-3"
                                                color={"success"}
                                                loading={showLoader}
                                                text={"Enregistrer"}
                                            />
                                            <Link to={`/compte`}>
                                                <B color="danger">Annuler</B>
                                            </Link>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </Container>}
                </div>
            ) : (
                <Error404 page="Modifier Utilisateur"/>
            )}
        </Fragment>
    );
};
Editcompte.propTypes = {
    getFournisseursP: PropTypes.func.isRequired,
    fournisseurData: PropTypes.object.isRequired,
    updatecompte: PropTypes.func.isRequired,
    getCurrentCompte: PropTypes.func.isRequired,
    getGroups: PropTypes.func.isRequired,
    compte: PropTypes.object.isRequired,
};

const mapstatetoprops = (state) => ({
    groupData: state.reducerg,
    compte: state.reducercp,
    fournisseurData: state.reducerf,
});
export default connect(mapstatetoprops, {
    updatecompte,
    getCurrentCompte,
    getGroups,
    getFournisseursP,
})(Editcompte);
