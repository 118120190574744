import React, {Fragment, useContext, useEffect, useMemo, useState,} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import PropTypes from "prop-types";
import {connect, useDispatch} from "react-redux";
import {Link, useLocation, useParams, withRouter} from "react-router-dom";
import {Card, CardBody, Col, Container, Row,} from "reactstrap";
import {getCurrentFinance, getFinances,} from "./action";
import {getColis, getColisByIds1} from "../colis/action";
import {getCurrentFournisseur} from "../Fournisseurs/action";
import Error404 from "../../pages/errors/error404";
import {reformatStatus} from "../colis/coli";
import Spinner from "../colis/spinner.gif";
import AppContext from "../../context/Context";

const Financeexport = ({
                           colisData,
                           fournisseurData,
                           finance: {finance, loading},
                       }) => {
    const [data, setData] = useState([]);
    const [fournisseur, setFournisseur] = useState([]);
    const [Finance, setFinance] = useState([]);
    const [idf, setIdf] = useState([]);
    const [timer, setTimer] = useState(null)
    const {id} = useParams();
    const dispatch = useDispatch();

    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };
    let query = useQuery();
    query.set("related_payment", id);
    useEffect(() => {
        dispatch(getCurrentFinance(id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setFinance(finance);
    }, [finance, loading, Finance]);
    useEffect(() => {
        if (finance) {
            setIdf(finance.fournisseur);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, idf]);
    query.set('ordering', "status")
    useEffect(() => {
        query.set("page_size", finance?.nombre_de_colis)
        dispatch(getColis(query));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (colisData.colis) {
            setData(colisData.colis);
        }
    }, [colisData.colis, colisData.loading, data]);
    let length = colisData.colis.length;
    const COD = colisData.colis.reduce((totalCod, cod) => totalCod + cod.prix, 0);
    const Frais = colisData.colis.reduce(
        (totalFrais, cod) => totalFrais + cod.hfl,
        0
    );
    const HFL = colisData.colis.reduce(() => COD - Frais, 0);
    useEffect(() => {
        if (fournisseurData.fournisseurs) {
            setFournisseur(fournisseurData.fournisseurs);
        }
    }, [fournisseurData.fournisseurs, fournisseurData.loading, fournisseur]);
    const getValSearch = () => {
        let params = new URLSearchParams(document.location.search);
        return params.get("q");
    };
    const inputChanged = (e) => {
        clearTimeout(timer)
        query.set("q", e)
        const newTimer = setTimeout(() => {
            dispatch(getColisByIds1(query, id));
        }, 500)
        setTimer(newTimer)
    }
    const {
        config: {group}
    } = useContext(AppContext)
    let user_groups_name = group;

    return (
        <Fragment>
            <Breadcrumb parent="Finance" title="Détails Finance"/>
            {user_groups_name.indexOf("admin") !== -1 || user_groups_name.indexOf("fournisseur") !== -1 ? (
                <div>
                    <Container fluid={true}>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <div className="col-md-5 offset-md-1">
                                                <h6>MF: 1736403 M/A/M/000</h6>
                                                <h6>TEL: 29812970</h6>
                                                <h6>
                                                    Adresse: Zaraani kalaa kbira est, Sousse, Tunisie{" "}
                                                </h6>
                                            </div>

                                            {finance && finance ? (
                                                <div className="col-md-5  m-auto text-center">
                                                    <h6>Fournisseur: {finance.name_fournisseur}</h6>
                                                    <h6>Tél: {finance.number_fournisseur}</h6>
                                                    <h6>
                                                        {finance.mf_fournisseur &&
                                                        finance.mf_fournisseur
                                                            ? "MF:  " + finance.mf_fournisseur
                                                            : ""}
                                                    </h6>
                                                    <h6>
                                                        {finance.mail_fournisseur &&
                                                        finance.mail_fournisseur
                                                            ? <>Email: <a href={`mailto:${finance.mail_fournisseur}`}>{finance.mail_fournisseur}</a></>
                                                            : ""}
                                                    </h6>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            {finance && finance ? (
                                                <div className="col-md-12 text-center my-4">
                                                    <h4>Etat de payement N°: {finance.id}</h4>
                                                    <h6>{finance.created_at} </h6>
                                                </div>
                                            ) : (
                                                ""
                                            )}

                                            <div className=" table-responsive">
                                                <div
                                                    id="example_wrapper"
                                                    className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
                                                >
                                                    <Col sm={"12"}>
                                                        <Row>
                                                            <Link className="btn  m-2 btn-excel text-white"
                                                                  to={`/finance/excel?related_payment=${id}&ordering=status&page_size=${finance?.nombre_de_colis}`}
                                                                  target={"_blank"}>
                                                                EXCEL
                                                            </Link>
                                                            <Col>
                                                    {finance?.url && <a
                                                        className="btn btn-excel text-white m-2 "
                                                        href={finance?.url}
                                                        target={"_blank"}
                                                        rel={"noreferrer"}
                                                    >
                                                        {"Finance"}
                                                    </a>}
                                                </Col>
                                                            <div className="search-5">
                                                                <h6>Rechercher:</h6>
                                                            </div>

                                                            <input
                                                                className=" border search "
                                                                style={{borderRadius: "7px"}}
                                                                type="search"
                                                                placeholder="Rechercher..."
                                                                name="search"
                                                                defaultValue={getValSearch()}
                                                                onChange={(e) => inputChanged(e.target.value)}
                                                            /></Row></Col>
                                                    {colisData && colisData.loading ? (
                                                        <div className="text-center">
                                                            <img src={Spinner} style={{width: "10%"}} alt=""/>
                                                        </div>
                                                    ) : (
                                                        <table
                                                            className="table align-items-center table-bordered dataTable no-footer"
                                                            id="example"
                                                            role="grid"
                                                        >
                                                            <thead className="thead-light">
                                                            <tr>
                                                                <th
                                                                    colSpan="8"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        fontWeight: "bold"
                                                                    }}
                                                                    hidden={true}
                                                                >Beezit - Finance
                                                                </th>
                                                            </tr>
                                                            <tr role="row">
                                                                <th
                                                                    className="sorting_disabled"
                                                                    rowSpan="1"
                                                                    colSpan="1"
                                                                >
                                                                    Code à barre
                                                                </th>
                                                                <th
                                                                    className="sorting_disabled"
                                                                    rowSpan="1"
                                                                    colSpan="1"
                                                                >
                                                                    Client
                                                                </th>
                                                                <th
                                                                    className="sorting_disabled"
                                                                    rowSpan="1"
                                                                    colSpan="1"
                                                                >
                                                                    Téléphone
                                                                </th>
                                                                <th
                                                                    className="sorting_disabled"
                                                                    rowSpan="1"
                                                                    colSpan="1"
                                                                >
                                                                    Ville
                                                                </th>
                                                                <th
                                                                    className="sorting_disabled"
                                                                    rowSpan="1"
                                                                    colSpan="1"
                                                                >
                                                                    Statut
                                                                </th>
                                                                <th
                                                                    className="text-center sorting_disabled"
                                                                    rowSpan="1"
                                                                    colSpan="1"
                                                                >
                                                                    COD
                                                                </th>
                                                                <th
                                                                    className="text-center sorting_disabled"
                                                                    rowSpan="1"
                                                                    colSpan="1"
                                                                >
                                                                    Frais
                                                                </th>
                                                                <th
                                                                    className="text-center sorting_disabled"
                                                                    rowSpan="1"
                                                                    colSpan="1"
                                                                >
                                                                    Total HFL
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {colisData &&
                                                                colisData.colis.map((b, index, elements) => {
                                                                    let next = elements[index + 1]
                                                                    return (
                                                                        <>
                                                                            <tr key={b.id}>
                                                                                <td>{b.code_a_barre}</td>
                                                                                <td>
                                                                                    {b.client_name}
                                                                                </td>
                                                                                <td>{b.client_telephone_1}</td>
                                                                                <td>{b.ville_name}</td>
                                                                                <td id="status"
                                                                                    defaultValue={b.status}>{reformatStatus(b.status)}</td>
                                                                                <td>{Math.round(b.prix * 100) / 100}</td>
                                                                                <td>{Math.round(b.hfl * 100) / 100}</td>
                                                                                <td>{Math.round((b.prix - b.hfl) * 100) / 100}</td>
                                                                            </tr>
                                                                            {next && b.status !== next.status ?
                                                                                <tr hidden={true}>
                                                                                    <td>{reformatStatus(next.status)}</td>
                                                                                </tr> :
                                                                                ""
                                                                            }
                                                                        </>
                                                                    )
                                                                })}
                                                            </tbody>
                                                            <tfoot>
                                                            <tr></tr>
                                                            <tr>
                                                                <td>Total</td>
                                                                <td>{length} </td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td>{Math.round(COD * 100)/ 100} </td>
                                                                <td>{Math.round(Frais * 100) / 100} </td>
                                                                <td>{Math.round(HFL * 100) / 100} </td>
                                                            </tr>
                                                            </tfoot>
                                                        </table>)}
                                                </div>
                                            </div>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            ) : (
                <Error404 page="Détails Finance"/>
            )}
        </Fragment>
    );
};

Financeexport.propTypes = {
    getColisByIds1: PropTypes.func.isRequired,
    getCurrentFournisseur: PropTypes.func.isRequired,
    getFinances: PropTypes.func.isRequired,
    colisData: PropTypes.object.isRequired,
    fournisseurData: PropTypes.object.isRequired,
    finance: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    colisData: state.reducerc,
    finance: state.reducerfin,
    fournisseurData: state.reducerf,
});

export default connect(mapStateToProps, {
    getCurrentFournisseur,
    getColisByIds1,
    getFinances,
})(withRouter(Financeexport));
