import { v4 } from "uuid";
import {
  SET_ALERT,
  REMOVE_ALERT,
  SET_ALERTS,
  REMOVE_ALERTS,
} from "../actionTypes";

export const setAlertD =
  (msg, alertType, timeout = 30000) =>
  (dispatch) => {
    const id = v4();
    dispatch({
      type: SET_ALERTS,
      payload: { msg, alertType, id },
    });

    setTimeout(() => dispatch({ type: REMOVE_ALERTS, payload: id }), timeout);
  };
export const setAlert =
  (msg, alertType, timeout = 5000) =>
  (dispatch) => {
    const id = v4();
    dispatch({
      type: SET_ALERT,
      payload: { msg, alertType, id },
    });

    setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
  };
