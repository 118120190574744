import React, {Fragment} from "react";
import sad from "../../assets/images/other-images/sad.png";
import {Container, Button, Media, Col} from "reactstrap";

const Error400 = () => {
    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="error-wrapper">
                    <Container>
                        <Media body className="img-100" src={sad} alt=""/>
                        <div className="error-heading">
                            <h2 className="headline font-info">{"Oops!"}</h2>
                        </div>
                        <Col md="8 offset-md-2">
                            <p className="sub-content">
                                {
                                    "Nous avons rencontrer un problème inattendu, cliquez sur actualiser."
                                }
                            </p>
                        </Col>

                            <Button color="info-gradien" size="lg" onClick={() => window.location.reload()}>
                                {"Actualiser"}
                            </Button>

                    </Container>
                </div>
            </div>
        </Fragment>
    );
};

export default Error400;
