import {useReducer} from "react";
import {settings} from "./config";
import AppContext from "./context/Context";
import {getItemFromStore} from "./helpers/utils";
import {configReducer} from "./reducers/configReducer";
import PropTypes from "prop-types";

const Main = props => {
  const configState = {
    isAuthenticated: getItemFromStore('isAuthenticated', settings.isAuthenticated),
    group: getItemFromStore('group', settings.group),
    refresh: getItemFromStore('refresh', settings.refresh),
    access: getItemFromStore('access', settings.access),
    avatar: getItemFromStore('avatar', settings.avatar),
    username: getItemFromStore('username', settings.username),
    accept_terms: getItemFromStore('accept_terms', settings.accept_terms),
    fournisseur: getItemFromStore('fournisseur', settings.fournisseur),
    stock_service: getItemFromStore('stock_service', settings.stock_service),
    user_id: getItemFromStore('user_id', JSON.stringify(settings.user_id)),
    disabledNavbarPosition: [],
    navbarStyle: getItemFromStore('navbarStyle', settings.navbarStyle),
    showSettingPanel: false,
    navbarCollapsed: false
  };
  const [config, configDispatch] = useReducer(configReducer, configState);

  const setConfig = (key, value) => {
    configDispatch({
      type: 'SET_CONFIG',
      payload: {
        key,
        value,
        setInStore: [
          'isAuthenticated',
          'user_id',
          'group',
          'avatar',
          'refresh',
          'username',
          'accept_terms',
          'fournisseur',
          'stock_service',
          'access',
          'navbarStyle'
        ].includes(key)
      }
    });
  };

  return (
    <AppContext.Provider value={{ config, setConfig, configDispatch }}>
      {props.children}
    </AppContext.Provider>
  );
};

Main.propTypes = { children: PropTypes.node };

export default Main;