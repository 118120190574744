import axios from "axios";
console.log("API URL", process.env.REACT_APP_API_URL)
export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const setAuthToken = (access) => {
  if (access) {
    api.defaults.headers.common["Authorization"] = "JWT " + access;
  } else {
    delete api.defaults.headers.common["Authorization"];
  }
};
