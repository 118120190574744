import React, {
    Fragment,
    useState,
    useEffect,
    useMemo, useContext,
} from "react";
import PropTypes from "prop-types";
import Alert from "../../redux/Alert";
import {connect} from "react-redux";
import {Link, withRouter, useLocation, useParams} from "react-router-dom";
import {
    getVille,
    getCite,
    getDeligation,
    getLivreur,
    getadresseF,
    getVille1,
    getCite1,
    getVilleF,
    getCiteF,
    updatecolis,
    getTracking2, getLivreurs,
} from "./action";
import {getFinances} from "../finance/action";
import {
    getCurrentFournisseur, getFournisseurs,
} from "../Fournisseurs/action";
import Breadcrumb from "../../layout/breadcrumb";

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Form,
    FormGroup,
    Label,
    Input,
    Button as B
} from "reactstrap";
import {useDispatch} from "react-redux";
import {
    addNewReclamation,
    getReponses,
} from "../reclamation/action";
import Error404 from "../../pages/errors/error404";
import Spinner from "./spinner.gif";
import {Field, Formik} from "formik";
import Select, {components} from "react-select";
import {api} from "../../utils/backend.instance";
import Button from "../../utils/Button";
import {getProduits} from "../stock/action";
import AppContext from "../../context/Context";

const Editcoli = ({
                      updatecolis,
                      history,
                      villeData,
                      citeDataf,
                      villeDataf,
                      financeData,
                      fournisseurData,
                      citeData,
                      deligationData,
                      stockData
                  }) => {
    const [stateville, setVille] = useState([
        {
            id: "",
            name: "",
        },
    ]);

    const [statecite, setCite] = useState([
        {
            id: "",
            name: "",
        },
    ]);
    const [statevillef, setVilleF] = useState([
        {
            id: "",
            name: "",
        },
    ]);

    const [statecitef, setCiteF] = useState([
        {
            id: "",
            name: "",
        },
    ]);
    const [statedelegation, setDelegation] = useState([
        {
            id: "",
            name: "",
        },
    ]);
    const [statefinance, setFinance] = useState([
        {
            id: "",
        },
    ]);
    const [livreur, setLivreur] = useState(null);
    const [currentPage, setCurrentPage] = useState(1)
    const [currentFournisseurPage, setCurrentFournisseurPage] = useState(1)
    const [livreurDatas, setLivreurData] = useState([])
    const [prix, setPrix] = useState([])
    const [totalPrice, setTotalPrice] = useState(0)
    const {
        config: {group, stock_service}
    } = useContext(AppContext);

    const {id} = useParams();


    let F = parseInt(localStorage.getItem("fournisseur"));
    const [formData, setFormData] = useState({
        client_telephone_1: "",
        client_telephone_2: "",
        client_email: "",
        client_prenom: "",
        client_name: "",
        client_zipcode: "",
        client_ville: "",
        client_deligaiton: "",
        client_cite: "",
        client_filiere: "",
        client_address: "",
        expiditeur_ville: "",
        expiditeur_cite: "",
        expiditeur_deligaiton: "",
        expiditeur_zipcode: "",
        livreur: "",
        fournisseur: "",
        prix: "",
        service: "",
        volume: "",
        remarque_echange: "",
        remarque: "",
        product_name: "",
        client_address_pickup: "",
        related_payment: "",
        products: [],
    });
    const [loading, setLoading] = useState(false)
    const [selectedfournisseur, setSelectedFournisseur] = useState([]);
    const [weight, ] = useState([])
    const [totalWeight, setTotalWeight] = useState(0)

    const [showLoader, setShowLoader] = useState(false)
    const [, setProducts] = useState([])

    const onChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const getCurrentColi = async () => {
        setLoading(true)
        await api.get(`/colis/colis/${id}/`)
            .then(res => {
                setFormData(res?.data)
                setSelectedFournisseur({label: res?.data?.fournisseur_name, value: res?.data?.fournisseur})
                setLivreur({value: res?.data?.livreur, label: res?.data?.livreur_name})
                // setPrix(res?.data?.products?.map((item, index) => ({[index]: item.product.cod * item.quantity})))
                // setWeight(res?.data?.products?.map((item, index) => ({[index]: item.product.weight * item.quantity})))
                setPrix(res?.data?.prix)
                setTotalPrice(res?.data?.prix)
                getFournisseur(res?.data?.fournisseur)
            })
        setLoading(false)
    }

    const handleSubmit = async (e, values) => {
        setShowLoader(true)
        console.log(values)
        e.preventDefault();
        await updatecolis({...values, prix: totalPrice}, id, history, true);
        setShowLoader(false)
    };
    const dispatch = useDispatch();

    const [taptopStyle, setTapTopStyle] = useState("none");

    const handleScroll = () => {
        if (window.scrollY > 600) {
            setTapTopStyle("block");
        } else {
            setTapTopStyle("none");
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        handleScroll();
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [taptopStyle]);

    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };

    let query = useQuery();
    let productQuery = useQuery()

    const onChangeDeligation = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
        query.set("gouvernat_id", e.target.value);
        dispatch(getVille(query));
    };

    const onChangeDeligationF = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
        query.set("gouvernat_id", e.target.value);
        dispatch(getVilleF(query));
    };

    const onChangeVille = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});

        query.set("ville_id", e.target.value);
        dispatch(getCite(query));
    };

    const onChangeVilleF = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});

        query.set("ville_id", e.target.value);
        dispatch(getCiteF(query));
    };
    useEffect(() => {
        getCurrentColi(id)
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getProduits(query)
            .then(res => setProducts(res?.results))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getFournisseur = async (id) => {
        setLoading(true)
        await api.get(`/fournisseur/fournisseur/${id}/`)
            .then(res => {
                setFormData({
                    ...formData,
                    fournisseur: id,
                    expiditeur_deligaiton: res?.data?.delegation,
                    expiditeur_ville: res?.data?.ville,
                    expiditeur_cite: res?.data?.cite,
                    expiditeur_zipcode: res?.data?.code_postale,
                    client_address_pickup: res?.data?.absolute_address,
                    petit_livraison: res?.data?.petit_livraison,
                    grand_livraison: res?.data?.grand_livraison,
                })
            })
        setLoading(false)
    }
    //set Fournisseur
    const onChangeFournisseur = (e) => {
        setSelectedFournisseur(e);
    };

    useEffect(() => {
        if (F) {
            setFormData({...formData, fournisseur: F});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //get ville
    useEffect(() => {
        dispatch(getVille(query));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        dispatch(getVille1());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setVille(villeData.villes);
    }, [villeData.villes, villeData.loading, stateville]);

    useEffect(() => {
        setVilleF(villeDataf.villeF);
    }, [villeDataf.villeF, villeDataf.loading, statevillef]);
    //get fournisseur
    useEffect(() => {
        query.set("page_size", "50")
        query.set("page", currentFournisseurPage)
        dispatch(getFournisseurs(query))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFournisseurPage]);

    //get deligation
    useEffect(() => {
        dispatch(getDeligation());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setDelegation(deligationData.deligations);
    }, [deligationData.deligations, deligationData.loading, statedelegation]);
    // get cite
    useEffect(() => {
        dispatch(getCite(query));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        dispatch(getCite1());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setCite(citeData.cites);
    }, [citeData.cites, citeData.loading, statecite]);
    useEffect(() => {
        setCiteF(citeDataf.citesF);
    }, [citeDataf.citesF, citeDataf.loading, statecitef]);

    useEffect(() => {
        query.set("page_size", "50")
        query.set("page", currentPage)
        getLivreurs(query)
            .then(res => setLivreurData([...livreurDatas, ...res?.results]))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    //get Finance
    useEffect(() => {
        dispatch(getFinances(query));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setFinance(financeData.finances);
    }, [financeData.finances, financeData.loading, statefinance]);
    //get adresse

    useEffect(() => {
        if (F) {
            dispatch(getadresseF(F));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //get livreur
    useEffect(() => {
        query.set("page_size", "50")
        query.set("page", currentPage)
        getLivreurs(query)
            .then(res => setLivreurData([...livreurDatas, ...res?.results]))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    let user_groups_name = group;
    let stock = stock_service;

    const NoOptionsMessage = props => {
        return (
            <components.NoOptionsMessage {...props}>
                <div className="text-center">
                    <img src={Spinner} style={{width: "10%"}} alt=""/>
                </div>
            </components.NoOptionsMessage>
        );
    };

    const data = [];
    fournisseurData &&
    fournisseurData.fournisseurs.map((F) =>
        data.push({
            value: parseInt(F.id),
            label: F.name,
        })
    );

    const onChangeLivreur = (e) => {
        setLoading(true)
        setLivreur(e);
        setFormData({...formData, livreur: e.value});
        setLoading(false)
    };

    let livreurOptions;

    livreurOptions = livreurDatas.map((livreur) => ({
        value: livreur.id,
        label: livreur.name
    }));

// Add the reset option
    livreurOptions = [
        {value: "", label: "------------------"},
        ...livreurOptions
    ];

    useEffect(() => {
        console.log(prix)
        if (prix.length > 0) {
            let total = 0
            // eslint-disable-next-line array-callback-return
            prix.map((p) => {
                for (const [, value] of Object.entries(p)) {
                    total += value
                }
            })
            setTotalPrice(total + (totalWeight > 10 ? formData.grand_livraison : formData.petit_livraison))
        }
    }, [formData.grand_livraison, formData.petit_livraison, prix, totalWeight])

    useEffect(() => {
        console.log(weight)
        if (weight.length > 0) {
            let total = 0
            // eslint-disable-next-line array-callback-return
            weight.map((p) => {
                for (const [, value] of Object.entries(p)) {
                    total += value
                }
            })
            setTotalWeight(total)
        }
    }, [weight])

    return (
        <Fragment>
            <Breadcrumb parent="Colis" title="Modifier colis"/>
            {user_groups_name && user_groups_name.indexOf("admin") !== -1 ? (
                <div>
                    {loading ?
                        <img src={Spinner} style={{width: "10%", position: "relative", left: "45%"}} alt=""/> :
                        <Container fluid={true}>
                            <Formik
                                initialValues={formData}
                                onSubmit={(values) => {
                                    console.log(values)
                                }}
                            >
                                {({values, setFieldValue}) => (
                                    <Form className="theme-form">
                                        <Alert/>
                                        <Row>
                                            <Col sm="7">
                                                {user_groups_name && user_groups_name.indexOf("admin") !== -1 ? (
                                                    <Card>
                                                        <CardHeader>
                                                            <h5> Details Fournisseur</h5>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <Row>
                                                                <Col>
                                                                    <FormGroup>
                                                                        <Label>Fournisseur</Label>
                                                                        <Field
                                                                            name={"fournisseur"}
                                                                            className={"form-control"}
                                                                        >
                                                                            {({field, form}) => (
                                                                                <Select
                                                                                    options={data}
                                                                                    components={{NoOptionsMessage}}
                                                                                    onChange={(e) => {
                                                                                        onChangeFournisseur(e);
                                                                                        getFournisseur(e.value)
                                                                                            .then(res => {
                                                                                                setFieldValue("client_address_pickup", res?.client_address_pickup)
                                                                                                setFieldValue("expiditeur_zipcode", res?.expiditeur_zipcode)
                                                                                                setFieldValue("expiditeur_deligaiton", res?.expiditeur_deligaiton)
                                                                                                setFieldValue("expiditeur_ville", res?.expiditeur_ville)
                                                                                                setFieldValue("expiditeur_cite", res?.expiditeur_cite)
                                                                                                productQuery.set("fournisseur_id", e.value)
                                                                                                getProduits(productQuery)
                                                                                                    .then(r => setProducts(r?.results))
                                                                                            })
                                                                                        setFieldValue(field.name, e.value)
                                                                                    }}
                                                                                    onInputChange={(e) => {
                                                                                        query.set('search', e);
                                                                                        dispatch(getFournisseurs(query))
                                                                                    }}
                                                                                    placeholder={"Fournisseur"}
                                                                                    onMenuScrollToBottom={(e) => {
                                                                                        console.log(e)
                                                                                        setCurrentFournisseurPage(page => page + 1)
                                                                                    }}
                                                                                    value={selectedfournisseur}
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <FormGroup>
                                                                        <Label>Adresse pickup</Label>
                                                                        <Field
                                                                            name={"client_address_pickup"}
                                                                            className={"form-control"}
                                                                        >
                                                                            {({field, form}) => (
                                                                                <Input
                                                                                    id="address_pickup"
                                                                                    maxLength={100}
                                                                                    type="text"
                                                                                    placeholder={"Adresse Pickup"}
                                                                                    value={field.value}
                                                                                    onChange={(e) => {
                                                                                        onChange(e)
                                                                                        setFieldValue(field.name, e.target.value)
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col>
                                                                    <FormGroup>
                                                                        <Label>Code postale expéditeur</Label>
                                                                        <Field
                                                                            name={"expiditeur_zipcode"}
                                                                            className={"form-control"}
                                                                        >
                                                                            {({field, form}) => (
                                                                                <Input
                                                                                    maxLength={100}
                                                                                    type="text"
                                                                                    placeholder={"Code postale"}
                                                                                    value={field.value}
                                                                                    onChange={(e) => {
                                                                                        onChange(e)
                                                                                        setFieldValue(field.name, e.target.value)
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <FormGroup>
                                                                        <Label>Délégation expéditeur</Label>
                                                                        <Field
                                                                            name={"expiditeur_deligaiton"}
                                                                            className={"form-control"}
                                                                        >
                                                                            {({field, form}) => (
                                                                                <select
                                                                                    className="form-control digits"
                                                                                    value={field.value}
                                                                                    onChange={(e) => {
                                                                                        onChangeDeligationF(e)
                                                                                        setFieldValue(field.name, e.target.value)
                                                                                    }}
                                                                                >
                                                                                    <option value="" disabled
                                                                                            defaultValue hidden>
                                                                                        ----------
                                                                                    </option>
                                                                                    {deligationData &&
                                                                                        deligationData.deligations.map((delegation) => (
                                                                                            <option
                                                                                                key={delegation.id}
                                                                                                value={delegation.id}
                                                                                            >
                                                                                                {delegation.name}
                                                                                            </option>
                                                                                        ))}
                                                                                </select>
                                                                            )}
                                                                        </Field>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <FormGroup>
                                                                        <Label>Ville expéditeur</Label>
                                                                        <Field
                                                                            name={"expiditeur_ville"}
                                                                            className={"form-control"}
                                                                        >
                                                                            {({field, form}) => (
                                                                                <select
                                                                                    className="form-control digits"
                                                                                    value={field.value}
                                                                                    onChange={(e) => {
                                                                                        onChangeVilleF(e)
                                                                                        setFieldValue(field.name, e.target.value)
                                                                                    }}
                                                                                >
                                                                                    {" "}
                                                                                    <option value="" disabled
                                                                                            defaultValue hidden>
                                                                                        ----------
                                                                                    </option>
                                                                                    {villeData &&
                                                                                        villeData.villes.map((ville) => (
                                                                                            <option key={ville.id}
                                                                                                    value={ville.id}>
                                                                                                {ville.name}
                                                                                            </option>
                                                                                        ))}
                                                                                </select>
                                                                            )}
                                                                        </Field>

                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <FormGroup>
                                                                        <Label>Cité expéditeur</Label>
                                                                        <Field
                                                                            name={"expiditeur_cite"}
                                                                            className={"form-control"}
                                                                        >
                                                                            {({field, form}) => (
                                                                                <select
                                                                                    className="form-control digits"
                                                                                    value={field.value}
                                                                                    onChange={(e) => {
                                                                                        onChange(e)
                                                                                        setFieldValue(field.name, e.target.value)
                                                                                    }}
                                                                                >
                                                                                    <option value="" disabled
                                                                                            defaultValue hidden>
                                                                                        ----------
                                                                                    </option>
                                                                                    {citeData &&
                                                                                        citeData.cites.map((cite) => (
                                                                                            <option key={cite.id}
                                                                                                    value={cite.id}>
                                                                                                {cite.name}
                                                                                            </option>
                                                                                        ))}
                                                                                </select>
                                                                            )}
                                                                        </Field>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                ) : (
                                                    ""
                                                )}
                                                <Card>
                                                    <CardHeader>
                                                        <h5>Détails Client</h5>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label>Nom et Prénom (*)</Label>
                                                                    <Field name={"client_name"}>
                                                                        {({field, form}) => (
                                                                            <Input
                                                                                className="form-control"
                                                                                type="text"
                                                                                required
                                                                                placeholder="Nom et Prenom"
                                                                                value={field.value}
                                                                                onChange={(e) => {
                                                                                    onChange(e);
                                                                                    setFieldValue(field.name, e.target.value)
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label>Adresse client (*)</Label>
                                                                    <Field name={"client_address"}>
                                                                        {({field, form}) => (
                                                                            <Input
                                                                                className="form-control"
                                                                                type="text"
                                                                                required
                                                                                maxLength={100}
                                                                                placeholder="Adresse"
                                                                                value={field.value}
                                                                                onChange={(e) => {
                                                                                    onChange(e)
                                                                                    setFieldValue(field.name, e.target.value)
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label>Email</Label>
                                                                    <Field name={"client_email"}>
                                                                        {({field, form}) => (
                                                                            <Input
                                                                                className="form-control"
                                                                                type="text"
                                                                                placeholder="Email"
                                                                                value={field.value}
                                                                                onChange={(e) => {
                                                                                    onChange(e)
                                                                                    setFieldValue(field.name, e.target.value)
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label>Délégation client (*)</Label>
                                                                    <Field name={"client_deligaiton"}>
                                                                        {({field, form}) => (
                                                                            <select
                                                                                className="form-control digits"
                                                                                id="delegation"
                                                                                required
                                                                                value={field.value}
                                                                                onChange={(e) => {
                                                                                    onChangeDeligation(e)
                                                                                    setFieldValue(field.name, e.target.value)
                                                                                }}
                                                                            >
                                                                                <option value="" disabled defaultValue
                                                                                        hidden>
                                                                                    ----------
                                                                                </option>
                                                                                {deligationData &&
                                                                                    deligationData.deligations.map((delegation) => (
                                                                                        <option key={delegation.id}
                                                                                                value={delegation.id}>
                                                                                            {delegation.name}
                                                                                        </option>
                                                                                    ))}
                                                                            </select>
                                                                        )}
                                                                    </Field>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label>Téléphone (*)</Label>
                                                                    <Field name={"client_telephone_1"}>
                                                                        {({field, form}) => (
                                                                            <Input
                                                                                className="form-control"
                                                                                type="text"
                                                                                required
                                                                                name="client_telephone_1"
                                                                                placeholder="Téléphone 1"
                                                                                value={field.value}
                                                                                onChange={(e) => {
                                                                                    onChange(e)
                                                                                    setFieldValue(field.name, e.target.value)
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label>Ville client (*)</Label>
                                                                    <Field name={"client_ville"}>
                                                                        {({field, form}) => (
                                                                            <select
                                                                                className="form-control digits"
                                                                                id="ville"
                                                                                required
                                                                                value={field.value}
                                                                                onChange={(e) => {
                                                                                    onChangeVille(e)
                                                                                    setFieldValue(field.name, e.target.value)
                                                                                }}
                                                                            >
                                                                                {" "}
                                                                                <option value="" disabled defaultValue
                                                                                        hidden>
                                                                                    ----------
                                                                                </option>
                                                                                {villeDataf &&
                                                                                    villeDataf.villeF.map((ville) => (
                                                                                        <option key={ville.id}
                                                                                                value={ville.id}>
                                                                                            {ville.name}
                                                                                        </option>
                                                                                    ))}
                                                                            </select>
                                                                        )}
                                                                    </Field>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label>Téléphone 2</Label>
                                                                    <Field name={"client_telephone_2"}>
                                                                        {({field, form}) => (
                                                                            <Input
                                                                                className="form-control"
                                                                                type="text"
                                                                                name="client_telephone_2"
                                                                                placeholder="Téléphone 2"
                                                                                value={field.value}
                                                                                onChange={(e) => {
                                                                                    onChange(e)
                                                                                    setFieldValue(field.name, e.target.value)
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label>Cité client (*)</Label>
                                                                    <Field name={"client_cite"}>
                                                                        {({field, form}) => (
                                                                            <select
                                                                                className="form-control digits"
                                                                                id="cite"
                                                                                required
                                                                                value={field.value}
                                                                                onChange={(e) => {
                                                                                    onChange(e)
                                                                                    setFieldValue(field.name, e.target.value)
                                                                                }}
                                                                            >
                                                                                <option value="" disabled defaultValue
                                                                                        hidden>
                                                                                    ----------
                                                                                </option>
                                                                                {citeDataf &&
                                                                                    citeDataf.citesF.map((cite) => (
                                                                                        <option key={cite.id}
                                                                                                value={cite.id}>
                                                                                            {cite.name}
                                                                                        </option>
                                                                                    ))}
                                                                            </select>
                                                                        )}
                                                                    </Field>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>

                                            <Col sm="5">
                                                <Card>
                                                    <CardHeader>
                                                        <h5>Détails Colis</h5>
                                                    </CardHeader>
                                                    <CardBody>
                                                        {user_groups_name.indexOf("admin") === -1 || !stock ?
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label>Produit (*)</Label>
                                                                    <Field name={"product_name"}>
                                                                        {({field, form}) => (
                                                                            <Input
                                                                                className="form-control"
                                                                                type="text"
                                                                                maxLength={255}
                                                                                required
                                                                                name="product_name"
                                                                                placeholder="product"
                                                                                value={field.value}
                                                                                onChange={(e) => {
                                                                                    onChange(e)
                                                                                    setFieldValue(field.name, e.target.value)
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </FormGroup>
                                                            </Col> : ""
                                                        }
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label>Prix <span className={"text-muted"}>Avec frais de livraison</span> (*)</Label>
                                                                    <Field name={"prix"}>
                                                                        {({field, form}) => (
                                                                            <Input
                                                                                className="form-control"
                                                                                required
                                                                                type="text"
                                                                                name="prix"
                                                                                placeholder="prix + frais de livraison"
                                                                                value={totalPrice}
                                                                                onChange={(e) => {
                                                                                    setTotalPrice(e.target.value)
                                                                                    onChange(e)
                                                                                    setFieldValue(field.name, e.target.value)
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </FormGroup>
                                                            </Col>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label>Volume (*)</Label>
                                                                <br/>
                                                                <Field name={"volume"}>
                                                                    {({field, form}) => (
                                                                        <Row>
                                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <Col>
                                                                                <Input
                                                                                    type="radio"
                                                                                    checked={field.value === 1}
                                                                                    value={1}
                                                                                    name="volume"
                                                                                    onChange={(e) => {
                                                                                        onChange(e)
                                                                                        setFieldValue(field.name, 1)
                                                                                    }}
                                                                                />
                                                                                Petit
                                                                            </Col>
                                                                            <Col>
                                                                                <Input
                                                                                    type="radio"
                                                                                    checked={field.value === 11}
                                                                                    value={11}
                                                                                    name="volume"
                                                                                    onChange={(e) => {
                                                                                        onChange(e)
                                                                                        setFieldValue(field.name, 11)
                                                                                    }}
                                                                                />
                                                                                Grand
                                                                            </Col>
                                                                        </Row>
                                                                    )}
                                                                </Field>

                                                            </FormGroup>
                                                        </Col>

                                                        <Col>
                                                            <FormGroup>
                                                                {/* eslint-disable-next-line no-mixed-operators */}
                                                                {user_groups_name &&
                                                                // eslint-disable-next-line no-mixed-operators
                                                                user_groups_name.indexOf("admin") !== -1 || user_groups_name && (user_groups_name.indexOf("fournisseur") !== -1 && stock.indexOf("true") !== -1) || user_groups_name && (user_groups_name.indexOf("personnel") !== -1 && stock.indexOf("true") !== -1) ? (
                                                                    <Label>Livreur</Label>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                {/* eslint-disable-next-line no-mixed-operators */}
                                                                {user_groups_name &&
                                                                // eslint-disable-next-line no-mixed-operators
                                                                user_groups_name.indexOf("admin") !== -1 || user_groups_name && (user_groups_name.indexOf("fournisseur") !== -1 && stock.indexOf("true") !== -1) || user_groups_name && (user_groups_name.indexOf("personnel") !== -1 && stock.indexOf("true") !== -1) ? (
                                                                    <Field name={"livreur"}>
                                                                        {({field, form}) => (
                                                                            <Select
                                                                                placeholder={"Livreur"}
                                                                                options={livreurOptions}
                                                                                onChange={(e) => {
                                                                                    onChangeLivreur(e)
                                                                                    setFieldValue(field.name, e.value)
                                                                                }}
                                                                                value={livreur}
                                                                                name={'livreur'}
                                                                                className="m-2"
                                                                                onInputChange={(e) => {
                                                                                    query.set('search', e);
                                                                                    getLivreurs(query);
                                                                                }}
                                                                                onMenuScrollToBottom={(e) => {
                                                                                    console.log(e)
                                                                                    setCurrentPage(page => page + 1)
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </FormGroup>
                                                        </Col>

                                                        {user_groups_name &&
                                                        user_groups_name.indexOf("admin") !== -1 ? (
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label>Related payment</Label>
                                                                    <Field name={"related_payment"}>
                                                                        {({field, form}) => (
                                                                            <select
                                                                                className="form-control"
                                                                                name="related_payment"
                                                                                value={field.value}
                                                                                onChange={(e) => {
                                                                                    onChange(e)
                                                                                    setFieldValue(field.name, e.target.value)
                                                                                }}
                                                                            >
                                                                                <option value="" disabled defaultValue
                                                                                        hidden>
                                                                                    ----------
                                                                                </option>
                                                                                {financeData &&
                                                                                    financeData.finances.map((f) => (
                                                                                        <option key={f.id} value={f.id}>
                                                                                            {f.id}
                                                                                        </option>
                                                                                    ))}
                                                                            </select>
                                                                        )}
                                                                    </Field>
                                                                </FormGroup>
                                                            </Col>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <Col>
                                                            <FormGroup>
                                                                <Label>Service (*)</Label>
                                                                <br/>
                                                                <Field name={"service"}>
                                                                    {({field, form}) => (
                                                                        <Row>
                                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <Col>
                                                                                <Input
                                                                                    type="radio"
                                                                                    checked={field.value === "livraison"}
                                                                                    value="livraison"
                                                                                    name="service"
                                                                                    onChange={(e) => {
                                                                                        onChange(e)
                                                                                        setFieldValue(field.name, "livraison")
                                                                                    }}
                                                                                />
                                                                                Livraison
                                                                            </Col>
                                                                            <Col>
                                                                                <Input
                                                                                    type="radio"
                                                                                    checked={field.value === "echange"}
                                                                                    value="echange"
                                                                                    name="service"
                                                                                    onChange={(e) => {
                                                                                        onChange(e)
                                                                                        setFieldValue(field.name, "echange")
                                                                                    }}
                                                                                />
                                                                                Echange
                                                                            </Col>
                                                                        </Row>
                                                                    )}
                                                                </Field>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label hidden={values.service === "livraison"}>
                                                                    Remarque echange
                                                                </Label>
                                                                <Field name={"remarque_echange"}>
                                                                    {({field, form}) => (
                                                                        <Input
                                                                            hidden={field.value === "livraison"}
                                                                            className="form-control"
                                                                            type="text"
                                                                            maxLength={100}
                                                                            name="remarque_echange"
                                                                            placeholder="Description"
                                                                            value={field.value}
                                                                            onChange={(e) => {
                                                                                onChange(e)
                                                                                setFieldValue(field.name, e.target.value)
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Field>

                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label>Status (*)</Label>
                                                                <Field name={"status"}>
                                                                    {({field, form}) => (
                                                                        <select
                                                                            className="form-control"
                                                                            name="status"
                                                                            placeholder="Status"
                                                                            value={field.value}
                                                                            onChange={(e) => {
                                                                                setFieldValue(field.name, e.target.value)
                                                                            }}
                                                                        >
                                                                            <option value="" disabled defaultValue
                                                                                    hidden>
                                                                                ----------
                                                                            </option>
                                                                            <option value="nouveau">Nouveau</option>
                                                                            <option value="payer">Payé</option>
                                                                            <option value="livrer">Livré</option>
                                                                            <option value="valider">Validé</option>
                                                                            <option value="non_ramasser">Non ramassé
                                                                            </option>
                                                                            <option value="anomalie">Anomalie</option>
                                                                            <option value="retour">Retour</option>
                                                                            <option value="en_cours_de_livraison">
                                                                                En cours de livraison
                                                                            </option>
                                                                            <option value="retour_aux_sources">
                                                                                Retour aux sources
                                                                            </option>
                                                                        </select>
                                                                    )}
                                                                </Field>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label>Remarque</Label>
                                                                <Field name={"remarque"}>
                                                                    {({field, form}) => (
                                                                        <Input
                                                                            className="form-control"
                                                                            type="text"
                                                                            maxLength={100}
                                                                            name="remarque"
                                                                            placeholder="remarque"
                                                                            value={field.value}
                                                                            onChange={(e) => {
                                                                                onChange(e)
                                                                                setFieldValue(field.name, e.target.value)
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </FormGroup>
                                                        </Col>
                                                    </CardBody>
                                                </Card>
                                            </Col>


                                        </Row>
                                        {/*{user_groups_name.indexOf("admin") !== -1 || stock === true ?*/}
                                        {/*    <Row>*/}
                                        {/*        <Col>*/}
                                        {/*            <Card>*/}
                                        {/*                <CardHeader>*/}
                                        {/*                    <Row>*/}
                                        {/*                        <Col sm={8}>*/}
                                        {/*                            <h5>Produits</h5>*/}
                                        {/*                        </Col>*/}
                                        {/*                        <Col sm={4}>*/}
                                        {/*                            <FormGroup>*/}
                                        {/*                                <Label>Prix <span*/}
                                        {/*                                    className={"text-muted fs--1"}>Avec frais de livraison</span> (*)</Label>*/}
                                        {/*                                <Field name={"prix"}>*/}
                                        {/*                                    {({field, form}) => (*/}
                                        {/*                                        <Input*/}
                                        {/*                                            className="form-control"*/}
                                        {/*                                            required*/}
                                        {/*                                            type="text"*/}
                                        {/*                                            name="prix"*/}
                                        {/*                                            placeholder="prix + frais de livraison"*/}
                                        {/*                                            value={totalPrice}*/}
                                        {/*                                            onChange={(e) => {*/}
                                        {/*                                                setTotalPrice(e.target.value)*/}
                                        {/*                                                onChange(e)*/}
                                        {/*                                                setFieldValue(field.name, e.target.value)*/}
                                        {/*                                            }}*/}
                                        {/*                                        />*/}
                                        {/*                                    )}*/}
                                        {/*                                </Field>*/}
                                        {/*                            </FormGroup>*/}
                                        {/*                        </Col>*/}
                                        {/*                    </Row>*/}
                                        {/*                </CardHeader>*/}
                                        {/*                <CardBody>*/}
                                        {/*                    <Row>*/}
                                        {/*                        <FieldArray*/}
                                        {/*                            name="products"*/}
                                        {/*                            render={arrayHelpers => (*/}
                                        {/*                                <>*/}
                                        {/*                                    <Table>*/}
                                        {/*                                        {values.products && values.products.length > 0 &&*/}
                                        {/*                                            <thead>*/}
                                        {/*                                            <tr>*/}
                                        {/*                                                <th>Ref</th>*/}
                                        {/*                                                <th>Image</th>*/}
                                        {/*                                                <th>Prix</th>*/}
                                        {/*                                                <th>Quantité</th>*/}
                                        {/*                                                <th></th>*/}
                                        {/*                                            </tr>*/}
                                        {/*                                            </thead>*/}
                                        {/*                                        }*/}
                                        {/*                                        <tbody>*/}
                                        {/*                                        {values.products && values.products.length > 0 ? (*/}
                                        {/*                                                values.products.map((product, index) => (*/}
                                        {/*                                                    <tr key={index}*/}
                                        {/*                                                        className={"mt-3"}>*/}
                                        {/*                                                        <td className={"align-items-center justify-content-center"}>{product.product ? product.product.reference : product.reference}</td>*/}
                                        {/*                                                        <td>*/}
                                        {/*                                                            <img*/}
                                        {/*                                                                src={product.product ? product.product.image : product.image}*/}
                                        {/*                                                                alt={product.product ? product.product.reference : product.reference}*/}
                                        {/*                                                                width={100}*/}
                                        {/*                                                                height={100}/>*/}
                                        {/*                                                        </td>*/}
                                        {/*                                                        <td>{product.product ? product.product.cod : product.cod}</td>*/}
                                        {/*                                                        <td>*/}
                                        {/*                                                            <Field*/}
                                        {/*                                                                name={`products.${index}.quantity`}*/}
                                        {/*                                                                placeholder={"Quantity"}*/}
                                        {/*                                                            >*/}
                                        {/*                                                                {({*/}
                                        {/*                                                                      field,*/}
                                        {/*                                                                      form*/}
                                        {/*                                                                  }) => (*/}
                                        {/*                                                                    <FormControl*/}
                                        {/*                                                                        type={"number"}*/}
                                        {/*                                                                        min={0}*/}
                                        {/*                                                                        onChange={(e) => {*/}
                                        {/*                                                                            form.handleChange(e);*/}
                                        {/*                                                                            setFieldValue(field.name, e.target.value);*/}
                                        {/*                                                                            const productPrice = product.product ? product.product.cod : product.cod*/}
                                        {/*                                                                            const newPrixValue = productPrice * e.target.value;*/}

                                        {/*                                                                            // Update the prix array*/}
                                        {/*                                                                            setPrix((prevPrix) => {*/}
                                        {/*                                                                                const newPrix = [...prevPrix]; // clone the previous prix array*/}
                                        {/*                                                                                const existingIndex = newPrix.findIndex(p => p[index] !== undefined);*/}

                                        {/*                                                                                if (existingIndex > -1) {*/}
                                        {/*                                                                                    newPrix[existingIndex] = {[index]: newPrixValue}; // update the existing object*/}
                                        {/*                                                                                } else {*/}
                                        {/*                                                                                    newPrix.push({[index]: newPrixValue}); // add a new object if it doesn't exist*/}
                                        {/*                                                                                }*/}

                                        {/*                                                                                return newPrix;*/}
                                        {/*                                                                            });*/}
                                        {/*                                                                            const newWeightValue = (product.product ? product.product.weight : product.weight) * e.target.value;*/}

                                        {/*                                                                            // Update the weight array*/}
                                        {/*                                                                            setWeight((prevWeight) => {*/}
                                        {/*                                                                                const newWeight = [...prevWeight]; // clone the previous prix array*/}
                                        {/*                                                                                const existingIndex = newWeight.findIndex(p => p[index] !== undefined);*/}

                                        {/*                                                                                if (existingIndex > -1) {*/}
                                        {/*                                                                                    newWeight[existingIndex] = {[index]: newWeightValue}; // update the existing object*/}
                                        {/*                                                                                } else {*/}
                                        {/*                                                                                    newWeight.push({[index]: newWeightValue}); // add a new object if it doesn't exist*/}
                                        {/*                                                                                }*/}

                                        {/*                                                                                return newWeight;*/}
                                        {/*                                                                            });*/}
                                        {/*                                                                        }}*/}
                                        {/*                                                                        value={field.value}*/}
                                        {/*                                                                    />*/}
                                        {/*                                                                )}*/}
                                        {/*                                                            </Field>*/}
                                        {/*                                                        </td>*/}
                                        {/*                                                        <td>*/}
                                        {/*                                                            <MinusCircle*/}
                                        {/*                                                                style={{cursor: "pointer"}}*/}
                                        {/*                                                                onClick={() => {*/}
                                        {/*                                                                    arrayHelpers.remove(index)*/}
                                        {/*                                                                    const productPrice = product.product ? product.product.cod : product.cod*/}
                                        {/*                                                                    setPrix(prevState => prevState.filter(p => product.quantity * productPrice !== p[index]))*/}
                                        {/*                                                                    setWeight(prevState => prevState.filter(p => product.quantity * (product.product ? product.product.weight : product.weight) !== p[index]))*/}
                                        {/*                                                                }}/>*/}
                                        {/*                                                        </td>*/}
                                        {/*                                                    </tr>*/}
                                        {/*                                                ))*/}
                                        {/*                                            )*/}
                                        {/*                                            : ""*/}
                                        {/*                                        }*/}
                                        {/*                                        </tbody>*/}
                                        {/*                                    </Table>*/}
                                        {/*                                    <Row*/}
                                        {/*                                        className={"d-flex flex-wrap justify-content-center"}>*/}
                                        {/*                                        <Col md={12}>*/}
                                        {/*                                            <button*/}
                                        {/*                                                type="button"*/}
                                        {/*                                                className="btn text-white ml-2"*/}
                                        {/*                                                style={{backgroundColor: "#0d9a0d"}}*/}
                                        {/*                                                onClick={toggleModal}*/}
                                        {/*                                            >*/}
                                        {/*                                                Ajouter produit*/}
                                        {/*                                            </button>*/}
                                        {/*                                        </Col>*/}

                                        {/*                                    </Row>*/}
                                        {/*                                    <Modal isOpen={modal2} toggle={toggleModal}*/}
                                        {/*                                           centered*/}
                                        {/*                                           size="lg">*/}
                                        {/*                                        <ModalHeader*/}
                                        {/*                                            style={{*/}
                                        {/*                                                backgroundImage:*/}
                                        {/*                                                    "linear-gradient(to right,#FFD166, #133A4A  )",*/}
                                        {/*                                            }}*/}
                                        {/*                                            className="p-3 text-white"*/}
                                        {/*                                            toggle={toggleModal}>Ajouter</ModalHeader>*/}
                                        {/*                                        <ModalBody>*/}
                                        {/*                                            <div>*/}
                                        {/*                                                <Label*/}
                                        {/*                                                    htmlFor="filter">Filtre:</Label>*/}
                                        {/*                                                <Input type="search" id="filter"*/}
                                        {/*                                                       value={searchValue}*/}
                                        {/*                                                       autoFocus*/}
                                        {/*                                                       onChange={(e) => {*/}
                                        {/*                                                           setSearchValue(e.target.value)*/}
                                        {/*                                                           query.set("search", e.target.value)*/}
                                        {/*                                                           delayedLoadProducts(query).then(r => setProducts(r?.results))*/}
                                        {/*                                                       }}/>*/}
                                        {/*                                            </div>*/}
                                        {/*                                            <Table>*/}
                                        {/*                                                <thead>*/}
                                        {/*                                                <tr>*/}
                                        {/*                                                    <th>Ref</th>*/}
                                        {/*                                                    <th>Image</th>*/}
                                        {/*                                                    <th>Prix</th>*/}
                                        {/*                                                    <th>Disponible</th>*/}
                                        {/*                                                    <th>Etage</th>*/}
                                        {/*                                                    <th>Quantité</th>*/}
                                        {/*                                                </tr>*/}
                                        {/*                                                </thead>*/}
                                        {/*                                                <tbody>*/}
                                        {/*                                                {products?.map((product, index) => (*/}
                                        {/*                                                    <tr key={product.reference}>*/}
                                        {/*                                                        <td>{product.reference}</td>*/}
                                        {/*                                                        <td>*/}
                                        {/*                                                            <img*/}
                                        {/*                                                                src={product.image}*/}
                                        {/*                                                                width={50}*/}
                                        {/*                                                                height={50}*/}
                                        {/*                                                                alt={product.reference}/>*/}
                                        {/*                                                        </td>*/}
                                        {/*                                                        <td>{product.cod}</td>*/}
                                        {/*                                                        <td>{product.stock > 0 ? 'Disponible' : 'Non disponible'}</td>*/}
                                        {/*                                                        <td>{product.etage}</td>*/}
                                        {/*                                                        <td>{product.stock}</td>*/}
                                        {/*                                                        <td>*/}
                                        {/*                                                            <PlusCircle*/}
                                        {/*                                                                onClick={() => {*/}
                                        {/*                                                                    arrayHelpers.insert(values.products.length, product)*/}
                                        {/*                                                                    setFieldValue("prix", product.cod)*/}
                                        {/*                                                                    setModal2(false)*/}
                                        {/*                                                                }}*/}
                                        {/*                                                                style={{cursor: "pointer"}}*/}
                                        {/*                                                            />*/}
                                        {/*                                                        </td>*/}
                                        {/*                                                    </tr>*/}
                                        {/*                                                ))}*/}
                                        {/*                                                </tbody>*/}
                                        {/*                                            </Table>*/}
                                        {/*                                        </ModalBody>*/}
                                        {/*                                    </Modal>*/}
                                        {/*                                </>*/}
                                        {/*                            )}*/}
                                        {/*                        />*/}
                                        {/*                    </Row>*/}
                                        {/*                </CardBody>*/}
                                        {/*            </Card>*/}
                                        {/*        </Col>*/}
                                        {/*    </Row> : ""*/}
                                        {/*}*/}
                                        <Row>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <Col>
                                                <FormGroup className="mb-0">
                                                    <Button
                                                        onClick={(e) => handleSubmit(e, values)}
                                                        disabled={showLoader}
                                                        className="btn btn-success mr-3"
                                                        color={"success"}
                                                        loading={showLoader}
                                                        text={"Enregistrer"}
                                                    />
                                                    <Link to={`/colis?created_at=today`}>
                                                        <B color="danger">Annuler</B>
                                                    </Link>
                                                    <br/>
                                                    <br/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </Container>}
                </div>
            ) : (
                <Error404 page="Modifier colis"/>
            )}
        </Fragment>
    );
};
Editcoli.propTypes = {
    getFournisseurs: PropTypes.func.isRequired,
    getVille: PropTypes.func.isRequired,
    getVille1: PropTypes.func.isRequired,
    getVilleF: PropTypes.func.isRequired,
    getCite: PropTypes.func.isRequired,
    updatecolis: PropTypes.func.isRequired,
    getCite1: PropTypes.func.isRequired,
    getCiteF: PropTypes.func.isRequired,
    getDeligation: PropTypes.func.isRequired,
    getLivreur: PropTypes.func.isRequired,
    getadresseF: PropTypes.func.isRequired,
    fournisseurData: PropTypes.object.isRequired,
    addNewcolis: PropTypes.func.isRequired,
    getCurrentFournisseur: PropTypes.func.isRequired,
    getReponses: PropTypes.func.isRequired,
    addNewReclamation: PropTypes.func.isRequired,
    getTracking2: PropTypes.func.isRequired,
    reclamationData: PropTypes.object.isRequired,
    addNewReponse: PropTypes.func.isRequired,
    reponseData: PropTypes.object.isRequired,
};

const mapstatetoprops = (state) => ({
    villeData: state.reducerc,
    villeDataf: state.reducerdvc,
    citeDataf: state.reducerdvc,
    fournisseurData: state.reducerf,
    financeData: state.reducerfin,
    citeData: state.reducerc,
    deligationData: state.reducerc,
    livreurData: state.reducerc,
    adresseData: state.reducerc,
    reclamationData: state.reducerr,
    reponseData: state.reducerr,
    trackingData: state.reducerc,
    stockData: state.reducerst
});

export default connect(mapstatetoprops, {
    updatecolis,
    getVille,
    getVille1,
    getVilleF,
    getCite,
    getCite1,
    getCiteF,
    getDeligation,
    addNewReclamation,
    getReponses,
    getTracking2,
    getFournisseurs,
    getFinances,
    getLivreur,
    getadresseF,
    getCurrentFournisseur,
    getProduits
})(withRouter(Editcoli));
