import React, {Fragment, useState} from "react";
import {Col, Form, Input, Label, Row} from "reactstrap";
import Button from "../../../utils/Button";
import {api} from "../../../utils/backend.instance";
import {useHistory} from "react-router-dom";

const Mapping = ({fileColumns = [], reportId}) => {
    const [mappedColumns, setMappedColumns] = useState([]);
    const [showLoader, setShowLoader] = useState(false)

    const history = useHistory()

    const handleMappingChange = (dbField, e) => {
        setMappedColumns(prevState => (
            [
                ...prevState,
                {
                    header_field: e.target.value,
                    invoice_field: dbField.value,
                    finance_file: reportId,
                }
            ]
        ));
    }
    const submitFun = async () => {
        setShowLoader(true)
        console.log(mappedColumns);
        mappedColumns?.map(async (column) => {
            await api.post("/fournisseur/import-mapping/", column)
        })
        await api.patch(`/fournisseur/import-frais/${reportId}/`, {submitted: true})
        history.push('/import-frais-livreurs')
    }

    const dbFields = [
        {label: "Code de suivi", value: "tracking_code"},
        {label: "Frais livreur", value: "carrier_fee"}
    ];

    return (
        <Fragment>
            <Row>
                <Col sm="12">
                    <Form className="needs-validation">
                        {dbFields.map(dbField => (
                            <Row form key={dbField}>
                                <Col md="12 mb-3">
                                    <Label>{dbField.label}</Label>
                                    <Input type="select" onChange={e => handleMappingChange(dbField, e)}>
                                        <option value="">Selectionner une colonne</option>
                                        {fileColumns.map(column => (
                                            <option key={column} value={column}>
                                                {column}
                                            </option>
                                        ))}
                                    </Input>
                                    <div className="valid-feedback">{"Looks good!"}</div>
                                </Col>
                            </Row>
                        ))}
                        <Button
                            onClick={submitFun}
                            disabled={showLoader}
                            className="btn btn-success mr-3 pull-right"
                            color={"success"}
                            loading={showLoader}
                            text={"Importer"}
                        />
                    </Form>
                </Col>
            </Row>
        </Fragment>
    )
}

export default Mapping