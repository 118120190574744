import React, {useState} from 'react';
import {
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Table, Col,
} from "reactstrap";
import Spinner from "../../components/colis/spinner.gif";
import {withRouter} from "react-router-dom";
import {getCurrentNew, getUnseenNews} from "../../components/news/actions";
import PropTypes from "prop-types";
import {connect, useDispatch} from "react-redux";
import {updatecompte} from "../../components/compte/action";

const NewsPopup = ({unseenNews, new: {neww, loading}, updatecompte}) => {

    const [modalShow, setModalShow] = useState(false);
    const [modalDetail, setModalDetail] = useState(false)
    const [id, setId] = useState("")
    const user_id = localStorage.getItem("user_id")

    const dispatch = useDispatch()

    React.useEffect(() => {
        if (unseenNews && unseenNews.news.length > 0) {
            setModalShow(true);
        }
    }, [unseenNews]);
    return (
        <>
            {modalShow && (
                <Modal
                    centered
                    className="relative min-h-screen flex flex-col sm:justify-center items-center"
                    isOpen={modalShow}>
                    <ModalHeader
                        style={{
                            backgroundImage:
                                "linear-gradient(to right,#FFD166, #133A4A  )",
                        }}
                        className="p-3 text-white"
                    >
                        New updates available
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <h4>You have {unseenNews.news.length} unseen news.</h4>
                        </FormGroup>
                        <Col sm="12">

                            <div className="table-responsive">
                                {unseenNews && unseenNews.loading ? (
                                    <div className="text-center">
                                        <img src={Spinner} style={{width: "10%"}}
                                             alt=""/>
                                    </div>
                                ) : (
                                    <Table striped>
                                        <thead>
                                        <tr>
                                            <th>{"Titre"}</th>
                                            <th>{"Action"} </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {unseenNews &&
                                            unseenNews.news.map((b) => (
                                                <tr key={b.id}>
                                                    <td>
                                                        {b.title}
                                                    </td>
                                                    <td>
                                                        <button
                                                            className="btn btn-danger"
                                                            onClick={() => {
                                                                setId(b.id)
                                                                dispatch(getCurrentNew(b.id))
                                                                setModalDetail(true)
                                                            }
                                                            }
                                                        >
                                                            <i className="fa fa-eye"></i>
                                                        </button>
                                                    </td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                )}
                            </div>
                        </Col>
                        <ModalFooter>
                            <Row>
                                <button
                                    onClick={() =>
                                        setModalShow(!modalShow)
                                    }
                                    className="p-2 btn rounded text-white"
                                    style={{backgroundColor: "#133A4A"}}
                                >
                                    ok
                                </button>
                            </Row>
                        </ModalFooter>
                    </ModalBody>
                </Modal>
            )}
            {modalDetail && (
                <Modal
                    centered
                    className="relative min-h-screen flex flex-col sm:justify-center items-center"
                    isOpen={modalDetail}>
                    <ModalHeader
                        style={{
                            backgroundImage:
                                "linear-gradient(to right,#FFD166, #133A4A  )",
                        }}
                        className="p-3 text-white"
                    >
                        {neww ? neww.title : ""}
                    </ModalHeader>
                    <ModalBody>
                        {neww ? neww.description : ""}
                        <ModalFooter>
                            <Row>
                                <button
                                    onClick={() => {
                                        setModalDetail(!modalDetail)
                                        const fd = new FormData()
                                        fd.append("read_news", id)
                                        updatecompte(fd, user_id)
                                        dispatch(getUnseenNews())
                                        dispatch(getUnseenNews())
                                        dispatch(getUnseenNews())
                                        dispatch(getUnseenNews())
                                    }
                                    }
                                    className="p-2 btn rounded text-white"
                                    style={{backgroundColor: "#133A4A"}}
                                >
                                    Mark as read
                                </button>
                            </Row>
                        </ModalFooter>
                    </ModalBody>
                </Modal>
            )}
        </>
    );
};

NewsPopup.propTypes = {
    getCurrentNew: PropTypes.func.isRequired,
    new: PropTypes.object.isRequired,
    updatecompte: PropTypes.func.isRequired,
}

const mapstatetoprops = (state) => ({
    new: state.reducernews,
});

export default connect(mapstatetoprops, {
    getCurrentNew,
    updatecompte,
})(withRouter(NewsPopup));