import React, {Fragment, useState, useMemo, useEffect, useContext} from "react";
import {connect, useDispatch} from "react-redux";
import {Link, useHistory, useLocation, withRouter} from "react-router-dom";
import {deleteNew, getNews} from "./actions";
import Spinner from "../colis/spinner.gif";
import PropTypes from "prop-types";
import Breadcrumb from "../../layout/breadcrumb";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    InputGroupAddon,
    Row,
    Table,
} from "reactstrap";
import Alert from "../../redux/Alert";
import Error404 from "../../pages/errors/error404";
import PaginationWrapper from "../pagination/pagination";
import AppContext from "../../context/Context";

const News = ({newsData, deleteNew}) => {
    const [alldata, searchData] = useState([]);
    const dispatch = useDispatch();
    const {
        config: {group}
    } = useContext(AppContext)
    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };

    let query = useQuery();
    const history = useHistory()

    useEffect(() => {
        dispatch(getNews(query));
    }, [dispatch, query]);

    useEffect(() => {
        if (newsData.news) {
            searchData(newsData.news);
        }
    }, [newsData.news, newsData.loading, alldata]);

    let user_groups_name = group;
    const getValSearch = () => {
        let params = new URLSearchParams(document.location.search);
        return params.get("search");
    };
    const onSearchQuery = (e) => {
        query.set("search", e.target.value)
        dispatch(getNews(query));
        history.push({search: query.toString()})
    };

    let count = newsData.count;
    let pagenumber = query.get("page") ? query.get("page") : 1;
    const Navigate = (page) => {
        query.set("page", Number(page));

        window.location.replace(`/news/?${query}`);
    };

    return (
        <Fragment>
            <Breadcrumb parent="News" title={`Nouvelles`}/>
            {user_groups_name.indexOf("admin") !== -1
                ? (
                    <div>
                        <Container fluid={true}>
                            <Alert/>
                            <Row>
                                <Col sm="12">
                                    <Card>
                                        {/* {datacolis.colis.length > 0 ? () : ()} */}

                                        <CardBody>
                                            <Col sm="12">
                                                <Row>
                                                    <Col sm="4"></Col>
                                                </Row>
                                                <br/>
                                                <br/>
                                                <Row>
                                                    <Col>
                                                        <Form className="theme-form">
                                                            <InputGroupAddon addonType="append">
                                                                <Col>
                                                                    <div>
                                                                        <Link
                                                                            className="btn  m-2 "
                                                                            style={{backgroundColor: "#FDF203"}}
                                                                            to="/news/new"
                                                                        >
                                                                            {"Ajouter"}
                                                                        </Link>
                                                                    </div>
                                                                </Col>
                                                            </InputGroupAddon>
                                                        </Form>
                                                    </Col>
                                                    <div className="search-5">
                                                        <h6>Rechercher:</h6>
                                                    </div>

                                                    <input
                                                        className=" border search "
                                                        style={{borderRadius: "7px"}}
                                                        type="search"
                                                        placeholder="Rechercher..."
                                                        name="search"
                                                        defaultValue={getValSearch()}
                                                        onChange={(e) => onSearchQuery(e)}
                                                    />
                                                </Row>
                                                <Card>
                                                    <div className="card-block row">
                                                        <Col sm="12">

                                                            <div className="table-responsive">
                                                                {newsData && newsData.loading ? (
                                                                    <div className="text-center">
                                                                        <img src={Spinner} style={{width: "10%"}}
                                                                             alt=""/>
                                                                    </div>
                                                                ) : (
                                                                    <Table striped>
                                                                        <thead>
                                                                        <tr>
                                                                            <th scope="col"></th>
                                                                            <th>{"Titre"}</th>
                                                                            <th>{"Utilisateur"}</th>
                                                                            <th>{"Description"}</th>
                                                                            <th>{"Création"}</th>
                                                                            <th>{"Action"} </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {newsData &&
                                                                            newsData.news.map((b) => (
                                                                                <tr key={b.id}>
                                                                                    <td></td>
                                                                                    <td>
                                                                                        <Link
                                                                                            to={`/news/edit/${b.id}`}
                                                                                        >
                                                                                            {b.title}
                                                                                        </Link>
                                                                                    </td>
                                                                                    <td>{b.username}</td>
                                                                                    <td>{b.description.length > 20
                                                                                        ? b.description.substring(0, 20) + "..."
                                                                                        : b.description}</td>
                                                                                    <td>{b.created_at}</td>
                                                                                    <td>
                                                                                        <button
                                                                                            className="btn btn-danger"
                                                                                            onClick={() => {
                                                                                                deleteNew(b.id);
                                                                                                dispatch(getNews(query));
                                                                                                dispatch(getNews(query))
                                                                                            }}>
                                                                                            <i className="fa fa-trash"></i>
                                                                                        </button>
                                                                                    </td>

                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </Table>
                                                                )}
                                                            </div>
                                                        </Col>
                                                    </div>
                                                </Card>
                                            </Col>{" "}
                                            <Row>
                                                <Col sm="10"></Col>
                                                {newsData && newsData.loading ? "" :
                                                    <Col>
                                                        <PaginationWrapper
                                                            name="Nouvelles"
                                                            length={newsData && newsData.news.length}
                                                            count={count}
                                                            currentPage={pagenumber}
                                                            onNavigate={Navigate}
                                                        />
                                                    </Col>}
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                ) : (
                    <Error404 page="News"/>
                )}
        </Fragment>
    );
};

News.propTypes = {
    deleteNew: PropTypes.func.isRequired,
    getNews: PropTypes.func.isRequired,
    newsData: PropTypes.object.isRequired,
};

const mapstatetoprops = (state) => ({
    newsData: state.reducernews,
});

export default connect(mapstatetoprops, {
    getNews,
    deleteNew,
})(withRouter(News));
