import React, {Fragment, useEffect, useMemo, useState} from "react";
import Alert from "../../redux/Alert";
import Breadcrumb from "../../layout/breadcrumb";
import {Card, CardBody, CardFooter, CardHeader, Table} from "reactstrap";
import {api} from "../../utils/backend.instance";
import Spinner from "../dashboard/icons/spinner.gif";
import {useHistory, useLocation} from "react-router-dom";
import PaginationWrapper from "../pagination/pagination";

export const Analytiques = () => {
    const [loading, setLoading] = useState(false)
    const [coliByClient, setColiByClient] = useState([])
    const [lteDate, setLteDate] = useState(null)
    const [gteDate, setGteDate] = useState(null)
    const [gteSearchVal, setSearchVal] = useState(null)
    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };
    const query = useQuery()

    const history = useHistory()

    const getGte = () => {
        let params = new URLSearchParams(document.location.search);
        return params.get("created_at__gte");
    };

    const getLte = () => {
        let params = new URLSearchParams(document.location.search);
        return params.get("created_at__lte");
    };
    const getQPram = () => {
        let params = new URLSearchParams(document.location.search);
        return params.get("q");
    };
    

    const getColiByClient = async () => {
        setLoading(true)
        // query.set("page_size", "15")
        await api.get(`/stats/analytics/?${query.toString()}`).then(res => setColiByClient(res?.data))
        setLoading(false)
    }

    useEffect(() => {
        getColiByClient()
        // eslint-disable-next-line
    }, []);

    let pagenumber = query.get("page") ? query.get("page") : 1;
    const Navigate = (page) => {
        query.set("page", `${Number(page)}`)
        history.push({search: query.toString()})
        getColiByClient()
    };

    return (
        <Fragment>
            <Alert/>
            <Breadcrumb parent="Analytiques"/>
            <Card>
                <CardHeader style={{padding: "15px", backgroundColor: "#eaeaea"}}>
                    <div className={"d-flex flex-wrap justify-content-between"}>
                        <div className="row m-1">
                            <div class="row m-1">
                            <h5 style={{color: "#193256"}} className={"m-2"}>Commandes par fournisseurs</h5>
                                <div class="col-sm-3">
                                    <div class="form-group">
                                        <input 
                                            placeholder="Name" 
                                            name="q" 
                                            type="text" 
                                            class="form-control"
                                            defaultValue={getQPram()}
                                            onChange={(e) => {
                                                setSearchVal(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row m-2">
                            <h4>Du</h4>
                            <input
                                className="btn btn-light"
                                type="date"
                                name="created_at__gte"
                                defaultValue={getGte()}
                                onChange={(e) => {
                                    setGteDate(e.target.value)
                                }}
                            />
                            <h4>A</h4>
                            <input
                                className="btn btn-light"
                                type="date"
                                name="created_at__lte"
                                defaultValue={getLte()}
                                onChange={(e) => {
                                    setLteDate(e.target.value)
                                }}
                            />
                            <button
                                disabled={false}
                                type="button"
                                className="btn text-white  ml-2"
                                style={{backgroundColor: "#27B3B0"}}
                                onClick={() => {
                                    if (lteDate)
                                        query.set("created_at__lte", lteDate)
                                    if (gteDate)
                                        query.set("created_at__gte", gteDate)
                                    if(gteSearchVal)
                                        query.set("q", gteSearchVal)
                                    history.push({search: query.toString()})
                                    getColiByClient(query)
                                }}
                            >
                                Valider
                            </button>
                        </div>
                    </div>
                </CardHeader>
                <CardBody style={{padding: "0px"}}>
                    <Table responsive style={{width: "100%", textAlign: "center"}}>
                        <thead style={{backgroundColor: "#eaeaea"}}>
                        <tr>
                            <th style={{color: "#193256"}}>Client</th>
                            <th style={{color: "#193256"}}>Colis</th>
                            <th style={{color: "#193256"}}>Colis livrés</th>
                            <th style={{color: "#193256"}}>Colis Payer</th>
                            <th style={{color: "#193256"}}>colis retours</th>
                        </tr>
                        </thead>
                        {loading ?
                            <div className="text-center">
                                <img src={Spinner}
                                     style={{width: "10%"}}
                                     alt=""/>
                            </div> :
                            <tbody>
                            {coliByClient?.results?.map(client => (
                                <tr>
                                    <td>{client.name}</td>
                                    <td>{client.colis}</td>
                                    <td>{client.livrer}</td>
                                    <td>{client.payer}</td>
                                    <td>{client.retour}</td>
                                </tr>
                            ))}
                            </tbody>
                        }
                    </Table>
                </CardBody>
                <CardFooter>
                    <PaginationWrapper
                        name="Clients"
                        length={coliByClient?.results?.length}
                        count={coliByClient?.count}
                        currentPage={pagenumber}
                        onNavigate={Navigate}
                    />
                </CardFooter>
            </Card>
        </Fragment>
    )
}