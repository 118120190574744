import React, {
    useState,
    useEffect, useMemo, useContext,
} from "react";
import {
    Row,
    Col,
    Table,
    Card,
    CardBody,
    Container
} from "reactstrap";
import Breadcrumb from "../../layout/breadcrumb";

import {Fragment} from "react";
import {
    getActivities,
} from "./action";
import {connect, useDispatch} from "react-redux";
import {Link, useHistory, useLocation, withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import Alert from "../../redux/Alert";
import Spinner from "../colis/spinner.gif";
import Error404 from "../../pages/errors/error404";
import {getFournisseurs} from "../Fournisseurs/action";
import Select, {components} from "react-select";
import PaginationWrapper from "../pagination/pagination";
import AppContext from "../../context/Context";

const StockActivity = ({activityData, fournisseurData}) => {
    const [selectedfournisseur, setSelectedFournisseur] = useState([]);
    const [currentFournisseurPage, setCurrentFournisseurPage] = useState(1)
    const history = useHistory()
    const {
        config: {group, stock_service}
    } = useContext(AppContext)

    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };
    let query = useQuery();
    let fquery = useQuery();

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getActivities(query))
    }, [dispatch, query]);

    useEffect(() => {
        fquery.set("page_size", "50")
        fquery.set("page", currentFournisseurPage)
        dispatch(getFournisseurs(fquery))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fquery, currentFournisseurPage]);

    const onSearchQuery = (e) => {
        query.set('search', e.target.value)
        history.push({search: query.toString()})
    };
    const getValSearch = () => {
        let params = new URLSearchParams(document.location.search);
        return params.get("q");
    };

    const dataF = [];
    fournisseurData &&
    fournisseurData.fournisseurs.map((F) =>
        dataF.push({
            value: parseInt(F.id),
            label: F.name,
        })
    );

    const NoOptionsMessage = props => {
        return (
            <components.NoOptionsMessage {...props}>
                <div className="text-center">
                    <img src={Spinner} style={{width: "10%"}} alt=""/>
                </div>
            </components.NoOptionsMessage>
        );
    };

    const onChangeFournisseur = (e) => {
        setSelectedFournisseur(e);
        const fournisseurid = e.value;

        fquery.set("fournisseur", fournisseurid);
        history.push({search: fquery.toString()})
    };

    let user_groups_name = group;

    let count = activityData.count;
    let pagenumber = query.get("page") ? query.get("page") : 1;
    const Navigate = (page) => {
        query.set("page", Number(page));

        window.location.replace(`/stock_depot/?${query}`);
    };

    return (
        <Fragment>
            <Breadcrumb parent="Stock" title="Activités de stock"/>
            {/* eslint-disable-next-line no-mixed-operators */}
            {user_groups_name && user_groups_name.indexOf("admin") !== -1 || stock_service ? (
                    <div>
                        <Container fluid={true}>
                            <Alert/>
                            <Row>
                                <Col sm="12">
                                    <Card>
                                        {/* {datacolis.colis.length > 0 ? () : ()} */}
                                        <CardBody>
                                            <Row className={"m-1"}>
                                                <Col></Col>
                                                {user_groups_name && user_groups_name.indexOf("admin") !== -1 &&
                                                    <Col sm={4}>
                                                        <Select
                                                            value={selectedfournisseur}
                                                            options={dataF}
                                                            placeholder={"Choisir Un Fournisseur"}
                                                            components={{NoOptionsMessage}}
                                                            onInputChange={(e) => {
                                                                fquery.set("search", e)
                                                                dispatch(getFournisseurs(fquery))
                                                            }}
                                                            onMenuScrollToBottom={(e) => {
                                                                console.log(e)
                                                                setCurrentFournisseurPage(page => page + 1)
                                                            }}
                                                            onChange={(e) => {
                                                                onChangeFournisseur(e);
                                                            }}
                                                        />
                                                    </Col>
                                                }

                                                <div className="search-5">
                                                    <h6>Rechercher:</h6>
                                                </div>
                                                <input
                                                    className=" border search "
                                                    style={{borderRadius: "7px"}}
                                                    type="search"
                                                    placeholder="Rechercher..."
                                                    name="search"
                                                    defaultValue={getValSearch()}
                                                    onChange={onSearchQuery}
                                                />
                                            </Row>
                                            <Col sm="12">
                                                <Card>
                                                    <div className="card-block row">
                                                        <Col sm="12" lg="12" xl="12">
                                                            <div className="table-responsive">
                                                                {activityData && activityData.loading ? (
                                                                    <div className="text-center">
                                                                        <img src={Spinner} style={{width: "10%"}} alt=""/>
                                                                    </div>
                                                                ) : (
                                                                    <Table striped>
                                                                        <thead>
                                                                        <tr>
                                                                            <th
                                                                                scope="col"
                                                                            >
                                                                                {"Produit"}
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                            >
                                                                                {"Action"}
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                            >
                                                                                {"Stock"}
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                            >
                                                                                {"Message"}
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                            >
                                                                                {"Reason"}
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                            >
                                                                                {"Création"}
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {activityData && activityData.activities &&
                                                                            activityData.activities.map((b) => (
                                                                                <tr key={b.id}>
                                                                                    <td>
                                                                                        <Link
                                                                                            to={`/stock_depot/edit/${b.product}`}
                                                                                        >
                                                                                            {b.product_ref}
                                                                                        </Link>
                                                                                    </td>
                                                                                    <td>{b.action === "add" ? `+${b.quantity * b.case}` : `-${b.quantity * b.case}`}</td>
                                                                                    <td>{b.stock}</td>
                                                                                    <td>{b.notes}</td>
                                                                                    <td>{b.reason_display}</td>
                                                                                    <td>{b.date}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </Table>)}
                                                            </div>
                                                        </Col>
                                                    </div>
                                                </Card>
                                            </Col>{" "}
                                            <Row>
                                                <Col sm="10"></Col>
                                                {activityData && activityData.loading ? "" :
                                                    <Col>
                                                        <PaginationWrapper
                                                            name="Activités"
                                                            length={activityData && activityData.count}
                                                            count={count}
                                                            currentPage={pagenumber}
                                                            onNavigate={Navigate}
                                                        />
                                                    </Col>}
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                ) :
                (
                    <Error404 page="Stock dépôt"/>
                )
            }
        </Fragment>

    )
}

StockActivity.propTypes = {
    getActivities: PropTypes.func.isRequired,
    activityData: PropTypes.object.isRequired,
    fournisseurData: PropTypes.object.isRequired,
};

const mapstatetoprops = (state) => ({
    activityData: state.reducerst,
    fournisseurData: state.reducerf
});

export default connect(mapstatetoprops, {
    getActivities,
    getFournisseurs
})(withRouter(StockActivity));
