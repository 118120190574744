import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {getColis, getColisP} from "./action";
import {connect, useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import PropTypes from "prop-types";
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import Spinner from "./spinner.gif";
import {day2digits} from "./gestiondescolis";
import {Table} from "reactstrap";

const Download = ({colisData}) => {
    const [data, setData] = useState([]);
    const [ascId, setAscSortId] = useState(true);
    const [asctr, setAscSorttr] = useState(true);
    const [ascst, setAscSortst] = useState(true);
    const [asctel, setAscSorttel] = useState(true);
    const [ascser, setAscSortser] = useState(true);
    const [ascpr, setAscSortpr] = useState(true);
    const [alldata, searchData] = useState([]);
    const dispatch = useDispatch();

    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };


    let query = useQuery();
    let params = new URLSearchParams(document.location.search);
    let status = params.get("status");
    let relatedpayment = params.get("related_payment")
    useEffect(() => {
        if (colisData.colisP) {
            searchData(colisData.colisP);
        }
    }, [colisData.colisP, colisData.loading, alldata]);


    ReactHTMLTableToExcel.format = (s, c) => {
        if (c && c["table"]) {
            const html = c.table;
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, "text/html");
            const rows = doc.querySelectorAll("thead tr th");
            for (const row of rows) {
                row.setAttribute(
                    "style",
                    "background: #B8CCE4"
                );
            }


            c.table = doc.querySelector("table").outerHTML;
        }

        return s.replace(/{(\w+)}/g, (m, p) => c[p]);
    };

    const getDate = () => {
        let params = new URLSearchParams(document.location.search);
        let date = params.get("created_at");
        if (date === "today") {
            let today = new Date();
            let m = today.getMonth() + 1;
            if (m < 10) {
                m = "0" + m;
            }
            return today.getFullYear() + "-" + m + "-" + day2digits(today.getDate());
        } else {
            return date;
        }
    };
    const getStatus = () => {
        let params = new URLSearchParams(document.location.search);
        let status = params.get("status");
        let service = params.get("service");
        let relatedpayment = params.get("related_payment")
        let gte = params.get("created_at__gte");
        let lte = params.get('created_at__lte');
        if (status === "supprimer") {
            return "Colis supprimés";
        } else if (status === "retour") {
            return "Colis retour";
        } else if (status === "modifier") {
            return "Colis modifiés";
        } else if (service === "echange") {
            return "Colis echange";
        } else if (status === "payer") {
            return "Colis payés";
        } else if (gte && lte) {
            return `Gestions des colis du ${gte} à ${lte} ${status && status ? "et status= " + status : ""}`
        } else if (relatedpayment) {
            return 'Détails Finances'
        } else {
            return `Colis du jour : ${getDate()}`;
        }
    };

    const onAscSort = () => {
        query.set("ordering", "code_a_barre")
        setAscSortId(false);
        dispatch(getColis(query));
        setData(data);
    };

    const onDescSort = () => {
        query.set("ordering", "-code_a_barre")
        setAscSortId(true);
        dispatch(getColis(query));
        setData(data);
    };

    const onAscSorttr = () => {
        query.set("ordering", "shipment__tracking_code")
        setAscSorttr(false);
        dispatch(getColis(query));
        setData(data);
    };

    const onDescSorttr = () => {
        query.set("ordering", "-shipment__tracking_code")
        setAscSorttr(true);
        dispatch(getColis(query));
        setData(data);
    };

    const onAscSortst = () => {
        query.set("ordering", "status")
        setAscSortst(false);
        dispatch(getColis(query));
        setData(data);
    };

    const onDescSortst = () => {
        query.set("ordering", "-status")
        setAscSortst(true);
        dispatch(getColis(query));
        setData(data);
    };

    const onAscSorttel = () => {
        query.set("ordering", "client_telephone_1")
        setAscSorttel(false);
        dispatch(getColis(query));
        setData(data);
    };

    const onDescSorttel = () => {
        query.set("ordering", "-client_telephone_1")
        setAscSorttel(true);
        dispatch(getColis(query));
        setData(data);
    };

    const onAscSortpr = () => {
        query.set("ordering", "prix")
        setAscSortpr(false);
        dispatch(getColis(query));
        setData(data);
    };

    const onDescSortpr = () => {
        query.set("ordering", "-prix")
        setAscSortpr(true);
        dispatch(getColis(query));
        setData(data);
    };

    const onAscSortser = () => {
        query.set("ordering", "service")
        setAscSortser(false);
        dispatch(getColis(query));
        setData(data);
    };

    const onDescSortser = () => {
        query.set("ordering", "-service")
        setAscSortser(true);
        dispatch(getColis(query));
        setData(data);
    };



    let length = colisData.colisP.length
    const COD = colisData.colisP.reduce((totalCod, cod) => totalCod + cod.prix, 0);
    useEffect(() => {
        dispatch(getColisP(query))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (length > 0) {
            setTimeout(() => {
                window.close()
            }, 500)
        }
    }, [length])

    useEffect(() => {
        if (length > 0) {
            document.getElementById("excelexport").click()
        }
    }, [length])
    return (
        <Fragment>
            {colisData && colisData.loading ? <div className="text-center">
                <img src={Spinner} style={{width: "10%"}} alt=""/>
            </div> : ""
            }
            <div hidden>
                <ReactHTMLTableToExcel
                    table="example"
                    buttonText="EXCEL"
                    className="btn  m-2 btn-excel text-white"
                    filename={`Beezit - ${getStatus()}`}
                    sheet={getStatus()}
                    id="excelexport"
                />
                <Table striped id="example">
                    <thead>
                    <tr>
                        <th
                            style={{cursor: "pointer"}}
                            id="code_a_barre"
                            title={`Trier ${
                                ascId ? "Ascendant" : "Descendant"
                            }`}
                            onClick={(e) =>
                                ascId ? onAscSort(e) : onDescSort(e)
                            }
                            className={`bi ${
                                ascId ? "bi-sort-down" : "bi-sort-up"
                            }`}
                        >
                            {"Code à barre"} &nbsp;&nbsp;&nbsp;&nbsp;
                            {ascId ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-sort-up"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                </svg>
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className={`bi bi-sort-down`}
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                </svg>
                            )}
                        </th>
                        <th
                            title={`Trier ${
                                asctr ? "Ascendant" : "Descendant"
                            }`}
                            style={{cursor: "pointer"}}
                            scope="col"
                            id="tracking_code"
                            onClick={(e) =>
                                asctr ? onAscSorttr(e) : onDescSorttr(e)
                            }
                        >
                            {"Code de suivi"} &nbsp;&nbsp;&nbsp;&nbsp;
                            {asctr ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-sort-up"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                </svg>
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className={`bi bi-sort-down`}
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                </svg>
                            )}
                        </th>
                        <th>
                            Date Création
                        </th>
                        <th>
                            Date Modification
                        </th>
                        <th
                            style={{cursor: "pointer"}}
                            scope="col"
                            id="fournisseur_name"
                        >
                            {"Expéditeur"}
                        </th>
                        <th
                            style={{cursor: "pointer"}}
                            scope="col"
                            id="client_address"
                        >
                            {"Destinataire"}
                        </th>
                        <th
                            title={`Trier ${
                                asctel ? "Ascendant" : "Descendant"
                            }`}
                            style={{cursor: "pointer"}}
                            scope="col"
                            id="client_telephone_1"
                            onClick={(e) =>
                                asctel
                                    ? onAscSorttel(e)
                                    : onDescSorttel(e)
                            }
                        >
                            {"Tél 1 / Tél 2"} &nbsp;&nbsp;&nbsp;&nbsp;
                            {asctel ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-sort-up"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                </svg>
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className={`bi bi-sort-down`}
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                </svg>
                            )}
                        </th>
                        <th
                            style={{cursor: "pointer"}}
                            scope="col"
                            id="client_ville"
                        >
                            {"Ville"}
                        </th>
                        <th
                            style={{cursor: "pointer"}}
                            scope="col"
                            id="client_delegation"
                        >
                            {"Délégation"}
                        </th>
                         <th
                            title={`Trier ${
                                ascst ? "Ascendant" : "Descendant"
                            }`}
                            style={{cursor: "pointer"}}
                            scope="col"
                            id="status"
                            onClick={(e) =>
                                ascst ? onAscSortst(e) : onDescSortst(e)
                            }
                        >
                            {"Status"} &nbsp;&nbsp;&nbsp;&nbsp;
                            {ascst ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-sort-up"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                </svg>
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className={`bi bi-sort-down`}
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                </svg>
                            )}
                        </th>
                        <th
                            title={`Trier ${
                                ascser ? "Ascendant" : "Descendant"
                            }`}
                            style={{cursor: "pointer"}}
                            scope="col"
                            id="service"
                            onClick={(e) =>
                                ascser
                                    ? onAscSortser(e)
                                    : onDescSortser(e)
                            }
                        >
                            {"Service"} &nbsp;&nbsp;&nbsp;&nbsp;
                            {ascser ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-sort-up"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                </svg>
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className={`bi bi-sort-down`}
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                </svg>
                            )}
                        </th>
                        <th> Produit</th>
                        <th>{"Remarque"}</th>
                        <th>{"Remarque échange"}</th>
                        <th
                            title={`Trier ${
                                ascpr ? "Ascendant" : "Descendant"
                            }`}
                            style={{cursor: "pointer"}}
                            scope="col"
                            id="prix"
                            onClick={(e) =>
                                ascpr ? onAscSortpr(e) : onDescSortpr(e)
                            }
                        >
                            {"COD"} &nbsp;&nbsp;&nbsp;&nbsp;
                            {ascpr ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-sort-up"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                </svg>
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className={`bi bi-sort-down`}
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                </svg>
                            )}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {colisData &&
                        colisData.colisP.map((b) => (
                            <tr
                                key={b.id}
                                className={b.selected ? "selected" : ""}
                            >
                                <td>{b.code_a_barre}</td>
                                <td>{b.tracking_code}</td>
                                <td>{b.created_at}</td>
                                <td>{b.updated_at}</td>
                                <td>{b.fournisseur_name}</td>
                                <td>
                                    {b.client_name}
                                </td>
                                <td>
                                    {b.client_telephone_1}
                                    {b.client_telephone_2 ? "/ " + b.client_telephone_2 : ""}
                                </td>
                                <td>{b.ville_name}</td>
                                <td>{b.delegation_name}</td>
                                <td>{b.status_display}</td>
                                <td>{b.service}</td>
                                <td>{b.product_name}</td>
                                <td>{b.remarque}</td>
                                <td>{b.remarque_echange}</td>
                                <td>{b.prix}</td>
                            </tr>
                        ))}
                    </tbody>


                    {status === "payer" || relatedpayment ?
                        <tfoot>
                        <tr></tr>
                        {colisData && colisData.loading ? (
                            <div className="text-center">
                                <img src={Spinner} style={{width: "10%"}} alt=""/>
                            </div>
                        ) : (
                            <tr>
                                <td>Total</td>
                                <td>{length} Colis payés</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{COD} </td>
                            </tr>)}
                        </tfoot> : ""}
                </Table>
            </div>
        </Fragment>
    )
}
Download.propTypes = {
    getColisP: PropTypes.func.isRequired,
    colisData: PropTypes.object.isRequired,
}
const mapstatetoprops = (state) => ({
    colisData: state.reducerc,
});

export default connect(mapstatetoprops, {getColisP})(Download)