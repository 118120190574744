import React, {Fragment, useState, useEffect, useMemo, useContext} from "react";
import PropTypes from "prop-types";
import Alert from "../../redux/Alert";
import {connect} from "react-redux";
import {Link, useLocation} from "react-router-dom";
import {Multiselect} from "multiselect-react-dropdown";
import {addNewCompte} from "./action";
import {getGroups} from "../group/action";
import Breadcrumb from "../../layout/breadcrumb";
import {useDispatch} from "react-redux";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button as B,
    CardHeader,
} from "reactstrap";
import {getFournisseurs} from "../Fournisseurs/action";
import Error404 from "../../pages/errors/error404";
import Select, {components} from "react-select";
import Spinner from "../colis/spinner.gif";
import Button from "../../utils/Button";
import AppContext from "../../context/Context";

const Newcompte = ({
                       addNewCompte,
                       groupData,
                       history,
                       fournisseurData,
                   }) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        username: "",
        email: "",
        first_name: "",
        last_name: "",
        avatar: null,
        fournisseur: "",
        is_active: "",
        number: "",
        groups: [],
        user_permissions: [],
        password: "",
    });
    const [stateselectedgroup, setSelectedGroup] = useState([]);
    const [stategroup, setGroup] = useState([
        {
            id: "",
            group: "",
            permissions: [],
        },
    ]);
    const [selectedfournisseur, setSelectedFournisseur] = useState([]);
    const [currentFournisseurPage, setCurrentFournisseurPage] = useState(1)
    const [showLoader, setShowLoader] = useState(false)
    const [statefournisseur, setFournisseur] = useState([
        {
            id: "",
            responsable: "",
        },
    ]);

    const [taptopStyle, setTapTopStyle] = useState("none");

    const {
        config: {group}
    } = useContext(AppContext)

    const handleScroll = () => {
        if (window.scrollY > 600) {
            setTapTopStyle("block");
        } else {
            setTapTopStyle("none");
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        handleScroll();
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [taptopStyle]);
    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };
    let query = useQuery();
    //get groups
    useEffect(() => {
        dispatch(getGroups(query));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setGroup(groupData.groups);
    }, [groupData.groups, groupData.loading, stategroup]);

    useEffect(() => {
        query.set("page_size", "50")
        query.set("page", currentFournisseurPage)
        dispatch(getFournisseurs(query))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFournisseurPage]);

    useEffect(() => {
        setFournisseur(fournisseurData.fournisseurs);
    }, [fournisseurData.fournisseurs, fournisseurData.loading, statefournisseur]);
    const onChangeFournisseur = (e) => {
        setSelectedFournisseur(e);
        const fournisseurid = e.value;

        setFormData({
            ...formData,
            fournisseur: fournisseurid,
        });
    };
    const onChange = (e) =>
        setFormData({...formData, [e.target.name]: e.target.value});
    const onChangeimg = (e) => {
        setFormData({...formData, avatar: e.target.files[0]});
    };
    const onSelectGroup = (e) => {
        setSelectedGroup(e);
        const groupid = e.map((s) => s.id);
        const permissions = e.map((s) => s.permissions);

        setFormData({
            ...formData,
            groups: groupid,
            user_permissions: permissions,
        });
    };
    const datagroup = [];
    groupData &&
    groupData.groups.map((group) =>
        datagroup.push({
            id: parseInt(group.id),
            group: group.name,
            permissions: group.permissions,
        })
    );

    const onSubmit = async (e) => {
        setShowLoader(true)
        e.preventDefault();
        const fd = new FormData();
        fd.append("username", formData.username);
        fd.append("email", formData.email);
        fd.append("first_name", formData.first_name);
        fd.append("last_name", formData.last_name);
        fd.append("password", formData.password);
        fd.append("fournisseur", formData.fournisseur);
        fd.append("is_active", formData.is_active);
        fd.append("number", formData.number);
        formData.groups.forEach((item) => {
            fd.append("groups", item);
        });
        formData.user_permissions.forEach((item) => {
            // eslint-disable-next-line array-callback-return
            item.map((i) => {
                fd.append("user_permissions", i);
            });
        });
        if (formData.avatar) {
            fd.append("avatar", formData.avatar);
        }
        await addNewCompte(fd, history);
        setShowLoader(false)
    };
    let user_groups_name = group;
    const data = [];
    fournisseurData &&
    fournisseurData.fournisseurs.map((F) =>
        data.push({
            value: parseInt(F.id),
            label: F.name,
        })
    );
    const NoOptionsMessage = props => {
        return (
            <components.NoOptionsMessage {...props}>
                <div className="text-center">
                    <img src={Spinner} style={{width: "10%"}} alt=""/>
                </div>
            </components.NoOptionsMessage>
        );
    };
    return (
        <Fragment>
            <Breadcrumb parent="Compte" title="Ajouter Utilisateur"/>
            <Container fluid={true}>
                <Alert/>
                {user_groups_name.indexOf("personnel") === -1 ? (
                    <Form
                        className="theme-form"
                        encType="multipart/form-data"
                        onSubmit={(e) => onSubmit(e)}
                    >
                        <Row>
                            <Col sm="7">
                                <Card>
                                    <CardHeader>
                                        <Row>
                                            <Col sm="10">
                                                <h5>Confidentalité</h5>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Nom d'utilisateur</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        required
                                                        placeholder="Nom d'utilisateur"
                                                        name="username"
                                                        value={formData.username}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Mot de passe</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="password"
                                                        name="password"
                                                        placeholder="********"
                                                        value={formData.password}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    {user_groups_name &&
                                                    user_groups_name.indexOf("admin") !== -1 ? (
                                                        <Label>Fournisseur</Label>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {user_groups_name &&
                                                    user_groups_name.indexOf("admin") !== -1 ? (
                                                        <Select
                                                            value={selectedfournisseur}
                                                            options={data}
                                                            placeholder={"Fournisseur"}
                                                            components={{NoOptionsMessage}}
                                                            onChange={(e) => {
                                                                onChangeFournisseur(e);
                                                            }}
                                                            onInputChange={(e) => {
                                                                query.set('search', e);
                                                                dispatch(getFournisseurs(query))
                                                            }}
                                                            onMenuScrollToBottom={(e) => {
                                                                console.log(e)
                                                                setCurrentFournisseurPage(page => page + 1)
                                                            }}
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Avatar</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="file"
                                                        required
                                                        name="avatar"
                                                        accept="image/jpeg,image/png,image/gif"
                                                        onChange={(e) => {
                                                            onChangeimg(e);
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardHeader>
                                        <h5>Groupe</h5>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Groupe</Label>
                                                    <Row>
                                                        <Col sm="8">
                                                            <Multiselect
                                                                name="groups"
                                                                placeholder={"Groupe"}
                                                                value={stateselectedgroup}
                                                                options={datagroup}
                                                                displayValue="group"
                                                                onSelect={(e) => {
                                                                    onSelectGroup(e);
                                                                }}
                                                            />
                                                        </Col>
                                                        {user_groups_name &&
                                                        user_groups_name.indexOf("admin") !== -1 ? (
                                                            <Col className="ml-4">
                                                                <FormGroup>
                                                                    <Row>
                                                                        <Col>
                                                                            <Input
                                                                                className="ml-2"
                                                                                key={"active"}
                                                                                type="checkbox"
                                                                                name="is_active"
                                                                                onChange={(e) => onChange(e)}
                                                                            />
                                                                            <Label className="ml-4">Active</Label>
                                                                        </Col>
                                                                    </Row>
                                                                </FormGroup>
                                                            </Col>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Row>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="5">
                                <Card>
                                    <CardHeader>
                                        <h5>Données personnelles</h5>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Nom</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Nom"
                                                        name="last_name"
                                                        value={formData.last_name}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Prénom</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        name="first_name"
                                                        placeholder="Prénom"
                                                        value={formData.first_name}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>{" "}
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Email</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        required
                                                        placeholder="Email"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>{" "}
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Téléphone</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="number"
                                                        name="number"
                                                        placeholder="Téléphone"
                                                        value={formData.number}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Row>
                                <Col>
                                    <FormGroup className="mb-6">
                                        <Button
                                            onClick={onSubmit}
                                            disabled={showLoader}
                                            className="btn btn-success mr-3"
                                            color={"success"}
                                            loading={showLoader}
                                            text={"Enregistrer"}
                                        />
                                        <Link to={`/compte`}>
                                            <B color="danger">Annuler</B>
                                        </Link>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Row>
                    </Form>
                ) : (
                    <Error404 page="Ajouter Utilisateur"/>
                )}
            </Container>
        </Fragment>
    );
};
Newcompte.propTypes = {
    getFournisseurs: PropTypes.func.isRequired,
    fournisseurData: PropTypes.object.isRequired,
    fournisseur: PropTypes.object.isRequired,
    addNewCompte: PropTypes.func.isRequired,
    getGroups: PropTypes.func.isRequired,
};

const mapstatetoprops = (state) => ({
    groupData: state.reducerg,
    fournisseurData: state.reducerf,
    fournisseur: state.reducerf,
});
export default connect(mapstatetoprops, {
    addNewCompte,
    getGroups,
    getFournisseurs,
})(Newcompte);
