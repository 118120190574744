import React, {Fragment, useState, useEffect, useContext} from "react";
import {MENUITEMS} from "./menu";
import {ArrowRight, ArrowLeft} from "react-feather";
import {Link} from "react-router-dom";
import {translate} from "react-switch-lang";
import Zoom from '../sidebar/zoom.svg'
import AppContext from "../../context/Context";

const Sidebar = (props) => {
    // eslint-disable-next-line
    const [mainmenu, setMainMenu] = useState(MENUITEMS);
    const [margin, setMargin] = useState(0);
    const [width, setWidth] = useState(0);
    const [sidebartoogle, setSidebartoogle] = useState(true);

    const {
        config: {group, stock_service}
    } = useContext(AppContext)

    useEffect(() => {
        document.querySelector(".left-arrow").classList.add("d-none");
        window.addEventListener("resize", handleResize);
        handleResize();
        const currentUrl = window.location.pathname;
        MENUITEMS.map((items) => {
            items.Items.filter((Items) => {
                if (Items.path === currentUrl) setNavActive(Items);
                if (!Items.children) return false;
                Items.children.filter((subItems) => {
                    if (subItems.path === currentUrl) setNavActive(subItems);
                    if (!subItems.children) return false;
                    subItems.children.filter((subSubItems) => {
                        if (subSubItems.path === currentUrl) {
                            setNavActive(subSubItems);
                            return true;
                        } else {
                            return false;
                        }
                    });
                    return subItems;
                });
                return Items;
            });
            return items;
        });
    }, []);

    const handleResize = () => {
        setWidth(window.innerWidth);
    };

    const setNavActive = (item) => {
        MENUITEMS.map((menuItems) => {
            menuItems.Items.filter((Items) => {
                if (Items !== item) {
                    Items.active = false;
                    document.querySelector(".bg-overlay1").classList.remove("active");
                }
                if (Items.children && Items.children.includes(item)) {
                    Items.active = true;
                    document.querySelector(".sidebar-link").classList.add("active");
                }
                if (Items.children) {
                    Items.children.filter((submenuItems) => {
                        if (submenuItems.children && submenuItems.children.includes(item)) {
                            Items.active = true;
                            submenuItems.active = true;
                            return true;
                        } else {
                            return false;
                        }
                    });
                }
                return Items;
            });
            return menuItems;
        });
        item.active = !item.active;
        setMainMenu({mainmenu: MENUITEMS});
    };

    const toggletNavActive = (item) => {
        if (window.innerWidth <= 991) {
            document.querySelector(".page-header").className =
                "page-header close_icon";
            document.querySelector(".sidebar-wrapper").className =
                "sidebar-wrapper close_icon ";

            if (item.type === "sub") {
                document.querySelector(".page-header").className = "page-header ";
                document.querySelector(".sidebar-wrapper").className =
                    "sidebar-wrapper ";
            }
        }

        if (!item.active) {
            MENUITEMS.map((a) => {
                a.Items.filter((Items) => {
                    if (a.Items.includes(item)) Items.active = false;
                    if (!Items.children) return false;
                    Items.children.forEach((b) => {
                        if (Items.children.includes(item)) {
                            b.active = false;
                        }
                        if (!b.children) return false;
                        b.children.forEach((c) => {
                            if (b.children.includes(item)) {
                                c.active = false;
                            }
                        });
                    });
                    return Items;
                });
                return a;
            });
        }
        item.active = !item.active;
        setMainMenu({mainmenu: MENUITEMS});
    };

    const scrollToRight = () => {
        if (margin <= -2598 || margin <= -2034) {
            if (width === 492) {
                setMargin(-3570);
            } else {
                setMargin(-3464);
            }
            document.querySelector(".right-arrow").classList.add("d-none");
            document.querySelector(".left-arrow").classList.remove("d-none");
        } else {
            document.querySelector(".left-arrow").classList.remove("d-none");
        }
    };

    const scrollToLeft = () => {
        if (margin >= -width) {
            setMargin(0);
            document.querySelector(".left-arrow").classList.add("d-none");
            document.querySelector(".right-arrow").classList.remove("d-none");
        } else {
            document.querySelector(".right-arrow").classList.remove("d-none");
        }
    };

    const closeOverlay = () => {
        document.querySelector(".bg-overlay1").classList.remove("active");
        document.querySelector(".sidebar-link").classList.remove("active");
    };

    const activeClass = () => {
        try {
            document.querySelector(".sidebar-link").classList?.add("active");
            document.querySelector(".bg-overlay1").classList?.add("active");
        } catch (e) {
            console.warn(e)
        }
    };

    const openCloseSidebar = (toggle) => {
        if (toggle) {
            setSidebartoogle(!toggle);
            document.querySelector(".page-header").className =
                "page-header close_icon";
            document.querySelector(".sidebar-wrapper").className =
                "sidebar-wrapper close_icon ";
        } else {
            setSidebartoogle(!toggle);

            document.querySelector(".page-header").className = "page-header";
            document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper ";
        }
    };

    const responsiveSidebar = () => {
        document.querySelector(".page-header").className = "page-header close_icon";
        document.querySelector(".sidebar-wrapper").className =
            "sidebar-wrapper close_icon";
    };

    return (
        <Fragment>
            <div
                className={`bg-overlay1`}
                onClick={() => {
                    closeOverlay();
                }}
            ></div>

            <div className="sidebar-wrapper" id="sidebar-wrapper">
                <div className="logo-wrapper" style={{backgroundColor: "#193256", color: "black", padding: "0px"}}>
                    <div>
                        <img
                            className="img-fluid for-light"
                            src={require("../../assets/images/logo/logo_dark.png")}
                            alt="" height={"200px"} width={"200px"}
                        />
                    </div>
                    <div>
                        <img
                            className="img-fluid for-dark"
                            src={require("../../assets/images/logo/logo_dark.png")}
                            alt=""
                        />
                    </div>

                    <div className="back-btn" onClick={() => responsiveSidebar()}>
                        <i className="fa fa-angle-left"></i>
                    </div>
                    <div
                        className="toggle-sidebar"
                        onClick={() => openCloseSidebar(sidebartoogle)}
                    >
                        {/* <Grid
              className="status_toggle  sidebar-toggle"
              style={{ color: "#F4D56D" }}
            /> */}
                        <img src={Zoom} alt={""} height={16}/>
                    </div>
                </div>
                <div
                    className="logo-icon-wrapper"
                    style={{backgroundColor: "#133A4A"}}
                >
                    <img
                        className="imagelogo"
                        src={require("../../assets/images/logo/logo-icon.png")}
                        alt=""
                    />
                </div>
                <nav className="sidebar-main" id="sidebar-main">
                    <div className="left-arrow" onClick={scrollToLeft}>
                        <ArrowLeft/>
                    </div>
                    <div id="sidebar-menu">
                        <ul className="sidebar-links">
                            <li className="back-btn">
                                <div className="mobile-back text-right">
                                    <span>{"Back"}</span>
                                    <i className="fa fa-angle-right pl-2" aria-hidden="true"></i>
                                </div>
                            </li>
                            {MENUITEMS.map((Item, i) => (
                                <Fragment key={i}>
                                    {Item.Items.map(
                                        (menuItem, i) =>
                                            (menuItem.permission.some(name => name === group.toString()) || (menuItem.stock && stock_service)) && (
                                                <li className="sidebar-list" key={i}>
                                                    {menuItem.type === "sub" ? (
                                                        <a
                                                            href="/"
                                                            className={`sidebar-link sidebar-title ${
                                                                menuItem.active ? activeClass() : ""
                                                            }`}
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                setNavActive(menuItem);
                                                            }}
                                                        >
                                                            <menuItem.icon/>
                                                            <span>{props.t(menuItem.title)}</span>
                                                            {menuItem.badge ? (
                                                                <label className={menuItem.badge}>
                                                                    {menuItem.badgetxt}
                                                                </label>
                                                            ) : (
                                                                ""
                                                            )}
                                                            <div className="according-menu">
                                                                {menuItem.active ? (
                                                                    <i className="fa fa-angle-down"></i>
                                                                ) : (
                                                                    <i className="fa fa-angle-right"></i>
                                                                )}
                                                            </div>
                                                        </a>
                                                    ) : (
                                                        ""
                                                    )}

                                                    {menuItem.type === "link" ? (
                                                        <Link
                                                            to={menuItem.path}
                                                            className={`sidebar-link sidebar-title link-nav  ${
                                                                menuItem.active ? "active" : ""
                                                            }`}
                                                            onClick={() => toggletNavActive(menuItem)}
                                                        >
                                                            <menuItem.icon/>
                                                            <span>{props.t(menuItem.title)}</span>
                                                            {menuItem.badge ? (
                                                                <label className={menuItem.badge}>
                                                                    {menuItem.badgetxt}
                                                                </label>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Link>
                                                    ) : (
                                                        ""
                                                    )}

                                                    {menuItem.children ? (
                                                        <ul
                                                            className="sidebar-submenu"
                                                            style={
                                                                menuItem.active
                                                                    ? sidebartoogle
                                                                        ? {
                                                                            opacity: 1,
                                                                            transition: "opacity 500ms ease-in",
                                                                        }
                                                                        : {display: "block"}
                                                                    : {display: "none"}
                                                            }
                                                        >
                                                            {menuItem.children.map((childrenItem, index) => {
                                                                return (
                                                                    (childrenItem.permission.some(name => name === group.toString()) || (childrenItem.stock && stock_service)) && (
                                                                        <li key={index}>
                                                                            {childrenItem.type === "sub" ? (
                                                                                <a
                                                                                    href="/"
                                                                                    className={`${
                                                                                        childrenItem.active ? "active" : ""
                                                                                    }`}
                                                                                    onClick={(event) => {
                                                                                        event.preventDefault();
                                                                                        toggletNavActive(childrenItem);
                                                                                    }}
                                                                                >
                                                                                    {props.t(childrenItem.title)}
                                                                                    <span className="sub-arrow">
                                            <i className="fa fa-chevron-right"></i>
                                          </span>
                                                                                    <div className="according-menu">
                                                                                        {childrenItem.active ? (
                                                                                            <i className="fa fa-angle-down"></i>
                                                                                        ) : (
                                                                                            <i className="fa fa-angle-right"></i>
                                                                                        )}
                                                                                    </div>
                                                                                </a>
                                                                            ) : (
                                                                                ""
                                                                            )}

                                                                            {childrenItem.type === "link" ? (
                                                                                <Link
                                                                                    to={childrenItem.path}
                                                                                    className={`${
                                                                                        childrenItem.active ? "active" : ""
                                                                                    }`}
                                                                                    onClick={() =>
                                                                                        toggletNavActive(childrenItem)
                                                                                    }
                                                                                >
                                                                                    {props.t(childrenItem.title)}
                                                                                </Link>
                                                                            ) : childrenItem.type === "hyperlink" ? (
                                                                                <a
                                                                                    href={childrenItem.path}
                                                                                    className={`${
                                                                                        childrenItem.active ? "active" : ""
                                                                                    }`}
                                                                                    onClick={() =>
                                                                                        toggletNavActive(childrenItem)
                                                                                    }
                                                                                >
                                                                                    {props.t(childrenItem.title)}
                                                                                </a>
                                                                            ) : (
                                                                                ""
                                                                            )}

                                                                            {childrenItem.children ? (
                                                                                <ul
                                                                                    className="nav-sub-childmenu submenu-content"
                                                                                    style={
                                                                                        childrenItem.active
                                                                                            ? {display: "block"}
                                                                                            : {display: "none"}
                                                                                    }
                                                                                >
                                                                                    {childrenItem.children.map(
                                                                                        (childrenSubItem, key) => (
                                                                                            <li key={key}>
                                                                                                {childrenSubItem.type ===
                                                                                                "link" ? (
                                                                                                    <Link
                                                                                                        to={childrenSubItem.path}
                                                                                                        className={`${
                                                                                                            childrenSubItem.active
                                                                                                                ? "active"
                                                                                                                : ""
                                                                                                        }`}
                                                                                                        onClick={() =>
                                                                                                            toggletNavActive(
                                                                                                                childrenSubItem
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        {props.t(
                                                                                                            childrenSubItem.title
                                                                                                        )}
                                                                                                    </Link>
                                                                                                ) : (
                                                                                                    ""
                                                                                                )}
                                                                                            </li>
                                                                                        )
                                                                                    )}
                                                                                </ul>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </li>
                                                                    )
                                                                );
                                                            })}
                                                        </ul>
                                                    ) : (
                                                        ""
                                                    )}
                                                </li>
                                            )
                                    )}
                                </Fragment>
                            ))}
                        </ul>
                    </div>
                    <div className="right-arrow" onClick={scrollToRight}>
                        <ArrowRight/>
                    </div>
                </nav>
            </div>
        </Fragment>
    );
};

export default translate(Sidebar);
