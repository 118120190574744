import React from 'react'
import {
    Col,
    Row,
} from "reactstrap";
import cr1 from "../images/stockage.png"
import cr2 from "../images/centre-d'appel.png"
import cr3 from "../images/livraison.png"

import "../css/crd.css" ;
import Card from 'react-bootstrap/Card';
export default function Threecards() {
  return (
    <Row style={{backgroundColor : "#f3f6f4" , padding :" 20px 0px"}}>
        <Col md={4} sm={12} style={{alignItems : "center" , justifyContent : "center" , display : "flex"  , textAlign : "center" }} >
        <Card style={{ width: '20rem' , borderRadius : "30px" }}>
      <Card.Img variant="top" src={cr1} style={{ borderRadius : "5px"}} />
      <Card.Body>
        <Card.Title><h2>Stockage</h2></Card.Title>
        <Card.Text>
        <h5>solution de stockage automatisé</h5>
        </Card.Text>
      </Card.Body>
    </Card>
        </Col>

        <Col md={4} sm={12} style={{alignItems : "center" , justifyContent : "center" , display : "flex"  , textAlign : "center"}}>
        <Card style={{ width: '20rem' , borderRadius : "15px"  }}>
      <Card.Img variant="top" src={cr2} style={{ borderRadius : "5px"}} />
      <Card.Body>
        <Card.Title><h2>Call center</h2></Card.Title>
        <Card.Text>
       <h5> Résolution et assistance</h5>
       <h5 style={{visibility : "hidden"}}> Résolution et assistance</h5>
        </Card.Text>
      </Card.Body>
    </Card>
        </Col>

        <Col md={4} sm={12} style={{alignItems : "center" , justifyContent : "center" , display : "flex" , textAlign : "center"}}>
        <Card style={{ width: '20rem' , borderRadius : "15px" }}>
      <Card.Img variant="top" src={cr3} style={{ borderRadius : "5px"}}/>
      <Card.Body>
        <Card.Title><h2>livraison</h2></Card.Title>
        <Card.Text>
       <h5> Une solution de livraison complète</h5>
        </Card.Text>
      </Card.Body>
    </Card>
        </Col>
    </Row>

  )
}


