import { api } from "../../utils/backend.instance";
import {
  LOGOUT,
} from "../actionTypes";

export const loadUser = async (access)  => {
  const body = { refresh: access };

  return await api.post("/accounts/auth/refresh/", body)
};

export const login = async (username, password) => {
  const body = JSON.stringify({ username, password });

  return await api.post("/accounts/auth/login/", body)
};

export const Logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
};
