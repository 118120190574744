import {
  GET_ADRESSE,
  GET_ADRESSES,
  ADRESSE_LOADING,
  ADRESSE_ERROR,
    UPDATE_ADRESSE
} from "../../redux/actionTypes";

const initial_state = {
  adresse: null,
  count: 0,
  adresses: [],
  loading: true,
  error: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initial_state, action) {
  const { type, payload } = action;
  switch (type) {
    case ADRESSE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADRESSE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case GET_ADRESSE:
      return {
        ...state,
        adresse: payload,
        loading: false,
      };
    case GET_ADRESSES:
      return {
        ...state,
        count: payload.count,
        adresses: payload.results,
        loading: false,
      };
      case UPDATE_ADRESSE:
      return {
        ...state,
        count: payload.count,
        adresse: payload.results,
        loading: false,
      };
    default:
      return state;
  }
}
