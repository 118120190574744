import React, {
    Fragment,
    useState,
    useEffect,
    useMemo, useContext,
} from "react";
import PropTypes from "prop-types";
import Select, {components} from "react-select";
import Alert from "../../redux/Alert";
import {connect} from "react-redux";
import {Link, withRouter, useLocation} from "react-router-dom";
import {Field, Formik} from "formik";
import {
    addNewcolis,
    getVille,
    getCite,
    getDeligation,
    getLivreur,
    getadresseF,
    getVille1,
    getCite1,
    getVilleF,
    getCiteF, getLivreurs,
} from "./action";
import {getFinances} from "../finance/action";
import {getProduits} from "../stock/action";
import {
    getCurrentFournisseur, getFournisseurs,
} from "../Fournisseurs/action";
import Breadcrumb from "../../layout/breadcrumb";
import delegation from "../../data/delegation.json"
import ville from "../../data/ville.json"
import cite from "../../data/cite.json"
import am_city from "../../data/am_city.json"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button as B,
    CardHeader, Modal, ModalHeader, ModalBody, ModalFooter,
} from "reactstrap";
import {useDispatch} from "react-redux";
import Spinner from "./spinner.gif";
import Button from "../../utils/Button";
import AppContext from "../../context/Context";
import {api} from "../../utils/backend.instance";

const Newcoli = ({
                     addNewcolis,
                     history,
                     financeData,
                     fournisseurData,
                     fournisseur: {fournisseur, loading},
                 }) => {
    const [stateville, setVille] = useState(ville);
    const [selectedfournisseur, setSelectedFournisseur] = useState([]);
    const [statecite, setCite] = useState(cite);
    const [statevillef, setVilleF] = useState(ville);

    const [statecitef, setCiteF] = useState(cite);
    const [delegations, ] = useState(delegation);
    const [statefournisseur, setFournisseur] = useState([
        {
            id: "",
            responsable: "",
        },
    ]);
    const [statefinance, setFinance] = useState([
        {
            id: "",
        },
    ]);
    const [livreur, setLivreur] = useState(null);
    const [Forlivreur, setForlivreur] = useState("");
    const [currentPage, setCurrentPage] = useState(1)
    const [currentFournisseurPage, setCurrentFournisseurPage] = useState(1)
    const [livreurDatas, setLivreurData] = useState([])
    let F = parseInt(localStorage.getItem("fournisseur"));
    const [formData, setFormData] = useState({
        client_telephone_1: "",
        client_telephone_2: "",
        client_email: "",
        client_prenom: ".",
        client_name: "",
        client_zipcode: "",
        client_ville: "",
        client_deligaiton: "",
        client_cite: "",
        client_filiere: "",
        client_address: "",
        expiditeur_ville: "",
        expiditeur_cite: "",
        expiditeur_deligaiton: "",
        expiditeur_zipcode: "",
        remarque_echange: "",
        livreur: "",
        fournisseur: "",
        prix: 0,
        service: "livraison",
        volume: 1,
        remarque: "",
        product_name: "",
        client_address_pickup: "",
        related_payment: "",
        products: [],
    });
    const [showLoader, setShowLoader] = useState(false)
    const [prix, ] = useState([])
    const [weight, ] = useState([])
    const [totalPrice, setTotalPrice] = useState(null)
    const [totalWeight, setTotalWeight] = useState(0)
    const [loadings, setLoadings] = useState(false)
    const [modalWarning, setModalWarning] = useState(false)
    const [, setProducts] = useState([])

    const {
        config: {group, }
    } = useContext(AppContext);


    const onChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };
    const dispatch = useDispatch();

    const [taptopStyle, setTapTopStyle] = useState("none");

    const handleScroll = () => {
        if (window.scrollY > 600) {
            setTapTopStyle("block");
        } else {
            setTapTopStyle("none");
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        handleScroll();
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [taptopStyle]);

    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };
    let query = useQuery();
    let productQuery = useQuery()

    // console.log("am_city ",am_city);

    // const getDeligation = async () => {
    //     setLoadings(true)
    //     if (localStorage.access) {
    //         setAuthToken(localStorage.access);
    //     }
    //     await api.get(`/colis/delegation/`).then(r => setDelegation(r.data?.results));
    //     setLoadings(false)
    // };

    // const getVille = async (params) => {
    //     if (localStorage.access) {
    //         setAuthToken(localStorage.access);
    //     }
    //     return await api.get(`/colis/ville/?${params.toString()}`)
    // };

    // const getCite = async (params) => {
    //     if (localStorage.access) {
    //         setAuthToken(localStorage.access);
    //     }
    //     return await api.get(`/colis/cite/?${params.toString()}`)
    // };

    const onChangeZipcode = (e) => {
        setLoadings(true)
        setFormData({...formData, [e.target.name]: e.target.value});
        console.log("Fornisure ", Forlivreur)
        if(Forlivreur === 'aramex' || Forlivreur === 'Aramex'){
            setCite(am_city.sort((a, b) => (a.name || '').localeCompare(b.name || '')));
        }else{
            setCite(cite.filter(c => c.zipcode === e.target.value))
        } 
        setLoadings(false)
    };

    const onChangeDeligation = (e) => {
        setLoadings(true)
        setFormData({...formData, [e.target.name]: e.target.value});
        setVille(ville.filter(v => v.country_id === e.target.value))
        setLoadings(false)
    };

    const onChangeDeligationF = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
        setVilleF(ville.filter(v => v.country_id === e.target.value))
    };

    const onChangeVille = (e) => {
        setLoadings(true)
        setFormData({...formData, [e.target.name]: e.target.value});
        console.log("Fornisure ", Forlivreur)
        if(Forlivreur === 'aramex' || Forlivreur === 'Aramex'){
            setCite(am_city.sort((a, b) => (a.name || '').localeCompare(b.name || '')));
        }else{
            setCite(cite.filter(c => c.ville_id === e.target.value))
        }
        setLoadings(false)
    };

    const onChangeVilleF = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});

        setCiteF(cite.filter(c => c.ville_id === e.target.value))
    };

    const getFournisseur = async (id) => {
        setLoadings(true)
        await api.get(`/fournisseur/fournisseur/${id}/`)
            .then(res => {
                setFormData({
                    ...formData,
                    fournisseur: id,
                    expiditeur_deligaiton: res?.data?.delegation,
                    expiditeur_ville: res?.data?.ville,
                    expiditeur_cite: res?.data?.cite,
                    expiditeur_zipcode: res?.data?.code_postale,
                    client_address_pickup: res?.data?.absolute_address,
                    petit_livraison: res?.data?.petit_livraison,
                    grand_livraison: res?.data?.grand_livraison,
                })
                setForlivreur(res?.data?.livreur_name)
                if(res?.data?.livreur_name.toLowerCase() === 'aramex'){
                    console.log("================================ ", res?.data?.livreur_name)
                    setCiteF(am_city.sort((a, b) => (a.name || '').localeCompare(b.name || '')));
                }
            })
        setLoadings(false)
        // if(Forlivreur === 'Aramex' || Forlivreur === 'aramex'){
        //     console.log('am_city Executed')
        //     setCiteF(am_city.sort((a, b) => (a.name || '').localeCompare(b.name || '')))
        // }else{
        //     console.log('am_city not Executed')
        // }
    }


    

    useEffect(() => {
        if (F)
            getFournisseur(F)
        // eslint-disable-next-line
    }, [F])

    useEffect(() => {
        if (fournisseur) {
            setFormData({
                ...formData,
                fournisseur: fournisseur.id,
                client_address_pickup: fournisseur.absolute_address,
                expiditeur_cite: fournisseur.cite,
                expiditeur_deligaiton: fournisseur.delegation,
                expiditeur_ville: fournisseur.ville,
                expiditeur_zipcode: fournisseur.code_postale,
                petit_livraison: fournisseur?.petit_livraison,
                grand_livraison: fournisseur?.grand_livraison,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, selectedfournisseur]);

//get ville
    useEffect(() => {
        if (!F) {
            dispatch(getVille1());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    //get fournisseur
    useEffect(() => {
        if (!F) {
            query.set("page_size", "50")
            query.set("page", currentFournisseurPage)
            dispatch(getFournisseurs(query))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFournisseurPage]);

    useEffect(() => {
        setFournisseur(fournisseurData.fournisseurs);
    }, [fournisseurData.fournisseurs, fournisseurData.loading, statefournisseur]);


    // get cite
    useEffect(() => {
        if (!F) {
            dispatch(getCite1());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const onChangeFournisseur = (e) => {
        setLoadings(true)
        setSelectedFournisseur(e);
        setLoadings(false)
        // if(Forlivreur.toLowerCase() === 'aramex'){
        //     console.log("===== Set Loading False =========== ", Forlivreur)
        //     setCiteF(am_city.sort((a, b) => (a.name || '').localeCompare(b.name || '')));
        // }
    };

    //get livreur
    useEffect(() => {
        query.set("page_size", "50")
        query.set("page", currentPage)
        getLivreurs(query)
            .then(res => setLivreurData([...livreurDatas, ...res?.results]))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    useEffect(() => {
        getProduits(query)
            .then(res => setProducts(res?.results))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    let user_groups_name = group;
    //get Finance
    useEffect(() => {
        if (F) {
            dispatch(getFinances(query));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setFinance(financeData.finances);
    }, [financeData.finances, financeData.loading, statefinance]);
    const data = [];
    fournisseurData &&
    fournisseurData.fournisseurs.map((F) =>
        data.push({
            value: parseInt(F.id),
            label: F.name,
        })
    );
    const NoOptionsMessage = props => {
        return (
            <components.NoOptionsMessage {...props}>
                <div className="text-center">
                    <img src={Spinner} style={{width: "10%"}} alt=""/>
                </div>
            </components.NoOptionsMessage>
        );
    };
    const handleSubmit = async (e, values) => {
        setShowLoader(true)
        e.preventDefault();
        console.log(values)
        await addNewcolis({...values, prix: totalPrice}, history);
        setShowLoader(false)
    };

    const onChangeLivreur = (e) => {
        setFormData({...formData, livreur: e.value});
        setLivreur(e);
    };

    let livreurOptions;

    livreurOptions = livreurDatas.map((livreur) => ({
        value: livreur.id,
        label: livreur.name
    }));

// Add the reset option
    livreurOptions = [
        {value: "", label: "------------------"},
        ...livreurOptions
    ];

    useEffect(() => {
        setLoadings(true)
        console.log(prix)
        if (prix.length > 0) {
            let total = 0
            // eslint-disable-next-line array-callback-return
            prix.map((p) => {
                for (const [, value] of Object.entries(p)) {
                    total += value
                }
            })
            setTotalPrice(total + (totalWeight > 10 ? formData.grand_livraison : formData.petit_livraison))
        }
        setLoadings(false)
    }, [formData.grand_livraison, formData.petit_livraison, prix, totalWeight])

    useEffect(() => {
        setLoadings(true)
        console.log(weight)
        if (weight.length > 0) {
            let total = 0
            // eslint-disable-next-line array-callback-return
            weight.map((p) => {
                for (const [, value] of Object.entries(p)) {
                    total += value
                }
            })
            setTotalWeight(total)
        }
        setLoadings(false)
    }, [weight])

    return (
        <Fragment>
            <Breadcrumb parent="Colis" title="Ajouter un colis"/>
            {loadings ?
                <img src={Spinner} style={{width: "10%", position: "relative", left: "45%"}} alt=""/> :
                <Container fluid={true}>
                    <Formik
                        initialValues={formData}
                        onSubmit={(values) => {
                            console.log(values)
                        }}
                    >
                        {({values, setFieldValue}) => (
                            <Form className="theme-form">
                                <Alert/>
                                <Row>
                                    <Col lg={12} xl="7">
                                        {user_groups_name && user_groups_name.indexOf("admin") !== -1 ? (
                                            <Card>
                                                <CardHeader>
                                                    <h5> Details Fournisseur</h5>
                                                </CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label>Fournisseur</Label>
                                                                <Field
                                                                    name={"fournisseur"}
                                                                    className={"form-control"}
                                                                >
                                                                    {({field, form}) => (
                                                                        <Select
                                                                            options={data}
                                                                            placeholder={"Fournisseur"}
                                                                            components={{NoOptionsMessage}}
                                                                            onInputChange={(e) => {
                                                                                query.set('search', e);
                                                                                dispatch(getFournisseurs(query))
                                                                            }}
                                                                            onMenuScrollToBottom={(e) => {
                                                                                console.log(e)
                                                                                setCurrentFournisseurPage(page => page + 1)
                                                                            }}
                                                                            onChange={(e) => {
                                                                                onChangeFournisseur(e);
                                                                                getFournisseur(e.value)
                                                                                    .then(res => {
                                                                                        setFieldValue("client_address_pickup", res?.client_address_pickup)
                                                                                        setFieldValue("expiditeur_zipcode", res?.expiditeur_zipcode)
                                                                                        setFieldValue("expiditeur_deligaiton", res?.expiditeur_deligaiton)
                                                                                        setFieldValue("expiditeur_ville", res?.expiditeur_ville)
                                                                                        setFieldValue("expiditeur_cite", res?.expiditeur_cite)
                                                                                        productQuery.set("fournisseur_id", e.value)
                                                                                        getProduits(productQuery)
                                                                                            .then(r => setProducts(r?.results))
                                                                                    })
                                                                                setFieldValue(field.name, e.value)
                                                                            }}
                                                                            value={selectedfournisseur}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label>Adresse pickup</Label>
                                                                <Field
                                                                    name={"client_address_pickup"}
                                                                    className={"form-control"}
                                                                >
                                                                    {({field, form}) => (
                                                                        <Input
                                                                            id="address_pickup"
                                                                            maxLength={100}
                                                                            type="text"
                                                                            placeholder={"Adresse Pickup"}
                                                                            value={field.value}
                                                                            onChange={(e) => {
                                                                                onChange(e)
                                                                                setFieldValue(field.name, e.target.value)
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </FormGroup>
                                                        </Col>

                                                        <Col>
                                                            <FormGroup>
                                                                <Label>Code postale expéditeur</Label>
                                                                <Field
                                                                    name={"expiditeur_zipcode"}
                                                                    className={"form-control"}
                                                                >
                                                                    {({field, form}) => (
                                                                        <Input
                                                                            maxLength={100}
                                                                            type="text"
                                                                            placeholder={"Code postale"}
                                                                            value={field.value}
                                                                            onChange={(e) => {
                                                                                onChange(e)
                                                                                setFieldValue(field.name, e.target.value)
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label>Délégation expéditeur</Label>
                                                                <Field
                                                                    name={"expiditeur_deligaiton"}
                                                                    className={"form-control"}
                                                                >
                                                                    {({field, form}) => (
                                                                        <select
                                                                            className="form-control digits"
                                                                            value={field.value}
                                                                            onChange={(e) => {
                                                                                onChangeDeligationF(e)
                                                                                setFieldValue(field.name, e.target.value)
                                                                            }}
                                                                        >
                                                                            <option value="" disabled defaultValue
                                                                                    hidden>
                                                                                ----------
                                                                            </option>
                                                                            {delegations?.map((delegation) => (
                                                                                    <option
                                                                                        key={delegation.id}
                                                                                        value={delegation.id}
                                                                                    >
                                                                                        {delegation.name}
                                                                                    </option>
                                                                                ))}
                                                                        </select>
                                                                    )}
                                                                </Field>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label>Ville expéditeur</Label>
                                                                <Field
                                                                    name={"expiditeur_ville"}
                                                                    className={"form-control"}
                                                                >
                                                                    {({field, form}) => (
                                                                        <select
                                                                            className="form-control digits"
                                                                            value={field.value}
                                                                            onChange={(e) => {
                                                                                onChangeVilleF(e)
                                                                                setFieldValue(field.name, e.target.value)
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            <option value="" disabled defaultValue
                                                                                    hidden>
                                                                                ----------
                                                                            </option>
                                                                            {statevillef?.map((ville) => (
                                                                                    <option key={ville.id}
                                                                                            value={ville.id}>
                                                                                        {ville.name}
                                                                                    </option>
                                                                                ))}
                                                                        </select>
                                                                    )}
                                                                </Field>

                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label>Cité expéditeur</Label>
                                                                <Field
                                                                    name={"expiditeur_cite"}
                                                                    className={"form-control"}
                                                                >
                                                                    {({field, form}) => (
                                                                        <select
                                                                            className="form-control digits"
                                                                            value={field.value}
                                                                            onChange={(e) => {
                                                                                onChange(e)
                                                                                setFieldValue(field.name, e.target.value)
                                                                            }}
                                                                        >
                                                                            <option value="" disabled defaultValue
                                                                                    hidden>
                                                                                ----------
                                                                            </option>
                                                                            {statecitef?.map((cite) => (
                                                                                    <option key={cite.id}
                                                                                            value={cite.id}>
                                                                                        {cite.name}
                                                                                    </option>
                                                                                ))}
                                                                        </select>
                                                                    )}
                                                                </Field>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        ) : (
                                            ""
                                        )}
                                        <Card>
                                            <CardHeader>
                                                <h5>Détails Client</h5>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Nom et Prénom (*)</Label>
                                                            <Field name={"client_name"}>
                                                                {({field, form}) => (
                                                                    <Input
                                                                        className="form-control"
                                                                        type="text"
                                                                        required
                                                                        name={"client_name"}
                                                                        placeholder="Nom et Prenom"
                                                                        value={field.value}
                                                                        onChange={(e) => {
                                                                            onChange(e);
                                                                            setFieldValue(field.name, e.target.value)
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Code postale (*)</Label>
                                                            <Field name={"client_zipcode"}>
                                                                {({field, form}) => (
                                                                    <Input
                                                                        className="form-control"
                                                                        type="text"
                                                                        required
                                                                        name={"client_zipcode"}
                                                                        maxLength={100}
                                                                        placeholder="Code postale"
                                                                        value={field.value}
                                                                        onChange={(e) => {
                                                                            onChangeZipcode(e)
                                                                            setFieldValue(field.name, e.target.value)
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Email</Label>
                                                            <Field name={"client_email"}>
                                                                {({field, form}) => (
                                                                    <Input
                                                                        className="form-control"
                                                                        type="text"
                                                                        name={"client_email"}
                                                                        placeholder="Email"
                                                                        value={field.value}
                                                                        onChange={(e) => {
                                                                            onChange(e)
                                                                            setFieldValue(field.name, e.target.value)
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Délégation client (*)</Label>
                                                            <Field name={"client_deligaiton"}>
                                                                {({field, form}) => (
                                                                    <select
                                                                        className="form-control digits"
                                                                        id="delegation"
                                                                        required
                                                                        name={"client_deligaiton"}
                                                                        value={field.value}
                                                                        onChange={(e) => {
                                                                            onChangeDeligation(e)
                                                                            setFieldValue(field.name, e.target.value)
                                                                        }}
                                                                    >
                                                                        <option value="" disabled defaultValue hidden>
                                                                            ----------
                                                                        </option>
                                                                        {delegations?.map((delegation) => (
                                                                                <option key={delegation.id}
                                                                                        value={delegation.id}>
                                                                                    {delegation.name}
                                                                                </option>
                                                                            ))}
                                                                    </select>
                                                                )}
                                                            </Field>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Téléphone (*)</Label>
                                                            <Field name={"client_telephone_1"}>
                                                                {({field, form}) => (
                                                                    <Input
                                                                        className="form-control"
                                                                        type="text"
                                                                        required
                                                                        name="client_telephone_1"
                                                                        placeholder="Téléphone 1"
                                                                        value={field.value}
                                                                        onChange={(e) => {
                                                                            onChange(e)
                                                                            setFieldValue(field.name, e.target.value)
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Ville client (*)</Label>
                                                            <Field name={"client_ville"}>
                                                                {({field, form}) => (
                                                                    <select
                                                                        className="form-control digits"
                                                                        id="ville"
                                                                        required
                                                                        name={"client_ville"}
                                                                        value={field.value}
                                                                        onChange={(e) => {
                                                                            onChangeVille(e)
                                                                            setFieldValue(field.name, e.target.value)
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        <option value="" disabled defaultValue hidden>
                                                                            ----------
                                                                        </option>
                                                                        {stateville?.map((ville) => (
                                                                                <option key={ville.id} value={ville.id}>
                                                                                    {ville.name}
                                                                                </option>
                                                                            ))}
                                                                    </select>
                                                                )}
                                                            </Field>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Téléphone 2</Label>
                                                            <Field name={"client_telephone_2"}>
                                                                {({field, form}) => (
                                                                    <Input
                                                                        className="form-control"
                                                                        type="text"
                                                                        name="client_telephone_2"
                                                                        placeholder="Téléphone 2"
                                                                        value={field.value}
                                                                        onChange={(e) => {
                                                                            onChange(e)
                                                                            setFieldValue(field.name, e.target.value)
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Cité client (*)</Label>
                                                            <Field name={"client_cite"}>
                                                                {({field, form}) => (
                                                                    <select
                                                                        className="form-control digits"
                                                                        id="cite"
                                                                        name={"client_cite"}
                                                                        required
                                                                        value={field.value}
                                                                        onChange={(e) => {
                                                                            onChange(e)
                                                                            setFieldValue(field.name, e.target.value)
                                                                        }}
                                                                    >
                                                                        <option value="" disabled defaultValue hidden>
                                                                            ----------
                                                                        </option>
                                                                        {statecite?.map((cite) => (
                                                                                <option key={cite.id} value={cite.id}>
                                                                                    {cite.name}
                                                                                </option>
                                                                            ))}
                                                                    </select>
                                                                )}
                                                            </Field>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Adresse client (*)</Label>
                                                            <Field name={"client_address"}>
                                                                {({field, form}) => (
                                                                    <Input
                                                                        className="form-control"
                                                                        type="text"
                                                                        required
                                                                        name={"client_address"}
                                                                        maxLength={100}
                                                                        placeholder="Adresse"
                                                                        value={field.value}
                                                                        onChange={(e) => {
                                                                            onChange(e)
                                                                            setFieldValue(field.name, e.target.value)
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col lg={12} xl="5">
                                        <Card>
                                            <CardHeader>
                                                <h5>Détails Colis</h5>
                                            </CardHeader>
                                            <CardBody>

                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Produit (*)</Label>
                                                            <Field name={"product_name"}>
                                                                {({field, form}) => (
                                                                    <Input
                                                                        className="form-control"
                                                                        type="text"
                                                                        maxLength={255}
                                                                        required
                                                                        name="product_name"
                                                                        placeholder="Produit"
                                                                        value={field.value}
                                                                        onChange={(e) => {
                                                                            onChange(e)
                                                                            setFieldValue(field.name, e.target.value)
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Prix <span className={"text-muted"}>Avec frais de livraison</span> (*)</Label>
                                                            <Field name={"prix"}>
                                                                {({field, form}) => (
                                                                    <Input
                                                                        className="form-control"
                                                                        required
                                                                        type="text"
                                                                        name="prix"
                                                                        placeholder="prix + frais de livraison"
                                                                        value={totalPrice}
                                                                        onChange={(e) => {
                                                                            setTotalPrice(e.target.value)
                                                                            onChange(e)
                                                                            setFieldValue(field.name, e.target.value)
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </FormGroup>
                                                    </Col>

                                                <Col>
                                                    <FormGroup>
                                                        <Label>Volume (*)</Label>
                                                        <br/>
                                                        <Field name={"volume"}>
                                                            {({field, form}) => (
                                                                <Row>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                    <Col>
                                                                        <Input
                                                                            type="radio"
                                                                            checked={field.value === 1}
                                                                            value={1}
                                                                            name="volume"
                                                                            onChange={(e) => {
                                                                                onChange(e)
                                                                                setFieldValue(field.name, 1)
                                                                            }}
                                                                        />
                                                                        Petit
                                                                    </Col>
                                                                    <Col>
                                                                        <Input
                                                                            type="radio"
                                                                            checked={field.value === 11}
                                                                            value={11}
                                                                            name="volume"
                                                                            onChange={(e) => {
                                                                                onChange(e)
                                                                                setFieldValue(field.name, 11)
                                                                            }}
                                                                        />
                                                                        Grand
                                                                    </Col>
                                                                </Row>
                                                            )}
                                                        </Field>

                                                    </FormGroup>
                                                </Col>

                                                <Col>
                                                    <FormGroup>
{/* eslint-disable-next-line no-mixed-operators */}
                                                        {user_groups_name &&
                                                        // eslint-disable-next-line no-mixed-operators
                                                        user_groups_name.indexOf("admin") !== -1 ? (
                                                            <Label>Livreur</Label>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {/* eslint-disable-next-line no-mixed-operators */}
                                                        {user_groups_name &&
                                                        // eslint-disable-next-line no-mixed-operators
                                                        user_groups_name.indexOf("admin") !== -1 ? (
                                                            <Field name={"livreur"}>
                                                                {({field, form}) => (
                                                                    <Select
                                                                        options={livreurOptions}
                                                                        onChange={(e) => {
                                                                            onChangeLivreur(e)
                                                                            setFieldValue(field.name, e.value)
                                                                        }}
                                                                        value={livreur}
                                                                        name={'livreur'}
                                                                        className="m-2"
                                                                        onInputChange={(e) => {
                                                                            query.set('search', e);
                                                                            getLivreurs(query);
                                                                        }}
                                                                        onMenuScrollToBottom={(e) => {
                                                                            console.log(e)
                                                                            setCurrentPage(page => page + 1)
                                                                        }}
                                                                        placeholder={"Livreur"}
                                                                    />
                                                                )}
                                                            </Field>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </FormGroup>
                                                </Col>

                                                {user_groups_name &&
                                                user_groups_name.indexOf("admin") !== -1 ? (
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Related payment</Label>
                                                            <Field name={"related_payment"}>
                                                                {({field, form}) => (
                                                                    <select
                                                                        className="form-control"
                                                                        name="related_payment"
                                                                        value={field.value}
                                                                        onChange={(e) => {
                                                                            onChange(e)
                                                                            setFieldValue(field.name, e.target.value)
                                                                        }}
                                                                    >
                                                                        <option value="" disabled defaultValue hidden>
                                                                            ----------
                                                                        </option>
                                                                        {financeData &&
                                                                            financeData.finances.map((f) => (
                                                                                <option key={f.id} value={f.id}>
                                                                                    {f.id}
                                                                                </option>
                                                                            ))}
                                                                    </select>
                                                                )}
                                                            </Field>
                                                        </FormGroup>
                                                    </Col>
                                                ) : (
                                                    ""
                                                )}
                                                <Col>
                                                    <FormGroup>
                                                        <Label>Service (*)</Label>
                                                        <br/>
                                                        <Field name={"service"}>
                                                            {({field, form}) => (
                                                                <Row>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                    <Col>
                                                                        <Input
                                                                            type="radio"
                                                                            checked={field.value === "livraison"}
                                                                            value="livraison"
                                                                            name="service"
                                                                            onChange={(e) => {
                                                                                onChange(e)
                                                                                setFieldValue(field.name, "livraison")
                                                                            }}
                                                                        />
                                                                        Livraison
                                                                    </Col>
                                                                    <Col>
                                                                        <Input
                                                                            type="radio"
                                                                            checked={field.value === "echange"}
                                                                            value="echange"
                                                                            name="service"
                                                                            onChange={(e) => {
                                                                                onChange(e)
                                                                                setFieldValue(field.name, "echange")
                                                                            }}
                                                                        />
                                                                        Echange
                                                                    </Col>
                                                                </Row>
                                                            )}
                                                        </Field>
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <Label hidden={formData.service === "livraison"}>
                                                            Remarque echange
                                                        </Label>
                                                        <Field name={"remarque_echange"}>
                                                            {({field, form}) => (
                                                                <Input
                                                                    hidden={formData.service === "livraison"}
                                                                    className="form-control"
                                                                    type="text"
                                                                    maxLength={100}
                                                                    name="remarque_echange"
                                                                    placeholder="Description"
                                                                    value={field.value}
                                                                    onChange={(e) => {
                                                                        onChange(e)
                                                                        setFieldValue(field.name, e.target.value)
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>

                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <Label>Remarque</Label>
                                                        <Field name={"remarque"}>
                                                            {({field, form}) => (
                                                                <Input
                                                                    className="form-control"
                                                                    type="text"
                                                                    maxLength={100}
                                                                    name="remarque"
                                                                    placeholder="remarque"
                                                                    value={field.value}
                                                                    onChange={(e) => {
                                                                        onChange(e)
                                                                        setFieldValue(field.name, e.target.value)
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </FormGroup>
                                                </Col>
                                            </CardBody>
                                        </Card>
                                    </Col>


                                </Row>
                                {/*{user_groups_name.indexOf("admin") !== -1 || stock ?*/}
                                {/*    <Row>*/}
                                {/*        <Col>*/}
                                {/*            <Card>*/}
                                {/*                <CardHeader>*/}
                                {/*                    <Row>*/}
                                {/*                        <Col sm={8}>*/}
                                {/*                            <h5>Produits</h5>*/}
                                {/*                        </Col>*/}
                                {/*                        <Col sm={4}>*/}
                                {/*                            <FormGroup>*/}
                                {/*                                <Label>Prix <span className={"text-muted fs--1"}>Avec frais de livraison</span> (*)</Label>*/}
                                {/*                                <Field name={"prix"}>*/}
                                {/*                                    {({field, form}) => (*/}
                                {/*                                        <Input*/}
                                {/*                                            className="form-control"*/}
                                {/*                                            required*/}
                                {/*                                            type="text"*/}
                                {/*                                            name="prix"*/}
                                {/*                                            placeholder="prix + frais de livraison"*/}
                                {/*                                            value={totalPrice}*/}
                                {/*                                            onChange={(e) => {*/}
                                {/*                                                setTotalPrice(e.target.value)*/}
                                {/*                                                onChange(e)*/}
                                {/*                                                setFieldValue(field.name, e.target.value)*/}
                                {/*                                            }}*/}
                                {/*                                        />*/}
                                {/*                                    )}*/}
                                {/*                                </Field>*/}
                                {/*                            </FormGroup>*/}
                                {/*                        </Col>*/}
                                {/*                    </Row>*/}

                                {/*                </CardHeader>*/}
                                {/*                <CardBody>*/}
                                {/*                    <Row>*/}
                                {/*                        <FieldArray*/}
                                {/*                            name="products"*/}
                                {/*                            render={arrayHelpers => (*/}
                                {/*                                <>*/}
                                {/*                                    <Table>*/}
                                {/*                                        {values.products && values.products.length > 0 &&*/}
                                {/*                                            <thead>*/}
                                {/*                                            <tr>*/}
                                {/*                                                <th>Ref</th>*/}
                                {/*                                                <th>Image</th>*/}
                                {/*                                                <th>Prix</th>*/}
                                {/*                                                <th>Quantité</th>*/}
                                {/*                                                <th></th>*/}
                                {/*                                            </tr>*/}
                                {/*                                            </thead>*/}
                                {/*                                        }*/}
                                {/*                                        <tbody>*/}
                                {/*                                        {values.products && values.products.length > 0 ? (*/}
                                {/*                                                values.products.map((product, index) => (*/}
                                {/*                                                    <tr key={index} className={"mt-3"}>*/}
                                {/*                                                        <td className={"align-items-center justify-content-center"}>{product.reference}</td>*/}
                                {/*                                                        <td>*/}
                                {/*                                                            <img src={product.image}*/}
                                {/*                                                                 alt={product.reference}*/}
                                {/*                                                                 width={100} height={100}/>*/}
                                {/*                                                        </td>*/}
                                {/*                                                        <td>{product.cod}</td>*/}
                                {/*                                                        <td>*/}
                                {/*                                                            <Field*/}
                                {/*                                                                name={`products.${index}.quantity`}*/}
                                {/*                                                                placeholder={"Quantity"}*/}
                                {/*                                                            >*/}
                                {/*                                                                {({*/}
                                {/*                                                                      field,*/}
                                {/*                                                                      form*/}
                                {/*                                                                  }) => (*/}
                                {/*                                                                    <FormControl*/}
                                {/*                                                                        type={"number"}*/}
                                {/*                                                                        min={0}*/}
                                {/*                                                                        onChange={(e) => {*/}
                                {/*                                                                            form.handleChange(e);*/}
                                {/*                                                                            setFieldValue(field.name, e.target.value);*/}
                                {/*                                                                            const newPrixValue = product.cod * e.target.value;*/}

                                {/*                                                                            // Update the prix array*/}
                                {/*                                                                            setPrix((prevPrix) => {*/}
                                {/*                                                                                const newPrix = [...prevPrix]; // clone the previous prix array*/}
                                {/*                                                                                const existingIndex = newPrix.findIndex(p => p[index] !== undefined);*/}

                                {/*                                                                                if (existingIndex > -1) {*/}
                                {/*                                                                                    newPrix[existingIndex] = {[index]: newPrixValue}; // update the existing object*/}
                                {/*                                                                                } else {*/}
                                {/*                                                                                    newPrix.push({[index]: newPrixValue}); // add a new object if it doesn't exist*/}
                                {/*                                                                                }*/}

                                {/*                                                                                return newPrix;*/}
                                {/*                                                                            });*/}
                                {/*                                                                            const newWeightValue = product.weight * e.target.value;*/}

                                {/*                                                                            // Update the weight array*/}
                                {/*                                                                            setWeight((prevWeight) => {*/}
                                {/*                                                                                const newWeight = [...prevWeight]; // clone the previous prix array*/}
                                {/*                                                                                const existingIndex = newWeight.findIndex(p => p[index] !== undefined);*/}

                                {/*                                                                                if (existingIndex > -1) {*/}
                                {/*                                                                                    newWeight[existingIndex] = {[index]: newWeightValue}; // update the existing object*/}
                                {/*                                                                                } else {*/}
                                {/*                                                                                    newWeight.push({[index]: newWeightValue}); // add a new object if it doesn't exist*/}
                                {/*                                                                                }*/}

                                {/*                                                                                return newWeight;*/}
                                {/*                                                                            });*/}
                                {/*                                                                        }}*/}
                                {/*                                                                        value={field.value}*/}
                                {/*                                                                    />*/}
                                {/*                                                                )}*/}
                                {/*                                                            </Field>*/}
                                {/*                                                        </td>*/}
                                {/*                                                        <td>*/}
                                {/*                                                            <MinusCircle*/}
                                {/*                                                                style={{cursor: "pointer"}}*/}
                                {/*                                                                onClick={() => {*/}
                                {/*                                                                    arrayHelpers.remove(index)*/}
                                {/*                                                                    setPrix(prevState => prevState.filter(p => product.quantity * product.cod !== p[index]))*/}
                                {/*                                                                    setWeight(prevState => prevState.filter(p => product.quantity * product.weight !== p[index]))*/}
                                {/*                                                                }}/>*/}
                                {/*                                                        </td>*/}
                                {/*                                                    </tr>*/}
                                {/*                                                ))*/}
                                {/*                                            )*/}
                                {/*                                            : ""*/}
                                {/*                                        }*/}
                                {/*                                        </tbody>*/}
                                {/*                                    </Table>*/}
                                {/*                                    <Row*/}
                                {/*                                        className={"d-flex flex-wrap justify-content-center"}>*/}
                                {/*                                        <Col md={12}>*/}
                                {/*                                            <button*/}
                                {/*                                                type="button"*/}
                                {/*                                                className="btn text-white ml-2"*/}
                                {/*                                                style={{backgroundColor: "#0d9a0d"}}*/}
                                {/*                                                onClick={toggleModal}*/}
                                {/*                                            >*/}
                                {/*                                                Ajouter produit*/}
                                {/*                                            </button>*/}
                                {/*                                        </Col>*/}

                                {/*                                    </Row>*/}
                                {/*                                    <Modal isOpen={modal2} toggle={toggleModal} centered*/}
                                {/*                                           size="lg">*/}
                                {/*                                        <ModalHeader*/}
                                {/*                                            style={{*/}
                                {/*                                                backgroundImage:*/}
                                {/*                                                    "linear-gradient(to right,#FFD166, #133A4A  )",*/}
                                {/*                                            }}*/}
                                {/*                                            className="p-3 text-white"*/}
                                {/*                                            toggle={toggleModal}>Ajouter</ModalHeader>*/}
                                {/*                                        <ModalBody>*/}
                                {/*                                            <div>*/}
                                {/*                                                <Label htmlFor="filter">Filtre:</Label>*/}
                                {/*                                                <Input type="search" id="filter"*/}
                                {/*                                                       value={searchValue} autoFocus*/}
                                {/*                                                       onChange={(e) => {*/}
                                {/*                                                           setSearchValue(e.target.value)*/}
                                {/*                                                           query.set("search", e.target.value)*/}
                                {/*                                                           delayedLoadProducts(query).then(res => setProducts(res?.results))*/}
                                {/*                                                       }}*/}
                                {/*                                                />*/}
                                {/*                                            </div>*/}
                                {/*                                            <Table>*/}
                                {/*                                                <thead>*/}
                                {/*                                                <tr>*/}
                                {/*                                                    <th>Ref</th>*/}
                                {/*                                                    <th>Image</th>*/}
                                {/*                                                    <th>Prix</th>*/}
                                {/*                                                    <th>Disponible</th>*/}
                                {/*                                                    <th>Etage</th>*/}
                                {/*                                                    <th>Quantité</th>*/}
                                {/*                                                </tr>*/}
                                {/*                                                </thead>*/}
                                {/*                                                <tbody>*/}
                                {/*                                                {products && products.map((product, index) => (*/}
                                {/*                                                    <tr key={product.reference}>*/}
                                {/*                                                        <td>{product.reference}</td>*/}
                                {/*                                                        <td>*/}
                                {/*                                                            <img src={product.image}*/}
                                {/*                                                                 width={50} height={50}*/}
                                {/*                                                                 alt={product.reference}/>*/}
                                {/*                                                        </td>*/}
                                {/*                                                        <td>{product.cod}</td>*/}
                                {/*                                                        <td>{product.stock > 0 ? 'Disponible' : 'Non disponible'}</td>*/}
                                {/*                                                        <td>{product.etage}</td>*/}
                                {/*                                                        <td>{product.stock}</td>*/}
                                {/*                                                        <td>*/}
                                {/*                                                            <PlusCircle onClick={() => {*/}
                                {/*                                                                arrayHelpers.insert(values.products.length, product)*/}
                                {/*                                                                setModal2(false)*/}
                                {/*                                                            }}*/}
                                {/*                                                                        style={{cursor: "pointer"}}*/}
                                {/*                                                            />*/}
                                {/*                                                        </td>*/}
                                {/*                                                    </tr>*/}
                                {/*                                                ))}*/}
                                {/*                                                </tbody>*/}
                                {/*                                            </Table>*/}
                                {/*                                        </ModalBody>*/}
                                {/*                                    </Modal>*/}
                                {/*                                </>*/}
                                {/*                            )}*/}
                                {/*                        />*/}
                                {/*                    </Row>*/}
                                {/*                </CardBody>*/}
                                {/*            </Card>*/}
                                {/*        </Col>*/}
                                {/*    </Row> : ""*/}
                                {/*}*/}
                                <Row>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Col>
                                        <FormGroup className="mb-0">
                                            <Button
                                                onClick={(e) => {
                                                    if (totalPrice?.toString().length > 5)
                                                        setModalWarning(true)
                                                    else
                                                        handleSubmit(e, values)
                                                }}
                                                disabled={showLoader}
                                                className="btn btn-success mr-3"
                                                color={"success"}
                                                loading={showLoader}
                                                text={"Enregistrer"}
                                            />
                                            <Link to={`/colis?created_at=today`}>
                                                <B color="danger">Annuler</B>
                                            </Link>
                                            <br/>
                                            <br/>
                                            <Modal isOpen={modalWarning} toggle={() => setModalWarning(!modalWarning)}
                                                   centered
                                                   size="lg">
                                                <ModalHeader
                                                    style={{
                                                        backgroundImage:
                                                            "linear-gradient(to right,#FFD166, #133A4A  )",
                                                    }}
                                                    className="p-3 text-white"
                                                    toggle={() => setModalWarning(!modalWarning)}>Confirmer votre
                                                    coli</ModalHeader>
                                                <ModalBody>
                                                    <div className={"d-flex justify-content-center"}>
                                                        Etes vous sur de livrer ce coli avec ce montant {totalPrice}DT?
                                                    </div>
                                                </ModalBody>
                                                <ModalFooter>
                                                    <button
                                                        onClick={(e) => {
                                                            setModalWarning(!modalWarning)
                                                            handleSubmit(e, values)
                                                        }}
                                                        type="submit"
                                                        className="p-2 btn rounded"
                                                        style={{backgroundColor: "#FDF203"}}
                                                    >
                                                        Confirmer
                                                    </button>
                                                </ModalFooter>
                                            </Modal>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Container>
            }
        </Fragment>
    );
};
addNewcolis.propTypes = {
    getFournisseurs: PropTypes.func.isRequired,
    getVille: PropTypes.func.isRequired,
    getVille1: PropTypes.func.isRequired,
    getVilleF: PropTypes.func.isRequired,
    getCite: PropTypes.func.isRequired,
    getCite1: PropTypes.func.isRequired,
    getCiteF: PropTypes.func.isRequired,
    getDeligation: PropTypes.func.isRequired,
    getLivreur: PropTypes.func.isRequired,
    getadresseF: PropTypes.func.isRequired,
    fournisseurData: PropTypes.object.isRequired,
    addNewcolis: PropTypes.func.isRequired,
    fournisseur: PropTypes.object.isRequired,
    getCurrentFournisseur: PropTypes.func.isRequired,
    getProduits: PropTypes.func.isRequired,
    stockData: PropTypes.object.isRequired,
};

const mapstatetoprops = (state) => ({
    villeData: state.reducerc,
    villeDataf: state.reducerdvc,
    citeDataf: state.reducerdvc,
    fournisseurData: state.reducerf,
    financeData: state.reducerfin,
    citeData: state.reducerc,
    deligationData: state.reducerc,
    livreurData: state.reducerc,
    adresseData: state.reducerc,
    fournisseur: state.reducerf,
    stockData: state.reducerst
});

export default connect(mapstatetoprops, {
    addNewcolis,
    getVille,
    getVille1,
    getVilleF,
    getCite,
    getCite1,
    getCiteF,
    getDeligation,
    getFournisseurs,
    getFinances,
    getLivreur,
    getadresseF,
    getCurrentFournisseur,
    getProduits
})(withRouter(Newcoli));
