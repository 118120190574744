import {
    GET_COLI,
    COLISP_LOADING,
    GET_COLISP,
    COLISP_ERROR,
    GET_CHART,
    CHART_LOADING,
    GET_GENERATE,
    GENERATE_ERROR,
    CHART_ERROR,
    TOTAL_ERROR,
    TOTAL_LOADING,
    GET_TOTAL,
    EXPORT_LOADING,
    VILLEVENTE_LOADING,
    GET_VILLEVENTE,
    VILLEVENTE_ERROR,
    SECTEURPERCENTS_LOADING,
    GET_SECTEURPERCENTS,
    SECTEURPERCENTS_ERROR,
    EXPORT_ERROR,
    GET_EXPORT,
    GET_COLISM,
    GET_COLISS,
    COLISS_LOADING,
    COLISS_ERROR,
    GET_COLISR,
    COLISR_LOADING,
    COLISR_ERROR,
    GET_COLISE,
    RESET_COLI,
    COLISE_LOADING,
    COLISE_ERROR,
    GET_COLISMO,
    COLISMO_LOADING,
    COLISMO_ERROR,
    GET_COLISPY,
    COLISPY_LOADING,
    COLISPY_ERROR,
    COLISM_ERROR,
    GET_COLIS,
    HISTORY_ERROR,
    HISTORY_LOADING,
    GET_HISTORY,
    COLIS_ERROR,
    GET_LIVREUR,
    LIVREUR_ERROR,
    LIVREUR_LOADING,
    GET_ADRESSE,
    ADRESSE_ERROR,
    GET_COLISP1,
    ADRESSE_LOADING,
    COLIS_LOADING,
    UPDATE_COLIS_SUCCESS,
    UPDATE_COLIS,
    GET_VILLE,
    VILLE_ERROR,
    VILLE_LOADING,
    DELIGATION_LOADING,
    DELIGATION_ERROR,
    GET_DELIGATION,
    CITE_LOADING,
    CITE_ERROR,
    GET_CITE,
    FILIERE_LOADING,
    FILIERE_ERROR,
    GET_FILIERE,
    GET_TRACKING,
    UPDATE_TRACKINGS,
    TRACKING_ERROR,
    TRACKING_LOADING,
    GET_TRACKING_ACTIVITIES,
    TRACKING_ACTIVITIES_LOADING,
    TRACKING_ACTIVITIES_ERROR,
    GESTION_LOADING,
    GET_GESTIONS,
    GESTION_ERROR,
    DASHBOARD_LOADING,
    GET_DASHBOARD,
    DASHBOARD_ERROR,
    GET_VILLEF,
    VILLEF_ERROR,
    VILLEF_LOADING,
    CITEF_LOADING,
    GET_CITEF,
    CITEF_ERROR,
    COLISH_LOADING,
    GET_COLISH,
    COLISH_ERROR,
    GENERATED_LOADING,
    GENERATED_ERROR,
    GET_GENERATED,
    LOGS_LOADING, GET_LOGS, LOGS_ERROR,
} from "../../redux/actionTypes";
import {setAlert, setAlertD} from "../../redux/alert/action";
import {setAuthToken, api} from "../../utils/backend.instance";
import axios from "axios";

export const addNewcolis = (formData, history) => async (dispatch) => {
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.post("/colis/colis/", formData);
        dispatch({
            type: GET_COLI,
            payload: res.data,
        });
        dispatch(setAlert("Coli Successfully Created", "success"));
        history.push("/colis?created_at=today");
    } catch (err) {
        const errors = err.response.data;
        if (errors) {
            dispatch(
                setAlertD(
                    (errors.client_email ? "Email: " + errors.client_email : "") +
                    (errors.client_address
                        ? "Adresse client: " + errors.client_address + " "
                        : "") +
                    (errors.client_name
                        ? "Nom: " + errors.client_name + " "
                        : "") +
                    (errors.client_prenom
                        ? "Prénom: " + errors.client_prenom + " "
                        : "") +
                    (errors.client_telephone_1
                        ? "Téléphone 1: " + errors.client_telephone_1 + " "
                        : "") +
                    (errors.client_telephone_2
                        ? "Téléphone 2: " + errors.client_telephone_2 + " "
                        : "") +
                    (errors.client_email
                        ? "Email: " + errors.client_email + " "
                        : "") +
                    (errors.client_deligaiton
                        ? "Délégation client: " + errors.client_deligaiton + " "
                        : "") +
                    (errors.client_ville
                        ? "Ville client: " + errors.client_ville + " "
                        : "") +
                    (errors.client_cite
                        ? "Cité client: " + errors.client_cite + " "
                        : "") +
                    (errors.client_zipcode
                        ? "Code postale: " + errors.client_zipcode + " "
                        : "") +
                    (errors.prix ? "Prix: " + errors.prix + " " : "") +
                    (errors.product_name
                        ? "Produit: " + errors.product_name + " "
                        : "") +
                    (errors.service ? "Service: " + errors.service + " " : "") +
                    (errors.products ? "Produits: " + errors.products + " " : "") +
                    (errors.quantity ? "Quantité: " + errors.quantity + " " : "") +
                    (errors.volume ? "Volume: " + errors.volume + " " : ""),
                    "danger"
                )
            );
        }

        dispatch({
            type: COLIS_ERROR,
            payload: {
                msg: err.response.data,
                status: err.response.status,
            },
        });
    }
};

export const addNewTracking = (formData) => async (dispatch) => {
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.post("/colis/trackingactivities/", formData);
        dispatch({
            type: GET_TRACKING_ACTIVITIES,
            payload: res.data,
        });
        dispatch(setAlert("Tracking Successfully Created", "success"));
    } catch (err) {
        const errors = err.response.data;
        if (errors) {
            dispatch(
                setAlertD(
                    (errors.client_email ? "Email: " + errors.client_email : "") +
                    (errors.client_address
                        ? "Adresse client: " + errors.client_address + " "
                        : "") +
                    (errors.client_name
                        ? "Nom: " + errors.client_name + " "
                        : "") +
                    (errors.client_prenom
                        ? "Prénom: " + errors.client_prenom + " "
                        : "") +
                    (errors.client_telephone_1
                        ? "Téléphone 1: " + errors.client_telephone_1 + " "
                        : "") +
                    (errors.client_telephone_2
                        ? "Téléphone 2: " + errors.client_telephone_2 + " "
                        : "") +
                    (errors.client_email
                        ? "Email: " + errors.client_email + " "
                        : "") +
                    (errors.client_deligaiton
                        ? "Délégation client: " + errors.client_deligaiton + " "
                        : "") +
                    (errors.client_ville
                        ? "Ville client: " + errors.client_ville + " "
                        : "") +
                    (errors.client_cite
                        ? "Cité client: " + errors.client_cite + " "
                        : "") +
                    (errors.client_zipcode
                        ? "Code postale: " + errors.client_zipcode + " "
                        : "") +
                    (errors.prix ? "Prix: " + errors.prix + " " : "") +
                    (errors.product_name
                        ? "Produit: " + errors.product_name + " "
                        : "") +
                    (errors.service ? "Service: " + errors.service + " " : "") +
                    (errors.volume ? "Volume: " + errors.volume + " " : ""),
                    "danger"
                )
            );
        }

        dispatch({
            type: TRACKING_ACTIVITIES_ERROR,
            payload: {
                msg: err.response.data,
                status: err.response.status,
            },
        });
    }
};

export const addNewadresse = (formData) => async (dispatch) => {
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.post("/colis/address/", formData);
        dispatch({
            type: GET_ADRESSE,
            payload: res.data,
        });
        dispatch(setAlert("Adresse Successfully Created", "success"));
    } catch (err) {
        const errors = err.response.data;
        if (errors) {
            dispatch(
                setAlertD(
                    (errors.first_name ? "Nom: " + errors.first_name + "\n" : "") +
                    (errors.last_name ? "Prénom: " + errors.last_name + " " : "") +
                    (errors.email ? "Email: " + errors.email + " " : "") +
                    (errors.telephone_1
                        ? "Téléphone: " + errors.telephone_1 + " "
                        : "") +
                    (errors.address ? "Adresse: " + errors.address + " " : "") +
                    (errors.zipcode
                        ? "Code postale: " + errors.zipcode + " "
                        : ""),
                    "danger",
                    30000
                )
            );
        }

        dispatch({
            type: ADRESSE_ERROR,
            payload: {
                msg: err.response.data,
                status: err.response.status,
            },
        });
    }
};

export const updateStatuscolis = (formData, id) => async (dispatch) => {
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.patch(`/colis/colis/${id}/`, formData);
        dispatch({
            type: GET_COLI,
            payload: res.data,
        });
        dispatch(setAlert("Status Successfully Updated", "success"));
    } catch (err) {
        dispatch({
            type: COLIS_ERROR,
            payload: {
                msg: err.response.data,
                status: err.response.status,
            },
        });
    }
};

export const updatecolis = (formData, id, history) => async (dispatch) => {
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.put(`/colis/colis/${id}/`, formData);
        dispatch({
            type: GET_COLI,
            payload: res.data,
        });
        dispatch(setAlert("Coli Successfully Updated", "success"));
        history.push(`/colis/detail/${id}`);
    } catch (err) {
        const errors = err.response.data;
        if (errors) {
            dispatch(
                setAlertD(
                    (errors.client_email
                        ? "Email: " + errors.client_email + " "
                        : "") +
                    (errors.client_address
                        ? "Adresse client: " + errors.client_address + " "
                        : "") +
                    (errors.client_name
                        ? "Nom: " + errors.client_name + " "
                        : "") +
                    (errors.client_telephone_1
                        ? "Téléphone 1: " + errors.client_telephone_1 + " "
                        : "") +
                    (errors.client_email
                        ? "Email: " + errors.client_email + " "
                        : "") +
                    (errors.client_zipcode
                        ? "Code postale: " + errors.client_zipcode + " "
                        : "") +
                    (errors.prix ? "Prix: " + errors.prix + " " : "") +
                    (errors.product_name
                        ? "Produit: " + errors.product_name + " "
                        : "") +
                    (errors.service ? "Service: " + errors.service + " " : "") +
                    (errors.volume ? "Volume: " + errors.volume + " " : ""),
                    "danger"
                )
            );
        }

        dispatch({
            type: COLIS_ERROR,
            payload: {
                msg: err.response.data,
                status: err.response.status,
            },
        });
    }
};
export const updateColisBulk = (params) => async (dispatch) => {
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.patch(`/colis/update_multiple/colis/?${params.toString()}`);
        dispatch({
            type: GET_COLIS,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: COLIS_ERROR,
            payload: {
                msg: err.response.data,
                status: err.response.status,
            },
        });
    }
};

export const updatecolisPrint = (formData, id) => async (dispatch) => {
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.patch(`/colis/colis/${id}/`, formData);
        dispatch({
            type: GET_COLI,
            payload: res.data,
        });
    } catch (err) {
        const errors = err.response.data;
        if (errors) {
            dispatch(
                setAlertD(
                    (errors.client_email
                        ? "Email: " + errors.client_email + " "
                        : "") +
                    (errors.client_address
                        ? "Adresse client: " + errors.client_address + " "
                        : "") +
                    (errors.client_name
                        ? "Nom: " + errors.client_name + " "
                        : "") +
                    (errors.client_prenom
                        ? "Prénom: " + errors.client_prenom + " "
                        : "") +
                    (errors.client_telephone_1
                        ? "Téléphone 1: " + errors.client_telephone_1 + " "
                        : "") +
                    (errors.client_email
                        ? "Email: " + errors.client_email + " "
                        : "") +
                    (errors.client_zipcode
                        ? "Code postale: " + errors.client_zipcode + " "
                        : "") +
                    (errors.prix ? "Prix: " + errors.prix + " " : "") +
                    (errors.product_name
                        ? "Produit: " + errors.product_name + " "
                        : "") +
                    (errors.service ? "Service: " + errors.service + " " : "") +
                    (errors.volume ? "Volume: " + errors.volume + " " : ""),
                    "danger"
                )
            );
        }

        dispatch({
            type: COLIS_ERROR,
            payload: {
                msg: err.response.data,
                status: err.response.status,
            },
        });
    }
};

export const getCurrentcolis = (id) => async (dispatch) => {
    dispatch({type: RESET_COLI});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }

    try {
        const res = await api.get(`/colis/colis/${id}/`);

        dispatch({
            type: GET_COLI,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: COLIS_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};


export const getChart = (params) => async (dispatch) => {
    dispatch({type: CHART_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }

    try {
        const res = await api.get(`/stats/chart/?${params.toString()}`);

        dispatch({
            type: GET_CHART,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: CHART_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getGestions = (params) => async (dispatch) => {
    dispatch({type: GESTION_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(`/stats/gestion/?${params.toString()}`);

        dispatch({
            type: GET_GESTIONS,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: GESTION_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getGestionsLivreur = (params) => async (dispatch) => {
    dispatch({type: GESTION_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(`/stats/gestion/?livreur=beez&${params.toString()}`);

        dispatch({
            type: GET_GESTIONS,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: GESTION_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};


export const getHistory = (params) => async (dispatch) => {
    dispatch({type: HISTORY_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(`/colis/history/?${params.toString()}`);

        dispatch({
            type: GET_HISTORY,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: HISTORY_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};
export const getDashboard = (params) => async (dispatch) => {
    dispatch({type: DASHBOARD_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(`/stats/dashboard/?${params.toString()}`);

        dispatch({
            type: GET_DASHBOARD,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: DASHBOARD_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};
export const getVilleVente = (params) => async (dispatch) => {
    dispatch({type: VILLEVENTE_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(`/stats/ville/vente${"?" + params.toString()}`);

        dispatch({
            type: GET_VILLEVENTE,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: VILLEVENTE_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getSecteursPercents = (params) => async (dispatch) => {
    dispatch({type: SECTEURPERCENTS_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(`/stats/secteur/percents${"?" + params.toString()}`);

        dispatch({
            type: GET_SECTEURPERCENTS,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: SECTEURPERCENTS_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getColis = (params, cancelTokenSource) => async (dispatch) => {
    dispatch({type: COLIS_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        if (cancelTokenSource) {
            cancelTokenSource.cancel("New request triggered");
        }

        // Create a new cancel token source
        // eslint-disable-next-line react-hooks/exhaustive-deps
        cancelTokenSource = axios.CancelToken.source();
        const res = await api.get(
            `/colis/colis/?${params.toString()}`, {cancelToken: cancelTokenSource.token}
        );
        if (cancelTokenSource) {
            cancelTokenSource.cancel("Component unmounted");
        }

        dispatch({
            type: GET_COLIS,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: COLIS_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getLogs = (params) => async (dispatch) => {
    dispatch({type: LOGS_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        // const res = await api.get(
        //     `/colis/history/?${params.toString()}`
        // );
        const res = await api.get(
            `/accounts/log/?${params.toString()}`
        );
        dispatch({
            type: GET_LOGS,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: LOGS_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getGenerated = (params) => async (dispatch) => {
    dispatch({type: GENERATED_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(
            `/colis/generated/?${params.toString()}`
        );

        dispatch({
            type: GET_GENERATED,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: GENERATED_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};
export const GenerateColis = (formData, query) => async (dispatch) => {
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.post(
            `/colis/generate/`, formData
        );

        dispatch({
            type: GET_GENERATE,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: GENERATE_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getColisLivreur = (params) => async (dispatch) => {
    dispatch({type: COLIS_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(
            `/colis/colis/?livreur=beez&${params.toString()}`
        );

        dispatch({
            type: GET_COLIS,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: COLIS_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};


export const getColisS = (params) => async (dispatch) => {
    dispatch({type: COLISS_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(
            `/colis/colis/${"?" + params.toString()}`
        );

        dispatch({
            type: GET_COLISS,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: COLISS_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getColisR = (params) => async (dispatch) => {
    dispatch({type: COLISR_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(
            `/colis/colis/${"?" + params.toString()}`
        );

        dispatch({
            type: GET_COLISR,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: COLISR_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getColisE = (params) => async (dispatch) => {
    dispatch({type: COLISE_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(
            `/colis/colis/${"?" + params.toString()}`
        );

        dispatch({
            type: GET_COLISE,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: COLISE_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getColisMO = (params) => async (dispatch) => {
    dispatch({type: COLISMO_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(
            `/colis/colis/${"?" + params.toString()}`
        );

        dispatch({
            type: GET_COLISMO,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: COLISMO_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getColisPY = (params) => async (dispatch) => {
    dispatch({type: COLISPY_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(
            `/colis/colis/${"?" + params.toString()}`
        );

        dispatch({
            type: GET_COLISPY,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: COLISPY_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getColisH = (id) => async (dispatch) => {
    dispatch({type: COLISH_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(
            `/colis/colis/?related_decharge=${id}`
        );

        dispatch({
            type: GET_COLISH,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: COLISH_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};
export const getColisP = (params) => async (dispatch) => {
    dispatch({type: COLISP_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(
            `/colis/colis/?${params.toString()}`
        );

        dispatch({
            type: GET_COLISP,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: COLISP_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};
export const getColisPP = (params) => async (dispatch) => {
    dispatch({type: COLISP_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(
            `/colis/colis/?${params.toString()}`
        );

        dispatch({
            type: GET_COLISP,
            payload: res.data,
        });
        setTimeout(() => {
            window.print();
        }, 1000);
    } catch (err) {
        dispatch({
            type: COLISP_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};
export const getVilleF = (params) => async (dispatch) => {
    dispatch({type: VILLE_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }

    try {
        const res = await api.get(`/colis/ville/?${params.toString()}`);

        dispatch({
            type: GET_VILLE,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: VILLE_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getVille = (params) => async (dispatch) => {
    dispatch({type: VILLEF_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }

    try {
        const res = await api.get(`/colis/ville/?${params.toString()}`);

        dispatch({
            type: GET_VILLEF,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: VILLEF_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};
export const getVillef = (params, key) => async (dispatch) => {
    dispatch({type: VILLEF_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }

    try {
        const res = await api.get(`/colis/ville/?${params.toString()}&key=${key}`);

        dispatch({
            type: GET_VILLEF,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: VILLEF_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getVille1 = () => async (dispatch) => {
    dispatch({type: VILLE_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }

    try {
        const res = await api.get(`/colis/ville/`);

        dispatch({
            type: GET_VILLE,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: VILLE_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getCiteF = (params) => async (dispatch) => {
    dispatch({type: CITE_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(`/colis/cite/?${params.toString()}`);

        dispatch({
            type: GET_CITE,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: CITE_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getCite = (params) => async (dispatch) => {
    dispatch({type: CITEF_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(`/colis/cite/?${params.toString()}`);

        dispatch({
            type: GET_CITEF,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: CITEF_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};
export const getCitef = (params, key) => async (dispatch) => {
    dispatch({type: CITEF_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(`/colis/cite/?${params.toString()}&key=${key}`);

        dispatch({
            type: GET_CITEF,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: CITEF_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};
export const getCite1 = () => async (dispatch) => {
    dispatch({type: CITE_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(`/colis/cite/`);

        dispatch({
            type: GET_CITE,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: CITE_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getfiliere = () => async (dispatch) => {
    dispatch({type: FILIERE_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(`/colis/filliere/`);

        dispatch({
            type: GET_FILIERE,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: FILIERE_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getDeligation = () => async (dispatch) => {
    dispatch({type: DELIGATION_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(`/colis/delegation/`);

        dispatch({
            type: GET_DELIGATION,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: DELIGATION_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};
export const getDeligationf = (key) => async (dispatch) => {
    dispatch({type: DELIGATION_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(`/colis/delegation/?key=${key}`);

        dispatch({
            type: GET_DELIGATION,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: DELIGATION_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};
export const getLivreur = (query) => async (dispatch) => {
    dispatch({type: LIVREUR_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(`/colis/livreur/?${query.toString()}`);

        dispatch({
            type: GET_LIVREUR,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: LIVREUR_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getLivreurs = async (query) => {
    return (await api.get(`/colis/livreur/?${query.toString()}`)).data
}

export const getadresse = () => async (dispatch) => {
    dispatch({type: ADRESSE_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(`/colis/address/}`);

        dispatch({
            type: GET_ADRESSE,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: ADRESSE_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};
export const getadresseF = (id) => async (dispatch) => {
    dispatch({type: ADRESSE_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }

    try {
        const res = await api.get(`/fournisseur/fournisseur/${id}`);

        dispatch({
            type: GET_ADRESSE,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: ADRESSE_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const deletecolis = () => async (dispatch) => {
    try {
        const res = await api.delete(`/colis/delete_multiple/colis/colis/id=4`);

        dispatch({
            type: UPDATE_COLIS,
            payload: res.data,
        });
        dispatch(setAlert("Experience Successfully Deleted", "success"));
    } catch (err) {
        dispatch({
            type: COLIS_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const deleteColis = (id, history) => async (dispatch) => {
    try {
        const res = await api.delete(`/colis/colis/${id}/`);

        dispatch({
            type: UPDATE_COLIS_SUCCESS,
            payload: res.data.results,
        });

        dispatch(setAlert("Colis Successfully Deleted", "success"));
        history.push("/colis?created_at=today");
    } catch (err) {
        dispatch({
            type: COLIS_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const deleteColisG = (id, history) => async (dispatch) => {
    try {
        const res = await api.delete(`/colis/colis/${id}/`);

        dispatch({
            type: UPDATE_COLIS_SUCCESS,
            payload: res.data.results,
        });

        dispatch(setAlert("Colis Successfully Deleted", "success"));
        history.push(getColis());
    } catch (err) {
        dispatch({
            type: COLIS_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getTracking = (id) => async (dispatch) => {
    dispatch({type: TRACKING_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(`/colis/tracking/?colis_id=${id}`);

        dispatch({
            type: GET_TRACKING,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: TRACKING_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getTrackingActivities = (id) => async (dispatch) => {
    dispatch({type: TRACKING_ACTIVITIES_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(`/colis/trackingactivities/?colis_id=${id}`);

        dispatch({
            type: GET_TRACKING_ACTIVITIES,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: TRACKING_ACTIVITIES_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};


export const getTracking2 = (id) => async (dispatch) => {
    dispatch({type: TRACKING_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(
            `/colis/tracking/?colis_id=${id}&refresh=true`
        );

        dispatch({
            type: GET_TRACKING,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: TRACKING_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const deleteTracking = (id) => async (dispatch) => {
    try {
        const res = await api.delete(`/colis/tracking/${id}/`);

        dispatch({
            type: UPDATE_TRACKINGS,
            payload: res.data,
        });

        dispatch(setAlert("Tracking Successfully Deleted", "success"));
    } catch (err) {
        dispatch({
            type: TRACKING_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const GetColisByIds = (formData, query) => async (dispatch) => {
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }

    try {
        let list_id = formData.code_a_barre.split("\n");
        let url_params = new URLSearchParams();

        for (let code_a_barre = 0; code_a_barre < list_id.length; code_a_barre++) {
            url_params.append("colis_cab", list_id[code_a_barre]);
        }
        const res = await api.post(`/colis/manifeste/colis/?${query.toString()}`, {colis_cab: list_id});
        dispatch({
            type: GET_COLISM,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: COLISM_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};


export const getColisByIds = (params) => async (dispatch) => {
    dispatch({type: COLIS_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }

    try {
        const res = await api.get(`/colis/colis/?${params.toString()}`);

        dispatch({
            type: GET_COLIS,
            payload: res.data,
        });
        setTimeout(() => {
            window.print();
        }, 1000);
    } catch (err) {
        dispatch({
            type: COLIS_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};
export const getColisByIds1 = (params) => async (dispatch) => {
    dispatch({type: COLISP_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }

    try {
        const res = await api.get(`/colis/colis/?${params.toString()}`);

        dispatch({
            type: GET_COLISP1,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: COLISP_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};
export const getColisByIdsP = (params) => async (dispatch) => {
    dispatch({type: COLISP_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }

    try {
        const res = await api.get(`/colis/colis/?${params.toString()}`);

        dispatch({
            type: GET_COLISP,
            payload: res.data,
        });

        setTimeout(() => {
            window.print();
        }, 1000);

    } catch (err) {
        dispatch({
            type: COLISP_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};


export const getColisByIdsP1 = (params) => async (dispatch) => {
    dispatch({type: COLISP_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }

    try {
        const res = await api.get(`/colis/colis/?${params.toString()}`);

        dispatch({
            type: GET_COLISP,
            payload: res.data,
        });
        setTimeout(() => {
            window.print();
        }, 1000);

    } catch (err) {
        dispatch({
            type: COLISP_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};
export const ExportCSV = (params, filename) => async (dispatch) => {
    dispatch({type: EXPORT_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    const Headears = {
        responseType: 'blob'
    }
    try {
        const res = await api.get(`/colis/export/colis/?${params.toString()}`, Headears);
        const href = URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();
        dispatch({
            type: GET_EXPORT,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: EXPORT_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
}
export const getTotal = (params) => async (dispatch) => {
    dispatch({type: TOTAL_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(`/colis/coliss/total_prix/?${params.toString()}`);
        dispatch({
            type: GET_TOTAL,
            payload: res.data.prix__sum,
        });
    } catch (err) {
        dispatch({
            type: TOTAL_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
}