import {
    GET_FINANCE,
    GET_TOTAL_FINANCE,
    TOTAL_FINANCE_LOADING,
    TOTAL_FINANCE_ERROR,
    GET_FINANCES,
    FINANCE_LOADING,
    FINANCE_ERROR,
    GET_FINANCESP,
    FINANCEP_LOADING,
    FINANCEP_ERROR,
    UPDATE_FINANCE,
} from "../../redux/actionTypes";

const initial_state = {
    finance: null,
    count: 0,
    total:[],
    finances: [],
    financesP: [],
    loading: true,
    error: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initial_state, action) {
    const {type, payload} = action;
    switch (type) {
        case TOTAL_FINANCE_LOADING:
        case FINANCE_LOADING:
        case FINANCEP_LOADING:
            return {
                ...state,
                loading: true,
            };
        case TOTAL_FINANCE_ERROR:
        case FINANCE_ERROR:
        case FINANCEP_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        case GET_TOTAL_FINANCE:
            return {
                ...state,
                total: payload,
                loading: false
            }
        case GET_FINANCE:
            return {
                ...state,
                finance: payload,
                loading: false,
            };
        case UPDATE_FINANCE:
            return {
                ...state,
                finance: payload,
                loading: false,
            };
        case GET_FINANCES:
            return {
                ...state,
                count: payload.count,
                finances: payload.results,
                loading: false,
            };
        case GET_FINANCESP:
            return {
                ...state,
                financesP: payload.results,
                loading: false,
            };

        default:
            return state;
    }
}
