import React, {Fragment, useState, useEffect} from "react";
import Card from 'react-bootstrap/Card';
import dd1 from "./images/Centre-De-Stockage-Et-Livraison-Express.png"
import dd2 from "./images/Emballage.png"
import dd3 from "./images/Livraison-Standard-Et-Points-Relais.png";
import dd4 from "./images/previlege.jpg";
import dd5 from "./images/previlege-photo.jpg";
import team from "./images/Our-Team-=-Your-Team.png"

import "./css/service.css"
import {
    Col,
    Row,
} from "reactstrap";
import {Link} from "react-router-dom";
import Nava from "./components/Navbar";
import Footer from "./components/footer";

const Service = (props) => {
    const [, setTapTopStyle] = useState("none");
    const executeScroll = () => {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    };

    const handleScroll = () => {
        if (window.scrollY > 600) {
            setTapTopStyle("block");
        } else {
            setTapTopStyle("none");
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        handleScroll();
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    return (
        <Fragment>
            <div style={{backgroundColor: "#F8F9FE", fontFamily: "Raleway"}}>
                {/* {window.innerWidth >= 1200 ? (
                    <div className="page-header flex " style={{backgroundColor: "#193256"}}>
                        <Row className="header-wrapper m-0">
                            <Col sm="2"></Col>
                            <Col>
                                <LeftHeader/>
                            </Col>
                            <Col sm="4">
                                <Nave/>
                            </Col>
                            <Col sm="3">
                                <RightHeader/>
                            </Col>
                        </Row>
                    </div>
                ) : (
                    <Row>
                        <Col>
                            <img
                                className="img-fluid for-light"
                                style={{
                                    width: "200px",
                                    height: "80px",
                                }}
                                src={require("../../assets/images/logo/logo_dark.png")}
                                alt=""
                            />
                        </Col>
                        <Row hidden={!menu}>
                            <div
                                sm="1"
                                style={{
                                    width: "100%",
                                    borderRadius: "15px",

                                    backgroundColor: "white",
                                }}
                            >
                                <br/>
                                <br/>
                                <Row>
                                    <Col sm="1"></Col>
                                    <Col>
                                        {" "}
                                        <Row>
                                            <Col></Col>
                                            <Link to="/">Home</Link>
                                            <Col></Col>
                                        </Row>
                                        <br/>
                                        <Row>
                                            <Col></Col>
                                            <Link to="/about">About</Link>
                                            <Col></Col>
                                        </Row>
                                        <br/>
                                        <Row>
                                            {" "}
                                            <Col></Col>
                                            <Link to="/services">Privilège</Link>
                                            <Col></Col>
                                        </Row>
                                        <br/>
                                        <Row>
                                            <Col></Col>
                                            <Link to="/partner">Contact</Link>
                                            <Col></Col>
                                        </Row>
                                        <br/>
                                        <Row>
                                            <Col></Col>
                                            <Link
                                                to={`/signin`}
                                                style={{
                                                    color: "#FFC107",
                                                    padding: "10px",
                                                    borderRadius: "15px",
                                                    border: "1px solid #FFC107",
                                                    fontSize: "16px",
                                                    textTransform: "uppercase",
                                                }}
                                            >
                                                Login &nbsp;&nbsp;&nbsp;&nbsp;
                                                <i className="fa fa-sign-in"></i>
                                            </Link>
                                            <Col></Col>
                                        </Row>
                                    </Col>
                                    <Col sm="1"></Col>
                                </Row>
                                <br/>
                            </div>
                        </Row>

                        <i
                            onClick={() => setMenu(!menu)}
                            className="fa fa-bars"
                            style={{
                                position: "relative",
                                top: "20px",
                                right: "7%",
                                fontSize: "30px",
                                color: "#FFC107",
                            }}
                        ></i>
                    </Row>
                )} */}
                <Nava/>

                <div className="page-section-heading text-center  jn mt-4">
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <h1 style={{fontSize: "70px", fontWeight: "bold"}}>
                        L'univers Beezit
                    </h1>
                    <br/>
                    <br/>
                    
                    <div className="page-section-heading text-center  jn mt-4">
                        <h2
                            style={{
                                position: "relative",
                                fontSize: "33px",
                                fontWeight: "bold",
                                color: "#F4D56D",
                            }}
                        >
                            Une infinité de privilèges inclus
                        </h2>
                        <br/>
                        <br/>

                        <p
                            style={{
                                position: "relative",
                                fontSize: "18px",
                            }}
                        >
                            Devenez partenaire Beezit et profitez des services exclusifs qui
                            vous permettent <br/> d'avoir une expérience e-commerce optimale
                            dans le seul but est de faire grandir votre business
                        </p>
                    </div>
                    <br/>
                    <br/>
                    <Row className={"big"}>
                        
                        <Col >
                        <img src={dd4} height={"750px"} width={"100%"} alt={""}/>
                        </Col>
                    </Row>
                    <div className={"small"}>
                    <Row >
                        
                        <Col sm={12} >
                        <img src={dd5} height={"100%"} width={"100%"} style={{display : "block"}} alt={""}/>
                        </Col>
                        <Col sm={12}  >
                        <div style={{backgroundColor : "#F2B707" , margin : "20px"  , padding: "50px" , color:"white" , textAlign : "left"}}>
                            <h5 style={{fontWeight : "600"}}>Virement Personnalisé</h5>
                            <h6>Vous pouvez choisir la durée <br/> que vous voulez pour recevoir votre argent .</h6>
                            <h5 style={{fontWeight : "600"}}>Tracking temps réel</h5>
                            <h6>Vous et votre clientèle puissiez suivre l'état <br/> de vos colis en temps réel par localisation <br/> géographique.</h6>
                            <h5 style={{fontWeight : "600"}}>Assisatance</h5>
                            <h6>Beezit Vous assiste selon vos besoins dans <br/> votre activité e-commerce par des consultations <br/> privées et une formation continue</h6>
                        </div>
                        </Col>
                        <Col sm={12} >
                        <div style={{backgroundColor : "#193256" , margin : "20px"  , padding: "50px" , color:"white" , textAlign : "left"}}>
                            <h5 style={{fontWeight : "600"}}>Service client</h5>
                            <h6>Rejoignez votre assistante Beezit Par la messagerie <br/> intégrée dans la plateforme pour toute reclamation <br/> ou modification</h6>
                            <h5 style={{fontWeight : "600"}}>Guidance</h5>
                            <h6>Consultation avec des experts Formation <br/> continue e-commerce.</h6>
                            <h5 style={{fontWeight : "600"}}>Statistiques</h5>
                            <h6>Nous vous fournirons des informations clés que vous <br/> pouviez l'utiliser afin d'aller plus loin et plus vite.</h6>
                        </div>
                        </Col>
                    </Row>
                    </div>

                    <br/>
                    <br/>
                    
                    <Link
                    to={"/signin"}
                        className="btn "
                        style={{
                            fontSize: "30px",
                            fontWeight: "bold",
                            color: "white",
                            borderRadius: "30px",
                            backgroundColor: "#FFC107",
                        }}
                    >
                        En profiter
                    </Link>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <h3
                        style={{
                            position: "relative",
                            fontSize: "40px",
                            fontWeight: "bold",
                            color: "#F4D56D",
                        }}
                    >
                        Beezit Services
                    </h3>
                    <br/>
                    <br/>
                    <br/>
                </div>

                <div className="text-center page-body">
                    <Row>
                        <Col ></Col>
                        <Col sm={12} md={3} style={{alignItem: "center" ,display : "flex" , justifyContent:"center"}}>
                        <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src={dd1} />
      <Card.Body>
        <Card.Title><h4>Centre De Stockage Et Livraison Express</h4></Card.Title>
        <Card.Text>
        Beezit prend en charge tout le processus logistique ecommerce du stockage à la livraison
        <ul>
            <li>Stockage long durée dans des centres equipés</li>
            <li>Livraison 24h sur tout le territoire</li>
            <li>Retour gratuit</li>
            
        </ul>
        </Card.Text>
      </Card.Body>
    </Card>
                        </Col>
                        <Col sm={12} md={3} style={{alignItem: "center" , display : "flex" , justifyContent:"center"}}>
                        <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src={dd2} />
      <Card.Body>
        <Card.Title><h4>Emballage</h4></Card.Title>
        <Card.Text>
        L'emballage est une étape incontournable dans l'ecommerce mais malheureusement négligée.
        <ul>
            <li>Emballage spécial pour les marchandises sensibles</li>
            <li>Emballage personnalisé</li>
            <li>Coûts d'emballage moins cher</li>
            <li style={{visibility : "hidden"}}>Coûts d'emballage moins cher</li>
            <li style={{visibility : "hidden"}}>Coûts d'emballage moins cher</li>
        </ul>
        </Card.Text>
      
      </Card.Body>
    </Card>
                        </Col>
                        <Col sm={12} md={3} style={{alignItem: "center" ,display : "flex" , justifyContent:"center"}} >
                        <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src={dd3} />
      <Card.Body>
        <Card.Title><h4>Livraison Standard Et Points Relais</h4></Card.Title>
        <Card.Text>
        Beezit vous garantie une livraison standard fiable et introduit des points relais près de chez vous pour faciliter le pick-up et le retrait des colis.
        <ul>
            <li>Points pick up et retrait   </li>
            <li>Livraison moins cher</li>
            <li>Livraison plus rapide</li>
            <li style={{visibility : "hidden"}}>Retour gratuit</li>
        </ul>
        </Card.Text>
      
      </Card.Body>
    </Card>
                        </Col>
                        <Col></Col>
                    </Row>
                    <br/>
                    <br/>
                    <br/>

                    <h1
                        style={{
                            position: "relative",
                            fontSize: "40px",
                            fontWeight: "bold",
                            color: "#F4D56D",
                        }}
                    >
                        Our Team = Your Team
                    </h1>
                    <h6>Experience, Rigour, and a User-Centric Approach.</h6>
                    <br/>
                    <br/>
                    <img src={team} style={{width: "60%"}} alt={""}/>
                    <br/>
                    <br/>
                    <br/>
                    
                   
                
                    <br/>
                    <br/>
                    <br/>
                    <div className="text-center">
                        <h2
                            style={{
                                position: "relative",
                                fontSize: "40px",
                                fontWeight: "bold",
                                color: "#F4D56D",
                            }}
                        >
                            JOIN US
                        </h2>
                        <br/>
                        <button
                            className="btn"
                            style={{
                                fontSize: "25px",
                                fontWeight: "bold",
                                color: "white",
                                borderRadius:"30px",
                                backgroundColor: "#FFC107",
                            }}
                            onClick={() => executeScroll()}
                        >
                            More About us
                        </button>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
                <Footer/>
            </div>
            </Fragment>
    );
};

Service.propTypes = {};

export default Service;
