import {
    Home,
    Users,
    DollarSign,
    Package,
    Box,
    Phone,
    Settings, Star,
    BarChart
} from "react-feather";
import {currentMonthStartDate, currentMonthLastDate} from "../../components/colis/gestiondescolis";

export const MENUITEMS = [
    {
        menutitle: "Applications",
        menucontent: "",
        Items: [
            {
                permission: ["admin", "fournisseur", "commercial", "personnel"],
                title: "Tableau de bord",
                icon: Home,
                type: "link",
                badge: "badge badge-success",
                active: true,
                path: "/dashboard"
            },
            {
                permission: ["admin"],
                title: "Analytiques",
                icon: BarChart,
                type: "link",
                badge: "badge badge-success",
                active: true,
                path: "/analytics"
            },
            {
                title: "Colis",
                icon: Package,
                type: "sub",
                badge: "badge badge-danger",
                active: false,
                permission: ["admin", "fournisseur", "commercial", "personnel", "livreur"],

                children: [
                    {
                        permission: ["admin", "fournisseur", "commercial", "personnel", "livreur"],
                        title: "Colis du jour",
                        path: "/colis?created_at=today",
                        type: "link",
                    },
                    {
                        permission: ["admin", "fournisseur", "commercial", "personnel", "livreur"],
                        path: "/gestion-colis?created_at__gte=" + currentMonthStartDate() + "&created_at__lte=" + currentMonthLastDate(),
                        type: "link",
                        title: "Gestions Des colis",
                    },
                    {
                        permission: ["admin", "fournisseur", "commercial", "personnel"],
                        path: `/manifeste-colis`,
                        type: "link",
                        title: "Manifeste De Colis",
                    },
                    {
                        permission: ["admin"],
                        path: "/supprimer-colis",
                        type: "link",
                        title: "Supprimer colis",
                    },
                    {
                        permission: ["admin"],
                        path: "/colis/?status=supprimer",
                        type: "link",
                        title: "Colis Supprimés",
                    },
                    {
                        permission: ["admin"],
                        path: "/generate",
                        type: "link",
                        title: "Generate",
                    },
                    // {
                    //   permission: true,
                    //   path: "/colis/?service=echange",
                    //   type: "link",
                    //   title: "Colis Echange",
                    // },
                    // {
                    //     permission: ["admin"],
                    //     path: "/colis/?status=modifier",
                    //     type: "link",
                    //     title: "Colis Modifiés",
                    // },
                    // {
                    //     permission: ["admin", "fournisseur", "commercial", "personnel"],
                    //     title: "Filtres d'exportation",
                    //     type: "link",
                    //     active: false,
                    //     path: "/colis-export"
                    // },
                    //   {
                    //   permission:
                    //     (user_groups_name && user_groups_name.indexOf("admin") !== -1) ||
                    // (user_groups_name && user_groups_name.indexOf("fournisseur") !== -1)
                    //   ? true
                    //   : false,
                    //   path: "/colis/?status=payer",
                    //   type: "link",
                    //   title: "Colis Payés",
                    // },
                ],
            },

            {
                permission: ["admin", "fournisseur"],
                title: "User",
                icon: Users,
                type: "sub",
                active: false,
                children: [
                    {
                        permission: ["admin", "fournisseur", "commercial", "personnel", "livreur"],
                        path: "/compte",
                        title: "Compte",
                        type: "link",
                    },
                    {
                        permission: ["admin"],
                        path: "/compte/?type=personnel",
                        title: "Personnel",
                        type: "link",
                    },
                    {
                        permission: ["admin"],
                        path: "/group",
                        title: "Group",
                        type: "link",
                    },
                ],
            },
            {
                permission: ["admin", "fournisseur", "commercial", "personnel"],
                icon: Phone,
                active: false,
                path: "/reclamation",
                title: "Réclamation",
                type: "link",
            },
            {
                permission: ["admin"],
                        path: "/stock_depot",
                        title: "Stock dépôt",
                        type: "link",
                        stock: false,
                icon: Box,
                active: false,
                // children: [
                //     {
                //         permission: ["admin", "fournisseur", "commercial", "personnel", "livreur"],
                //         path: "/stock_depot",
                //         title: "Stock dépôt",
                //         type: "link",
                //         stock: true,
                //     },
                //     {
                //         permission: ["admin", "fournisseur", "commercial", "personnel", "livreur"],
                //         path: "/stock_depot/activity",
                //         title: "Activités de stock",
                //         type: "link",
                //         stock: true,
                //     },
                // ],
            },
            {
                permission: ["admin", "fournisseur", "commercial", "personnel"],
                icon: DollarSign,
                active: false,
                title: "Finance",
                path: "/finance",
                        type: "link",
                // children: [
                //     {
                //         permission: ["admin", "fournisseur", "commercial", "personnel"],
                //         title: "Finance",
                //         path: "/finance",
                //         type: "link",
                //     },
                //     {
                //         permission: ["admin"],
                //         title: "import frais livreur",
                //         path: "/import-frais-livreurs",
                //         type: "link",
                //     }
                // ]
            },
            {
                permission: ["admin"],
                title: "Gestion",
                icon: Settings,
                type: "sub",
                active: false,
                children: [
                    {
                        permission: ["admin"],
                        path: "/fournisseur",
                        title: "Fournisseur",
                        type: "link",
                    },

                    {
                        permission: ["admin"],
                        path: "/secteur-activite",
                        title: "Secteur d'activité",
                        type: "link",
                    },
                    {
                        permission: ["admin"],
                        path: "/demande",
                        title: "Demandes",
                        type: "link",
                    },
                    {
                        permission: ["admin"],
                        path: "/news",
                        title: "Nouvelles",
                        type: "link",
                    },
                ],
            },
            {
                permission: ["admin"],
                icon: Star,
                active: false,
                path: "/avis",
                title: "Avis",
                type: "link",
            },
        ],
    },
];