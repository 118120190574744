import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {getColisP} from "./action";
import {connect, useDispatch} from "react-redux";
import {Link, useLocation} from "react-router-dom";
import PropTypes from "prop-types";
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import Spinner from "./spinner.gif";
import {day2digits} from "./gestiondescolis";
import {Table} from "reactstrap";
import {reformatStatus} from "./coli";
import {CSVLink} from "react-csv";


const CSV = ({colisData}) => {
    const [alldata, searchData] = useState([]);
    const dispatch = useDispatch();

    const headers = [
        {label: "id", key: "id"},
        {label: "hfl", key: "hfl"},
        {label: "Code de suivi", key: "tracking_code"},
        {label: "adresse", key: "address_name"},
        {label: "Crée", key: "created_at"},
        {label: "Modifié", key: "updated_at"},
        {label: "Code à barre", key: "code_a_barre"},
        {label: "Email", key: "client_email"},
        {label: "Téléphone 1", key: "client_telephone_1"},
        {label: "Téléphone 2", key: "client_telephone_2"},
        {label: "Nom", key: "client_name"},
        {label: "Prénom", key: "client_prenom"},
        {label: "Code postale", key: "client_zipcode"},
        {label: "Adresse du client", key: "client_address"},
        {label: "prix", key: "prix"},
        {label: "service", key: "service"},
        {label: "volume", key: "volume"},
        {label: "remarque", key: "remarque"},
        {label: "remarque échange", key:"remarque_echange"},
        {label: "Nom du produit", key: "product_name"},
        {label: "status", key: "status"},
        {label: "livreur", key: "livreur"},
        {label: "client_address_pickup", key: "client_address_pickup"},
        {label: "client_ville", key: "client_ville"},
        {label: "client_cite", key: "client_cite"},
        {label: "client_deligaiton", key: "client_deligaiton"},
        {label: "related_payment", key: "related_payment"},
    ];
    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };
    let query = useQuery();
    let params = new URLSearchParams(document.location.search);
    let status = params.get("status");
    useEffect(() => {
        if (colisData.colisP) {
            searchData(colisData.colisP);
        }
    }, [colisData.colisP,colisData.loading, alldata]);

    ReactHTMLTableToExcel.format = (s, c) => {
        if (c && c["table"]) {
            const html = c.table;
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, "text/html");
            const rows = doc.querySelectorAll("thead tr th");
            for (const row of rows) {
                row.setAttribute(
                    "style",
                    "background: #B8CCE4"
                );
            }


            c.table = doc.querySelector("table").outerHTML;
        }

        return s.replace(/{(\w+)}/g, (m, p) => c[p]);
    };

    const getDate = () => {
        let params = new URLSearchParams(document.location.search);
        let date = params.get("created_at");
        if (date === "today") {
            let today = new Date();
            let m = today.getMonth() + 1;
            if (m < 10) {
                m = "0" + m;
            }
            return today.getFullYear() + "-" + m + "-" + day2digits(today.getDate());
        } else {
            return date;
        }
    };
    const getStatus = () => {
        let params = new URLSearchParams(document.location.search);
        let status = params.get("status");
        let service = params.get("service");
        let gte = params.get("created_at__gte");
        let lte = params.get('created_at__lte');
        if (status === "supprimer") {
            return "Colis supprimés";
        } else if (status === "retour") {
            return "Colis retour";
        } else if (status === "modifier") {
            return "Colis modifiés";
        } else if (service === "echange") {
            return "Colis echange";
        } else if (status === "payer") {
            return "Colis payés";
        } else if (gte && lte) {
            return `Gestions des colis du ${gte} à ${lte} ${status && status ? "et status= " + status : ""}`
        } else {
            return `Colis du jour : ${getDate()}`;
        }
    };
    const csvReport = {
        data: colisData.colisP,
        headers: headers,
        filename: `Beezit-${getStatus()}.csv`,
    };


    let length = colisData.colisP.length
    const COD = colisData.colisP.reduce((totalCod, cod) => totalCod + cod.prix, 0);
    useEffect(() => {
        dispatch(getColisP(query))
    }, [dispatch, query])

    useEffect(() => {
        if (length > 0) {
            setTimeout(() => {
                window.close()
            }, 500)
        }
    }, [length])

    useEffect(() => {
        if (length > 0) {
            document.getElementById("excelexport").click()
        }
    }, [length])
    return (
        <Fragment>
            {colisData && colisData.loading ? <div className="text-center">
                <img src={Spinner} style={{width: "10%"}} alt=""/>
            </div> : ""
            }
            <div hidden>
                <CSVLink
                    className="btn text-white m-2 "
                    style={{backgroundColor: "#E83680"}}
                    title="Export CSV"
                    {...csvReport}
                    id="excelexport"
                >
                    CSV
                </CSVLink>
                <Table striped id="example">
                    <thead>
                    <tr>
                        <th>
                            {"Code à barre"}
                        </th>
                        <th>
                            {"Code de suivi"}
                        </th>
                        <th>
                            {"Status"}
                        </th>
                        <th>
                            {"Expéditeur"}
                        </th>
                        <th>
                            {"Destinataire"}
                        </th>
                        <th>
                            {"Tél 1 / Tél 2"}
                        </th>
                        <th>
                            {"Ville"}
                        </th>
                        <th>
                            {"Délégation"}
                        </th>
                        <th>
                            {"Service"}
                        </th>
                        <th>
                            {"COD"}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {colisData &&
                        colisData.colisP.map((b) => (
                            <tr
                                key={b.id}
                                className={b.selected ? "selected" : ""}
                            >
                                <td>
                                    <Link to={`/colis/detail/${b.id}`}>
                                        {b.code_a_barre}
                                    </Link>
                                </td>
                                <td>{b.tracking_code}</td>
                                <td>{reformatStatus(b.status)}</td>
                                <td>{b.fournisseur_name}</td>
                                <td>
                                    {b.client_name}
                                </td>
                                <td>
                                    {b.client_telephone_1} /
                                    {b.client_telephone_2}
                                </td>
                                <td>{b.ville_name}</td>
                                <td>{b.delegation_name}</td>
                                <td>{b.service}</td>
                                <td>{b.prix}</td>
                            </tr>
                        ))}
                    </tbody>


                    {status && status === "payer" ?
                        <tfoot>
                        <tr></tr>
                        {colisData && colisData.loading ? (
                            <div className="text-center">
                                <img src={Spinner} style={{width: "10%"}} alt=""/>
                            </div>
                        ) : (
                            <tr>
                                <td>Total</td>
                                <td>{length} Colis payés</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{COD} </td>
                            </tr>)}
                        </tfoot> : ""}
                </Table>
            </div>
        </Fragment>
    )
}
CSV.propTypes = {
    getColisP: PropTypes.func.isRequired,
    colisData: PropTypes.object.isRequired,
}
const mapstatetoprops = (state) => ({
    colisData: state.reducerc,
});

export default connect(mapstatetoprops, {getColisP})(CSV)