import React, {
  Fragment,
  useState,
  useEffect,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Barcode from "react-barcode";
import { withRouter, useLocation } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardFooter,
  CardBody,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { getColisByIds } from "../colis/action";
import { getFournisseurcolis } from "../Fournisseurs/action";

const Financeexport = ({
  colisData,
}) => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  };
  let query = useQuery();

  useEffect(() => {
    dispatch(getColisByIds(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (colisData.colis) {
      setData(colisData.colis);
    }
  }, [colisData.colis, colisData.loading, data]);
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Row>
                  <div className="col-md-5 offset-md-1">
                    <img
                      className="imagedecharge"
                      src={require("./decharge.png")}
                      alt=""
                    />
                    <h6>TEL: 29812970</h6>
                    <h6>Adresse: Zaraani kalaa kbira est, Sousse, Tunisie </h6>
                  </div>
                  {colisData && colisData.colis[0] ? (
                    <div className="col-md-5  m-auto text-center">
                      <h6>
                        Fournisseur: {colisData.colis[0].fournisseur_name}{" "}
                      </h6>
                      <h6>Tél: {colisData.colis[0].fournisseur_tel} </h6>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="col-lg-12 table-responsive">
                    <div
                      id="example_wrapper"
                      className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
                    >
                      <table
                        className="table align-items-center table-bordered dataTable no-footer"
                        id="example"
                        role="grid"
                      >
                        <thead className="thead-light">
                          <tr role="row">
                            <th
                              className="sorting_disabled"
                              rowSpan="1"
                              colSpan="1"
                            >
                              Code à barre
                            </th>
                            <th
                              className="sorting_disabled"
                              rowSpan="1"
                              colSpan="1"
                            >
                              Client
                            </th>
                            <th
                              className="sorting_disabled"
                              rowSpan="1"
                              colSpan="1"
                            >
                              Ville
                            </th>
                            <th
                              className="sorting_disabled"
                              rowSpan="1"
                              colSpan="1"
                            >
                              Service
                            </th>
                            <th
                              className="sorting_disabled"
                              rowSpan="1"
                              colSpan="1"
                            >
                              Statut
                            </th>
                            <th
                              className="text-center sorting_disabled"
                              rowSpan="1"
                              colSpan="1"
                            >
                              COD
                            </th>
                            <th
                              className="text-center sorting_disabled"
                              rowSpan="1"
                              colSpan="1"
                            >
                              Pointage
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {colisData &&
                            colisData.colis.map((c) => (
                              <tr key={c.id}>
                                <td>
                                  <Barcode
                                    width="1"
                                    height="30"
                                    value={c.id}
                                  />
                                </td>
                                <td>{c.client_prenom + " " + c.client_name}</td>
                                <td>{c.ville_name}</td>
                                <td>{c.service}</td>
                                <td>{c.status}</td>
                                <td>{c.prix}</td>
                                <td></td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col sm="1"></Col>
                  <Col sm="2">
                    <Row>Date collecte</Row>
                    <Row>.......................</Row>
                  </Col>
                  <Col sm="1"></Col>
                  <Col sm="2">
                    <Row>Nom chauffeur</Row>
                    <Row>...........................</Row>
                  </Col>
                  <Col sm="1"></Col>
                  <Col sm="2">
                    <Row>Signature client</Row>
                    <Row>.............................</Row>
                  </Col>
                  <Col sm="1"></Col>
                  <Col sm="2">
                    <Row>Signature chauffeur</Row>
                    <Row>...................................</Row>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

Financeexport.propTypes = {
  getColisByIds: PropTypes.func.isRequired,
  getFournisseurcolis: PropTypes.func.isRequired,
  colisData: PropTypes.object.isRequired,
  fournisseurData: PropTypes.object.isRequired,
};
const mapStateToPorps = (state) => ({
  colisData: state.reducerc,
  financeData: state.reducerfin,
  fournisseurData: state.reducerf,
});

export default connect(mapStateToPorps, {
  getFournisseurcolis,
  getColisByIds,
})(withRouter(Financeexport));
