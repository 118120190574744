import React, {
    Fragment,
    useState,
    useEffect,
    useMemo, useContext,
} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import Error404 from "../../pages/errors/error404";
import Select, {components} from "react-select";
import Spinner from "../colis/spinner.gif";
import PaginationWrapper from "../pagination/pagination";
import Alert from "../../redux/Alert";
import {getFournisseurs, getFournisseursP} from "../Fournisseurs/action";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter, useLocation} from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Table,
    Card,
    CardHeader,
    CardBody,
    Form,
    Label,
} from "reactstrap";
import {useDispatch} from "react-redux";
import {getFinances, GetFinanceByIds, geTotalFinances} from "./action";
import {Link} from "react-router-dom";
import {getColis} from "../colis/action";
import AppContext from "../../context/Context";

const Finance = ({
                     financeData,
                     fournisseurData,
                     GetFinanceByIds,
                     history,
                 }) => {
    const [data, setData] = useState([]);
    const [statefournisseur, setFournisseur] = useState([
        {
            id: "",
            activiter: "",
        },
    ]);
    const [alldata, searchData] = useState([]);
    const [id, setId] = useState(0);
    const [formData, setFormData] = useState({});
    const [selectedfournisseur, setSelectedFournisseur] = useState([]);
    const [currentFournisseurPage, setCurrentFournisseurPage] = useState(1)
    const [ascId, setAscSortId] = useState(true);
    const [timer, setTimer] = useState(null)
    const {
        config: {group}
    } = useContext(AppContext)
    const dispatch = useDispatch();

    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };


    const onSubmit = (e) => {
        e.preventDefault();
        GetFinanceByIds(formData, id);
    };
    let query = useQuery();

    useEffect(() => {
        dispatch(getFinances(query));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (financeData.finances) {
            searchData(financeData.finances);
        }
    }, [financeData.finances, financeData.loading, alldata]);
    let pagenumber = query.get("page") ? query.get("page") : 1;
    const Navigate = (page) => {
        query.set("page", `${Number(page)}`)

        window.location.replace(`/finance/?${query}`);
    };

    //get fournisseurs
    useEffect(() => {
        query.set("page", currentFournisseurPage)
        dispatch(getFournisseurs(query))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFournisseurPage]);

    useEffect(() => {
        setFournisseur(fournisseurData.fournisseurs);
    }, [fournisseurData.fournisseurs, fournisseurData.loading, statefournisseur]);
    useEffect(() => {
        dispatch(geTotalFinances(query));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setFournisseur(fournisseurData.fournisseurs);
    }, [fournisseurData.fournisseurs, fournisseurData.loading, statefournisseur]);

    const link = (id) => {
        history.push(`/finance_f/${id}`);
    };
    let count = financeData.count;
    let user_groups_name = group;

    const from = () => {
        let params = new URLSearchParams(document.location.search);
        return params.get("created_at__gte");
    };

    const to = () => {
        let params = new URLSearchParams(document.location.search);
        return params.get("created_at__lte");
    };

    const onAscSort = (e) => {
        const qr = query.toString() + `&ordering=${e.target.id}`;
        setAscSortId(false);
        dispatch(getColis(qr));
        setData(data);
    };

    const onDescSort = (e) => {
        const qr = query.toString() + `&ordering=-${e.target.id}`;
        setAscSortId(true);
        dispatch(getColis(qr));
        setData(data);
    };
    const onChangeFournisseur = (e) => {
        setSelectedFournisseur(e);
        const fournisseurid = e.value;

        setFormData(fournisseurid);
    };
    const dataF = [];
    fournisseurData &&
    fournisseurData.fournisseurs.map((F) =>
        dataF.push({
            value: parseInt(F.id),
            label: F.name,
        })
    );
    const getValSearch = () => {
        let params = new URLSearchParams(document.location.search);
        return params.get("q");
    };
    const inputChanged = (e) => {
        query.set("search", e)
        clearTimeout(timer)
        query.set("search", e)
        const newTimer = setTimeout(() => {
            dispatch(getFinances(query));
            dispatch(geTotalFinances(query));
        }, 500)

        setTimer(newTimer)
    }

    const NoOptionsMessage = props => {
        return (
            <components.NoOptionsMessage {...props}>
                <div className="text-center">
                    <img src={Spinner} style={{width: "10%"}} alt=""/>
                </div>
            </components.NoOptionsMessage>
        );
    };
    return (
        <Fragment>
            <Breadcrumb parent="Finance"/>
            {(user_groups_name && user_groups_name.indexOf("admin") !== -1) ||
            (user_groups_name && user_groups_name.indexOf("fournisseur") !== -1) ? (
                <div>
                    <Container fluid={true}>
                        <Alert/>
                        {user_groups_name && user_groups_name.indexOf("admin") !== -1 ? (
                            <Row>
                                <Col sm="12">
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col sm="3"></Col>
                                                <Col sm="6">
                                                    <Form onSubmit={(e) => onSubmit(e)}>
                                                        <Label>Choisir Un Fournisseur</Label>
                                                        <Row>
                                                            <Col>
                                                                <Select
                                                                    value={selectedfournisseur}
                                                                    options={dataF}
                                                                    components={{NoOptionsMessage}}
                                                                    onChange={(e) => {
                                                                        onChangeFournisseur(e);
                                                                    }}
                                                                    onInputChange={(e) => {
                                                                        query.set('search', e);
                                                                        dispatch(getFournisseurs(query))
                                                                    }}
                                                                    onMenuScrollToBottom={(e) => {
                                                                        console.log(e)
                                                                        setCurrentFournisseurPage(page => page + 1)
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <button
                                                                    type="submit"
                                                                    onClick={() => {
                                                                        setId(formData);
                                                                        link(formData);
                                                                    }}
                                                                    className="btn "
                                                                    style={{backgroundColor: "#FDF203"}}
                                                                >
                                                                    Ajouter
                                                                </button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        ) : (
                            ""
                        )}
                        <Row>
                            <Col sm="12">
                                <Card>
                                    {/* {datacolis.colis.length > 0 ? () : ()} */}
                                    <Row>
                                        <Col>
                                            <CardHeader>
                                                <Row>

                                                    <Col sm={"8"}>
                                                        <Row>
                                                            <div className="m-4">
                                                                <h4>Du</h4>
                                                                <input
                                                                    className="btn btn-light"
                                                                    type="date"
                                                                    name="created_at__gte"
                                                                    defaultValue={from()}
                                                                    onChange={(e) => query.set("created_at__gte", e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="m-4">
                                                                <h4>A</h4>
                                                                <input
                                                                    className="btn btn-light"
                                                                    type="date"
                                                                    name="created_at__lte"
                                                                    defaultValue={to()}
                                                                    onChange={(e) => query.set("created_at__lte", e.target.value)}
                                                                />
                                                                <button
                                                                    type="submit"
                                                                    onClick={() => {
                                                                        // eslint-disable-next-line no-lone-blocks
                                                                        {
                                                                            history.push({search: query.toString()})
                                                                            dispatch(getFinances(query));
                                                                            dispatch(geTotalFinances(query))
                                                                        }
                                                                    }
                                                                    }
                                                                    className="btn text-white ml-2"
                                                                    style={{backgroundColor: "#27B3B0"}}
                                                                >
                                                                    Valider
                                                                </button>

                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Col>
                                                        <Table striped className="inline-table">
                                                            <thead>
                                                            <tr>
                                                                <th id="total_cod">Total COD</th>
                                                                <td>{financeData && Math.round(financeData.total.total_cod * 100) / 100}</td>
                                                            </tr>
                                                            <tr>
                                                                <th id="cous_livraison">Cout de livraison</th>
                                                                <td>{financeData && Math.round(financeData.total.total_hfl * 100) / 100}</td>
                                                            </tr>
                                                            <tr>
                                                                <th id="total_hfl">Total HFL</th>
                                                                <td>{financeData && Math.round(financeData.total.cous_livraison * 100) / 100}</td>
                                                            </tr>

                                                            </thead>
                                                            <tbody></tbody>
                                                            <tfoot>


                                                            </tfoot>
                                                        </Table></Col>


                                                    {/*{user_groups_name && user_groups_name.indexOf("admin") !== -1 ? (*/}
                                                    {/*<div><h5 style={{backgoundColor: "blue"}}>TotalCOD:<span style={{fontSize: "20px"}}>10000dt</span></h5></div>*/}
                                                    {/*):(*/}
                                                    {/*    <div style={{backgoundColor: "blue"}}><h5 >CountHRF:<span style={{fontSize: "20px"}}>2500dt</span></h5></div> */}
                                                    {/*)}*/}
                                                </Row>
                                            </CardHeader>
                                        </Col>
                                    </Row>

                                    <CardBody>
                                        <Col sm="12">
                                            <Row className={"m-1"}>
                                                <Col>
                                                    <Link
                                                        className="btn btn-excel text-white m-2 "
                                                        to={`/finance/export?${query.toString()}`}
                                                        target={"_blank"}
                                                    >
                                                        {"Excel"}
                                                    </Link>
                                                </Col>
                                                <div className="search-5">
                                                    <h6>Rechercher:</h6>
                                                </div>

                                                <input
                                                    className=" border search "
                                                    style={{borderRadius: "7px"}}
                                                    type="search"
                                                    placeholder="Rechercher..."
                                                    name="search"
                                                    defaultValue={getValSearch()}
                                                    onChange={(e) => inputChanged(e.target.value)}
                                                />
                                            </Row>
                                            <Card>
                                                <div className="card-block row">
                                                    <Col sm="12" lg="12" xl="12">
                                                        <div className="table-responsive">
                                                            {financeData && financeData.loading ? (
                                                                <div className="text-center">
                                                                    <img src={Spinner} style={{width: "10%"}} alt=""/>
                                                                </div>
                                                            ) : (
                                                                <Table striped>
                                                                    <thead>
                                                                    <tr>
                                                                        <th
                                                                            id="id"
                                                                            onClick={(e) =>
                                                                                ascId ? onAscSort(e) : onDescSort(e)
                                                                            }
                                                                        >
                                                                            {"Id"}
                                                                        </th>
                                                                        <th
                                                                            id="user_id"
                                                                            onClick={(e) =>
                                                                                ascId ? onAscSort(e) : onDescSort(e)
                                                                            }
                                                                        >
                                                                            {"User ID"}
                                                                        </th>
                                                                        <th
                                                                            id="created_at"
                                                                            onClick={(e) =>
                                                                                ascId ? onAscSort(e) : onDescSort(e)
                                                                            }
                                                                        >
                                                                            {"Date"}
                                                                        </th>

                                                                        <th
                                                                            id="nombre_de_colis"
                                                                            onClick={(e) =>
                                                                                ascId ? onAscSort(e) : onDescSort(e)
                                                                            }
                                                                        >
                                                                            {"Nombre de colis"}
                                                                        </th>
                                                                        <th
                                                                            id="name_fournisseur"
                                                                            onClick={(e) =>
                                                                                ascId ? onAscSort(e) : onDescSort(e)
                                                                            }
                                                                        >
                                                                            {"Fournisseur"}
                                                                        </th>
                                                                        <th
                                                                            id="username"
                                                                            onClick={(e) =>
                                                                                ascId ? onAscSort(e) : onDescSort(e)
                                                                            }
                                                                        >
                                                                            {"Nom d'utilisateur"}
                                                                        </th>
                                                                        <th
                                                                            id="total_cod"
                                                                            onClick={(e) =>
                                                                                ascId ? onAscSort(e) : onDescSort(e)
                                                                            }
                                                                        >
                                                                            {"Total COD"}
                                                                        </th>
                                                                        <th
                                                                            id="cous_livraison"
                                                                            onClick={(e) =>
                                                                                ascId ? onAscSort(e) : onDescSort(e)
                                                                            }
                                                                        >
                                                                            {"Cout total de livraison"}
                                                                        </th>
                                                                        <th
                                                                            id="total_hfl"
                                                                            onClick={(e) =>
                                                                                ascId ? onAscSort(e) : onDescSort(e)
                                                                            }
                                                                        >
                                                                            {"Total HFL"}
                                                                        </th>

                                                                        <th>{"Action"}</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {financeData &&
                                                                        financeData.finances.map((b) => (
                                                                            <tr key={b.id}>
                                                                                <td>
                                                                                    <Link to={`/finance/${b.id}`}>
                                                                                        {b.id}
                                                                                    </Link>
                                                                                </td>
                                                                                <td>
                                                                                    <Link to={`/fournisseur/edit/${b.fournisseur}`}>
                                                                                        {b.fournisseur}
                                                                                    </Link>
                                                                                </td>
                                                                                <td>{b.created_at}</td>
                                                                                <td>{b.nombre_de_colis}</td>
                                                                                <td>{b.name_fournisseur}</td>
                                                                                <td>{b.username}</td>
                                                                                <td>{Math.round(b.total_cod * 100) / 100}</td>
                                                                                <td>{Math.round(b.total_hfl * 100) / 100}</td>
                                                                                <td>{Math.round(b.cous_livraison * 100) / 100}</td>
                                                                                <td>
                                                                                    <Link
                                                                                        to={`/finance/${b.id}`}
                                                                                        className="btn  btn-primary m-1"
                                                                                    >
                                                                                        Details
                                                                                    </Link>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    <tr>
                                                                        <td></td>
                                                                    </tr>
                                                                    </tbody>
                                                                </Table>
                                                            )}
                                                        </div>
                                                    </Col>
                                                </div>
                                            </Card>
                                        </Col>{" "}
                                        <Row>
                                            <Col sm="10"></Col>
                                            {financeData && financeData.loading ? "" :
                                                <Col>
                                                    <PaginationWrapper
                                                        name="Finances"
                                                        length={financeData.finances.length}
                                                        count={count}
                                                        currentPage={pagenumber}
                                                        onNavigate={Navigate}
                                                    />
                                                </Col>}
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            ) : (
                <Error404/>
            )}
        </Fragment>
    );
};

Finance.propTypes = {
    geTotalFinances: PropTypes.func.isRequired,
    getFinances: PropTypes.func.isRequired,
    getFournisseursP: PropTypes.func.isRequired,
    GetFinanceByIds: PropTypes.func.isRequired,
    financeData: PropTypes.object.isRequired,
};

const mapstatetoprops = (state) => ({
    financeData: state.reducerfin,
    fournisseurData: state.reducerf,
});

export default connect(mapstatetoprops, {
    getFinances,
    geTotalFinances,
    getFournisseursP,
    GetFinanceByIds,
})(withRouter(Finance));
