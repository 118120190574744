import React from 'react';
import "../css/footer.css"
import md1 from "../../../assets/images/logo/logo_dark.png"
import { Link } from 'react-router-dom';
import { Container , Row , Col} from 'reactstrap';
import ff from "../images/ff.png"
import ii from "../images/ii.png"
import li from '../images/linkedini.png'

export default function Footer() {
  return (
    <div style={{backgroundColor : "#193256" , width:"100%" , padding : "30px 50px" , textAlign: "center" , color: "white" }}>
      
   <Container>
    <Row>
      <Col xs={12} md={4} xl={4} >
      <img src={md1} width={"200px"} alt=""/>
      <h3>who we are</h3>
      <p>Solution de livraison et de stockage basée sur des algorithmes qui ptimisent les processus pour le développement de vos activités E-commerce .</p>
      </Col>
      <Col xs={12} md={4} xl={4} style={{paddingTop:"20px"}} >
      
      <h5 > <Link  style={{color : "white" , fontWeight : "600"}} to={"/"}>HOME</Link></h5>
      <h5 >  <Link style={{color : "white" , fontWeight : "600"}} to={"/services"}> ABOUT  </Link></h5>
      <h5 > <Link style={{color : "white" , fontWeight : "600"}} to={"/about"}>COMMENT? </Link></h5>
      <h5 > <Link style={{color : "white" , fontWeight : "600"}} to={"/partner"}> CONTACT  </Link></h5>
      </Col>
      
      <Col xs={12} md={4} xl={4}  >
      
      <h2>Contact</h2>
      <h5>Adress</h5>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a>Jwahara Sousse</a><br/>
      <h5>phone</h5>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a> 29812970 / 29812980</a><br/>
        <h5>Email</h5>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a>info@beezit.pro</a>
      </Col>
    </Row>
<div>
  <a href='https://www.facebook.com/beezit.tn' style={{margin : "15px" , padding : "6px 0px"}}><img src={ff} height={"32px"}  alt={""}/></a>
  <a  href='https://www.instagram.com/beez.it/' style={{margin : "15px" , padding : "6px 0px"}}><img src={ii} height={"32px"}  alt={""}/></a>
  <a  href='https://www.linkedin.com/company/beezit/' style={{margin : "15px" , padding : "6px 0px"}}><img src={li} height={"32px"}  alt={""}/></a>
  
  </div>
   
   </Container>
   </div>
  );
}