import React, {Fragment, useState, useEffect, useMemo, useContext} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import Alert from "../../redux/Alert";
import PropTypes from "prop-types";
import PaginationWrapper from "../pagination/pagination";
import Spinner from "../colis/spinner.gif";
import {connect} from "react-redux";
import {withRouter, useLocation} from "react-router-dom";
import {Container, Row, Col, Table, Card, CardBody} from "reactstrap";
import {useDispatch} from "react-redux";
import {getGroups} from "./action";
import Error404 from "../../pages/errors/error404";
import AppContext from "../../context/Context";

const Group = ({groupData}) => {
    const [stategroup, setGroup] = useState([
        {
            id: "",
            name: "",
        },
    ]);
    const {
        config: {group}
    } = useContext(AppContext)
    const dispatch = useDispatch();

    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };

    let query = useQuery();
    let pagenumber = query.get("page") ? query.get("page") : 1;
    const Navigate = (page) => {
        query.set("page", Number(page));

        window.location.replace(`/group/?${query}`);
    };

    useEffect(() => {
        dispatch(getGroups(query));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setGroup(groupData.groups);
    }, [groupData.groups, groupData.loading, stategroup]);

    let count = groupData.count;
    let user_groups_name = group;
    return (
        <Fragment>
            <Breadcrumb parent="User" title="Groupes"/>
            {user_groups_name && user_groups_name.indexOf("admin") !== -1 ? (
                <div>
                    <Container fluid={true}>
                        <Alert/>
                        <Row>
                            <Col sm="12">
                                <Card>
                                    {/* {datacolis.colis.length > 0 ? () : ()} */}
                                    <CardBody>
                                        <Col sm="12">
                                            <Card>
                                                <div className="card-block row">
                                                    <Col sm="12" lg="12" xl="12">
                                                        <div className="table-responsive">
                                                            {groupData && groupData.loading ? (
                                                                <div className="text-center">
                                                                    <img src={Spinner} style={{width: "10%"}} alt=""/>
                                                                </div>
                                                            ) : (
                                                                <Table striped>
                                                                    <thead>
                                                                    <tr>
                                                                        <th>{"Nom du groupe"}</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {groupData &&
                                                                        groupData.groups.map((b) => (
                                                                            <tr key={b.name}>
                                                                                <td>{b.name}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </Table>
                                                            )}
                                                        </div>
                                                    </Col>
                                                </div>
                                            </Card>
                                        </Col>{" "}
                                        <Row>
                                            <Col sm="10"></Col>
                                            <Col>
                                                <PaginationWrapper
                                                    name="Groupes"
                                                    count={count}
                                                    length={groupData.groups.length}
                                                    currentPage={pagenumber}
                                                    onNavigate={Navigate}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            ) : (
                <Error404 page="Groupes"/>
            )}
        </Fragment>
    );
};

Group.propTypes = {
    getGroups: PropTypes.func.isRequired,
    groupData: PropTypes.object.isRequired,
};

const mapstatetoprops = (state) => ({
    groupData: state.reducerg,
});

export default connect(mapstatetoprops, {
    getGroups,
})(withRouter(Group));
