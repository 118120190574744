import {Fragment, useEffect, useMemo, useState} from "react";
import {getLivreurs} from "../../colis/action";
import {useLocation} from "react-router-dom";
import Select from "react-select";
import {Col, Form, Label, Row} from "reactstrap";
import {useForm} from "react-hook-form";

const Carrier = ({livreur, setLivreur, formData, setFormData}) => {
    const [currentPage, setCurrentPage] = useState(1)
    const [livreurDatas, setLivreurData] = useState([])

    const {handleSubmit, errors } = useForm();

    const onSubmit = data => {

        if (data !== '') {
            alert('You submitted the form and stuff!');
        } else {
            errors.showMessages();
        }
    };

    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };
    let query = useQuery();

    useEffect(() => {
        query.set("page_size", "50")
        query.set("page", currentPage)
        getLivreurs(query)
            .then(res => setLivreurData([...livreurDatas, ...res?.results]))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    const onChangeLivreur = (e) => {
        setLivreur(e);
        setFormData({...formData, livreur: livreur.value})
    };

    let livreurOptions;

    livreurOptions = livreurDatas.map((livreur) => ({
        value: livreur.id,
        label: livreur.name
    }));

// Add the reset option
    livreurOptions = [
        {value: "", label: "------------------"},
        ...livreurOptions
    ];

    return (
        <Fragment>
            <Row>
                <Col sm="12">
                    <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-row">

                            <Col md="12 mb-3">
                                <Label>Livreur</Label>
                                <Select
                                    options={livreurOptions}
                                    onChange={onChangeLivreur}
                                    value={livreur}
                                    placeholder={"Livreur"}
                                    name={'livreur'}
                                    className="text-start"
                                    onInputChange={(e) => {
                                        query.set('search', e);
                                        getLivreurs(query);
                                    }}
                                    onMenuScrollToBottom={(e) => {
                                        console.log(e)
                                        setCurrentPage(page => page + 1)
                                    }}
                                />
                            </Col>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Fragment>
    )
}

export default Carrier