import {
    GET_COLI,
    GET_GENERATE,
    GET_GENERATED,
    GENERATED_LOADING,
    GENERATE_ERROR,
    GENERATED_ERROR,
    COLISP_LOADING,
    GET_COLISP,
    COLISP_ERROR,
    COLISH_LOADING,
    VILLEVENTE_LOADING,
    GET_VILLEVENTE,
    VILLEVENTE_ERROR,
    SECTEURPERCENTS_LOADING,
    GET_SECTEURPERCENTS,
    SECTEURPERCENTS_ERROR,
    GET_CHART,
    CHART_LOADING,
    CHART_ERROR,
    EXPORT_LOADING,
    TOTAL_ERROR,
    TOTAL_LOADING,
    GET_TOTAL,
    EXPORT_ERROR,
    GET_EXPORT,
    COLISM_LOADING,
    GET_COLISM,
    COLISM_ERROR,
    GET_COLISH,
    COLISH_ERROR,
    HISTORY_ERROR,
    HISTORY_LOADING,
    GET_HISTORY,
    GET_COLIS,
    GET_COLISP1,
    DELETE_COLIS,
    UPDATE_COLIS,
    UPDATE_COLIS_REQUEST,
    UPDATE_COLIS_SUCCESS,
    CLEAR_COLIS,
    COLIS_ERROR,
    GET_LIVREUR,
    GET_ADRESSE,
    ADRESSE_ERROR,
    ADRESSE_LOADING,
    LIVREUR_LOADING,
    LIVREUR_ERROR,
    COLIS_LOADING,
    VILLE_LOADING,
    VILLE_ERROR,
    RESET_COLI,
    GET_VILLE,
    DELIGATION_LOADING,
    DELIGATION_ERROR,
    GET_DELIGATION,
    CITE_LOADING,
    CITE_ERROR,
    GET_CITE,
    FILIERE_LOADING,
    FILIERE_ERROR,
    GET_FILIERE,
    GET_TRACKING,
    UPDATE_TRACKINGS,
    TRACKING_ERROR,
    GET_TRACKING_ACTIVITIES,
    TRACKING_ACTIVITIES_LOADING,
    TRACKING_ACTIVITIES_ERROR,
    TRACKING_LOADING,
    GESTION_ERROR,
    GESTION_LOADING,
    GET_GESTIONS,
    DASHBOARD_ERROR,
    DASHBOARD_LOADING,
    GET_DASHBOARD,
    GET_COLISS,
    COLISS_LOADING,
    COLISS_ERROR,
    GET_COLISR,
    COLISR_LOADING,
    COLISR_ERROR,
    GET_COLISE,
    COLISE_LOADING,
    COLISE_ERROR,
    GET_COLISMO,
    COLISMO_LOADING,
    COLISMO_ERROR,
    GET_COLISPY,
    COLISPY_LOADING,
    COLISPY_ERROR,
    GENERATE_ORDER,
    GENERATE_ORDER_SUCCESS,
    GENERATE_ORDER_FAIL, LOGS_ERROR, LOGS_LOADING, GET_LOGS

} from "../../redux/actionTypes";

const initial_state = {
    dashboardLoading: false,
    coli: null,
    colip: null,
    coliss: [],
    generated: [],
    generate: [],
    colisr: [],
    colismo: [],
    colise: [],
    colispy: [],
    colisP: [],
    colisP1: [],
    colism: [],
    gestions: {},
    dashboard: {},
    max: [],
    history: [],
    counth: 0,
    pays: {},
    percents: {},
    cites: [],
    countm: 0,
    colish: [],
    livreurs: [],
    adresses: [],
    deligations: [],
    villeMax: 0,
    villeMin: 0,
    count: 0,
    filieres: [],
    villes: [],
    tracking: [],
    trackingactivities: [],
    logs: [],
    colis: [],
    villevente: [],
    chart: null,
    secteurpercents: [],
    total: null,
    link: null,
    loading: false,
    loadingg: false,
    chartloading: false,
    secteurloading: false,
    villeventeloading: false,
    error: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initial_state, action) {
    const {type, payload} = action;
    switch (type) {
        case CHART_ERROR:
            return {
                ...state,
                error: payload,
                chartloading: false
            }
        case VILLEVENTE_ERROR:
            return {
                ...state,
                error: payload,
                villeventeloading: false
            }
        case SECTEURPERCENTS_ERROR:
            return {
                ...state,
                error: payload,
                secteurloading: false,
            }
        case COLISMO_ERROR:
        case TOTAL_ERROR:
        case EXPORT_ERROR:
        case COLISS_ERROR:
        case COLISE_ERROR:
        case COLISPY_ERROR:
        case COLISR_ERROR:
        case COLISH_ERROR:
        case COLISM_ERROR:
        case GENERATE_ERROR:
        case GENERATED_ERROR:
        case COLISP_ERROR:
        case HISTORY_ERROR:
        case ADRESSE_ERROR:
        case COLIS_ERROR:
        case TRACKING_ERROR:
        case TRACKING_ACTIVITIES_ERROR:
        case FILIERE_ERROR:
        case DELIGATION_ERROR:
        case CITE_ERROR:
        case LOGS_ERROR:
        case VILLE_ERROR:
        case LIVREUR_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        case GENERATE_ORDER:
            return {
                ...state,
                generateOrder: payload,
                loading: false
            }
        case GENERATE_ORDER_SUCCESS:
            return {
                ...state,
                generateOrder: payload,
                loading: false
            }
        case GENERATE_ORDER_FAIL:
            return {
                ...state,
                error: payload
            }
        case GESTION_ERROR:
            return {
                ...state,
                error: payload,
                loadingg: false,
            }
        case DASHBOARD_ERROR:
            return {
                ...state,
                error: payload,
                dashboardLoading: false
            }
        case CHART_LOADING:
            return {
                ...state,
                chartloading: true
            }
        case VILLEVENTE_LOADING:
            return {
                ...state,
                villeventeloading: true,
            }
        case SECTEURPERCENTS_LOADING:
            return {
                ...state,
                secteurloading: true
            }
        case COLISS_LOADING:
        case TOTAL_LOADING:
        case COLISR_LOADING:
        case GENERATED_LOADING:
        case EXPORT_LOADING:
        case COLISPY_LOADING:
        case COLISMO_LOADING:
        case COLISE_LOADING:
        case COLISH_LOADING:
        case COLISP_LOADING:
        case COLISM_LOADING:
        case HISTORY_LOADING:
        case ADRESSE_LOADING:
        case LIVREUR_LOADING:
        case DELIGATION_LOADING:
        case CITE_LOADING:
        case TRACKING_LOADING:
        case TRACKING_ACTIVITIES_LOADING:
        case FILIERE_LOADING:
        case COLIS_LOADING:
        case LOGS_LOADING:
        case VILLE_LOADING:
        case UPDATE_COLIS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_GENERATE:
            return {
                ...state,
                generate: payload.results,
                loading: false
            }
        case GET_GENERATED:
            return {
                ...state,
                generated: payload.results,
                count: payload.count,
                loading: false
            }
        case GET_CHART:
            return {
                ...state,
                chart: payload,
                chartloading: false
            }
        case GET_VILLEVENTE:
            return {
                ...state,
                villeMax: payload.max,
                villeMin: payload.min,
                villevente: payload.data,
                villeventeloading: false
            }
        case DASHBOARD_LOADING:
            return {
                ...state,
                dashboardLoading: true
            }
        case GET_SECTEURPERCENTS:
            return {
                ...state,
                secteurpercents: payload,
                secteurloading: false
            }
        case GET_TOTAL:
            return {
                ...state,
                total: payload,
                loading: false,
            }
        case GESTION_LOADING:
            return {
                ...state,
                loadingg: true,
            }
        case GET_COLISS :
            return {
                ...state,
                count: payload.count,
                coliss: payload.results,
                loading: false,
            }
        case GET_EXPORT:
            return {
                ...state,
                link: payload,
                loading: false
            }
        case GET_COLISMO:
            return {
                ...state,
                count: payload.count,
                colismo: payload.results,
                loading: false,
            }
        case GET_COLISR:
            return {
                ...state,
                count: payload.count,
                colisr: payload.results,
                loading: false,
            }
        case GET_COLISE :
            return {
                ...state,
                count: payload.count,
                colise: payload.results,
                loading: false
            }
        case GET_COLISPY:
            return {
                ...state,
                count: payload.count,
                colispy: payload.results,
                loading: false
            }
        case GET_COLISM:
            return {
                ...state,
                countm: payload.count,
                colism: payload.results,
                loading: false,
            }
        case GET_COLISH:
            return {
                ...state,
                count: payload.count,
                colish: payload.results,
                loading: false,
            }
        case GET_LOGS:
            return {
                ...state,
                count: payload.count,
                logs: payload.results,
                loading: false,
            }
        case GET_HISTORY:
            return {
                ...state,
                counth: payload.count,
                history: payload.results,
                loading: false,
            }
        case GET_DASHBOARD:
            return {
                ...state,
                max: payload.max_3_pays,
                pays: payload.pays,
                dashboard: payload,
                dashboardLoading: false,
            };
        case GET_COLISP1 :
            return {
                ...state,
                colisP1: payload,
                loading: false,
            }
        case GET_COLISP:
            return {
                ...state,
                colisP: payload.results,
                loading: false
            }
        case GET_GESTIONS:
            return {
                ...state,
                percents: payload.percents,
                gestions: payload,
                loadingg: false,
            };
        case GET_VILLE:
            return {
                ...state,
                villes: payload,
                loading: false,
            };
        case GET_ADRESSE:
            return {
                ...state,
                adresses: payload.results,
                loading: false,
            };
        case GET_LIVREUR:
            return {
                ...state,
                livreurs: payload.results,
                loading: false,
            };
        case GET_TRACKING:
            return {
                ...state,
                tracking: payload.results,
                loading: false,
            };
        case GET_TRACKING_ACTIVITIES:
            return {
                ...state,
                trackingactivities: payload.results,
                loading: false,
            }
        case GET_FILIERE:
            return {
                ...state,
                filieres: payload.results,
                loading: false,
            };
        case GET_CITE:
            return {
                ...state,
                cites: payload,
                loading: false,
            };
        case GET_DELIGATION:
            return {
                ...state,
                deligations: payload.results,
                loading: false,
            };
        case RESET_COLI:
            return {
                ...state,
                coli: null,
                loading: true,
            }
        case GET_COLI:
        case UPDATE_COLIS_SUCCESS:
            return {
                ...state,
                coli: payload,
                loading: false,
            };
        case DELETE_COLIS:
            return {
                ...state,
                colip: payload,
                loading: false,
            }
        case GET_COLIS:
        case UPDATE_COLIS:
            return {
                ...state,
                colis: payload.results,
                count: payload.count,
                loading: false,
            };
        case UPDATE_TRACKINGS:
            return {
                ...state,
                tracking: payload.results,
                loading: false,
            };
        case CLEAR_COLIS:
            return {
                ...state,
                loading: false,
                colis: [],
            };

        default:
            return state;

    }
}