import React, {
    Fragment,
    useState,
    useEffect,
    useMemo, useContext,
} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import Alert from "../../redux/Alert";
import Spinner from "../colis/spinner.gif";
import PaginationWrapper from "../pagination/pagination";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter, useLocation, Link} from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Card,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Table,
    CardHeader,
    CardBody,
    Form,
    Input,
} from "reactstrap";

import {useDispatch} from "react-redux";

import {
    getReclamations,
    deleteReclamation,
    addNewReponse,
    getReponses,
    addNewReclamation,
} from "./action";
import {getColis, getCurrentcolis} from "../colis/action";
import {reformatStatus} from "../colis/coli";
import AppContext from "../../context/Context";

const Reclamation = ({
                         reclamationData,
                         reponseData,
                         addNewReponse,
                         addNewReclamation,
                         coli: {coli},
                         history,
                     }) => {
    const [reclamationid, setReclamationId] = useState(0);
    const [alldata, searchData] = useState([]);
    const [modal, setModal] = useState(false);
    const [modalreclamation, setModalReclamation] = useState(false);
    const [modalreponse, setModalReponse] = useState(false);
    const [modalcolis, setModalColis] = useState(false)
    const [formData, setFormData] = useState({
        type: "",
        colis: "",
        description: "",
    });
    const [lteDate, setLteDate] = useState(null)
    const [gteDate, setGteDate] = useState(null)
    const {
        config: {group, user_id}
    } = useContext(AppContext)
    const [formDatareponse, setFormDataReponse] = useState({
        description: "",
        reclamation: {reclamationid},
        user: user_id
    });
    const onChangereclamation = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };
    const onChange = (e) =>
        setFormDataReponse({
            ...formDatareponse,
            [e.target.name]: e.target.value,
        });

    const onSubmitReponse = (e) => {
        e.preventDefault();
        addNewReponse(formDatareponse, reclamationid, history, true);
        dispatch(getReclamations(query));
    };

    const dispatch = useDispatch();
    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };
    let query = useQuery();
    useEffect(() => {
        dispatch(getReclamations(query));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);
    let pagenumber = query.get("page") ? query.get("page") : 1;
    const Navigate = (page) => {
        query.set("page", Number(page));

        window.location.replace(`/reclamation/?${query}`);
    };

    useEffect(() => {
        if (reclamationData.reclamations) {
            searchData(reclamationData.reclamations);
        }
    }, [reclamationData.reclamations, reclamationData.loading, alldata]);
    let count = reclamationData.count;

    // useEffect(() => {
    //     if (reclamationData.loading)
    //         setDisable(true)
    //     else
    //         setDisable(false)
    // }, [reclamationData.loading])

    const getGte = () => {
        let params = new URLSearchParams(document.location.search);
        return params.get("created_at__gte");
    };

    const getLte = () => {
        let params = new URLSearchParams(document.location.search);
        return params.get("created_at__lte");
    };

    const onSubmit = (e) => {
        e.preventDefault();
        addNewReclamation(formData);
        dispatch(getReclamations(query));
    };
    let user_groups_name = group;
    return (
        <Fragment>
            <Breadcrumb parent="Réclamation"/>

            <Container fluid={true}>
                <Alert/>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col sm="3"></Col>
                                    <Form>
                                        <div className=" row m-4">
                                            <h4>Du</h4>
                                            <input
                                                className="btn btn-light"
                                                type="date"
                                                name="created_at__gte"
                                                defaultValue={getGte()}
                                                onChange={(e) => {
                                                    setGteDate(e.target.value)
                                                }}
                                            />
                                            <h4>A</h4>
                                            <input
                                                className="btn btn-light"
                                                type="date"
                                                name="created_at__lte"
                                                defaultValue={getLte()}
                                                onChange={(e) => {
                                                    setLteDate(e.target.value)
                                                }}
                                            />
                                            <button
                                                disabled={false}
                                                type="button"
                                                className="btn text-white  ml-2"
                                                style={{backgroundColor: "#27B3B0"}}
                                                onClick={() => {
                                                    if (lteDate)
                                                        query.set("created_at__lte", lteDate)
                                                    if (gteDate)
                                                        query.set("created_at__gte", gteDate)
                                                    history.push({search: query.toString()})
                                                    dispatch(getReclamations(query))
                                                }}
                                            >
                                                Valider
                                            </button>
                                        </div>
                                    </Form>
                                </Row>
                            </CardHeader>

                            <div>
                                <Modal
                                    centered
                                    className="relative min-h-screen flex flex-col sm:justify-center items-center"
                                    isOpen={modal}
                                    toggle={() => setModal(!modal)}
                                >
                                    <ModalHeader
                                        className="p-3 text-white"
                                        toggle={() => setModal(!modal)}
                                        style={{backgroundColor: "#51BB25"}}
                                    >
                                        Repondre au réclamtion
                                    </ModalHeader>
                                    <ModalBody style={{backgroundColor: "#fff"}}>
                                        <Row>
                                            <Col sm="12">
                                                <Form
                                                    className="theme-form"
                                                    onSubmit={(e) => {
                                                        onSubmitReponse(e);
                                                        dispatch(getReclamations(query))
                                                        dispatch(getReclamations(query))
                                                    }}
                                                >
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Réponse</Label>
                                                            <Input
                                                                className="form-control"
                                                                type="textarea"
                                                                name="description"
                                                                value={formDatareponse.description}
                                                                placeholder="Réponse"
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <ModalFooter>
                                                        <button
                                                            onClick={() => setModal(!modal)}
                                                            type="submit"
                                                            className="p-2 btn rounded"
                                                            style={{backgroundColor: "#FDF203"}}
                                                        >
                                                            Envoyer
                                                        </button>
                                                    </ModalFooter>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </ModalBody>
                                </Modal>
                            </div>
                            <div>
                                <Modal
                                    centered
                                    className="relative min-h-screen flex flex-col sm:justify-center items-center"
                                    isOpen={modalreponse}
                                    toggle={() => setModalReponse(!modalreponse)}
                                >
                                    <ModalHeader
                                        className="p-3 bg-success"
                                        toggle={() => setModalReponse(!modalreponse)}
                                    >
                                        Details
                                    </ModalHeader>
                                    <ModalBody className="bg-white">
                                        <Row>
                                            <Col sm="12">
                                                <Col>
                                                    <FormGroup>
                                                        <Table striped>
                                                            <thead>
                                                            <tr>
                                                                <th>{"Reponses"}</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {reponseData &&
                                                                reponseData.reponses.map((b) => (
                                                                    <tr key={b.id}>
                                                                        <td>{b.description}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </FormGroup>
                                                </Col>
                                                <ModalFooter>
                                                    <button
                                                        onClick={() => setModalReponse(!modalreponse)}
                                                        className="p-2 btn btn-warning rounded"
                                                    >
                                                        Close
                                                    </button>
                                                </ModalFooter>
                                            </Col>
                                        </Row>
                                    </ModalBody>
                                </Modal>
                            </div>
                            <div>
                                <Modal
                                    centered
                                    size="xl"
                                    className="relative min-h-screen flex flex-col sm:justify-center items-center"
                                    isOpen={modalcolis}
                                    toggle={() => setModalColis(!modalcolis)}
                                >
                                    <ModalHeader
                                        className="p-3 bg-success"
                                        toggle={() => setModalColis(!modalcolis)}
                                        style={{
                                            backgroundImage:
                                                "linear-gradient(to right,#FFD166, #133A4A  )",
                                        }}
                                    >
                                        Details Colis
                                    </ModalHeader>
                                    <ModalBody className="bg-white">
                                        <Row>
                                            <Col sm="5">
                                                <h4>{coli ? coli.code_a_barre : ""}</h4>
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <Row>
                                            <Col>
                                                <h4>Livreur</h4>
                                                <Label>Nom: {coli ? coli.livreur_name : ""}</Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Label>
                                                    Code à barre: {coli ? coli.tracking_code : ""}
                                                </Label>
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <Row>
                                            <Col>
                                                <h4>Expéditeur</h4>
                                                <Label>Type: Coli fournisseur</Label>
                                            </Col>
                                            <Col>
                                                <h4>Destinataire</h4>
                                                <Label>Nom: {coli ? coli.client_name : ""}</Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {user_groups_name &&
                                                user_groups_name.indexOf("admin") !== -1 ? (
                                                    <Label>Raison: {coli ? coli.fournisseur_name : ""}</Label>
                                                ) : (
                                                    ""
                                                )}
                                            </Col>
                                            <Col>
                                                <Label>
                                                    Téléphone: {coli ? coli.client_telephone_1 : ""}
                                                </Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Label>Téléphone: {coli ? coli.fournisseur_tel : ""}</Label>
                                            </Col>
                                            <Col>
                                                <Label>Ville: {coli ? coli.ville_name : ""}</Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Label>
                                                    Adresse: {coli ? coli.client_address_pickup_name : ""}
                                                </Label>
                                            </Col>
                                            <Col>
                                                <Label>
                                                    Délégation: {coli ? coli.delegation_name : ""}
                                                </Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col></Col>
                                            <Col>
                                                <Label>Adresse: {coli ? coli.client_address : ""}</Label>
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <Row>
                                            <Col sm="12" style={{textAlign: "center"}}>
                                                <Label>
                                                    Libellé de marchandise: {coli ? coli.product_name : ""}
                                                </Label>
                                                <p></p>
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <Row>
                                            <Col sm="12" style={{textAlign: "center"}}>
                                                <Label>Service: {coli ? coli.service : ""}</Label>
                                                <p></p>
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <Row>
                                            <Col sm="12" style={{textAlign: "center"}}>
                                                <Label>Remarque: {coli ? coli.remarque : ""}</Label>
                                                <p></p>
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <Row>
                                            <Col sm="12" style={{textAlign: "center"}}>
                                                <Label>COD: {coli ? coli.prix : ""}</Label>
                                                <p></p>
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <Row>
                                            <Col sm="12" style={{textAlign: "center"}}>
                                                <Label>
                                                    Dernier status: {coli ? reformatStatus(coli.status) : ""}
                                                </Label>
                                                <p></p>
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <Row>
                                            <Col sm="12" style={{textAlign: "center"}}>
                                                <Label>
                                                    Date dernier status: {coli ? coli.updated_at : ""}
                                                </Label>
                                                <p></p>
                                            </Col>
                                        </Row>
                                        <hr/>
                                        <Row>
                                            <Col sm="12" style={{textAlign: "center"}}>
                                                <Label>
                                                    Date de création: {coli ? coli.created_at : ""}
                                                </Label>
                                                <p></p>
                                            </Col>
                                        </Row>
                                    </ModalBody>
                                </Modal>
                            </div>
                            <div>
                                <Modal
                                    centered
                                    className="relative min-h-screen flex flex-col sm:justify-center items-center"
                                    isOpen={modalreclamation}
                                    toggle={() => setModalReclamation(!modalreclamation)}
                                >
                                    <ModalHeader
                                        style={{
                                            backgroundImage:
                                                "linear-gradient(to right,#FFD166, #133A4A  )",
                                        }}
                                        className="p-3 text-white"
                                        toggle={() => setModalReclamation(!modalreclamation)}
                                    >
                                        Ajouter réclamtion
                                    </ModalHeader>
                                    <ModalBody>
                                        <Form onSubmit={(e) => onSubmit(e)}>
                                            <FormGroup>
                                                <Label>Type de réclamtion</Label>
                                                <select
                                                    className="form-control digits"
                                                    name="type"
                                                    value={formData.type}
                                                    onChange={(e) => onChangereclamation(e)}
                                                >
                                                    <option value="" disabled defaultValue hidden>
                                                        ----------
                                                    </option>
                                                    <option value={"modification"}>
                                                        Modification colis
                                                    </option>
                                                    <option value={"retard"}>Retard</option>
                                                    <option value={"comportement_incorrect"}>
                                                        Comportement non correcte
                                                    </option>
                                                    <option value={"retour"}>Retour colis</option>
                                                    <option value={"endommage"}>Colis Endomagé</option>
                                                    <option value={"perdu"}>Colis perdu</option>
                                                    <option value={"Other"}>Autres</option>
                                                </select>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Code à barre</Label>
                                                <Input
                                                    type="number"
                                                    name="colis"
                                                    placeholder="Code à barre"
                                                    value={formData.colis}
                                                    onChange={(e) => onChangereclamation(e)}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Description</Label>
                                                <Input
                                                    type="textarea"
                                                    name="description"
                                                    placeholder="description"
                                                    value={formData.description}
                                                    onChange={(e) => onChangereclamation(e)}
                                                />
                                            </FormGroup>
                                            <ModalFooter>
                                                <Row>
                                                    <button
                                                        onClick={() =>
                                                            setModalReclamation(!modalreclamation)
                                                        }
                                                        className="p-2 btn rounded text-white"
                                                        style={{backgroundColor: "#133A4A"}}
                                                    >
                                                        Anuuler
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        onClick={() =>
                                                            setModalReclamation(!modalreclamation)
                                                        }
                                                        className="p-2 ml-1 btn rounded"
                                                        style={{backgroundColor: "#FFD166"}}
                                                    >
                                                        Sauvegarder
                                                    </button>
                                                </Row>
                                            </ModalFooter>
                                        </Form>
                                    </ModalBody>
                                </Modal>
                            </div>
                            <CardBody>
                                <Row>
                                    <Col sm="10"></Col>
                                    <Col>
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => setModalReclamation(!modalreclamation)}
                                        >
                                            Nouvelle reclamation
                                        </button>
                                    </Col>
                                </Row>
                                <br/>
                                <Col sm="12">
                                    <Card>
                                        <div className="card-block row">
                                            <Col sm="12" lg="12" xl="12">
                                                <div className="table-responsive">
                                                    {reclamationData && reclamationData.loading ? (
                                                        <div className="text-center">
                                                            <img src={Spinner} style={{width: "10%"}} alt=""/>
                                                        </div>
                                                    ) : (
                                                        <Table striped>
                                                            <thead>
                                                            <tr>
                                                                <th>{"Date"}</th>
                                                                <th>{"type"}</th>
                                                                <th>{"Status"} </th>
                                                                <th>{"Fournisseur"} </th>
                                                                <th>{"Code à barre"}</th>
                                                                <th>{"Description"}</th>
                                                                <th>{"Réponse"} </th>

                                                                {user_groups_name &&
                                                                user_groups_name.indexOf("admin") !== -1 ? (
                                                                    <>
                                                                        <th>{"Par"}</th>
                                                                        <th>{"Action"}</th>
                                                                    </>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {reclamationData &&
                                                                reclamationData.reclamations.map((b) => (
                                                                    <tr key={b.id}>
                                                                        <td>{b.created_at}</td>
                                                                        <td>{b.type && b.type === "modification" ? "Modification colis" : b.type === "retard" ? "Retard"
                                                                            : b.type === "comportement_incorrect" ? "Comportement non correcte" : b.type === "retour" ? "Retour colis"
                                                                                : b.type === "endommage" ? "Colis endommagé" : b.type === "perdu" ? "Colis perdu" : "Autres"}</td>
                                                                        {b.status && b.status === "ok" ? (
                                                                            <td className="text-success">
                                                                                {b.status}
                                                                            </td>
                                                                        ) : (
                                                                            <td className="text-danger">
                                                                                {" "}
                                                                                {b.status}
                                                                            </td>
                                                                        )}
                                                                        <td>
                                                                            {b.fournisseur_name}
                                                                        </td>
                                                                        <td>
                                                                            <Link to={`/colis/detail/${b.colis}`}>
                                                                                {b.code}
                                                                            </Link>
                                                                        </td>
                                                                        <td>{b.description}</td>
                                                                        <td>
                                                                            {" "}
                                                                            {b.last_reponse
                                                                                ? b.last_reponse
                                                                                : "En attente de réponse..."}{" "}
                                                                        </td>
                                                                        {user_groups_name &&
                                                                        user_groups_name.indexOf("admin") !==
                                                                        -1 ? (
                                                                            <>
                                                                                <td>{b.user_username}</td>
                                                                                <td>
                                                                                    <button
                                                                                        className="btn ml-1 btn-primary"
                                                                                        onClick={() => {
                                                                                            setModalColis(!modalcolis);
                                                                                            dispatch(getCurrentcolis(b.colis))
                                                                                        }}
                                                                                    >
                                                                                        <i className="fa fa-eye"></i>
                                                                                    </button>
                                                                                    <button
                                                                                        onClick={() => {
                                                                                            setModal(true);
                                                                                            setReclamationId(b.id);
                                                                                        }}
                                                                                        className="btn btn-success  m-1"
                                                                                    >
                                                                                        <i className="fa fa-reply"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>)}

                                                </div>
                                            </Col>
                                        </div>
                                    </Card>
                                </Col>{" "}
                                <Row>
                                    <Col sm="10"></Col>
                                    {reclamationData && reclamationData.loading ? "" :
                                        <Col>
                                            <PaginationWrapper
                                                name="Reclamations"
                                                length={reclamationData.reclamations.length}
                                                count={count}
                                                currentPage={pagenumber}
                                                onNavigate={Navigate}
                                            />
                                        </Col>}
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};
Reclamation.propTypes = {
    getCurrentcolis: PropTypes.func.isRequired,
    addNewReclamation: PropTypes.func.isRequired,
    getReclamations: PropTypes.func.isRequired,
    getReponses: PropTypes.func.isRequired,
    getColis: PropTypes.func.isRequired,
    deleteReclamation: PropTypes.func.isRequired,
    reclamationData: PropTypes.object.isRequired,
    addNewReponse: PropTypes.func.isRequired,
    reponseData: PropTypes.object.isRequired,
    colisData: PropTypes.object.isRequired,
};

const mapstatetoprops = (state) => ({
    coli: state.reducerc,
    colisData: state.reducerc,
    reclamationData: state.reducerr,
    reponseData: state.reducerr,
});

export default connect(mapstatetoprops, {
    getCurrentcolis,
    addNewReclamation,
    getColis,
    getReclamations,
    deleteReclamation,
    addNewReponse,
    getReponses,
})(withRouter(Reclamation));
