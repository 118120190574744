import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";

const Breadcrumbs = (props) => {
  return (
    <Fragment>
      <Container fluid={true}>
        <div className="page-title">
          <Row>
            <Col xs="6">
              {props && props.title ? (
                <h3>{props.title}</h3>
              ) : (
                <h3>{props.parent}</h3>
              )}
            </Col>
            <Col xs="6">
              {/* <Breadcrumb>
                <BreadcrumbItem>
                  <a href={`/dashboard`}>
                    <Home />
                  </a>
                </BreadcrumbItem>
                <BreadcrumbItem>{props.parent}</BreadcrumbItem>
                <BreadcrumbItem active>{props.title}</BreadcrumbItem>
              </Breadcrumb> */}
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default Breadcrumbs;
