
import {
  GET_GROUP,
  GET_GROUPS,
  GROUP_LOADING,
  GROUP_ERROR,
  UPDATE_GROUP,
  GET_PERMISSIONS,
  PERMISSION_LOADING,
  PERMISSION_ERROR,
} from "../../redux/actionTypes";

const initial_state = {
  group: null,
  count: 0,
  groups: [],
  permissions: [],
  loading: true,
  error: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initial_state, action) {
  const { type, payload } = action;
  switch (type) {
    case PERMISSION_LOADING:
    case GROUP_LOADING:
      return {
        ...state,
        loading: true,
      };
    case PERMISSION_ERROR:
    case GROUP_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case GET_GROUP:
      return {
        ...state,
        group: payload.SECTEUR,
        loading: false,
      };
    case UPDATE_GROUP:
      return {
        ...state,
        group: payload,
        loading: false,
      };
    case GET_PERMISSIONS:
      return {
        ...state,
        permissions: payload.results,
        loading: false,
      };
    case GET_GROUPS:
      return {
        ...state,
        count: payload.count,
        groups: payload.results,
        loading: false,
      };

    default:
      return state;
  }
}
