import React, {Fragment, useContext, useEffect, useState,} from "react";
import PropTypes from "prop-types";
import Alert from "../../redux/Alert";
import {connect, useDispatch} from "react-redux";
import {Link, useParams, withRouter} from "react-router-dom";
import {getCurrentNew, updateNew,} from "./actions";
import Breadcrumb from "../../layout/breadcrumb";

import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Row,} from "reactstrap";
import AppContext from "../../context/Context";

const EditNew = ({
  updateNew,
  new: { neww, loading },
  history,
}) => {

  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });
  const {
        config: {group}
    } = useContext(AppContext)

  const { id } = useParams();

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    updateNew(formData, id, history);
  };
  const dispatch = useDispatch();

  const [taptopStyle, setTapTopStyle] = useState("none");
  const executeScroll = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleScroll = () => {
    if (window.scrollY > 600) {
      setTapTopStyle("block");
    } else {
      setTapTopStyle("none");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [taptopStyle]);


  useEffect(() => {
    dispatch(getCurrentNew(id))
  }, [dispatch, id])

  useEffect(() => {
    setFormData({
      title:
        loading || !neww?.title ? "" : neww.title,
      description: loading || !neww?.description ? "" : neww.description,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, neww]);

  return (
    <Fragment>
      <Breadcrumb parent="News" title="Modifier une nouvelle" />
      <Container fluid={true}>
        <Form className="theme-form" onSubmit={(e) => onSubmit(e)}>
          <Alert />
          <Row>
            <Col sm="12">
              {group.indexOf("admin") !== -1 ? (
                <Card>
                  <CardHeader>
                    <h5> Details Nouvelle</h5>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Titre</Label>
                            <Input
                                className="form-control digits"
                                name="title"
                                id="title"
                                type="text"
                                value={formData.title}
                                onChange={(e) => onChange(e)}
                            />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Description</Label>
                          <textarea
                            className="form-control digits"
                            name="description"
                            id="description"
                            value={formData.description}
                            onChange={(e) => onChange(e)}
                            rows={5}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              ) : (
                ""
              )}
              </Col>


            <Row>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Col>
                <FormGroup className="mb-0">
                  <button
                    type="submit"
                    onClick={() => executeScroll()}
                    className="btn btn-success mr-3"
                  >
                    Enregistrer
                  </button>
                  <Link to={`/news`}>
                    <Button color="danger">Annuler</Button>
                  </Link>
                  <br />
                  <br />
                </FormGroup>
              </Col>
            </Row>
          </Row>
        </Form>
      </Container>
    </Fragment>
  );
};
EditNew.propTypes = {
  updateNew: PropTypes.func.isRequired,
  getCurrentNew: PropTypes.func.isRequired,
  new: PropTypes.object.isRequired,
};

const mapstatetoprops = (state) => ({
  new: state.reducernews,
});

export default connect(mapstatetoprops, {
  updateNew,
  getCurrentNew,
})(withRouter(EditNew));
