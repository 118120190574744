import React, {useContext} from "react";
import {connect} from "react-redux";
import {TransitionGroup} from "react-transition-group";
import {CSSTransition} from "react-transition-group";
import App from "../components/app";
import {routes} from "../route";
import PropTypes from "prop-types";
import {Redirect, Route,} from "react-router-dom";
import AppContext from "../context/Context";

const PrivateRoute = () => {
    const {
        config: {isAuthenticated},
    } = useContext(AppContext);

    if (isAuthenticated) {
        return <Route
            render={(props) =>

                <App>
                    <TransitionGroup>
                        {routes.map(({path, Component}) => (
                            <Route key={path} exact path={`${path}`}>
                                {({match}) => (
                                    <CSSTransition
                                        in={match != null}
                                        timeout={100}
                                        unmountOnExit
                                    >
                                        <div>
                                            <Component {...props} />
                                        </div>
                                    </CSSTransition>
                                )}
                            </Route>
                        ))}
                    </TransitionGroup>
                </App>
            }
        />
    } else return <Redirect to={'/'}/>
};
PrivateRoute.propTypes = {
    authState: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    authState: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
