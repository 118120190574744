import React, {Fragment, useState, useEffect, useMemo, useContext} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import AlertS from "../../redux/AlertS";
import {Link, withRouter, useParams, useLocation} from "react-router-dom";
import {Multiselect} from "multiselect-react-dropdown";
import {getCurrentFournisseur, updateFournisseur} from "./action";
import {getSecteurs} from "../secteuractivite/action";
import {getadresse, getCite, getDeligation, getLivreurs, getVille} from "../colis/action";
import Breadcrumb from "../../layout/breadcrumb";
import am_city from "../../data/am_city.json"

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button as B,
} from "reactstrap";
import {useDispatch} from "react-redux";
import Error404 from "../../pages/errors/error404";
import Spinner from "../colis/spinner.gif";
import {getCommercial} from "../compte/action";
import {getLivreur} from "../colis/action";
import Select from "react-select";
import Button from "../../utils/Button";
import AppContext from "../../context/Context";

const EditFournisseur = ({
                             fournisseur: {fournisseur, loading},
                             getCurrentFournisseur,
                             updateFournisseur,
                             adresseData,
                             commercialData,
                             livreurData,
                             secteurData,
                             citeData,
                             deligationData,
                             villeData,
                             history,
                         }) => {
    const {id} = useParams();
    const [stateville, setVille] = useState("");
    const [statecite, setCite] = useState([
        {
            id: "",
            name: "",
        },
    ]);
    const [statedelegation, setDelegation] = useState("");
    const [commercial, setCommercial] = useState("");
    const [livreur, setLivreur] = useState(null);
    const [showLoader, setShowLoader] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [livreurDatas, setLivreurData] = useState([])
    const [statesecteur, setsecteur] = useState([
        {
            id: "",
            activiter: "",
        },
    ]);
    const [, setSelectedSecteur] = useState({
        id: "",
        activiter: "",
    });
    const [formData, setFormData] = useState({
        responsable: "",
        tel: "",
        email: "",
        name: "",
        nb_colis: 3,
        adresse: "",
        code_postale: "",
        petit_livraison: "",
        grand_livraison: "",
        petit_retour: "",
        grand_retour: "",
        stock_service: "",
        img: null,
        rib: "",
        mf: "",
        tentative: "",
        filiere: "",
        ville: "",
        delegation: "",
        cite: "",
        commercial: "",
        livreur: "",
        secteur: [],
    });
    const {
        config: {group}
    } = useContext(AppContext)
    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };

    let query = useQuery();

    const dispatch = useDispatch();

    const onChange = (e) =>
        setFormData({...formData, [e.target.name]: e.target.value});
    const onChangeimg = (e) => {
        const image = e.target.files[0];
        setFormData({...formData, img: image});
    };
    const onSubmit = async (e) => {
        setShowLoader(true)
        e.preventDefault();
        const fd = new FormData();
        fd.append("delegation", formData.delegation);
        fd.append("ville", formData.ville);
        fd.append("cite", formData.cite);
        fd.append("adresse", formData.adresse);
        fd.append("code_postale", formData.code_postale);
        fd.append("email", formData.email);
        fd.append("grand_retour", formData.grand_retour);
        fd.append("grand_livraison", formData.grand_livraison);
        if (formData.img) {
            fd.append("img", formData.img);
        }
        fd.append("name", formData.name);
        fd.append("mf", formData.mf);
        fd.append("nb_colis", formData.nb_colis);
        fd.append("petit_retour", formData.petit_retour);
        fd.append("petit_livraison", formData.petit_livraison);
        fd.append("responsable", formData.responsable);
        fd.append("stock_service", formData.stock_service);
        fd.append("commercial", formData.commercial);
        if (livreur)
            fd.append("livreur", livreur.value);
        fd.append("rib", formData.rib);
        formData.secteur.forEach((item) => {
            fd.append("secteur", item);
        });
        fd.append("tel", formData.tel);
        fd.append("tentative", formData.tentative);
        await updateFournisseur(fd, id, history);
        setShowLoader(false)
        history.push('/fournisseur')
    };

    const onSelectSecteur = (e) => {
        setSelectedSecteur(e);
        const Secteurid = e.map((s) => s.id);
        setFormData({...formData, secteur: Secteurid});
    };
    //get secteurs
    useEffect(() => {
        dispatch(getSecteurs(query));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setsecteur(secteurData.secteurs);
    }, [secteurData.secteurs, secteurData.loading, statesecteur]);

    const onChangeDeligation = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
        query.set("gouvernat_id", e.target.value);
        dispatch(getVille(query));
    };

    const onChangeVille = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});

        query.set("ville_id", e.target.value);
        dispatch(getCite(query));
    };

    const onChangestock = (e) =>
        setFormData({...formData, [e.target.name]: e.target.checked});
    // get cite
    useEffect(() => {
        setCite(citeData.cites);
    }, [citeData.cites, citeData.loading, statecite]);
    let sortcite = {}
    console.log("livreur ", livreur)
    if(livreur?.label === 'aramex' || livreur?.label === 'Aramex'){
        sortcite = am_city.sort((a, b) => (a.name || '').localeCompare(b.name || ''))
    }else{
        sortcite = citeData.citesF
    }
    const datasecteur = [];
    secteurData &&
    secteurData.secteurs.map((secteur) =>
        datasecteur.push({
            id: secteur.id,
            activiter: secteur.activiter,
        })
    );
    const dataadresse = [];
    adresseData &&
    adresseData.adresses.map((address) =>
        dataadresse.push({
            label: address.id,
            value: address.address,
        })
    );

    useEffect(() => {
        getCurrentFournisseur(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setFormData({
            responsable:
                loading || !fournisseur?.responsable ? "" : fournisseur.responsable,
            name: loading || !fournisseur?.name ? "" : fournisseur.name,
            tel: loading || !fournisseur?.tel ? "" : fournisseur.tel,
            email: loading || !fournisseur?.email ? "" : fournisseur.email,
            adresse: loading || !fournisseur?.adresse ? "" : fournisseur.adresse,
            delegation:
                loading || !fournisseur?.delegation ? "" : fournisseur.delegation,
            ville: loading || !fournisseur?.ville ? "" : fournisseur.ville,
            cite: loading || !fournisseur?.cite ? "" : fournisseur.cite,
            code_postale:
                loading || !fournisseur?.code_postale ? "" : fournisseur.code_postale,
            rib: loading || !fournisseur?.rib ? "" : fournisseur.rib,
            mf: loading || !fournisseur?.mf ? "" : fournisseur.mf,
            nb_colis: loading || !fournisseur?.nb_colis ? "" : fournisseur.nb_colis,
            tentative:
                loading || !fournisseur?.tentative ? "" : fournisseur.tentative,
            adresse_pickup:
                loading || !fournisseur?.adresse_pickup
                    ? ""
                    : fournisseur.adresse_pickup,
            secteur: loading || !fournisseur?.secteur ? "" : fournisseur.secteur,
            commercial: loading || !fournisseur?.commercial ? "" : fournisseur.commercial,
            petit_livraison:
                loading || !fournisseur?.petit_livraison
                    ? ""
                    : fournisseur.petit_livraison,
            petit_retour:
                loading || !fournisseur?.petit_retour ? "" : fournisseur.petit_retour,
            stock_service:
                loading || !fournisseur?.stock_service ? "" : fournisseur.stock_service,
            grand_livraison:
                loading || !fournisseur?.grand_livraison
                    ? ""
                    : fournisseur.grand_livraison,
            grand_retour:
                loading || !fournisseur?.grand_retour ? "" : fournisseur.grand_retour,
        });
        setLivreur({
            value: loading || !fournisseur?.livreur ? "" : fournisseur.livreur,
            label: loading || !fournisseur?.livreur_name ? "------------------" : fournisseur.livreur_name,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    useEffect(() => {
        setDelegation(formData.delegation);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statedelegation]);

    useEffect(() => {
        setCommercial(formData.commercial);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commercial]);

    useEffect(() => {
        setVille(formData.ville);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateville]);

    useEffect(() => {
        dispatch(getVille(query));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(getCommercial());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        query.set("page_size", "50")
        query.set("page", currentPage)
        getLivreurs(query)
            .then(res => {
                setLivreurData([...livreurDatas, ...res?.results])
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    useEffect(() => {
        dispatch(getDeligation(query));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(getCite(query));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const stateselectedsecteur = [];

    fournisseur &&
    fournisseur.secteur.map((secteur) =>
        // eslint-disable-next-line array-callback-return
        secteurData.secteurs.map((s) => {
            if (s.id === secteur) {
                stateselectedsecteur.push({
                    id: secteur,
                    activiter: s.activiter,
                });
            }
        })
    );

    const [taptopStyle, setTapTopStyle] = useState("none");

    const handleScroll = () => {
        if (window.scrollY > 600) {
            setTapTopStyle("block");
        } else {
            setTapTopStyle("none");
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        handleScroll();
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [taptopStyle]);

    let user_groups_name = group;
    const onChangeCommercial = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const onChangeLivreur = (e) => {
        setFormData({...formData, livreur: e.value});
        setLivreur(e);
    };

    let livreurOptions;

    livreurOptions = livreurDatas.map((livreur) => ({
        value: livreur.id,
        label: livreur.name
    }));

// Add the reset option
    livreurOptions = [
        {value: "", label: "------------------"},
        ...livreurOptions
    ];

    return (
        <Fragment>
            <Breadcrumb parent="Fournisseur" title="Modifier Fournisseur"/>
            {user_groups_name && user_groups_name.indexOf("admin") !== -1 ? (
                <div>
                    {loading && loading ? (
                        <div className="text-center">
                            <img src={Spinner} style={{width: "10%"}} alt=""/>
                        </div>
                    ) : (
                        <Container fluid={true}>
                            <AlertS/>
                            <Form
                                className="theme-form"
                                encType="multipart/form-data"
                                onSubmit={(e) => onSubmit(e)}
                            >
                                <Row>
                                    <Col sm="8">
                                        <Card>
                                            <CardHeader>
                                                <h5>Données personnelles</h5>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col sm="4">
                                                        <Label>
                                                            <h6>Image</h6>
                                                        </Label>
                                                        <br/>
                                                        <br/>
                                                        {fournisseur && fournisseur.img ? (
                                                            <img
                                                                className="product"
                                                                name="img"
                                                                src={`${fournisseur.url_path}`}
                                                                alt=""
                                                            />
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Col>
                                                    <Col>
                                                        {" "}
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label>Résponsable (*)</Label>
                                                                    <Input
                                                                        className="form-control"
                                                                        type="text"
                                                                        name="responsable"
                                                                        placeholder="Résponsable"
                                                                        value={formData.responsable}
                                                                        onChange={(e) => onChange(e)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>{" "}
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label>Raison sociale (*)</Label>
                                                                    <Input
                                                                        className="form-control"
                                                                        type="text"
                                                                        name="name"
                                                                        placeholder="Raison sociale"
                                                                        value={formData.name}
                                                                        onChange={(e) => onChange(e)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label>Email (*)</Label>
                                                                    <Input
                                                                        className="form-control"
                                                                        type="text"
                                                                        name="email"
                                                                        placeholder="Email"
                                                                        value={formData.email}
                                                                        onChange={(e) => onChange(e)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label>Téléphone (*)</Label>
                                                                    <Input
                                                                        className="form-control"
                                                                        type="tel"
                                                                        name="tel"
                                                                        placeholder="Téléphone"
                                                                        value={formData.tel}
                                                                        onChange={(e) => onChange(e)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Input
                                                                className="form-control "
                                                                type="file"
                                                                name="img"
                                                                accept="image/*"
                                                                onChange={(e) => {
                                                                    onChangeimg(e);
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="ml-4">
                                                        <FormGroup>
                                                            <Row>
                                                                <Col>
                                                                    <Input
                                                                        className="ml-2"
                                                                        key={"active"}
                                                                        checked={formData.stock_service === true}
                                                                        type="checkbox"
                                                                        name="stock_service"
                                                                        onChange={(e) => onChangestock(e)}
                                                                    />
                                                                    <Label className="ml-4">Stock service</Label>
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                        <Card>
                                            <CardHeader>
                                                <h5>Confidentalités</h5>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>RIB</Label>
                                                            <Input
                                                                className="form-control"
                                                                type="text"
                                                                name="rib"
                                                                placeholder="RIB"
                                                                value={formData.rib}
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>MF</Label>
                                                            <Input
                                                                className="form-control"
                                                                type="text"
                                                                name="mf"
                                                                placeholder="MF"
                                                                value={formData.mf}
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            {" "}
                                                            <Label>Petit</Label>
                                                            <Row>
                                                                <Col>
                                                                    <Input
                                                                        className="form-control"
                                                                        type="number"
                                                                        name="petit_livraison"
                                                                        placeholder="0"
                                                                        value={formData.petit_livraison}
                                                                        onChange={(e) => onChange(e)}
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <Input
                                                                        className="form-control"
                                                                        type="number"
                                                                        placeholder="0"
                                                                        name="petit_retour"
                                                                        value={formData.petit_retour}
                                                                        onChange={(e) => onChange(e)}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Tentative (*)</Label>
                                                            <Input
                                                                className="form-control"
                                                                type="number"
                                                                name="tentative"
                                                                placeholder="Tentative"
                                                                value={formData.tentative}
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Grand</Label>
                                                            <Row>
                                                                <Col>
                                                                    <Input
                                                                        className="form-control"
                                                                        type="number"
                                                                        placeholder="0"
                                                                        name="grand_livraison"
                                                                        value={formData.grand_livraison}
                                                                        onChange={(e) => onChange(e)}
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <Input
                                                                        className="form-control"
                                                                        type="number"
                                                                        placeholder="0"
                                                                        name="grand_retour"
                                                                        value={formData.grand_retour}
                                                                        onChange={(e) => onChange(e)}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col sm="4">
                                        <Card>
                                            <CardHeader>
                                                <h5>Cordonnées</h5>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Livreur</Label>
                                                            <Select
                                                                options={livreurOptions}
                                                                onChange={onChangeLivreur}
                                                                value={livreur}
                                                                name={'livreur'}
                                                                placeholder={"Livreur"}
                                                                onInputChange={(e) => {
                                                                    query.set('search', e);
                                                                    getLivreurs(query);
                                                                }}
                                                                className="m-2"
                                                                onMenuScrollToBottom={(e) => {
                                                                    console.log(e)
                                                                    setCurrentPage(page => page + 1)
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Commercial</Label>
                                                            <select
                                                                className="form-control digits"
                                                                name="commercial"
                                                                value={formData.commercial}
                                                                onChange={(e) => onChangeCommercial(e)}
                                                            >
                                                                <option
                                                                    value=""
                                                                    disabled
                                                                    defaultValue
                                                                    selected
                                                                    hidden
                                                                >
                                                                    ----------
                                                                </option>
                                                                {commercialData &&
                                                                    commercialData.commercials.map((compte) => (
                                                                        <option
                                                                            key={compte.id}
                                                                            value={compte.id}
                                                                        >
                                                                            {compte.username}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Adresse (*)</Label>
                                                            <Input
                                                                className="form-control"
                                                                type="text"
                                                                name="adresse"
                                                                placeholder="Adresse"
                                                                value={formData.adresse}
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Code Postale (*)</Label>
                                                            <Input
                                                                className="form-control"
                                                                type="number"
                                                                name="code_postale"
                                                                placeholder="Code Postale"
                                                                value={formData.code_postale}
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Délégation (*)</Label>
                                                            <select
                                                                className="form-control digits"
                                                                name="delegation"
                                                                value={formData.delegation}
                                                                onChange={(e) => {
                                                                    setDelegation(e.target.value);
                                                                    onChangeDeligation(e);
                                                                }}
                                                            >
                                                                <option value="" disabled defaultValue hidden>
                                                                    ----------
                                                                </option>
                                                                {deligationData &&
                                                                    deligationData.deligations.map((delegation) => (
                                                                        <option
                                                                            key={delegation.id}
                                                                            value={delegation.id}
                                                                        >
                                                                            {delegation.name}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Ville</Label>

                                                            <select
                                                                className="form-control digits"
                                                                name="ville"
                                                                value={formData.ville}
                                                                onChange={(e) => {
                                                                    setVille(e.target.value);
                                                                    onChangeVille(e);
                                                                }}
                                                            >
                                                                {" "}
                                                                <option value="" disabled defaultValue hidden>
                                                                    ----------
                                                                </option>
                                                                {villeData &&
                                                                    villeData.villeF.map((ville) => (
                                                                        <option key={ville.id} value={ville.id}>
                                                                            {ville.name}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Cité</Label>
                                                            <select
                                                                className="form-control digits"
                                                                name="cite"
                                                                value={formData.cite}
                                                                onChange={(e) => onChange(e)}
                                                            >
                                                                <option value="" disabled defaultValue hidden>
                                                                    ----------
                                                                </option>
                                                                {sortcite &&
                                                                    sortcite.map((cite) => (
                                                                        <option key={cite.id} value={cite.id}>
                                                                            {cite.name}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Secteur (*)</Label>
                                                            <Multiselect
                                                                name="secteur"
                                                                selectedValues={stateselectedsecteur}
                                                                options={datasecteur}
                                                                placeholder={"Secteurs"}
                                                                displayValue="activiter"
                                                                onSelect={(e) => {
                                                                    onSelectSecteur(e);
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup className="mb-6">
                                            <Button
                                                onClick={onSubmit}
                                                disabled={showLoader}
                                                className="btn btn-success mr-3"
                                                color={"success"}
                                                loading={showLoader}
                                                text={"Enregistrer"}
                                            />
                                            <Link to={`/fournisseur`}>
                                                <B color="danger">Annuler</B>
                                            </Link>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </Container>)}</div>
            ) : (
                <Error404 page="Modifier Fournisseur"/>
            )}
        </Fragment>
    );
};
EditFournisseur.propTypes = {
    updateFournisseur: PropTypes.func.isRequired,
    getCurrentFournisseur: PropTypes.func.isRequired,
    fournisseur: PropTypes.object.isRequired,
    getadresse: PropTypes.func.isRequired,
    getSecteurs: PropTypes.func.isRequired,
    getVille: PropTypes.func.isRequired,
    getCite: PropTypes.func.isRequired,
    getDeligation: PropTypes.func.isRequired,
    getCommercial: PropTypes.func.isRequired,
    getLivreur: PropTypes.func.isRequired,
};

const mapstatetoprops = (state) => ({
    adresseData: state.reducerc,
    fournisseur: state.reducerf,
    secteurData: state.reducersc,
    villeData: state.reducerdvc,
    citeData: state.reducerdvc,
    deligationData: state.reducerc,
    commercialData: state.reducercp,
    livreurData: state.reducerc,
});

export default connect(mapstatetoprops, {
    updateFournisseur,
    getVille,
    getCite,
    getDeligation,
    getCommercial,
    getLivreur,
    getadresse,
    getSecteurs,
    getCurrentFournisseur,
})(withRouter(EditFournisseur));
