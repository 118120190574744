import React, {
    Fragment,
    useState,
    useEffect,
    useMemo, useContext,
} from "react";
import PropTypes from "prop-types";
import Alert from "../../redux/Alert";
import Select, {components} from "react-select";
import {connect} from "react-redux";
import {Link, useLocation} from "react-router-dom";
import {getFournisseurs, getFournisseursP} from "../Fournisseurs/action";
import {getCategories, getSousCategories, addNewStock} from "./action";

import Breadcrumb from "../../layout/breadcrumb";

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button as B,
    CardHeader,
} from "reactstrap";
import {useDispatch} from "react-redux";
import Error404 from "../../pages/errors/error404";
import Spinner from "../colis/spinner.gif";
import Button from "../../utils/Button";
import AppContext from "../../context/Context";

export const AddStock = ({history, fournisseurData, stockData, addNewStock}) => {
    const [taptopStyle, setTapTopStyle] = useState("none");
    const [currentFournisseurPage, setCurrentFournisseurPage] = useState(1)
    const [showLoader, setShowLoader] = useState(false)
    const [categoryValue, setCategoryValue] = useState()
    const [categoryFormData, setCategoryFormData] = useState({name: ""})
    const [categoryMenuIsOpen, setCategoryMenuIsOpen] = useState(false);
    const [subCategoryValue, setSubCategoryValue] = useState()
    const [subCategoryFormData, setSubCategoryFormData] = useState({name: ""})
    const [subCategoryMenuIsOpen, setSubCategoryMenuIsOpen] = useState(false);
    const {
        config: {group, stock_service}
    } = useContext(AppContext)

    const handleScroll = () => {
        if (window.scrollY > 600) {
            setTapTopStyle("block");
        } else {
            setTapTopStyle("none");
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        handleScroll();
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [taptopStyle]);
    const dispatch = useDispatch();
    const [fournisseurs, setFournisseurs] = useState([]);
    const [selectedfournisseur, setSelectedFournisseur] = useState([]);
    const [category, setCategory] = useState([]);
    const [categoryForm, setCategoryForm] = useState(false);
    const [subCategoryForm, setSubCategoryForm] = useState(false);
    const [formData, setFormData] = useState({
        reference: "",
        name: "",
        stock: "",
        cod: "",
        case: "",
        etage: "",
        weight: 1,
        image: null,
        fournisseur: "",
        category: "",
        sub_category: "",
    });

    const onChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };
    const onChangeFournisseur = (e) => {
        setSelectedFournisseur(e);
        const fournisseurid = e.value;

        setFormData({
            ...formData,
            fournisseur: fournisseurid,
        });
    };
    const onChangeimg = (e) => {
        setFormData({...formData, image: e.target.files[0]});
    };
    const onChangeCategorie = (e) => {
        setFormData({...formData, category: e});
        query.set("category_id", e);
        dispatch(getSousCategories(query));
    };

    const onSubmit = async (e) => {
        setShowLoader(true)
        e.preventDefault();
        const fd = new FormData();
        fd.append("reference", formData.reference);
        fd.append("name", formData.name);
        fd.append("stock", formData.stock);
        fd.append("cod", formData.cod);
        fd.append("case", formData.case);
        fd.append("weight", formData.weight);
        fd.append("etage", formData.etage);
        fd.append("image", formData.image);
        fd.append("fournisseur", formData.fournisseur);
        fd.append("category", formData.category);
        fd.append("category_name", categoryFormData?.name ? categoryFormData?.name : "")
        fd.append("sub_category", formData.sub_category);
        fd.append("sub_category_name", subCategoryFormData?.name ? subCategoryFormData?.name : "");
        await addNewStock(fd, history);
        setShowLoader(false)
    };

    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };

    let query = useQuery();

    // getfournisseur
    useEffect(() => {
        query.set("stock_service", "True")
        query.set("page_size", "50")
        query.set("page", currentFournisseurPage)
        dispatch(getFournisseurs(query))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFournisseurPage]);

    useEffect(() => {
        setFournisseurs(fournisseurData.fournisseurs);
    }, [fournisseurData.fournisseurs, fournisseurData.loading, fournisseurs]);

    // getCategories
    useEffect(() => {
        dispatch(getCategories());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setCategory(stockData.categories);
    }, [stockData.categories, stockData.loading, category]);

    // getSousCategories
    useEffect(() => {
        dispatch(getSousCategories());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setCategory(stockData.souscategories);
    }, [stockData.souscategories, stockData.loading]);

    const handleCategoryInputChange = (e, actionMeta) => {
        if (actionMeta.action === 'input-change') {
            setCategoryFormData(prevState =>
                ({...prevState, name: e})
            );
        }
        query.set("search", e)
        dispatch(getCategories(query))
    };

    const handleSubCategoryInputChange = (e, actionMeta) => {
        if (actionMeta.action === 'input-change') {
            setSubCategoryFormData(prevState =>
                ({...prevState, name: e})
            );
        }
        query.set("search", e)
        dispatch(getSousCategories(query))
    };
    const handleCategoryAddNew = (e) => {
        setCategoryForm(true);
        setCategoryValue("")
        setFormData({...formData, category: ""})
        setCategoryMenuIsOpen(false);
    };

    const handleSubCategoryAddNew = (e) => {
        setSubCategoryForm(true);
        setSubCategoryValue("")
        setFormData({...formData, sub_category: ""})
        setSubCategoryMenuIsOpen(false);
    };

    const handleCategoryChange = (e) => {
        setCategoryFormData({...categoryFormData, [e.target.name]: e.target.value})
    };

    const handleSubCategoryChange = (e) => {
        setSubCategoryFormData({...subCategoryFormData, [e.target.name]: e.target.value})
    };

    const CategoryMenu = (props) => {
        const menuHeaderStyle = {
            padding: '6px 12px',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            cursor: "pointer"
        };
        return (
            <Fragment>
                <components.Menu {...props}>
                    <div>
                        {props.selectProps.fetchingData ? (
                            <span className="fetching">Fetching data...</span>
                        ) : (
                            <div>{props.children}</div>
                        )}
                        <div className="option" aria-disabled="false"
                             tabIndex="-1" style={menuHeaderStyle} onClick={handleCategoryAddNew}>
                            <p className={"bol"} style={{color: "#9da9bb"}}>Add new</p>

                        </div>
                    </div>
                </components.Menu>
            </Fragment>
        );
    };

    const SubCategoryMenu = (props) => {
        const menuHeaderStyle = {
            padding: '6px 12px',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            cursor: "pointer"
        };
        return (
            <Fragment>
                <components.Menu {...props}>
                    <div>
                        {props.selectProps.fetchingData ? (
                            <span className="fetching">Fetching data...</span>
                        ) : (
                            <div>{props.children}</div>
                        )}
                        <div className="option" aria-disabled="false"
                             tabIndex="-1" style={menuHeaderStyle} onClick={handleSubCategoryAddNew}>
                            <p className={"bol"} style={{color: "#9da9bb"}}>Add new</p>

                        </div>
                    </div>
                </components.Menu>
            </Fragment>
        );
    };

    let user_groups_name = group;
    let stock = stock_service;
    const data = [];
    fournisseurData &&
    fournisseurData.fournisseurs.map((F) =>
        data.push({
            value: parseInt(F.id),
            label: F.name,
        })
    );
    const NoOptionsMessage = props => {
        return (
            <components.NoOptionsMessage {...props}>
                <div className="text-center">
                    <img src={Spinner} style={{width: "10%"}} alt=""/>
                </div>
            </components.NoOptionsMessage>
        );
    };

    let categoryOptions = stockData &&
        stockData.categories.map((C) => (
            {label: C.name, value: C.id}
        ))
    categoryOptions = [
        {label: "-------------------------", value: ""},
        ...categoryOptions
    ]
    let subCategoryOptions = stockData &&
        stockData.souscategories.map((C) => (
            {label: C.name, value: C.id}
        ))
    subCategoryOptions = [
        {label: "-------------------------", value: ""},
        ...subCategoryOptions
    ]
    return (
        <Fragment>
            <Breadcrumb parent="Stock" title="Ajouter Stock"/>
            {/* eslint-disable-next-line no-mixed-operators */}
            {user_groups_name && user_groups_name.indexOf("admin") !== -1 || stock ? (
                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <Alert/>
                            <Form className="theme-form" onSubmit={(e) => onSubmit(e)}>
                                <Row>
                                    <Col md="7">
                                        <Card>
                                            <CardHeader>
                                                <h4>Détails du produit</h4>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Nom du produit (*)</Label>
                                                            <Input
                                                                className="form-control"
                                                                type="text"
                                                                name="name"
                                                                placeholder="Nom du produit"
                                                                value={formData.name}
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </FormGroup>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label>COD (*)</Label>
                                                                    <Input
                                                                        className="form-control"
                                                                        type="number"
                                                                        name="cod"
                                                                        placeholder="COD"
                                                                        value={formData.cod}
                                                                        onChange={(e) => onChange(e)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label>Poids (Kg)</Label>
                                                                    <Input
                                                                        className="form-control"
                                                                        type="number"
                                                                        name="weight"
                                                                        placeholder="Poids"
                                                                        value={formData.weight}
                                                                        onChange={(e) => onChange(e)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label>Etage</Label>
                                                                    <Input
                                                                        className="form-control"
                                                                        type="text"
                                                                        name="etage"
                                                                        placeholder="0  "
                                                                        value={formData.etage}
                                                                        onChange={(e) => onChange(e)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label>QTE(*)</Label>
                                                                    <Input
                                                                        className="form-control"
                                                                        type="number"
                                                                        name="stock"
                                                                        placeholder="0  "
                                                                        value={formData.stock}
                                                                        onChange={(e) => onChange(e)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Label>Image (*)</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="file"
                                                        name="image"
                                                        accept="image/jpeg,image/png,image/gif"
                                                        onChange={(e) => {
                                                            onChangeimg(e);
                                                        }}
                                                    />
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md="5">
                                        <Card>
                                            <CardHeader>
                                                <h4>Informations du produit</h4>
                                            </CardHeader>
                                            <CardBody>
                                                {user_groups_name && user_groups_name.indexOf("admin") !== -1 &&
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label>Fournisseur (*)</Label>
                                                                <Select
                                                                    value={selectedfournisseur}
                                                                    placeholder={"Fournisseur"}
                                                                    options={data}
                                                                    components={{NoOptionsMessage}}
                                                                    onChange={(e) => {
                                                                        onChangeFournisseur(e);
                                                                    }}
                                                                    onInputChange={(e) => {
                                                                        query.set('search', e);
                                                                        dispatch(getFournisseurs(query))
                                                                    }}
                                                                    onMenuScrollToBottom={(e) => {
                                                                        console.log(e)
                                                                        setCurrentFournisseurPage(page => page + 1)
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                }
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Catégorie (*)</Label>
                                                            <Select
                                                                onInputChange={(e, actionMeta) => handleCategoryInputChange(e, actionMeta)}
                                                                name={"category"}
                                                                options={categoryOptions}
                                                                value={categoryValue}
                                                                components={{Menu: CategoryMenu, NoOptionsMessage}}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        handleCategoryAddNew();
                                                                    }
                                                                }}
                                                                menuIsOpen={categoryMenuIsOpen}
                                                                onMenuOpen={() => setCategoryMenuIsOpen(true)} // Add this prop to open the menu
                                                                onMenuClose={() => setCategoryMenuIsOpen(false)}
                                                                onChange={(value) => {
                                                                    setCategoryValue(value)
                                                                    onChangeCategorie(value.value)
                                                                    setFormData({...formData, category: value.value})
                                                                    setCategoryForm(false)
                                                                }}
                                                            />
                                                        </FormGroup>
                                                        {categoryForm &&
                                                            <FormGroup>
                                                                <Label>Nom (*)</Label>
                                                                <Input
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="name"
                                                                    placeholder="Nom"
                                                                    value={categoryFormData.name}
                                                                    onChange={(e) => handleCategoryChange(e)}
                                                                />
                                                            </FormGroup>
                                                        }
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Sous Catégorie (*)</Label>
                                                            <Select
                                                                onInputChange={(e, actionMeta) => handleSubCategoryInputChange(e, actionMeta)}
                                                                name={"sub_category"}
                                                                options={subCategoryOptions}
                                                                value={subCategoryValue}
                                                                components={{Menu: SubCategoryMenu, NoOptionsMessage}}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        handleSubCategoryAddNew();
                                                                    }
                                                                }}
                                                                menuIsOpen={subCategoryMenuIsOpen}
                                                                onMenuOpen={() => setSubCategoryMenuIsOpen(true)} // Add this prop to open the menu
                                                                onMenuClose={() => setSubCategoryMenuIsOpen(false)}
                                                                onChange={(value) => {
                                                                    setSubCategoryValue(value)
                                                                    setFormData({
                                                                        ...formData,
                                                                        sub_category: value.value
                                                                    })
                                                                    setSubCategoryForm(false)
                                                                }}
                                                            />
                                                        </FormGroup>
                                                        {subCategoryForm &&
                                                            <FormGroup>
                                                                <Label>Nom (*)</Label>
                                                                <Input
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="name"
                                                                    placeholder="Nom"
                                                                    value={subCategoryFormData.name}
                                                                    onChange={(e) => handleSubCategoryChange(e)}
                                                                />
                                                            </FormGroup>
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Reference (*)</Label>
                                                            <Input
                                                                className="form-control"
                                                                type="text"
                                                                name="reference"
                                                                placeholder="Reference du produit"
                                                                value={formData.reference}
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Col>
                                    <FormGroup className="mb-0">
                                        <Button
                                            onClick={onSubmit}
                                            disabled={showLoader}
                                            className="btn btn-success mr-3"
                                            color={"success"}
                                            loading={showLoader}
                                            text={"Save"}
                                        />
                                        <Link to={`/stock_depot`}>
                                            <B color="danger">Cancel</B>
                                        </Link>
                                    </FormGroup>
                                </Col>
                                <br/>
                                <br/>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            ) : (
                <Error404 page="Ajouter Stock"/>
            )}
        </Fragment>
    );
};

AddStock.propTypes = {
    addNewStock: PropTypes.func.isRequired,
    fournisseurData: PropTypes.object.isRequired,
    stockData: PropTypes.object.isRequired,
    getFournisseursP: PropTypes.func.isRequired,
};

const mapStateToPorps = (state) => ({
    fournisseurData: state.reducerf,
    stockData: state.reducerst,
});

export default connect(mapStateToPorps, {
    getFournisseursP,
    getCategories,
    getSousCategories,
    addNewStock,
})(AddStock);
