import React, {Fragment, useState, useEffect, useMemo} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link, withRouter, useLocation} from "react-router-dom";
import {Multiselect} from "multiselect-react-dropdown";
import {addNewFournisseur1} from "../Fournisseurs/action";
import {useDispatch} from "react-redux";
import AlertS from "../../redux/AlertS";
import {getSecteurs} from "../secteuractivite/action";
import {getadresse, getCitef, getDeligationf, getVillef} from "../colis/action";
import {getCurrentDemandeF} from "../demandes/action";
import {
    Row,
    Col,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    CardHeader,
} from "reactstrap";
import Error404 from "../../pages/errors/error404";
import Nava from "./components/Navbar";

const Fournisseur = ({
                         addNewFournisseur1,
                         getCurrentDemandeF,
                         adresseData,
                         secteurData,
                         demandeData,
                         citeData,
                         deligationData,
                         villeData,
                         history,
                     }) => {

    const [, setsecteur] = useState([
        {
            id: "",
            activiter: "",
        },
    ]);
    const [, setVille] = useState([
        {
            id: "",
            name: "",
        },
    ]);
    const [, setCite] = useState([
        {
            id: "",
            name: "",
        },
    ]);
    const [, setDelegation] = useState([
        {
            id: "",
            name: "",
        },
    ]);
    const [stateselectedsecteur, setSelectedSecteur] = useState([]);
    const [formData, setFormData] = useState({
        responsable: "",
        tel: "",
        email: "",
        name: "",
        nb_colis: 3,
        adresse: "",
        code_postale: "",
        petit_livraison: "",
        grand_livraison: "",
        petit_retour: "",
        grand_retour: "",
        img: null,
        rib: "",
        mf: "",
        tentative: "",
        filiere: "",
        ville: "",
        delegation: "",
        cite: "",
        secteur: [],
    });
    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };

    let query = useQuery();
    const onChange = (e) =>
        setFormData({...formData, [e.target.name]: e.target.value});
    const onChangeimg = (e) => {
        setFormData({...formData, img: e.target.files[0]});
    };

    let params = new URLSearchParams(document.location.search)
    let key = params.get("key")
    useEffect(() => {
        getCurrentDemandeF(key)
    }, [getCurrentDemandeF, key])
let count = demandeData.count
    const onSubmit = (e) => {
        e.preventDefault();
        const fd = new FormData();
        fd.append("adresse", formData.adresse);
        fd.append("email", formData.email);
        fd.append("petit_retour", formData.petit_retour);
        fd.append("grand_livraison", formData.grand_livraison);
        fd.append("delegation", formData.delegation);
        fd.append("ville", formData.ville);
        fd.append("cite", formData.cite);
        fd.append("code_postale", formData.code_postale);
        fd.append("img", formData.img);
        fd.append("name", formData.name);
        fd.append("mf", formData.mf);
        fd.append("nb_colis", formData.nb_colis);
        fd.append("grand_retour", formData.grand_retour);
        fd.append("petit_livraison", formData.petit_livraison);
        fd.append("responsable", formData.responsable);
        fd.append("rib", formData.rib);
        formData.secteur.forEach((item) => {
            fd.append("secteur", item);
        });
        fd.append("tel", formData.tel);
        fd.append("tentative", formData.tentative);
        addNewFournisseur1(fd, history, key);
    };
    const onSelectSecteur = (e) => {
        setSelectedSecteur(e);
        const Secteurid = e.map((s) => s.value);
        setFormData({...formData, secteur: Secteurid});
    };

    const dispatch = useDispatch();


    const onChangeDeligation = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
        query.set("gouvernat_id", e.target.value);
        dispatch(getVillef(query, key));
    };

    const onChangeVille = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
        query.set("ville_id", e.target.value);
        dispatch(getCitef(query, key));
    };

    //get secteurs
    useEffect(() => {
        dispatch(getSecteurs(query));
    }, [dispatch, query]);
    useEffect(() => {
        setsecteur(secteurData.secteurs);
    }, [secteurData.loading, secteurData.secteurs]);

    const datasecteur = [];
    secteurData &&
    secteurData?.secteurs?.map((secteur) =>
        datasecteur.push({
            activiter: secteur.activiter,
            value: parseInt(secteur.id),
        })
    );
    const dataadresse = [];
    adresseData &&
    adresseData.adresses.map((address) =>
        dataadresse.push({
            address: address.address,
            value: parseInt(address.id),
        })
    );

    useEffect(() => {
        setVille(villeData.villes);
    }, [villeData.loading, villeData.villes]);

    useEffect(() => {
        dispatch(getDeligationf(key));
    }, [dispatch, key]);
    useEffect(() => {
        setDelegation(deligationData.deligations);
    }, [deligationData.deligations, deligationData.loading]);
    // get cite
    useEffect(() => {
        setCite(citeData.cités);
    }, [citeData.cités, citeData.loading]);

    const [, setTapTopStyle] = useState("none");

    const executeScroll = () => {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    };

    const handleScroll = () => {
        if (window.scrollY > 600) {
            setTapTopStyle("block");
        } else {
            setTapTopStyle("none");
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        handleScroll();
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <Fragment>
            <div style={{backgroundColor: "#F8F9FE", fontFamily: "Raleway"}}>
                {/* {window.innerWidth >= 1200 ? (
                    <div className="page-header flex " style={{backgroundColor: "#193256"}}>
                        <Row className="header-wrapper m-0">
                            <Col sm="2"></Col>
                            <Col>
                                <LeftHeader/>
                            </Col>
                            <Col sm="4">
                                <Nave/>
                            </Col>
                            <Col sm="3">
                                <RightHeader/>
                            </Col>
                        </Row>
                    </div>
                ) : (
                    <Row>
                        <Col>
                            <img
                                className="img-fluid for-light"
                                style={{
                                    width: "200px",
                                    height: "80px",
                                }}
                                src={require("../../assets/images/logo/logo_dark.png")}
                                alt=""
                            />
                        </Col>
                        <Row hidden={!menu}>
                            <div
                                sm="1"
                                style={{
                                    width: "100%",
                                    borderRadius: "15px",

                                    backgroundColor: "white",
                                }}
                            >
                                <br/>
                                <br/>
                                <Row>
                                    <Col sm="1"></Col>
                                    <Col>
                                        {" "}
                                        <Row>
                                            <Col></Col>
                                            <Link to="/">Home</Link>
                                            <Col></Col>
                                        </Row>
                                        <br/>
                                        <Row>
                                            <Col></Col>
                                            <Link to="/about">About</Link>
                                            <Col></Col>
                                        </Row>
                                        <br/>
                                        <Row>
                                            {" "}
                                            <Col></Col>
                                            <Link to="/services">Service</Link>
                                            <Col></Col>
                                        </Row>
                                        <br/>
                                        <Row>
                                            <Col></Col>
                                            <Link to="/partner">Contact</Link>
                                            <Col></Col>
                                        </Row>
                                        <br/>
                                        <Row>
                                            <Col></Col>
                                            <Link
                                                to={`/signin`}
                                                style={{
                                                    color: "#FFC107",
                                                    padding: "10px",
                                                    borderRadius: "15px",
                                                    border: "1px solid #FFC107",
                                                    fontSize: "16px",
                                                    textTransform: "uppercase",
                                                }}
                                            >
                                                Login &nbsp;&nbsp;&nbsp;&nbsp;
                                                <i className="fa fa-sign-in"></i>
                                            </Link>
                                            <Col></Col>
                                        </Row>
                                    </Col>
                                    <Col sm="1"></Col>
                                </Row>
                                <br/>
                            </div>
                        </Row>

                        <i
                            onClick={() => setMenu(!menu)}
                            className="fa fa-bars"
                            style={{
                                position: "relative",
                                top: "20px",
                                right: "7%",
                                fontSize: "30px",
                                color: "#FFC107",
                            }}
                        ></i>
                    </Row>
                )} */}
                <Nava/>
                {count && count !== 0 && key && key !==null ?
                    <Row>
                        <Col sm="1"></Col>
                        <Col>
                            <AlertS/>
                            <Form
                                className="theme-form"
                                encType="multipart/form-data"
                                onSubmit={(e) => onSubmit(e)}
                            >
                                <Row>
                                    <Col sm="8">
                                        <Card>
                                            <CardHeader>
                                                <h5>Données personnelles</h5>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Résponsable (*)</Label>
                                                            <Input
                                                                className="form-control"
                                                                type="text"
                                                                name="responsable"
                                                                placeholder="Résponsable"
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Raison sociale (*)</Label>
                                                            <Input
                                                                className="form-control"
                                                                type="text"
                                                                name="name"
                                                                placeholder="Raison sociale"
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Email (*)</Label>
                                                            <Input
                                                                className="form-control"
                                                                type="text"
                                                                name="email"
                                                                placeholder="Email"
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </FormGroup>
                                                    </Col>{" "}
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Téléphone (*)</Label>
                                                            <Input
                                                                className="form-control"
                                                                type="tel"
                                                                name="tel"
                                                                placeholder="Téléphone"
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Image </Label>
                                                            <Input
                                                                className="form-control"
                                                                type="file"
                                                                name="img"
                                                                accept="image/jpeg,image/png,image/gif"
                                                                onChange={(e) => {
                                                                    onChangeimg(e);
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                        <Card>
                                            <CardHeader>
                                                <h5>Confidentalités</h5>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>RIB </Label>
                                                            <Input
                                                                className="form-control"
                                                                type="text"
                                                                name="rib"
                                                                placeholder="RIB"
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>MF</Label>
                                                            <Input
                                                                className="form-control"
                                                                type="text"
                                                                name="mf"
                                                                placeholder="MF"
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Tentative (*)</Label>
                                                            <Input
                                                                className="form-control"
                                                                type="number"
                                                                name="tentative"
                                                                placeholder="Tentative"
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col sm="4">
                                        <Card>
                                            <CardHeader>
                                                <h5>Cordonnées</h5>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Adresse (*)</Label>
                                                            <Input
                                                                className="form-control"
                                                                type="text"
                                                                name="adresse"
                                                                placeholder="Adresse"
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Code Postale </Label>
                                                            <Input
                                                                className="form-control"
                                                                type="number"
                                                                name="code_postale"
                                                                placeholder="Code Postale"
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Délégation (*)</Label>
                                                            <select
                                                                className="form-control digits"
                                                                name="delegation"
                                                                onChange={(e) => onChangeDeligation(e)}
                                                            >
                                                                <option
                                                                    value=""
                                                                    disabled
                                                                    defaultValue
                                                                    selected
                                                                    hidden
                                                                >
                                                                    ----------
                                                                </option>
                                                                {deligationData &&
                                                                    deligationData?.deligations?.map(
                                                                        (delegation) => (
                                                                            <option
                                                                                key={delegation.id}
                                                                                value={delegation.id}
                                                                            >
                                                                                {delegation.name}
                                                                            </option>
                                                                        )
                                                                    )}
                                                            </select>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Ville</Label>

                                                            <select
                                                                className="form-control digits"
                                                                name="ville"
                                                                onChange={(e) => onChangeVille(e)}
                                                            >
                                                                {" "}
                                                                <option
                                                                    value=""
                                                                    disabled
                                                                    defaultValue
                                                                    selected
                                                                    hidden
                                                                >
                                                                    ----------
                                                                </option>
                                                                {villeData &&
                                                                    villeData?.villeF?.map((ville) => (
                                                                        <option key={ville.id} value={ville.id}>
                                                                            {ville.name}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Cité</Label>
                                                            <select
                                                                className="form-control digits"
                                                                name="cite"
                                                                onChange={(e) => onChange(e)}
                                                            >
                                                                <option
                                                                    value=""
                                                                    disabled
                                                                    defaultValue
                                                                    selected
                                                                    hidden
                                                                >
                                                                    ----------
                                                                </option>
                                                                {citeData &&
                                                                    citeData?.citésF?.map((cite) => (
                                                                        <option key={cite.id} value={cite.id}>
                                                                            {cite.name}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Secteur (*)</Label>
                                                            <Multiselect
                                                                name="secteur"
                                                                value={stateselectedsecteur}
                                                                options={datasecteur}
                                                                displayValue="activiter"
                                                                onSelect={(e) => {
                                                                    onSelectSecteur(e);
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup className="mb-6">
                                            <button
                                                type="submit"
                                                onClick={() => executeScroll()}
                                                className="btn btn-success mr-3"
                                            >
                                                Save
                                            </button>
                                            <Link to={`/fournisseur`}>
                                                <Button color="danger">Cancel</Button>
                                            </Link>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                        <Col sm="1"></Col>
                    </Row> :
                    <Error404 page={"Fournisseurs"}/>}

            </div>
        </Fragment>
    );
};
Fournisseur.propTypes = {
    getCurrentDemandef: PropTypes.func.isRequired,
    addNewFournisseur1: PropTypes.func.isRequired,
    getadresse: PropTypes.func.isRequired,
    getSecteurs: PropTypes.func.isRequired,
    getVillef: PropTypes.func.isRequired,
    getCitef: PropTypes.func.isRequired,
    getDeligationF: PropTypes.func.isRequired,
};

const mapstatetoprops = (state) => ({
    demandeData: state.reducerde,
    adresseData: state.reducerc,
    secteurData: state.reducersc,
    villeData: state.reducerdvc,
    citeData: state.reducerdvc,
    deligationData: state.reducerc,
});

export default connect(mapstatetoprops, {
    getCurrentDemandeF,
    addNewFournisseur1,
    getVillef,
    getCitef,
    getDeligationf,
    getadresse,
    getSecteurs,
})(withRouter(Fournisseur));
