import {Fragment, useRef, useState} from "react";
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import StepZilla from "react-stepzilla/dist/main";
import Carrier from "./Carrier";
import Upload from "./Upload";
import Mapping from "./Mapping";

const ProcessIfl = () => {
    const [formData, setFormData] = useState({
        livreur: "",
        file: null,
    })
    const [livreur, setLivreur] = useState(null);
    const [file, setFile] = useState(null)
    const [reportId, setReportId] = useState(null)
    const [fileColumns, setFileColumns] = useState([]);
    const stepZillaRef = useRef();

    const handleNext = () => {
        // You can perform any action here if needed before navigating
        stepZillaRef.current.jumpToStep(2);  // Move to the next step, i.e., 'Map fields'
    };

    const steps =
        [
            { name: 'Livreur', component: <Carrier
                    livreur={livreur}
                    setLivreur={setLivreur}
                    formData={formData}
                    setFormData={setFormData} /> },
            { name: 'Upload', component: <Upload
                    setFileColumns={setFileColumns}
                    file={file}
                    setFile={setFile}
                    setReportId={setReportId}
                    formData={formData}
                    setFormData={setFormData}
                    onNext={handleNext}  // Provide a prop for moving to the next step after successful upload
                /> },
            { name: 'Map fields', component: <Mapping formData={formData} fileColumns={fileColumns} reportId={reportId} /> },
        ]

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card className={"mt-3"}>
                            <CardHeader>
                                <h5>Import frais livreurs</h5>
                            </CardHeader>
                            <CardBody>
                            <StepZilla
                                steps={steps}
                                showSteps={true}
                                showNavigation={true}
                                stepsNavigation={true}
                                prevBtnOnLastStep={true}
                                dontValidate={false}
                                ref={stepZillaRef}
                            />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default ProcessIfl