import React, {Fragment, useState, useEffect, useMemo} from "react";
import {
    Container,
    Card,
    CardBody,
    FormGroup,
    Table,
    CardFooter
} from "reactstrap";
import PropTypes from "prop-types";
import {useHistory, useLocation, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Alert from "../../redux/Alert";
import Breadcrumb from "../../layout/breadcrumb";
import {
    getLivreur
} from "../colis/action";
import {getIfl} from "./actions";
import Button from "../../utils/Button";
import Spinner from "../colis/spinner.gif";
import PaginationWrapper from "../pagination/pagination";

const Ifl = () => {
    const [files, setFiles] = useState([])
    const [loading, setLoading] = useState(false)

    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };
    let query = useQuery();

    let pagenumber = query.get("page") ? query.get("page") : 1;
    const Navigate = (page) => {
        query.set("page", `${Number(page)}`)
        window.location.replace(`/import-frais-livreurs?${query}`);
    };

    const history = useHistory()

    //get livreur


    useEffect(() => {
        setLoading(true)
        getIfl()
            .then(res => {
                setFiles(res?.data?.results)
            })
        setLoading(false)
    }, [])

    const onSubmit = () => {
        history.push('/import-frais-livreurs/process')
    };


    return (
        <Fragment>
            <Breadcrumb parent="Finance" title="Import frais livreur"/>
            <Container fluid={true}>
                <Alert/>
                <Card>
                    <CardBody>
                        <div className={"d-flex flex-wrap justify-content-between align-items-center"}>
                            <FormGroup>
                                <Button
                                    onClick={onSubmit}
                                    className="btn btn-success mr-3"
                                    color={"success"}
                                    text={"Importer"}
                                />
                            </FormGroup>
                        </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <Table striped responsive>
                            <thead>
                            <tr>
                                <th>id</th>
                                <th>status</th>
                                <th>pourcentage</th>
                                <th>date</th>
                                <th>durée</th>
                                <th>lien de fichier</th>
                            </tr>
                            </thead>
                            <tbody>
                            {loading ?
                                <div className="text-center">
                                    <img src={Spinner} style={{width: "10%"}} alt=""/>
                                </div> :
                                (
                                    files?.map(item => (
                                        <tr>
                                            <td>{item.id}</td>
                                            <td>{item.status}</td>
                                            <td>{item.percentage.toFixed(2).replace(".00", "")}%</td>
                                            <td>{item.started_at}</td>
                                            <td>{item.duration}s</td>
                                            <td>
                                                <button disabled={!item.file} className="btn btn-success mr-3"
                                                        onClick={() => window.open(`${item.file}`)}>
                                                    <i className={"fa fa-download"}></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                )
                            }
                            </tbody>
                        </Table>
                    </CardBody>
                    <CardFooter>
                        <PaginationWrapper
                            name="Finance"
                            length={files.length}
                            count={files.length}
                            currentPage={pagenumber}
                            onNavigate={Navigate}
                        />
                    </CardFooter>
                </Card>
            </Container>
        </Fragment>
    )
}
getLivreur.propTypes = {
    getLivreur: PropTypes.func.isRequired,
};

const mapstatetoprops = (state) => ({
    livreurData: state.reducerc
})
export default connect(mapstatetoprops, getLivreur)(withRouter(Ifl))