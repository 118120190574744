import React, {
    Fragment,
    useState,
    useEffect,
    useMemo, useContext,
} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import Alert from "../../redux/Alert";
import PropTypes from "prop-types";
import PaginationWrapper from "../pagination/pagination";

import {connect} from "react-redux";
import {withRouter, useLocation} from "react-router-dom";
import Spinner from "../colis/spinner.gif";

import {
    Container,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Table,
    Card,
    CardBody,
    Form,
    Input,
} from "reactstrap";
import {useDispatch} from "react-redux";
import {getSecteurs, addNewSecteur, deleteSecteur} from "./action";
import {getColis} from "../colis/action";
import Error404 from "../../pages/errors/error404";
import AppContext from "../../context/Context";

const Sector = ({addNewSecteur, deleteSecteur, sectordata, history}) => {
    const [data, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const [ascId, setAscSortId] = useState(true);
    const [timer, setTimer] = useState(null)
    const [alldata, searchData] = useState([]);
    const dispatch = useDispatch();

    const {
        config: {group}
    } = useContext(AppContext)

    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };

    let query = useQuery();

    useEffect(() => {
        dispatch(getSecteurs(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (sectordata.secteurs) {
            searchData(sectordata.secteurs);
        }
    }, [sectordata.secteurs, sectordata.loading, alldata]);

    // useEffect(() => {
    //     if (sectordata.loading)
    //         setDisable(true)
    //     else
    //         setDisable(false)
    // }, [sectordata.loading])

    let pagenumber = query.get("page") ? query.get("page") : 1;
    const Navigate = (page) => {
        query.set("page", Number(page));

        window.location.replace(`/secteur-activite/?${query}`);
    };
    const [formData, setFormData] = useState({
        activiter: "",
    });

    const onChange = (e) =>
        setFormData({...formData, [e.target.name]: e.target.value});

    const onSubmit = (e) => {
        e.preventDefault();
        addNewSecteur(formData, history, true);
        dispatch(getSecteurs(query));
    };
    let count = sectordata.count;


    const onAscSort = (e) => {
        const qr = query.toString() + `&ordering=${e.target.id}`;
        setAscSortId(false);
        dispatch(getColis(qr));
        setData(data);
    };

    const onDescSort = (e) => {
        const qr = query.toString() + `&ordering=-${e.target.id}`;
        setAscSortId(true);
        dispatch(getColis(qr));
        setData(data);
    };

    let user_groups_name = group;
    const getValSearch = () => {
        let params = new URLSearchParams(document.location.search);
        return params.get("q");
    };

    const inputChanged = (e) => {
    clearTimeout(timer)
    query.set("search", e)
    const newTimer = setTimeout(() => {
      dispatch(getSecteurs(query));
    }, 500)

    setTimer(newTimer)
  }
    return (
        <Fragment>
            <Breadcrumb parent="Gestion" title="Secteur D'activitées"/>
            {user_groups_name && user_groups_name.indexOf("admin") !== -1 ? (
                <div>

                        <Container fluid={true}>
                            <Alert/>
                            <Row>
                                <Col sm="12">
                                    <Card>
                                        {/* {datacolis.colis.length > 0 ? () : ()} */}

                                        <CardBody>
                                            <Col sm="12">
                                                <Row>
                                                    <Col>
                                                        <button
                                                            disabled={false}
                                                            onClick={() => setModal(true)}
                                                            className="btn  ml-2"
                                                            style={{backgroundColor: "#FDF203"}}
                                                            type="button"
                                                        >
                                                            Ajouter
                                                        </button>
                                                    </Col>
                                                    <div className="search-6">
                                                        <h6>Rechercher:</h6>
                                                    </div>

                                                    <input
                                                        disabled={false}
                                                        className=" border search "
                                                        style={{borderRadius: "7px"}}
                                                        type="search"
                                                        placeholder="Rechercher..."
                                                        name="search"
                                                        defaultValue={getValSearch()}
                                                        onChange={(e) => inputChanged(e.target.value)}
                                                    />
                                                </Row>
                                                <br/>
                                                <Card>
                                                    <div className="card-block row">
                                                        <Col sm="12" lg="12" xl="12">
                                                            <div className="table-responsive">
                                                                {sectordata && sectordata.loading ? (
                                                                    <div className="text-center">
                                                                        <img src={Spinner} style={{width: "10%"}} alt=""/>
                                                                    </div>
                                                                ) : (
                                                                    <Table striped>
                                                                        <thead>
                                                                        <tr>
                                                                            <th
                                                                                id="activiter"
                                                                                onClick={(e) =>
                                                                                    ascId ? onAscSort(e) : onDescSort(e)
                                                                                }
                                                                            >
                                                                                {"Secteur D'activité"}
                                                                            </th>
                                                                            <th>{"Actions"}</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {sectordata &&
                                                                            sectordata.secteurs.map((b) => (
                                                                                <tr key={b.id}>
                                                                                    <td>{b.activiter}</td>
                                                                                    <td>
                                                                                        <button
                                                                                            onClick={(e) => {
                                                                                                deleteSecteur(b.id);
                                                                                                e.preventDefault();
                                                                                                dispatch(getSecteurs(query));
                                                                                                dispatch(getSecteurs(query));
                                                                                            }}
                                                                                            className="btn btn-danger m-1"
                                                                                        >
                                                                                            <i className="fa fa-trash"></i>
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </Table>
                                                                )}
                                                            </div>
                                                        </Col>
                                                    </div>
                                                </Card>
                                            </Col>{" "}
                                            <Row>
                                                <Col sm="10"></Col>
                                                {sectordata && sectordata.loading ? "" :
                                                <Col>
                                                    <PaginationWrapper
                                                        name="Secteurs"
                                                        length={sectordata.secteurs.length}
                                                        count={count}
                                                        currentPage={pagenumber}
                                                        onNavigate={Navigate}
                                                    />
                                                </Col>}
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <div>
                                <Modal
                                    centered
                                    fullscreen="sm"
                                    size="md"
                                    className="relative min-h-screen flex flex-col sm:justify-center items-center"
                                    isOpen={modal}
                                    toggle={() => setModal(!modal)}
                                >
                                    <ModalHeader className="p-3" toggle={() => setModal(!modal)}>
                                        Ajouter
                                    </ModalHeader>
                                    <ModalBody>
                                        <Row>
                                            <Col sm="12">
                                                <Form
                                                    className="theme-form"
                                                    onSubmit={(e) => {
                                                        onSubmit(e);
                                                        dispatch(getSecteurs(query));
                                                    }}
                                                >
                                                    <Label>Secteur D'activitée</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        name="activiter"
                                                        placeholder="activitée"
                                                        value={formData.activiter}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                    <ModalFooter>
                                                        <button
                                                            onClick={() => {
                                                                setModal(!modal);
                                                                dispatch(getSecteurs(query));
                                                            }}
                                                            type="submit "
                                                            className="p-2 btn-info rounded"
                                                        >
                                                            Confirm
                                                        </button>
                                                    </ModalFooter>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </ModalBody>
                                </Modal>
                            </div>
                        </Container>
                </div>
            ) : (
                <Error404 page="Secteur D'activitées"/>
            )}
        </Fragment>
    );
};

Sector.propTypes = {
    getSecteurs: PropTypes.func.isRequired,
    addNewSecteur: PropTypes.func.isRequired,
    deleteSecteur: PropTypes.func.isRequired,
    sectordata: PropTypes.object.isRequired,
};

const mapstatetoprops = (state) => ({
    sectordata: state.reducersc,
});

export default connect(mapstatetoprops, {
    getSecteurs,
    addNewSecteur,
    deleteSecteur,
})(withRouter(Sector));
