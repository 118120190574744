
import {
  GET_DEMANDE,
  GET_DEMANDES,
  DEMANDE_LOADING,
  DEMANDE_ERROR,
} from "../../redux/actionTypes";

const initial_state = {
  demande: null,
  demandes: [],
  count: 0,
  loading: true,
  error: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initial_state, action) {
  const { type, payload } = action;
  switch (type) {
    case DEMANDE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case DEMANDE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case GET_DEMANDE:
      return {
        ...state,
        demande: payload,
        loading: false,
      };
    case GET_DEMANDES:
      return {
        ...state,
        count: payload.count,
        demandes: payload.results,
        loading: false,
      };

    default:
      return state;
  }
}
