import React, {Fragment, useContext, useEffect, useMemo, useState,} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import Spinner from "./spinner.gif";
import Alert from "../../redux/Alert";
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import CountUp from "react-countup";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table,
} from "reactstrap";
import PropTypes from "prop-types";
import {connect, useDispatch} from "react-redux";
import PaginationWrapper from "../pagination/pagination";
import {
    addNewadresse,
    deleteColisG,
    getGestionsLivreur,
    getCite,
    getColis, getColisLivreur,
    getColisP,
    getDeligation,
    getGestions,
    getVille,
} from "./action";
import {Link, useLocation, withRouter} from "react-router-dom";
import AppContext from "../../context/Context";
const api = require('fastpanel-api');


export function month2digits(month) {
    return (month < 10 ? "0" : "") + month;
}

export function day2digits(day) {
    return (day < 10 ? "0" : "") + day;
}

export const currentMonthStartDate = () => {
    let date = new Date();
    return date.getFullYear() + "-" + month2digits(date.getMonth() + 1) + "-01";
};
export const currentMonthLastDate = () => {
    let date = new Date();
    return (
        date.getFullYear() +
        "-" +
        month2digits(date.getMonth() + 1) +
        "-" +
        day2digits(date.getDate())
    );
};

const GestionColi = ({
                         colisData,
                         gestionData,
                         deleteColisG,
                         deligationData,
                         villeData,
                         citeData,
                         history,
                     }) => {
    const [alldata, searchData] = useState([]);
    const [state, setState] = useState({
        MasterChecked: false,
        SelectedList: [],
    });
    const [id] = useState([]);
    const [ascId, setAscSortId] = useState(true);
    const [asctr, setAscSorttr] = useState(true);
    const [ascst, setAscSortst] = useState(true);
    const [asctel, setAscSorttel] = useState(true);
    const [ascser, setAscSortser] = useState(true);
    const [ascpr, setAscSortpr] = useState(true);
    const [ascFullname, setAscSortFullname] = useState(true);
    const [data, setData] = useState([
        {
            code_barre: "",
            client_telephone_1: "",
            client_telephone_2: "",
            client_full_name: "",
            client_ville: "",
            client_deligaiton: "",
            client_address: "",
            prix: "",
            service: "",
            volume: "",
            remarque: "",
            product_name: "",
            Address: "",
        },
    ]);
    const [modal, setModal] = useState(false);
    const [gestion, setGestion] = useState([]);
    const [timer, setTimer] = useState(null)
    const [stateville, setVille] = useState([
        {
            id: "",
            name: "",
        },
    ]);
    const [statecite, setCite] = useState([
        {
            id: "",
            name: "",
        },
    ]);
    const [lteDate, setLteDate] = useState(null)
    const [gteDate, setGteDate] = useState(null)
    const {
        config: {group},
    } = useContext(AppContext);
    const dispatch = useDispatch();

    let user_groups_name = group;

    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };
    let query = useQuery();
    useEffect(() => {
        if (user_groups_name.indexOf('livreur') !== -1) {
            dispatch(getColisLivreur(query))
        } else {
            dispatch(getColis(query));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    useEffect(() => {
        if (colisData.colis) {
            searchData(colisData.colis);
        }
    }, [colisData.colis, colisData.loading, alldata]);

    useEffect(() => {
        if (user_groups_name.indexOf('livreur') !== -1) {
            dispatch(getGestionsLivreur(query))
        } else {
            dispatch(getGestions(query));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (gestionData.gestions) {
            setGestion(gestionData.gestions);
        }
    }, [gestionData.gestions, gestionData.loading, gestion]);


    ReactHTMLTableToExcel.format = (s, c) => {
        if (c && c["table"]) {
            const html = c.table;
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, "text/html");
            const rows = doc.querySelectorAll("thead tr th");
            for (const row of rows) {
                row.setAttribute(
                    "style",
                    "background: #B8CCE4"
                );
            }
            c.table = doc.querySelector("table").outerHTML;
        }

        return s.replace(/{(\w+)}/g, (m, p) => c[p]);
    };

    let pagenumber = query.get("page") ? query.get("page") : 1;
    const Navigate = (page) => {
        query.set("page", `${Number(page)}`)
        window.location.replace(`/gestion-colis?${query}`);
    };

    const getgteDate = () => {
        let params = new URLSearchParams(document.location.search);
        return params.get("created_at__gte");
    };

    const getlteDate = () => {
        let params = new URLSearchParams(document.location.search);
        return params.get("created_at__lte");
    };


    let count = colisData.count;

    const [formDataadresse, setFormDataadresse] = useState({
        email: "",
        telephone_1: "",
        first_name: "",
        last_name: "",
        address: "",
        zipcode: "",
        ville: "",
        deligation: "",
        cite: "",
    });

    const onChangeDeligation = (e) => {
        setFormDataadresse({...formDataadresse, [e.target.name]: e.target.value});
        query.set("gouvernat_id", e.target.value);
        dispatch(getVille(query));
    };

    const onChangeVille = (e) => {
        setFormDataadresse({...formDataadresse, [e.target.name]: e.target.value});

        query.set("ville_id", e.target.value);
        dispatch(getCite(query));
    };

    const onChange = (e) => {
        setFormDataadresse({...formDataadresse, [e.target.name]: e.target.value});
    };

    const onSubmitadresse = (e) => {
        e.preventDefault();
        addNewadresse(formDataadresse);
    };

    useEffect(() => {
        setVille(villeData.villes);
    }, [villeData.villes, villeData.loading, stateville]);

    useEffect(() => {
        setCite(citeData.cites);
    }, [citeData.cites, citeData.loading, statecite]);



    const onAscSort = () => {
        query.set("ordering", "code_a_barre")
        setAscSortId(false);
        dispatch(getColis(query));
        setData(data);
    };

    const onDescSort = () => {
        query.set("ordering", "-code_a_barre")
        setAscSortId(true);
        dispatch(getColis(query));
        setData(data);
    };

    const onAscSorttr = () => {
        query.set("ordering", "shipment__tracking_code")
        setAscSorttr(false);
        dispatch(getColis(query));
        setData(data);
    };

    const onDescSorttr = () => {
        query.set("ordering", "-shipment__tracking_code")
        setAscSorttr(true);
        dispatch(getColis(query));
        setData(data);
    };

    const onAscSortst = async () => {
        query.set("ordering", "status")
        setAscSortst(false);
        let updatedData = await Promise.all(data.map(async (order) => {
        let lastStatus;
        try {
        lastStatus = await api.getLastStatus(order.trackingNumber);
        } catch (error) {
        console.error(error);
        }
        order.status = lastStatus;
        return order;
        }));
        setData(updatedData);
        dispatch(getColis(query));
        };

    const onDescSortst = async () => {
        query.set("ordering", "-status")
        setAscSortst(true);
        let updatedData = await Promise.all(data.map(async (order) => {
            let lastStatus;
            try {
            lastStatus = await api.getLastStatus(order.trackingNumber);
            } catch (error) {
            console.error(error);
            }
            order.status = lastStatus;
            return order;
            }));
            setData(updatedData);
            dispatch(getColis(query));
            };
    const onAscSorttel = () => {
        query.set("ordering", "client_telephone_1")
        setAscSorttel(false);
        dispatch(getColis(query));
        setData(data);
    };

    const onDescSorttel = () => {
        query.set("ordering", "-client_telephone_1")
        setAscSorttel(true);
        dispatch(getColis(query));
        setData(data);
    };

    const onAscSortpr = () => {
        query.set("ordering", "prix")
        setAscSortpr(false);
        dispatch(getColis(query));
        setData(data);
    };

    const onDescSortpr = () => {
        query.set("ordering", "-prix")
        setAscSortpr(true);
        dispatch(getColis(query));
        setData(data);
    };

    const onAscSortser = () => {
        query.set("ordering", "service")
        setAscSortser(false);
        dispatch(getColis(query));
        setData(data);
    };

    const onDescSortser = () => {
        query.set("ordering", "-service")
        setAscSortser(true);
        dispatch(getColis(query));
        setData(data);
    };

    const onAscSortexp = () => {
        query.set("ordering", "fournisseur__name")
        setAscSortFullname(false);
        dispatch(getColis(query));
        setData(data);
    };

    const onDescSortexp = () => {
        query.set("ordering", "-fournisseur__name")
        setAscSortFullname(true);
        dispatch(getColis(query));
        setData(data);
    };

    const onChangeCheck = (e) => {
        let tempList = colisData.colis;
        // eslint-disable-next-line array-callback-return
        tempList.map((l) => {
            l.selected = e.target.checked;
            if (e.target.checked) id.push(l.id);
            else id.length = 0;
        });

        setState({
            MasterChecked: e.target.checked,
            SelectedList: colisData.colis.filter((e) => e.selected),
        });
    };

    const onItemCheck = (e, item) => {
        let tempList = colisData.colis;
        // eslint-disable-next-line array-callback-return
        tempList.map((u) => {
            if (u.id === item) {
                u.selected = e.target.checked;
                if (e.target.checked) id.push(u.id);
                else id.splice(id.indexOf(u.id), 1);
            }
        });

        //To Control Master Checkbox State
        const totalItems = colisData.colis.length;
        const totalCheckedItems = id.length;

        // Update State
        setState({
            MasterChecked: totalItems !== totalCheckedItems,
            SelectedList: colisData.colis.filter((e) => e.selected),
        });
    };
    const getValSearch = () => {
        let params = new URLSearchParams(document.location.search);
        return params.get("q");
    };

    const inputChanged = (e) => {
        clearTimeout(timer)

        const newTimer = setTimeout(() => {
            query.set("search", e)
            history.push({search: query.toString()})
        }, 500)

        setTimer(newTimer)
    }
    const onChangeLivreur = (e) => {
        query.set('livreur', e)
        history.push({search: query.toString()})
    }

    // useEffect(() => {
    //     if (colisData.loading)
    //         setDisable(true)
    //     else
    //         setDisable(false)
    // }, [colisData.loading])

    return (
        <Fragment>
            <Breadcrumb
                parent="Colis"
                title={`Gestion des colis : Du : ${
                    getgteDate() || currentMonthStartDate()
                } A :  ${getlteDate() || currentMonthLastDate()} `}
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <Form className="theme-form">
                                    <Row>
                                        <Col sm="3"></Col>
                                        <div className=" row m-4">
                                            <h4>Du</h4>&nbsp;&nbsp;&nbsp;&nbsp;
                                            <input
                                                className="btn btn-light"
                                                type="date"
                                                name="created_at__gte"
                                                defaultValue={getgteDate() || currentMonthStartDate()}
                                                style={{padding: "5px 5px"}}
                                                onChange={(e) => {
                                                    setGteDate(e.target.value)
                                                }}
                                            />
                                        </div>
                                        <div className="row m-4">
                                            <h4>À  </h4>&nbsp;&nbsp;&nbsp;&nbsp;
                                            <input
                                                className="btn btn-light"
                                                type="date"
                                                name="created_at__lte"
                                                defaultValue={getlteDate() || currentMonthLastDate()}
                                                style={{padding: "5px 5px"}}
                                                onChange={(e) => {
                                                    setLteDate(e.target.value)
                                                }}
                                            />
                                            <button
                                                className="btn text-white  ml-2"
                                                style={{backgroundColor: "#27B3B0" }}
                                                type="button"
                                                disabled={false}
                                                onClick={() => {
                                                    if (lteDate)
                                                        query.set("created_at__lte", lteDate)
                                                    if (gteDate)
                                                        query.set("created_at__gte", gteDate)
                                                    history.push({search: query.toString()})
                                                    dispatch(getGestions(query))
                                                }}
                                            >
                                                Valider
                                            </button>
                                        </div>
                                    </Row>
                                </Form>
                            </CardHeader>
                            <Row>
                                {/* <CardBody>
                                    <Row>
                                        <Col></Col>
                                        <Link to={"/colis/history"} className={"btn btn-primary"}>
                                            Historique
                                        </Link>
                                    </Row>

                                </CardBody> */}
                            </Row>
                            <div>
                                <Row>
                                    <Col sm={2}></Col>
                                    <Col sm={2}>
                                        <Link
                                            to={`/gestion-colis?created_at__gte=${getgteDate() || currentMonthStartDate()}${`` || `&created_at__lte=${getlteDate() || currentMonthLastDate()}`}`}>
                                            <Card
                                                className="ecommerce-widget"
                                                style={{width: "100%"}}
                                            >

                                                <Row>
                                                    <Col></Col>
                                                    <div>
                                                        <span>Tous</span>

                                                    </div>
                                                    <Col></Col>
                                                </Row>
                                                <Row>
                                                    <Col></Col>
                                                    {gestionData && gestionData.loadingg ?
                                                        <div className="text-center">
                                                            <img src={Spinner} style={{width: "10%"}} alt=""/>
                                                        </div> :
                                                        <h5 className="total-num counter">
                                                            <CountUp end={gestionData.gestions.tous}/>
                                                        </h5>}
                                                    <Col></Col>
                                                </Row>
                                                <div className="progress-showcase m-2">
                                                    <div className="progress sm-progress-bar">
                                                        <div
                                                            className="progress-bar bg-warning"
                                                            role="progressbar"
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                        ></div>
                                                    </div>
                                                </div>
                                            </Card>
                                        </Link>
                                    </Col>
                                    <Col sm={2}>
                                        <Link
                                            to={`/gestion-colis?created_at__gte=${getgteDate() || currentMonthStartDate()}&created_at__lte=${getlteDate() || currentMonthLastDate()}&status=nouveau`}>
                                            <Card
                                                className="ecommerce-widget"
                                                style={{width: "100%"}}
                                            >


                                                <Row>
                                                    <Col></Col>
                                                    <div>
                                                        <span>Nouveau</span>

                                                    </div>
                                                    <Col></Col>
                                                </Row>
                                                <Row>
                                                    <Col></Col>
                                                    {gestionData && gestionData.loadingg ?
                                                        <div className="text-center">
                                                            <img src={Spinner} style={{width: "10%"}} alt=""/>
                                                        </div> :
                                                        <h5 className="total-num counter">
                                                            <CountUp end={gestionData.gestions.nouveau}/>
                                                        </h5>}
                                                    <Col></Col>
                                                </Row>
                                                <div className="progress-showcase m-2">
                                                    <div className="progress sm-progress-bar">
                                                        <div
                                                            className="progress-bar  bg-primary"
                                                            role="progressbar"
                                                            style={{
                                                                width: gestionData.percents.nouveau + "%",
                                                            }}
                                                        ></div>
                                                    </div>
                                                </div>

                                            </Card>
                                        </Link>
                                    </Col>
                                    <Col sm={2}>
                                        <Link
                                            to={`/gestion-colis?created_at__gte=${getgteDate() || currentMonthStartDate()}&created_at__lte=${getlteDate() || currentMonthLastDate()}&status=non_ramasser`}>
                                            <Card
                                                className="ecommerce-widget"
                                                style={{width: "100%"}}
                                            >


                                                <Row>
                                                    <Col></Col>
                                                    <div>
                                                        <span>Non ramassé</span>

                                                    </div>
                                                    <Col></Col>

                                                </Row>
                                                <Row>
                                                    <Col></Col>
                                                    {gestionData && gestionData.loadingg ?
                                                        <div className="text-center">
                                                            <img src={Spinner} style={{width: "10%"}} alt=""/>
                                                        </div> :
                                                        <h5 className="total-num counter">
                                                            <CountUp end={gestionData.gestions.non_ramasse}/>
                                                        </h5>}
                                                    <Col></Col>
                                                </Row>
                                                <div className="progress-showcase m-2">
                                                    <div className="progress sm-progress-bar">
                                                        <div
                                                            className="progress-bar bg-secondary"
                                                            role="progressbar"
                                                            style={{
                                                                width: gestionData.percents.non_ramasse + "%",
                                                            }}
                                                        ></div>
                                                    </div>
                                                </div>


                                            </Card> </Link>
                                    </Col>
                                    <Col sm={2}>
                                        <Link
                                            to={`/gestion-colis?created_at__gte=${getgteDate() || currentMonthStartDate()}&created_at__lte=${getlteDate() || currentMonthLastDate()}&status=valider`}>
                                            <Card
                                                className="ecommerce-widget"
                                                style={{width: "100%"}}
                                            >
                                                <Row>
                                                    <Col></Col>
                                                    <div>
                                                        <span>Validé</span>

                                                    </div>
                                                    <Col></Col>
                                                </Row>
                                                <Row>
                                                    <Col></Col>
                                                    {gestionData && gestionData.loadingg ?
                                                        <div className="text-center">
                                                            <img src={Spinner} style={{width: "10%"}} alt=""/>
                                                        </div> :
                                                        <h5 className="total-num counter">
                                                            <CountUp end={gestionData.gestions.valider}/>
                                                        </h5>}
                                                    <Col></Col>
                                                </Row>
                                                <div className="progress-showcase m-2">
                                                    <div className="progress sm-progress-bar">
                                                        <div
                                                            className="progress-bar bg-success"
                                                            role="progressbar"
                                                            style={{
                                                                width: gestionData.percents.valider + "%",
                                                            }}
                                                        ></div>
                                                    </div>
                                                </div>

                                            </Card> </Link>
                                    </Col>
                                    <Col sm={2}></Col>
                                </Row>
                                <Row>
                                    <Col sm={2}></Col>
                                    <Col sm={2}>
                                        <Link
                                            to={`/gestion-colis?created_at__gte=${getgteDate() || currentMonthStartDate()}&created_at__lte=${getlteDate() || currentMonthLastDate()}&status=en_cours_de_livraison`}>
                                            <Card
                                                className="ecommerce-widget"
                                                style={{width: "100%"}}
                                            >
                                                <Row>
                                                    <Col></Col>
                                                    <div>
                                                        <span>En cours </span>
                                                    </div>
                                                    <Col></Col>
                                                </Row>
                                                <Row>
                                                    <Col></Col>
                                                    {gestionData && gestionData.loadingg ?
                                                        <div className="text-center">
                                                            <img src={Spinner} style={{width: "10%"}} alt=""/>
                                                        </div> :
                                                        <h5 className="total-num counter">
                                                            <CountUp
                                                                end={gestionData.gestions.en_cours_de_livraison}
                                                            />
                                                        </h5>}
                                                    <Col></Col>
                                                </Row>
                                                <div className="progress-showcase m-2">
                                                    <div className="progress sm-progress-bar">
                                                        <div
                                                            className="progress-bar bg-info"
                                                            role="progressbar"
                                                            style={{
                                                                width:
                                                                    gestionData.percents.en_cours_de_livraison + "%",
                                                            }}
                                                        ></div>
                                                    </div>
                                                </div>

                                            </Card></Link>
                                    </Col>
                                    <Col sm={2}>
                                        <Link
                                            to={`/gestion-colis?created_at__gte=${getgteDate() || currentMonthStartDate()}&created_at__lte=${getlteDate() || currentMonthLastDate()}&status=anomalie`}>
                                            <Card
                                                className="ecommerce-widget"
                                                style={{width: "100%"}}
                                            >
                                                <Row>
                                                    <Col></Col>
                                                    <div>
                                                        <span>Anomalie</span>

                                                    </div>
                                                    <Col></Col>
                                                </Row>
                                                <Row>
                                                    <Col></Col>
                                                    {gestionData && gestionData.loadingg ?
                                                        <div className="text-center">
                                                            <img src={Spinner} style={{width: "10%"}} alt=""/>
                                                        </div> :
                                                        <h5 className="total-num counter">
                                                            <CountUp end={gestionData.gestions.anomalie}/>
                                                        </h5>}
                                                    <Col></Col>
                                                </Row>
                                                <div className="progress-showcase m-2">
                                                    <div className="progress sm-progress-bar">
                                                        <div
                                                            className="progress-bar bg-danger"
                                                            role="progressbar"
                                                            style={{
                                                                width: gestionData.percents.anomalie + "%",
                                                            }}
                                                        ></div>
                                                    </div>
                                                </div>

                                            </Card></Link>
                                    </Col>
                                    <Col sm={2}>
                                        <Link
                                            to={`/gestion-colis?created_at__gte=${getgteDate() || currentMonthStartDate()}&created_at__lte=${getlteDate() || currentMonthLastDate()}&status=livrer`}>
                                            <Card
                                                className="ecommerce-widget"
                                                style={{width: "100%"}}
                                            >
                                                <Row>
                                                    <Col></Col>
                                                    <div>
                                                        <span>Livré</span>

                                                    </div>
                                                    <Col></Col>
                                                </Row>
                                                <Row>
                                                    <Col></Col>
                                                    {gestionData && gestionData.loadingg ?
                                                        <div className="text-center">
                                                            <img src={Spinner} style={{width: "10%"}} alt=""/>
                                                        </div> :
                                                        <h5 className="total-num counter">
                                                            <CountUp end={gestionData.gestions.livrer}/>
                                                        </h5>}
                                                    <Col></Col>
                                                </Row>
                                                <div className="progress-showcase m-2">
                                                    <div className="progress sm-progress-bar">
                                                        <div
                                                            className="progress-bar bg-success"
                                                            role="progressbar"
                                                            style={{
                                                                width: gestionData.percents.livrer + "%",
                                                            }}
                                                        ></div>
                                                    </div>
                                                </div>
                                            </Card>
                                        </Link>
                                    </Col>
                                    <Col sm={2}>
                                        <Link
                                            to={`/gestion-colis?created_at__gte=${getgteDate() || currentMonthStartDate()}&created_at__lte=${getlteDate() || currentMonthLastDate()}&service=echange`}>
                                            <Card
                                                className="ecommerce-widget"
                                                style={{width: "100%"}}
                                            >
                                                <Row>
                                                    <Col></Col>
                                                    <div>
                                                        <span>Echange</span>

                                                    </div>
                                                    <Col></Col>
                                                </Row>
                                                <Row>
                                                    <Col></Col>
                                                    {gestionData && gestionData.loadingg ?
                                                        <div className="text-center">
                                                            <img src={Spinner} style={{width: "10%"}} alt=""/>
                                                        </div> :
                                                        <h5 className="total-num counter">
                                                            <CountUp end={gestionData.gestions.echange}/>
                                                        </h5>}
                                                    <Col></Col>
                                                </Row>
                                                <div className="progress-showcase m-2">
                                                    <div className="progress sm-progress-bar">
                                                        <div
                                                            className="progress-bar bg-success"
                                                            role="progressbar"
                                                            style={{
                                                                width: gestionData.percents.echange + "%",
                                                            }}
                                                        ></div>
                                                    </div>
                                                </div>
                                            </Card>
                                        </Link>
                                    </Col>
                                    <Col sm={2}></Col>
                                </Row>
                                <Row>
                                    <Col sm={2}></Col>
                                    <Col sm={2}>
                                        <Link
                                            to={`/gestion-colis?created_at__gte=${getgteDate() || currentMonthStartDate()}&created_at__lte=${getlteDate() || currentMonthLastDate()}&status=retour`}>
                                            <Card
                                                className="ecommerce-widget"
                                                style={{width: "100%"}}
                                            >
                                                <Row>
                                                    <Col></Col>
                                                    <div>
                                                        <span>Retour</span>

                                                    </div>
                                                    <Col></Col>
                                                </Row>
                                                <Row>
                                                    <Col></Col>
                                                    {gestionData && gestionData.loadingg ?
                                                        <div className="text-center">
                                                            <img src={Spinner} style={{width: "10%"}} alt=""/>
                                                        </div> :
                                                        <h5 className="total-num counter">
                                                            <CountUp end={gestionData.gestions.retour}/>
                                                        </h5>}
                                                    <Col></Col>
                                                </Row>
                                                <div className="progress-showcase m-2">
                                                    <div className="progress sm-progress-bar">
                                                        <div
                                                            className="progress-bar bg-primary"
                                                            role="progressbar"
                                                            style={{
                                                                width: gestionData.percents.retour + "%",
                                                            }}
                                                        ></div>
                                                    </div>
                                                </div>
                                            </Card>
                                        </Link>
                                    </Col>
                                    <Col sm={2}>
                                        <Link
                                            to={`/gestion-colis?created_at__gte=${getgteDate() || currentMonthStartDate()}&created_at__lte=${getlteDate() || currentMonthLastDate()}&status=retour_aux_sources`}>
                                            <Card
                                                className="ecommerce-widget"
                                                style={{width: "100%"}}
                                            >
                                                <Row>
                                                    <Col></Col>
                                                    <div>
                                                        <span style={{margin: "17px"}}>Retour aux sources</span>

                                                    </div>
                                                    <Col></Col>
                                                </Row>
                                                <Row>
                                                    <Col></Col>
                                                    {gestionData && gestionData.loadingg ?
                                                        <div className="text-center">
                                                            <img src={Spinner} style={{width: "10%"}} alt=""/>
                                                        </div> :
                                                        <h5 className="total-num counter">
                                                            <CountUp
                                                                end={gestionData.gestions.retour_aux_sources}
                                                            />
                                                        </h5>}
                                                    <Col></Col>
                                                </Row>
                                                <div className="progress-showcase m-2">
                                                    <div className="progress sm-progress-bar">
                                                        <div
                                                            className="progress-bar bg-info"
                                                            role="progressbar"
                                                            style={{
                                                                width: gestionData.percents.retour_aux_sources + "%",
                                                            }}
                                                        ></div>
                                                    </div>
                                                </div>
                                            </Card>
                                        </Link>
                                    </Col>
                                    <Col sm={2}>
                                        <Link
                                            to={`/gestion-colis?created_at__gte=${getgteDate() || currentMonthStartDate()}&created_at__lte=${getlteDate() || currentMonthLastDate()}&status=payer`}>
                                            <Card
                                                className="ecommerce-widget"
                                                style={{width: "100%"}}
                                            >
                                                <Row>
                                                    <Col></Col>
                                                    <div>
                                                        <span>Payé</span>

                                                    </div>
                                                    <Col></Col>
                                                </Row>
                                                <Row>
                                                    <Col></Col>
                                                    {gestionData && gestionData.loadingg ?
                                                        <div className="text-center">
                                                            <img src={Spinner} style={{width: "10%"}} alt=""/>
                                                        </div> :
                                                        <h5 className="total-num counter">
                                                            <CountUp end={gestionData.gestions.payer}/>
                                                        </h5>}
                                                    <Col></Col>
                                                </Row>
                                                <div className="progress-showcase m-2">
                                                    <div className="progress sm-progress-bar">
                                                        <div
                                                            className="progress-bar bg-danger"
                                                            role="progressbar"
                                                            style={{
                                                                width: gestionData.percents.payer + "%",
                                                            }}
                                                        ></div>
                                                    </div>
                                                </div>

                                            </Card></Link>
                                    </Col>
                                    <Col sm={2}></Col>
                                </Row>
                            </div>
                            <CardBody>
                                <Alert/>
                                <Col sm="12">
                                    <Row>
                                        <Col>

                                            <Row>
                                                <button
                                                    disabled={id.length === 0}
                                                    className="btn text-white  m-2 "
                                                    style={{
                                                        backgroundColor: "#C725C7",
                                                    }}
                                                    onClick={() => {
                                                        let q = new URLSearchParams();
                                                        q.set("colis_id", id.toString())
                                                        window.open(`/colis/print/?${q}`);
                                                    }}
                                                    title="Imprimer"
                                                >
                                                    IMPRIMER
                                                </button>
                                                {user_groups_name && user_groups_name.indexOf("livreur") !== -1 ? "" :
                                                    <Link className="btn  m-2 btn-excel text-white"
                                                          to={`/colis/csv?${query}&page=gestion_colis`}
                                                          target={"_blank"}>
                                                        EXCEL
                                                    </Link>}
                                                {user_groups_name && user_groups_name.indexOf('admin') !== -1 ?
                                                    <div style={{position: "relative", top : "8px"}}>
                                                        <select className="btn form-control digits"
                                                                onChange={(e) => onChangeLivreur(e.target.value)}>
                                                            <option value={""}>All</option>
                                                            <option value={"beez"}>Beezit</option>
                                                            <option value={"fp"}>Fparcel</option>
                                                            <option value={"AM"}>Aramex</option>
                                                        </select>
                                                    </div> : ""}
                                            </Row>
                                        </Col>


                                        <div className="search-5">
                                            <h6>Rechercher:</h6>
                                        </div>
                                        <input
                                            className="border search"
                                            style={{borderRadius: "7px"}}
                                            type="search"
                                            placeholder="Rechercher..."
                                            name="q"
                                            defaultValue={getValSearch()}
                                            onChange={(e) => inputChanged(e.target.value)}
                                        />

                                    </Row>
                                </Col>
                                <Col sm="12">
                                    <Card>
                                        <div className="card-block row">
                                            <Col sm="12" lg="12" xl="12">
                                                <div className="table-responsive">
                                                    {colisData && colisData.loading ? (
                                                        <div className="text-center">
                                                            <img src={Spinner} style={{width: "10%"}} alt=""/>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <Table striped>
                                                                <thead>
                                                                <tr>
                                                                    <th scope="col">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={state.MasterChecked}
                                                                            onChange={(e) => {
                                                                                onChangeCheck(e);
                                                                            }}
                                                                        />
                                                                    </th>
                                                                    <th
                                                                        title={`Trier ${
                                                                            ascId ? "Ascendant" : "Descendant"
                                                                        }`}
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="code_a_barre"
                                                                        onClick={(e) =>
                                                                            ascId ? onAscSort(e) : onDescSort(e)
                                                                        }
                                                                    >
                                                                        {"Code à barre"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {ascId ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-sort-up"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className={`bi bi-sort-down`}
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="created_at"
                                                                    >
                                                                        {"Date création"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                    </th>
                                                                    <th
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="livreur"
                                                                    >
                                                                        {"Partenaire"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                    </th>
                                                                    <th
                                                                        title={`Trier ${
                                                                            ascId ? "Ascendant" : "Descendant"
                                                                        }`}
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="tracking_code"
                                                                        onClick={(e) =>
                                                                            asctr ? onAscSorttr(e) : onDescSorttr(e)
                                                                        }
                                                                    >
                                                                        {"Code à barre partenaire"}{" "}
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {asctr ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-sort-up"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className={`bi bi-sort-down`}
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        title={`Trier ${
                                                                            ascst ? "Ascendant" : "Descendant"
                                                                        }`}
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="status"
                                                                        onClick={(e) =>
                                                                            ascst ? onAscSortst(e) : onDescSortst(e)
                                                                        }
                                                                    >
                                                                        {"Status"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {ascst ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-sort-up"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className={`bi bi-sort-down`}
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        title={`Trier ${
                                                                            ascFullname ? "Ascendant" : "Descendant"
                                                                        }`}
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="fournisseur_name"
                                                                        onClick={(e) =>
                                                                            ascFullname
                                                                                ? onAscSortexp(e)
                                                                                : onDescSortexp(e)
                                                                        }
                                                                    >
                                                                        {"Expéditeur"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {ascFullname ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-sort-up"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className={`bi bi-sort-down`}
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        )}
                                                                    </th>
                                                                    <th scope="col" id="client_address">
                                                                        {"Destinataire"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                    </th>
                                                                    <th
                                                                        title={`Trier ${
                                                                            asctel ? "Ascendant" : "Descendant"
                                                                        }`}
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="client_telephone_1"
                                                                        onClick={(e) =>
                                                                            asctel
                                                                                ? onAscSorttel(e)
                                                                                : onDescSorttel(e)
                                                                        }
                                                                    >
                                                                        {"Tel 1 / Tel 2"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {asctel ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-sort-up"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className={`bi bi-sort-down`}
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        )}
                                                                    </th>
                                                                    <th scope="col" id="client_ville">
                                                                        {"Ville"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                    </th>
                                                                    <th scope="col" id="client_ville">
                                                                        {"Adresse"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                    </th>
                                                                    <th scope="col" id="client_delegation">
                                                                        {"Délégation"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                    </th>
                                                                    <th
                                                                        title={`Trier ${
                                                                            ascser ? "Ascendant" : "Descendant"
                                                                        }`}
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="service"
                                                                        onClick={(e) =>
                                                                            ascser
                                                                                ? onAscSortser(e)
                                                                                : onDescSortser(e)
                                                                        }
                                                                    >
                                                                        {"Service"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {ascser ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-sort-up"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className={`bi bi-sort-down`}
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        title={`Trier ${
                                                                            ascpr ? "Ascendant" : "Descendant"
                                                                        }`}
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="prix"
                                                                        onClick={(e) =>
                                                                            ascpr ? onAscSortpr(e) : onDescSortpr(e)
                                                                        }
                                                                    >
                                                                        {"COD"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {ascpr ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-sort-up"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className={`bi bi-sort-down`}
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        )}
                                                                    </th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {colisData &&
                                                                    colisData.colis.map((b) => (
                                                                        <tr key={b.id}>
                                                                            <td>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    value={b.id}
                                                                                    checked={b.selected}
                                                                                    id={`rowcheck${b.id}`}
                                                                                    onChange={(e) => onItemCheck(e, b.id)}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Link to={`/colis/detail/${b.id}`}>
                                                                                    {b.code_a_barre}
                                                                                </Link>
                                                                            </td>
                                                                            <td>{b.created_at}</td>
                                                                            <td>{b.livreur_name}</td>
                                                                            <td>{b.tracking_code}</td>
                                                                            <td>{b.last_tracking}</td>
                                                                            <td>{b.fournisseur_name}</td>
                                                                            <td>
                                                                                {b.client_name}
                                                                            </td>
                                                                            <td>
                                                                                {b.client_telephone_1} /{" "}
                                                                                {b.client_telephone_2}
                                                                            </td>
                                                                            <td>{b.ville_name}</td>
                                                                            <td>{b.client_address} </td>
                                                                            <td>{b.delegation_name}</td>
                                                                            <td>{b.service}</td>
                                                                            <td>{b.prix}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                            <Table striped hidden={true} id="example">
                                                                <thead>
                                                                <tr>
                                                                    <th scope="col">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={state.MasterChecked}
                                                                            onChange={(e) => {
                                                                                onChangeCheck(e);
                                                                            }}
                                                                        />
                                                                    </th>
                                                                    <th
                                                                        scope="col"
                                                                    >
                                                                        {"Nom du fournisseur"}
                                                                    </th>
                                                                    <th
                                                                        title={`Trier ${
                                                                            ascId ? "Ascendant" : "Descendant"
                                                                        }`}
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="tracking_code"
                                                                        onClick={(e) =>
                                                                            asctr ? onAscSorttr(e) : onDescSorttr(e)
                                                                        }
                                                                    >
                                                                        {"Code de suivi"}{" "}
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {asctr ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-sort-up"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className={`bi bi-sort-down`}
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        title={`Trier ${
                                                                            ascId ? "Ascendant" : "Descendant"
                                                                        }`}
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="code_a_barre"
                                                                        onClick={(e) =>
                                                                            ascId ? onAscSort(e) : onDescSort(e)
                                                                        }
                                                                    >
                                                                        {"Code à barre"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {ascId ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-sort-up"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className={`bi bi-sort-down`}
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        title={`Trier ${
                                                                            ascst ? "Ascendant" : "Descendant"
                                                                        }`}
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="status"
                                                                        onClick={(e) =>
                                                                            ascst ? onAscSortst(e) : onDescSortst(e)
                                                                        }
                                                                    >
                                                                        {"Dernier status"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {ascst ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-sort-up"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className={`bi bi-sort-down`}
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="created_at"
                                                                    >
                                                                        {"Date création"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                    </th>
                                                                    <th
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="created_at"
                                                                    >
                                                                        {"Date de modification"}
                                                                    </th>
                                                                    <th scope="col" id="client_address">
                                                                        {"Destinataire"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                    </th>
                                                                    <th
                                                                        title={`Trier ${
                                                                            asctel ? "Ascendant" : "Descendant"
                                                                        }`}
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="client_telephone_1"
                                                                        onClick={(e) =>
                                                                            asctel
                                                                                ? onAscSorttel(e)
                                                                                : onDescSorttel(e)
                                                                        }
                                                                    >
                                                                        {"Téléphone 1"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {asctel ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-sort-up"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className={`bi bi-sort-down`}
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        title={`Trier ${
                                                                            asctel ? "Ascendant" : "Descendant"
                                                                        }`}
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="client_telephone_1"
                                                                        onClick={(e) =>
                                                                            asctel
                                                                                ? onAscSorttel(e)
                                                                                : onDescSorttel(e)
                                                                        }
                                                                    >
                                                                        {"Téléphone 2"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {asctel ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-sort-up"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className={`bi bi-sort-down`}
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        )}
                                                                    </th>
                                                                    <th scope="col" id="client_ville">
                                                                        {"Ville"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                    </th>
                                                                    <th scope="col" id="client_ville">
                                                                        {"Adresse"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                    </th>
                                                                    <th scope="col" id="client_delegation">
                                                                        {"Délégation"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                    </th>
                                                                    <th scope="col" id="client_zipcode">
                                                                        {"Code postale"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                    </th>
                                                                    <th scope="col" id="client_zipcode">
                                                                        {"Imprimé"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                    </th>
                                                                    <th
                                                                        title={`Trier ${
                                                                            ascser ? "Ascendant" : "Descendant"
                                                                        }`}
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="service"
                                                                        onClick={(e) =>
                                                                            ascser
                                                                                ? onAscSortser(e)
                                                                                : onDescSortser(e)
                                                                        }
                                                                    >
                                                                        {"Service"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {ascser ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-sort-up"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className={`bi bi-sort-down`}
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        title={`Trier ${
                                                                            ascpr ? "Ascendant" : "Descendant"
                                                                        }`}
                                                                        style={{cursor: "pointer"}}
                                                                        scope="col"
                                                                        id="prix"
                                                                        onClick={(e) =>
                                                                            ascpr ? onAscSortpr(e) : onDescSortpr(e)
                                                                        }
                                                                    >
                                                                        {"COD"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                        {ascpr ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-sort-up"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707V12.5zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className={`bi bi-sort-down`}
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                                                            </svg>
                                                                        )}
                                                                    </th>
                                                                    <th scope="col" id="remarque">
                                                                        {"Remarque"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                    </th>
                                                                    <th scope="col" id="produit">
                                                                        {"Produit"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                    </th>
                                                                    <th scope="col" id="remarque_echange">
                                                                        {"Remarque échange"} &nbsp;&nbsp;&nbsp;&nbsp;
                                                                    </th>
                                                                    {user_groups_name &&
                                                                    user_groups_name.indexOf("fournisseur") !==
                                                                    -1 ? (
                                                                        ""
                                                                    ) : (
                                                                        <th scope="col">{"Actions"}</th>
                                                                    )}
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {colisData &&
                                                                    colisData.colisP.map((b) => (
                                                                        <tr key={b.id}>
                                                                            <td>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    value={b.id}
                                                                                    checked={b.selected}
                                                                                    id={`rowcheck${b.id}`}
                                                                                    onChange={(e) => onItemCheck(e, b.id)}
                                                                                />
                                                                            </td>
                                                                            <td>{b.fournisseur_name}</td>
                                                                            <td>{b.tracking_code}</td>
                                                                            <td>

                                                                                {b.code_a_barre}

                                                                            </td>
                                                                            <td>{b.last_tracking}</td>
                                                                            <td>{b.created_at}</td>
                                                                            <td>{b.updated_at}</td>
                                                                            <td>
                                                                                {b.client_name}
                                                                            </td>
                                                                            <td>
                                                                                {b.client_telephone_1}
                                                                            </td>
                                                                            <td>
                                                                                {b.client_telephone_2}
                                                                            </td>
                                                                            <td>{b.ville_name}</td>
                                                                            <td>{b.client_address} </td>
                                                                            <td>{b.delegation_name}</td>
                                                                            <td>{b.client_zipcode}</td>
                                                                            <td>{b.colis_imprimer}</td>
                                                                            <td>{b.service}</td>
                                                                            <td>{b.prix}</td>
                                                                            <td>{b.remarque}</td>
                                                                            <td>{b.product_name}</td>
                                                                            <td>{b.remarque_echange}</td>
                                                                            {user_groups_name &&
                                                                            user_groups_name.indexOf(
                                                                                "fournisseur"
                                                                            ) !== -1 ? (
                                                                                ""
                                                                            ) : (
                                                                                <td>
                                                                                    <div>
                                                                                        <button
                                                                                            onClick={() => {
                                                                                                deleteColisG(b.id, history);
                                                                                                dispatch(getColis(query));
                                                                                                dispatch(getColis(query));
                                                                                            }}
                                                                                            className="btn btn-danger m-1"
                                                                                        >
                                                                                            {" "}
                                                                                            <i className="fa fa-trash"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </td>
                                                                            )}
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                        </>
                                                    )}

                                                </div>
                                            </Col>
                                        </div>
                                    </Card>
                                </Col>

                                <Row>
                                    <Col sm="10"></Col>
                                    {colisData && colisData.loading ? "" :
                                        <Col>
                                            <PaginationWrapper
                                                name="Colis"
                                                count={count}
                                                length={colisData.colis.length}
                                                currentPage={pagenumber}
                                                onNavigate={Navigate}
                                            />
                                        </Col>}
                                </Row>
                            </CardBody>
                            <div>
                                <Modal
                                    centered
                                    fullscreen="sm"
                                    size="md"
                                    className="relative min-h-screen flex flex-col sm:justify-center items-center"
                                    isOpen={modal}
                                    toggle={() => setModal(!modal)}
                                >
                                    <ModalHeader className="p-3" toggle={() => setModal(!modal)}>
                                        Ajouter
                                    </ModalHeader>
                                    <ModalBody>
                                        <Row>
                                            <Col sm="12">
                                                <Form className="theme-form">
                                                    <Label>Nom</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        name="first_name"
                                                        placeholder="Nom"
                                                        value={formDataadresse.first_name}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                    <Label>Prénom</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        name="last_name"
                                                        placeholder="Prénom"
                                                        value={formDataadresse.last_name}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                    <Label>Email</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        name="email"
                                                        placeholder="email"
                                                        value={formDataadresse.email}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                    <Label>Téléphone</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        name="telephone_1"
                                                        placeholder="telephone"
                                                        value={formDataadresse.telephone_1}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                    <Label>Code postale</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        name="zipcode"
                                                        placeholder="zipcode"
                                                        value={formDataadresse.zipcode}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                    <Label>Adresse</Label>
                                                    <Input
                                                        className="form-control"
                                                        type="text"
                                                        name="address"
                                                        placeholder="address"
                                                        value={formDataadresse.address}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                    <Label>Délégation</Label>
                                                    <select
                                                        className="form-control digits"
                                                        name="deligation"
                                                        onChange={(e) => onChangeDeligation(e)}
                                                    >
                                                        <option value="" disabled defaultValue hidden>
                                                            ----------
                                                        </option>
                                                        {deligationData &&
                                                            deligationData.deligations.map((delegation) => (
                                                                <option
                                                                    key={delegation.id}
                                                                    value={delegation.id}
                                                                >
                                                                    {delegation.name}
                                                                </option>
                                                            ))}
                                                    </select>
                                                    <Label>Ville</Label>

                                                    <select
                                                        className="form-control digits"
                                                        name="ville"
                                                        onChange={(e) => onChangeVille(e)}
                                                    >
                                                        {" "}
                                                        <option value="" disabled defaultValue hidden>
                                                            ----------
                                                        </option>
                                                        {villeData &&
                                                            villeData.villes.map((ville) => (
                                                                <option key={ville.id} value={ville.id}>
                                                                    {ville.name}
                                                                </option>
                                                            ))}
                                                    </select>
                                                    <Label>Cité</Label>
                                                    <select
                                                        className="form-control digits"
                                                        name="cite"
                                                        onChange={(e) => onChange(e)}
                                                    >
                                                        <option value="" disabled defaultValue hidden>
                                                            ----------
                                                        </option>
                                                        {citeData &&
                                                            citeData.cites.map((cite) => (
                                                                <option key={cite.id} value={cite.id}>
                                                                    {cite.name}
                                                                </option>
                                                            ))}
                                                    </select>
                                                    <ModalFooter>
                                                        <button
                                                            onClick={(e) => {
                                                                onSubmitadresse(e);
                                                                setModal(!modal);
                                                            }}
                                                            type="submit "
                                                            className="p-2 btn-info rounded"
                                                        >
                                                            Confirm
                                                        </button>
                                                    </ModalFooter>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </ModalBody>
                                </Modal>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
        ;
};
GestionColi.propTypes = {
    deleteColisG: PropTypes.func.isRequired,
    getColisLivreur: PropTypes.func.isRequired,
    getColisP: PropTypes.func.isRequired,
    getGestionsLivreur: PropTypes.func.isRequired,
    getColis: PropTypes.func.isRequired,
    colisData: PropTypes.object.isRequired,
    gestionData: PropTypes.object.isRequired,
    getDeligation: PropTypes.func.isRequired,
    getVille: PropTypes.func.isRequired,
    getCite: PropTypes.func.isRequired,
    addNewadresse: PropTypes.func.isRequired,
};

const mapstatetoprops = (state) => ({
    colisData: state.reducerc,
    gestionData: state.reducerc,
    deligationData: state.reducerc,
    villeData: state.reducerc,
    citeData: state.reducerc,
});

export default connect(mapstatetoprops, {
    getColis,
    deleteColisG,
    getColisP,
    getGestionsLivreur,
    getVille,
    getCite,
    getDeligation,
    getColisLivreur,
    addNewadresse,
})(withRouter(GestionColi));
