import React, {Fragment, useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import Alert from "../../redux/Alert";
import {connect, useDispatch} from "react-redux";
import {getCurrentCompte, updatecomptep,} from "../compte/action";
import Breadcrumb from "../../layout/breadcrumb";
import Error404 from "../../pages/errors/error404";
import {Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Row,} from "reactstrap";
import AppContext from "../../context/Context";
import {setAlert, setAlertD} from "../../redux/alert/action";

const Profile = ({
                     getCurrentCompte,
                     compte: {compte, loading},
                 }) => {
    const {
        config: {user_id, group, avatar},
        setConfig
    } = useContext(AppContext);
    const [formData, setFormData] = useState({
        username: "",
        email: "",
        first_name: "",
        last_name: "",
        avatar: null,
        fournisseur: "",
        is_active: "",
        number: "",
        password: "",
    });
    const id = user_id;
    useEffect(() => {
        getCurrentCompte(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [taptopStyle, setTapTopStyle] = useState("none");
    const executeScroll = () => {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    };

    const dispatch = useDispatch()

    const handleScroll = () => {
        if (window.scrollY > 600) {
            setTapTopStyle("block");
        } else {
            setTapTopStyle("none");
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        handleScroll();
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [taptopStyle]);

    const onChange = (e) =>
        setFormData({...formData, [e.target.name]: e.target.value});
    const onChangeimg = (e) => {
        setFormData({...formData, avatar: e.target.files[0]});
    };

    useEffect(() => {
        setFormData({
            username: loading || !compte?.username ? "" : compte.username,
            email: loading || !compte?.email ? "" : compte.email,
            first_name: loading || !compte?.first_name ? "" : compte.first_name,
            last_name: loading || !compte?.last_name ? "" : compte.last_name,
            fournisseur: loading || !compte?.fournisseur ? "" : compte.fournisseur,
            is_active: loading || !compte?.is_active ? "" : compte.is_active,
            number: loading || !compte?.number ? "" : compte.number,
            password: loading || !compte?.password ? "" : compte.password,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);
    const onSubmit = (e) => {
        e.preventDefault();
        const fd = new FormData();
        fd.append("email", formData.email);
        fd.append("username", formData.username);
        fd.append("first_name", formData.first_name);
        fd.append("last_name", formData.last_name);
        if (formData.password) {
            fd.append("password", formData.password);
        }
        fd.append("fournisseur", formData.fournisseur);
        fd.append("is_active", formData.is_active);
        fd.append("number", formData.number);
        if (formData.avatar) {
            fd.append("avatar", formData.avatar);
        }
        console.log(formData.avatar)
        updatecomptep(fd, id).then(r => {
            if (r.data?.avatar)
                setConfig('avatar', r.data?.avatar)
            dispatch(setAlert("User Successfully Updated", "success"));
        })
            .catch(error => {
                    const errors = error.response.data;
                    if (errors) {
                        dispatch(
                            setAlertD(
                                (errors.email ? "Email: " + errors.email + " " : "") +
                                (errors.last_name ? "Nom: " + errors.last_name + " " : "") +
                                (errors.first_name
                                    ? "Prénom: " + errors.first_name + " "
                                    : ""),
                                "danger"
                            )
                        );
                    }
            });
    };
    let user_groups_name = group;

    const onChangeCheck = (e) => {
        if (e.target.checked) {
            document.getElementById("password").style.display = "block";
        } else {
            document.getElementById("password").style.display = "none";
        }
    };
    return (
        <Fragment>
            <Breadcrumb parent="Profile"/>
            <Container fluid={true}>
                {id && id === user_id && user_groups_name !== 'personnel' ? (
                    <Row>
                        <Col sm="12">
                            <Form className="theme-form" onSubmit={(e) => onSubmit(e)}>
                                <Row>
                                    <Card className="profile-img">
                                        <CardBody>
                                            {compte && compte.avatar != null ? (
                                                <img
                                                    className="profile-img-circle"
                                                    src={avatar}
                                                    alt=""
                                                />
                                            ) : (
                                                <img
                                                    className="profile-img-circle"
                                                    src={require(`./profile.jpg`)}
                                                    alt=""
                                                />
                                            )}{" "}
                                            <div className="round">
                                                <Input
                                                    className="form-control"
                                                    type="file"
                                                    name="avatar"
                                                    accept="image/jpeg,image/png,image/gif"
                                                    onChange={(e) => {
                                                        onChangeimg(e);
                                                    }}
                                                    defaultValue={formData.avatar}
                                                />
                                                <i className="fa fa-camera"></i>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Row>
                                <Alert/>
                                <Row>
                                    <Col sm="7">
                                        <Card>
                                            <CardHeader>
                                                <h5>Paramètres Profile</h5>
                                            </CardHeader>

                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>
                                                                {" "}
                                                                {compte && compte.username ? (
                                                                    <h6>Utilisateur: {compte.username}</h6>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Email</Label>
                                                            <Input
                                                                className="form-control"
                                                                type="text"
                                                                placeholder="Email"
                                                                name="email"
                                                                value={formData.email}
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Nom</Label>
                                                            <Input
                                                                className="form-control"
                                                                type="text"
                                                                placeholder="Nom"
                                                                name="last_name"
                                                                value={formData.last_name}
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Prénom</Label>
                                                            <Input
                                                                className="form-control"
                                                                type="text"
                                                                name="first_name"
                                                                placeholder="Prénom"
                                                                value={formData.first_name}
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Téléphone</Label>
                                                            <Input
                                                                className="form-control"
                                                                type="number"
                                                                name="number"
                                                                placeholder="Téléphone"
                                                                value={formData.number}
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col sm="5">
                                        <Card>
                                            <CardHeader>
                                                <h5>Mot de passe</h5>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Input
                                                                className="ml-2"
                                                                type="checkbox"
                                                                id="checkPass"
                                                                onChange={(e) => onChangeCheck(e)}
                                                            />
                                                            <label className="ml-4">
                                                                Modifier le mot de passe ?{" "}
                                                            </label>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row id="password" style={{display: "none"}}>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Mot de passe</Label>
                                                            <Input
                                                                className="form-control"
                                                                type="password"
                                                                name="password"
                                                                placeholder="********"
                                                                value={formData.password}
                                                                onChange={(e) => onChange(e)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup className="mb-0">
                                            <button
                                                type="submit"
                                                onClick={() => executeScroll()}
                                                className="btn btn-success mr-3"
                                            >
                                                Save
                                            </button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                            <br/>
                            <br/>
                        </Col>
                    </Row>
                ) : (
                    <Error404/>
                )}
            </Container>
        </Fragment>
    );
};
Profile.propTypes = {
    getCurrentCompte: PropTypes.func.isRequired,
    compte: PropTypes.object.isRequired,
};

const mapstatetoprops = (state) => ({
    compte: state.reducercp,
});
export default connect(mapstatetoprops, {
    updatecomptep,
    getCurrentCompte,
})(Profile);
