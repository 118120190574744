
import { setAuthToken, api } from "../../utils/backend.instance";
import {
  GET_GROUPS,
  GROUP_LOADING,
  GROUP_ERROR,
} from "../../redux/actionTypes";


export const getGroups = (params) => async (dispatch) => {
  dispatch({ type: GROUP_LOADING });
  if (localStorage.access) {
    setAuthToken(localStorage.access);
  }
  try {
    const res = await api.get(`/accounts/group/?${params.toString()}`);

    dispatch({
      type: GET_GROUPS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GROUP_ERROR,
      payload: { msg: err.response.statusText, status: err.responses },
    });
  }
};

