import React, {Fragment, useEffect, useState, useMemo} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import logo from "./decharge.png";
import Barcode from "react-barcode";
import "./print.scss";
import {Row, Col, Card, CardBody, CardFooter} from "reactstrap";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import {getColisByIdsP, updateColisBulk, updatecolisPrint} from "../colis/action";

const Printdecharge = ({colisData}) => {
    const [colis, setColis] = useState([]);
    const dispatch = useDispatch();

    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };
    let query = useQuery();

    useEffect(() => {
        dispatch(getColisByIdsP(query));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    useEffect(() => {
        if (colisData.colisP) {
            setColis(colisData.colisP);
        }
    }, [colisData.colisP, colisData.loading, colis]);


    let creation = new Date()
    useEffect(() => {
        dispatch(updateColisBulk(query))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])
    return (
        <Fragment>
            <div>
                <Row>

                    <Col sm="12" >
                        {colis?.map((D) => (
                                <>
                                    <Col className=" mt-4">
                                        <Row
                                            style={{
                                                border: "2px solid #000",
                                            }}
                                        >
                                            <Col
                                                sm="6"
                                                style={{
                                                    border: "2px solid #000",
                                                }}
                                            >
                                                <img className="imagedecharge" id="logo" src={logo} alt=""/>
                                            </Col>
                                            <Col
                                                sm="6"
                                                style={{
                                                    border: "2px solid #000",
                                                }}
                                            >
                                                {D.livreur_name === 'AM' ? (
                                                    <>
                                                    <h2 style={{fontSize: "26pt"}}>{D.livreur_name} {'>>'} N°: {D.printable_tracking_code ? D.printable_tracking_code : D.tracking_code}</h2>
                                                    <Barcode width="3" displayValue={false} height="60"
                                                             value={D.printable_tracking_code ? D.printable_tracking_code : D.tracking_code}/>
                                                    </>
                                                ):(
                                                    <>
                                                    <h2 style={{fontSize: "22pt"}}>Bon de livraison
                                                    N°: {D.code_a_barre}</h2>
                                                    <Barcode width="3" displayValue={false} height="60"
                                                         value={D.id}/>
                                                    </>
                                                )}
                                                
                                            </Col>
                                        </Row>
                                        <Row
                                            style={{
                                                border: "2px solid #000",
                                            }}
                                        >
                                            <Col
                                                style={{
                                                    border: "2px solid #000",
                                                }}
                                                sm="6"
                                            >
                                                <Row className="m-2">
                                                    <h2 style={{fontSize: "26pt"}}>EXPÉDITEUR ➔ </h2>
                                                </Row>
                                                <Row className="m-2">
                                                    <h3 style={{fontSize: "22pt"}}>Nom:</h3>&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <h3 className={"wrapText"} style={{fontSize: "22pt"}}>{D.fournisseur_name}</h3>
                                                </Row>
                                                <Row className="m-2">
                                                    {" "}
                                                    <h3 style={{fontSize: "22pt"}}>Téléphone:</h3>&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <h3 style={{fontSize: "22pt"}}>{D.fournisseur_tel}</h3>
                                                </Row>
                                                <Row className="ml-2">
                                                    <h3 style={{fontSize: "22pt"}}>Adresse: </h3>&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <h3 className={"wrapText"} style={{fontSize: "22pt", whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>{D.expediteur_cite_name}, {D.expediteur_ville_name}, {D.expediteur_delegation_name}, {D.expiditeur_zipcode}</h3>
                                                </Row>
                                                <Row className="ml-2">
                                                    <h3 style={{fontSize: "22pt"}}>MF: </h3>&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <h3 className={"wrapText"} style={{fontSize: "22pt", whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>{D.mf}</h3>
                                                </Row>
                                                
                                            </Col>
                                            <Col
                                                style={{
                                                    border: "2px solid #000",
                                                }}
                                                sm="6"
                                            >
                                                <Row className="m-2">
                                                    <h2 style={{fontSize: "26pt"}}>DESTINATAIRE ➔</h2> &nbsp;&nbsp;
                                                </Row>
                                                <Row className="m-2">
                                                    <h3 style={{fontSize: "22pt"}}>Nom:</h3> &nbsp;&nbsp;
                                                    <h3 className={"wrapText"} style={{fontSize: "22pt"}}> {D.client_name}</h3>
                                                </Row>
                                                <Row className="m-2">
                                                    <h3 style={{fontSize: "22pt"}}>Téléphone:</h3> &nbsp;&nbsp;
                                                    <h3 style={{fontSize: "22pt"}}> {D.client_telephone_1}</h3>
                                                </Row>
                                                <Row className="ml-2">
                                                    <h3 style={{fontSize: "22pt"}}>Adresse
                                                        :</h3>&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <h3 className={"wrapText"} style={{fontSize: "22pt"}}>
                                                        {" "}
                                                        {D.client_address},{D.cite_name},{D.delegation_name}
                                                    </h3>
                                                </Row>
                                                <Row className="ml-2">
                                                    <h3 style={{fontSize: "22pt"}}>Ville :</h3>&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <h3 style={{fontSize: "22pt"}}>
                                                        {" "}
                                                        {D.ville_name}
                                                    </h3>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row
                                            style={{
                                                border: "2px solid #000",
                                            }}
                                        >
                                            <table striped style={{
                                                border: "2px solid #000",
                                                width: "100%"
                                            }}>
                                                <thead>
                                                <tr>
                                                    <th className="text-center" striped style={{
                                                        border: "2px solid #000",
                                                    }}><h4 style={{fontSize: "18pt"}} className="m-2">Remarque</h4>
                                                    </th>
                                                    <th className="text-center" striped style={{
                                                        border: "2px solid #000",
                                                    }}><h4 style={{fontSize: "18pt"}} className="m-2">Service</h4>
                                                    </th>
                                                    <th className="text-center" striped style={{
                                                        border: "2px solid #000",
                                                    }}><h4 style={{fontSize: "18pt"}} className="m-2">Date création</h4>
                                                    </th>
                                                    <th className="text-center" striped style={{
                                                        border: "2px solid #000",
                                                    }}><h4 style={{fontSize: "18pt"}} className="m-2">P.U.</h4>
                                                    </th>
                                                    <th className="text-center" striped style={{
                                                        border: "2px solid #000",
                                                    }}><h4 style={{fontSize: "18pt"}} className="m-2">Quantité</h4>
                                                    </th>
                                                    <th className="text-center" striped style={{
                                                        border: "2px solid #000",
                                                    }}><h4 style={{fontSize: "18pt"}} className="m-2">Total</h4>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td className="text-center" striped style={{
                                                        border: "2px solid #000",
                                                    }}>
                                                        {D.remarque_echange &&
                                                            <h4 style={{fontSize: "18pt"}}>{D.remarque_echange}</h4>}
                                                        {D.remarque &&
                                                            <h4 style={{fontSize: "18pt"}}>{D.remarque}</h4>}
                                                    </td>
                                                    {D.livreur_name === 'AM' && D.service === 'livraison' && D.prix === 0 ? (
                                                        <td className="text-center" striped style={{
                                                            border: "2px solid #000",
                                                        }}><h4 style={{fontSize: "18pt"}}>null</h4></td>
                                                    ): D.livreur_name === 'AM' && D.service === 'livraison' && D.prix !== 0 ?(
                                                        <td className="text-center" striped style={{
                                                            border: "2px solid #000",
                                                        }}><h4 style={{fontSize: "18pt"}}>CODS</h4></td>
                                                    ): D.livreur_name === 'AM' && D.service === 'echange' && D.prix === 0 ?(
                                                        <td 
                                                            className="text-center"
                                                            striped style={{
                                                            border: "2px solid #000",
                                                        }}>
                                                            <h4 style={{fontSize: "18pt"}}>RTRN</h4>
                                                        </td>
                                                    ): D.livreur_name === 'AM' && D.service === 'echange' && D.prix !== 0 ?(
                                                        <td className="text-center" striped style={{
                                                            border: "2px solid #000",
                                                        }}><h4 style={{fontSize: "18pt"}}>CODS,RTRN</h4></td>
                                                    ):(
                                                        <td className="text-center" striped style={{
                                                            border: "2px solid #000",
                                                        }}><h4 style={{fontSize: "18pt"}}>{D.service}</h4></td>
                                                    )
                                                    }
                                                    <td className="text-center" striped style={{
                                                        border: "2px solid #000",
                                                    }}><h4 style={{fontSize: "18pt"}}>{D.created_at}</h4></td>
                                                    <td className="text-center" striped style={{
                                                        border: "2px solid #000",
                                                    }}><h4
                                                        style={{fontSize: "18pt"}}>{parseFloat(D.prix / 1.19).toFixed(2)}</h4>
                                                    </td>
                                                    <td className="text-center" striped style={{
                                                        border: "2px solid #000",
                                                    }}><h4 style={{fontSize: "18pt"}}>1</h4></td>
                                                    <td className="text-center" striped style={{
                                                        border: "2px solid #000",
                                                    }}><h4
                                                        style={{fontSize: "18pt"}}>{parseFloat(D.prix / 1.19).toFixed(2)}</h4>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th colSpan={"4"} className="text-center" striped style={{
                                                        border: "2px solid #000",
                                                    }}><h4 style={{fontSize: "18pt"}}>Référence (produit)</h4></th>
                                                    <th className="text-right" striped style={{
                                                        border: "2px solid #000",
                                                    }}><h4 style={{fontSize: "18pt"}}>Total</h4></th>
                                                    <td className="text-center" striped style={{
                                                        border: "2px solid #000",
                                                    }}><h4
                                                        style={{fontSize: "18pt"}}>{parseFloat(D.prix / 1.19).toFixed(2)}</h4>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={"4"} rowSpan={"2"} className="text-center" striped
                                                        style={{
                                                            border: "2px solid #000",
                                                        }}><h4 className={"wrapTextProduct"} style={{fontSize: "18pt"}}>{D.product_name ? D.product_name : D.products.map(product => <>{product.product.reference}, </>)}</h4></td>
                                                    <th className="text-right" striped style={{
                                                        border: "2px solid #000",
                                                    }}><h4 style={{fontSize: "18pt"}}>TVA</h4></th>
                                                    <td className="text-center" striped style={{
                                                        border: "2px solid #000",
                                                    }}><h4 style={{fontSize: "18pt"}}>19%</h4></td>
                                                </tr>
                                                <tr>
                                                    <th className="text-right" striped style={{
                                                        border: "2px solid #000",
                                                    }}><h4 style={{fontSize: "18pt"}}>Total</h4></th>
                                                    <td className="text-center" striped style={{
                                                        border: "2px solid #000",
                                                    }}><h4 style={{fontSize: "18pt"}}>{D.prix}</h4></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </Row>
                                        <Row
                                            style={{
                                                border: "3px solid #000",
                                            }}
                                        >
                                            <Col
                                                sm="4"
                                                style={{
                                                    border: "2px solid #000",
                                                }}
                                            >
                                                <Row>
                                                    <Col sm="2"></Col>
                                                    <h3 style={{fontSize: "22pt"}}
                                                        className="text-center">Cachet/Signature</h3>
                                                </Row>
                                            </Col>
                                            <Col
                                                style={{
                                                    border: "2px solid #000",
                                                }}
                                            >
                                                {D.livreur_name === 'AM' ? (
                                                    <>
                                                    <h2 style={{fontSize: "22pt"}}>Bon de livraison
                                                    N°: {D.code_a_barre}</h2>
                                                    <Barcode width="3" displayValue={false} height="60"
                                                         value={D.id}/>
                                                    </>
                                                ) : (
                                                    <>
                                                    <h2 style={{fontSize: "26pt"}}>{D.livreur_name} {'>>'} N°: {D.printable_tracking_code ? D.printable_tracking_code : D.tracking_code}</h2>
                                                    <Barcode width="3" displayValue={false} height="60"
                                                             value={D.printable_tracking_code ? D.printable_tracking_code : D.tracking_code}/>
                                                    </>
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <div className={"pagebreaka"}></div>
                                </>
                            ))}
                    </Col>
                </Row>

                <Row className={"pagebreak"}>
                    <Col sm="12">
                        <Card className={"print"}>
                            <CardBody>
                                <Row>
                                    <div className="col-md-5 offset-md-1">
                                        <img
                                            className="imagedecharge"
                                            src={require("./decharge.png")}
                                            alt=""
                                        />
                                        <h6>TEL: 29812970</h6>
                                        <h6>Adresse: Zaraani kalaa kbira est, Sousse, Tunisie </h6>
                                    </div>
                                    {colisData && colisData.colisP[0] ? (
                                        <div className="col-md-5  m-auto text-center">
                                            <h6>
                                                Fournisseur: {colisData.colisP[0].fournisseur_name}{" "}
                                            </h6>
                                            <h6>Tél: {colisData.colisP[0].fournisseur_tel} </h6>
                                            <h6>Date de l'impression: {creation.toDateString()}</h6>
                                            <h6>Nombre de bon de livraison: {colisData.colisP.length}</h6>
                                        </div>
                                    ) : (
                                        ""
                                    )}

                                    <div className="col-lg-12 table-responsive">
                                        <div
                                            id="example_wrapper"
                                            className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
                                        >
                                            <table
                                                className="table align-items-center table-bordered dataTable no-footer"
                                                id="example"
                                                role="grid"
                                            >
                                                <thead className="thead-light">
                                                <tr role="row">
                                                    <th
                                                        className="sorting_disabled"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                    >
                                                        Code à barre
                                                    </th>
                                                    <th
                                                        className="sorting_disabled"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                    >
                                                        Client
                                                    </th>
                                                    <th
                                                        className="sorting_disabled"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                    >
                                                        Ville
                                                    </th>
                                                    <th
                                                        className="sorting_disabled"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                    >
                                                        Adresse pickup
                                                    </th>
                                                    <th
                                                        className="sorting_disabled"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                    >
                                                        Adresse client
                                                    </th>
                                                    <th
                                                        className="sorting_disabled"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                    >
                                                        Service
                                                    </th>
                                                    <th
                                                        className="sorting_disabled"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                    >
                                                        Statut
                                                    </th>
                                                    <th
                                                        className="text-center sorting_disabled"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                    >
                                                        COD
                                                    </th>
                                                    <th
                                                        className="text-center sorting_disabled"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                    >
                                                        Pointage
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {colis?.map((c) => (
                                                        <tr key={c.id}>
                                                            <td>
                                                                <Barcode
                                                                    width="1"
                                                                    height="30"
                                                                    value={c.id}
                                                                    displayValue={false}
                                                                />
                                                                <p style={{marginLeft: 15}}>{c.code_a_barre}</p>
                                                            </td>
                                                            <td>{c.client_name}</td>
                                                            <td>{c.ville_name}</td>
                                                            <td>{c.expediteur_cite_name}, {c.expediteur_ville_name}, {c.expediteur_delegation_name}, {c.expiditeur_zipcode}</td>
                                                            <td>{c.client_address},{c.cite_name},{c.delegation_name}</td>
                                                            {c.livreur_name === 'AM' && c.service === 'livraison' && c.prix === 0 ? (
                                                                <td>null</td>
                                                            ): c.livreur_name === 'AM' && c.service === 'livraison' && c.prix !== 0 ?(
                                                                <td>CODS</td>
                                                            ): c.livreur_name === 'AM' && c.service === 'echange' && c.prix === 0 ?(
                                                                <td>RTRN</td>
                                                            ): c.livreur_name === 'AM' && c.service === 'echange' && c.prix !== 0 ?(
                                                                <td>CODS,RTRN</td>
                                                            ):(
                                                                <td>{c.service}</td>
                                                            )
                                                            }
                                                            <td>{c.status_display}</td>
                                                            <td>{c.prix}</td>
                                                            <td></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <Col sm="1"></Col>
                                    <Col sm="2">
                                        <Row>Date collecte</Row>
                                        <Row>.......................</Row>
                                    </Col>
                                    <Col sm="1"></Col>
                                    <Col sm="2">
                                        <Row>Nom chauffeur</Row>
                                        <Row>...........................</Row>
                                    </Col>
                                    <Col sm="1"></Col>
                                    <Col sm="2">
                                        <Row>Signature client</Row>
                                        <Row>.............................</Row>
                                    </Col>
                                    <Col sm="1"></Col>
                                    <Col sm="2">
                                        <Row>Signature chauffeur</Row>
                                        <Row>...................................</Row>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Fragment>
    );
};

Printdecharge.propTypes = {
    getColisByIdsP: PropTypes.func.isRequired,
    updateColisBulk: PropTypes.func.isRequired,
    colisData: PropTypes.object.isRequired,
    updatecolisPrint: PropTypes.func.isRequired,
};

const mapstatetoprops = (state) => ({
    colisData: state.reducerc,
});

export default connect(mapstatetoprops, {updateColisBulk, getColisByIdsP, updatecolisPrint})(
    Printdecharge
);
