import {
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT,
} from "../actionTypes";

const initialstate = {
    refresh: localStorage.getItem("refresh"),
    access: localStorage.getItem("access"),
    username: localStorage.getItem("username"),
    stock_service: localStorage.getItem("stock_service"),
    accept_terms: localStorage.getItem("accept_terms"),
    id: localStorage.getItem("user_id"),
    fournisseur: localStorage.getItem("fournisseur"),
    groups_name: localStorage.getItem("user_groups_name"),
    avatar: localStorage.getItem("avatar"),
    isAuthenticated: null,
    loading: true,
    user: null,
    img: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialstate, action) {
    const {type, payload} = action;
    switch (type) {
        case USER_LOADED:
            if (localStorage.access) {
                localStorage.setItem("access", payload.access);
                localStorage.setItem("user_id", payload.user.id);
                localStorage.setItem("stock_service", payload.stock_service);
                localStorage.setItem("user_groups_name", payload.groups_name);
                localStorage.setItem("avatar", payload.user.fournisseur_image_path);
                localStorage.setItem("username", payload.user.username);
                localStorage.setItem("fournisseur", payload.user.fournisseur);
                localStorage.setItem("accept_terms", payload.accept_terms);
            }
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user: payload,
                img: payload.user
            };

        case AUTH_ERROR:
        case LOGIN_FAIL:
        case LOGOUT:
            localStorage.removeItem("access");
            localStorage.removeItem("refresh");
            return {
                ...state,
                refresh: null,
                access: null,
                isAuthenticated: false,
                loading: false,
                user: null,
            };

        case LOGIN_SUCCESS:
            localStorage.setItem("access", payload.access);
            localStorage.setItem("refresh", payload.refresh);
            localStorage.setItem("user_id", payload.user.id);
            localStorage.setItem("stock_service", payload.stock_service);
            localStorage.setItem("user_groups_name", payload.groups_name);
            localStorage.setItem("avatar", payload.user.fournisseur_image_path);
            localStorage.setItem("username", payload.user.username);
            localStorage.setItem("fournisseur", payload.user.fournisseur);
            localStorage.setItem("accept_terms", payload.accept_terms);
            return {
                ...state,
                ...payload,
                isAuthenticated: true,
                loading: false,
                user: payload,
                img: payload.user
            };
        default:
            return state;
    }
}
