import React, {Fragment, useCallback, useContext, useEffect, useMemo, useState} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {Card, CardBody, Col, Container, Row, Table} from "reactstrap";
import Alert from "../../redux/Alert";
import Spinner from "../colis/spinner.gif";
import {Link, useHistory, useLocation, withRouter} from "react-router-dom";
import PaginationWrapper from "../pagination/pagination";
import Error404 from "../../pages/errors/error404";
import {GetAvis} from "./actions";
import Select, {components} from "react-select";
import {getFournisseurs} from "../Fournisseurs/action";
import {getHistory} from "../colis/action";
import {connect, useDispatch} from "react-redux";
import PropTypes from "prop-types";
import {debounce} from "chart.js/helpers";
import AppContext from "../../context/Context";

const Avis = ({fournisseurData}) => {
    const [avis, setAvis] = useState([]);
    const [loading, setLoading] = useState(false)
    const [selectedfournisseur, setSelectedFournisseur] = useState([]);
    const [currentFournisseurPage, setCurrentFournisseurPage] = useState(1)
    const {
        config: {group}
    } = useContext(AppContext)
    const dispatch = useDispatch();
    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };
    const history = useHistory()

    let query = useQuery();
    let Fquery = useQuery();
    let pagenumber = query.get("page") ? query.get("page") : 1;
    const Navigate = (page) => {
        query.set("page", `${Number(page)}`)

        window.location.replace(`/avis?${query}`);
    };

    let user_groups_name = group;
    let count = avis.length;

    const fetchAvis = () => GetAvis(query).then(res => setAvis(res.results));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedLoadItems = useCallback(debounce(fetchAvis, 600), [])

    const getValSearch = () => {
        let params = new URLSearchParams(document.location.search);
        return params.get("search");
    };

    const NoOptionsMessage = props => {
        return (
            <components.NoOptionsMessage {...props}>
                <div className="text-center">
                    <img src={Spinner} style={{width: "10%"}} alt=""/>
                </div>
            </components.NoOptionsMessage>
        );
    };

    useEffect(() => {
        setLoading(true)
        GetAvis(query)
            .then(res => setAvis(res.results))
        setLoading(false)
    }, [query])

    useEffect(() => {
        Fquery.set("page_size", "50")
        Fquery.set("page", currentFournisseurPage.toString())
        dispatch(getFournisseurs(Fquery))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFournisseurPage]);

    const onChangeFournisseur = (e) => {
        setLoading(true)
        setSelectedFournisseur(e);
        const fournisseurid = e.value;

        query.set("fournisseur_id", fournisseurid)

        GetAvis(query)
            .then(res => setAvis(res.results))
        setLoading(false)
    };

    const data = [];
    fournisseurData &&
    fournisseurData.fournisseurs.map((F) =>
        data.push({
            value: parseInt(F.id),
            label: F.name,
        })
    );

    return (
        <Fragment>
            <Breadcrumb parent="Avis" title={`Avis`}/>
            {user_groups_name && user_groups_name.indexOf("admin") !== -1 ? (
                <Container fluid={true}>
                    <Alert/>
                    <Row>
                        <Col sm="12">
                            <Card>
                                {/* {datacolis.colis.length > 0 ? () : ()} */}

                                <CardBody>
                                    <Col sm="12">
                                        <br/>
                                        <br/>
                                        <Row>
                                            <Col md={4}>
                                                <Link
                                                    className="btn  m-2 "
                                                    style={{backgroundColor: "#FDF203", padding: "10px 20px"}}
                                                    to="/avis/new"
                                                >
                                                    {"Ajouter"}
                                                </Link>
                                            </Col>
                                            <Col md={4}>
                                                <Select
                                                    value={selectedfournisseur}
                                                    options={data}
                                                    placeholder={"Choisir Un Fournisseur"}
                                                    components={{NoOptionsMessage}}
                                                    onChange={(e) => {
                                                        onChangeFournisseur(e);
                                                        history.push({search: query.toString()})
                                                    }}
                                                    onInputChange={(e) => {
                                                        Fquery.set('search', e);
                                                        dispatch(getFournisseurs(Fquery))
                                                    }}
                                                    onMenuScrollToBottom={(e) => {
                                                        console.log(e)
                                                        setCurrentFournisseurPage(page => page + 1)
                                                    }}
                                                />
                                            </Col>
                                            <Col></Col>
                                            <div className="search-5">
                                                <h6>Rechercher:</h6>
                                            </div>

                                            <input
                                                className=" border search "
                                                style={{borderRadius: "7px"}}
                                                type="search"
                                                placeholder="Search..."
                                                name="search"
                                                defaultValue={getValSearch()}
                                                onChange={(e) => {
                                                    query.set("search", e.target.value)
                                                    delayedLoadItems(query)
                                                }}
                                            />
                                        </Row>
                                        <Card>
                                            <div className="card-block row">
                                                <Col sm="12">

                                                    <div className="table-responsive">
                                                        {loading ? (
                                                            <div className="text-center">
                                                                <img src={Spinner} style={{width: "10%"}} alt=""/>
                                                            </div>
                                                        ) : (
                                                            <Table striped>
                                                                <thead>
                                                                <tr>
                                                                    <th scope="col"></th>
                                                                    <th>{"Utilisateur"}</th>
                                                                    <th>{"Fournisseur"}</th>
                                                                    <th>{"Rate"}</th>
                                                                    <th>{"Avis"}</th>
                                                                    <th>{"Date"}</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {avis &&
                                                                    avis.map((b) => (
                                                                        <tr key={b.id}>
                                                                            <td></td>
                                                                            <td>{b.username}</td>
                                                                            <td>{b.fournisseur_name}</td>
                                                                            <td>{b.rating} étoiles</td>
                                                                            <td>{b.description}</td>
                                                                            <td>{b.created_at}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                        )}
                                                    </div>
                                                </Col>
                                            </div>
                                        </Card>
                                    </Col>{" "}
                                    <Row>
                                        <Col sm="10"></Col>
                                        {loading ? "" :
                                            <Col>
                                                <PaginationWrapper
                                                    name="Avis"
                                                    length={avis.length}
                                                    count={count}
                                                    currentPage={pagenumber}
                                                    onNavigate={Navigate}
                                                />
                                            </Col>}
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            ) : (
                <Error404 page="Avis"/>
            )}
        </Fragment>
    )
}
Avis.propTypes = {
    getHistory: PropTypes.func.isRequired,
    getFournisseurs: PropTypes.func.isRequired,
    historyData: PropTypes.object.isRequired,
};
const mapstatetoprops = (state) => ({
    historyData: state.reducerc,
    fournisseurData: state.reducerf,
});

export default connect(mapstatetoprops, {
    getHistory,
    getFournisseurs,
})(withRouter(Avis));