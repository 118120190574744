import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from "../../../assets/images/logo/logo_dark.png";
import "../css/Navbar.css";
import lel from "../images/book-a-demo.png";
import {useState} from 'react';
import {Link} from 'react-router-dom';
import {
    Card,
    CardBody,
    Col,
    Form,
    Row,
    Modal,
    ModalBody,
    Input,
    FormGroup
} from "reactstrap";

function Nava({addNewDemande}) {
    const [modal, setModal] = useState(false);
    const [formData, setFormData] = useState({
        commande: 0, first_name: "", last_name: "", type: "Demo", demo: "", number: "", email: "", entreprise: "",
    });
    const onSubmit = (e) => {
        e.preventDefault();
        addNewDemande(formData);
    };
    const onChange = (e) => {
        e.preventDefault();
        setFormData({...formData, [e.target.name]: e.target.value});
    };
    return (

        <Navbar collapseOnSelect expand="lg" style={{backgroundColor: "white"}} variant="light">
            <Container>
                <Navbar.Brand><img src={logo} height={"50px"} style={{marginRight: "20px"}} alt={""}/></Navbar.Brand>

                {/*<Nav className='bookd'>*/}
                {/*    <Nav.Link onClick={() => setModal(true)} href="#deets" style={{*/}
                {/*        color: "#F2B707",*/}
                {/*        marginRight: "20px",*/}
                {/*        backgroundColor: "#193256",*/}
                {/*        borderRadius: '7px',*/}
                {/*        fontWeight: "600",*/}
                {/*        padding: "10px"*/}
                {/*    }}>Book a demo</Nav.Link>*/}
                {/*</Nav>*/}

                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto navv">
                        <Nav.Link href="/"><h6 style={{fontWeight: "600", color: "black"}}>Home</h6></Nav.Link>
                        <Nav.Link href="/about"><h6 style={{fontWeight: "600", color: "black"}}>Comment?</h6></Nav.Link>
                        <Nav.Link href="/services"><h6 style={{fontWeight: "600", color: "black"}}>Services</h6>
                        </Nav.Link>
                        <Nav.Link href="/partner"><h6 style={{fontWeight: "600", color: "black"}}>Contact </h6>
                            <Nav.Link href="/signin" className='loginr'>Login</Nav.Link>
                        </Nav.Link>
                    </Nav>

                </Navbar.Collapse>

                <div className='btns'>
                    <button onClick={() => setModal(true)} className='bookdemo'>Book a demo</button>
                    <Link to={"/signin"} className='login'>Login</Link>

                </div>


            </Container>
            <Modal
                centered
                size="xl"

                className="relative flex flex-col sm:justify-center items-center"
                isOpen={modal}
                toggle={() => setModal(!modal)}

            >
                <ModalBody style={{backgroundColor: "#F5F8FA", backgroundImage: `url("${lel}")`}}>
                    <Row>
                        <Col sm="5" style={{backgroundImage: `url("${lel}")`, backgroundSize: "cover", color: "white"}}>
                            <p className="m-2" style={{fontSize: "30px"}}>
                                Réserver une démo
                            </p>
                            <br/>
                            <p className="m-2" style={{fontSize: "15px"}}>
                                Beezit est la solution logistique tunisienne garantissant la
                                meilleure experience clients et augmentant vos ventes
                            </p>
                            <br/>
                            <li className="m-2">Accédez à une tarification simple</li>

                            <li className="m-2">
                                Accédez à une solution de livraison premier ordre
                            </li>
                            <li className="m-2"> Augmentez vos ventes</li>
                        </Col>

                        <Col>
                            <Card>
                                <CardBody>

                                    <Form onSubmit={(e) => onSubmit(e)}>
                                        <Row>
                                            <Col>
                                                {" "}
                                                <FormGroup>
                                                    <Input
                                                        style={{height: "50px", borderRadius: "10px"}}
                                                        type="text"
                                                        name="last_name"
                                                        placeholder="Nom*"
                                                        value={formData.last_name}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                {" "}
                                                <FormGroup>
                                                    <Input
                                                        style={{height: "50px", borderRadius: "10px"}}
                                                        type="text"
                                                        name="first_name"
                                                        placeholder="Prénom*"
                                                        value={formData.first_name}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {" "}
                                                <FormGroup>
                                                    <Input
                                                        style={{height: "50px", borderRadius: "10px"}}
                                                        type="number"
                                                        name="number"
                                                        placeholder="Numéro de téléphone*"
                                                        value={formData.number}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                {" "}
                                                <FormGroup>
                                                    <Input
                                                        style={{height: "50px", borderRadius: "10px"}}
                                                        type="emails"
                                                        name="email"
                                                        placeholder="Email*"
                                                        value={formData.email}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <FormGroup>
                                            <select
                                                style={{height: "50px", borderRadius: "10px"}}
                                                className="form-control digits "
                                                name="type"
                                                value={formData.type}
                                                onChange={(e) => onChange(e)}
                                            >
                                                <option value="" disabled selected hidden>
                                                    Vous vendez principalemens vos produits sur*
                                                </option>
                                                <option hidden> vous vendez sur ?</option>
                                                <option value={"website"}>Site web</option>
                                                <option value={"social_media"}>
                                                    Résaux socieaux{" "}
                                                </option>
                                            </select>
                                        </FormGroup>
                                        <p>
                                            Beezit a besoin des cordonnées que vous nous fournisser
                                            pour vous contacter au sujet de nos produits et
                                            services. Vous pouvez vous désabonner de ces
                                            communications à tout moment. Pour plus d'informations,
                                            veuillez consulter notre politique de confidentalité
                                        </p>
                                        <Row>
                                            <Col>
                                                <button
                                                    type="submit"
                                                    onClick={() => setModal(!modal)}
                                                    className="p-2 text-white ml-1 btn"
                                                    style={{
                                                        backgroundColor: "#193256",
                                                        width: "100%",
                                                        borderRadius: "20px",
                                                    }}
                                                >
                                                    Soumettre
                                                </button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="1"></Col>
                    </Row>
                </ModalBody>
            </Modal>
        </Navbar>

        //
    );
}

export default Nava;
