import { setAlert } from "../../redux/alert/action";
import { setAuthToken, api } from "../../utils/backend.instance";
import {
  GET_SECTEUR,
  GET_SECTEURS,
  SECTEUR_LOADING,
  SECTEUR_ERROR,
  UPDATE_SECTEUR,
} from "../../redux/actionTypes";
export const addNewSecteur =
  (formData, history) =>
  async (dispatch) => {
    if (localStorage.access) {
      setAuthToken(localStorage.access);
    }
    try {
      const res = await api.post("/fournisseur/secteur/", formData);
      dispatch({
        type: GET_SECTEUR,
        payload: res.data,
      });
      dispatch(setAlert("Secteur Successfully Created", "success"));
      history.push("/secteur-activite");
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }
      dispatch({
        type: SECTEUR_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  };

export const getSecteurs = (params) => async (dispatch) => {
  dispatch({ type: SECTEUR_LOADING });
  if (localStorage.access) {
    setAuthToken(localStorage.access);
  }
  try {
    const res = await api.get(`/fournisseur/secteur/?${params.toString()}`);

    dispatch({
      type: GET_SECTEURS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SECTEUR_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const deleteSecteur = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/fournisseur/secteur/${id}/`);

    dispatch({
      type: UPDATE_SECTEUR,
      payload: res.data.results,
    });

    dispatch(setAlert("Secteur Successfully Deleted", "success"));
    dispatch(getSecteurs);
  } catch (err) {
    dispatch({
      type: SECTEUR_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
