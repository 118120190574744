import {setAlert, setAlertD} from "../../redux/alert/action";
import {setAuthToken, api} from "../../utils/backend.instance";
import {
    GET_FINANCE,
    GET_FINANCES,
    GET_TOTAL_FINANCE,
    TOTAL_FINANCE_LOADING,
    TOTAL_FINANCE_ERROR,
    FINANCE_LOADING,
    FINANCE_ERROR,
    GET_FINANCESP,
    FINANCEP_LOADING,
    FINANCEP_ERROR,
} from "../../redux/actionTypes";

export const addNewFinance =
    (formData, history) =>
        async (dispatch) => {
            if (localStorage.access) {
                setAuthToken(localStorage.access);
            }
            try {

                const res = await api.post("/fournisseur/finance/", formData);
                dispatch({
                    type: GET_FINANCE,
                    payload: res.data,
                });
                dispatch(setAlert("Finance Successfully Created", "success"));
                history.push("/finance");
            } catch (err) {
                const errors = err.response.data;
                if (errors) {
                    dispatch(setAlertD("Fournisseur: " + errors.fournisseur, "danger"));
                }
                dispatch({
                    type: FINANCE_ERROR,
                    payload: {msg: err.response.data, status: err.response.status},
                });
            }
        };


export const getCurrentFinance = (id) => async (dispatch) => {
    dispatch({type: FINANCE_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(`/fournisseur/finance/${id}`);

        dispatch({
            type: GET_FINANCE,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: FINANCE_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getFinances = (params) => async (dispatch) => {
    dispatch({type: FINANCE_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }

    try {
        const res = await api.get(`/fournisseur/finance/?${params.toString()}`);

        dispatch({
            type: GET_FINANCES,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: FINANCE_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const geTotalFinances = (params) => async (dispatch) => {
    dispatch({type: TOTAL_FINANCE_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }

    try {
        const res = await api.get(`/fournisseur/totalfinance/?${params.toString()}`);

        dispatch({
            type: GET_TOTAL_FINANCE,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: TOTAL_FINANCE_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getFinancesP = (params) => async (dispatch) => {
    dispatch({type: FINANCEP_LOADING});
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }

    try {
        const res = await api.get(`/fournisseur/finance/?${params.toString()}`);

        dispatch({
            type: GET_FINANCESP,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: FINANCEP_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const GetFinanceByIds = (id) => async (dispatch) => {
    if (localStorage.access) {
        setAuthToken(localStorage.access);
    }
    try {
        const res = await api.get(`/fournisseur/finance/?fournisseur_id=${id}`);
        dispatch({
            type: GET_FINANCES,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: FINANCE_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};
