import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {getFournisseursP} from "./action";
import {connect, useDispatch} from "react-redux";
import { useLocation} from "react-router-dom";
import PropTypes from "prop-types";
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import Spinner from "../colis/spinner.gif";
import {Table} from "reactstrap";


const Exportfournisseur = ({fournisseurData}) => {
    const [alldata, searchData] = useState([]);
    const dispatch = useDispatch();

    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };


    let query = useQuery();
    useEffect(() => {
        if (fournisseurData.fournisseursP) {
            searchData(fournisseurData.fournisseursP);
        }
    }, [fournisseurData.fournisseursP, fournisseurData.loading, alldata]);


    ReactHTMLTableToExcel.format = (s, c) => {
        if (c && c["table"]) {
            const html = c.table;
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, "text/html");
            const rows = doc.querySelectorAll("thead tr th");
            for (const row of rows) {
                row.setAttribute(
                    "style",
                    "background: #B8CCE4"
                );
            }


            c.table = doc.querySelector("table").outerHTML;
        }

        return s.replace(/{(\w+)}/g, (m, p) => c[p]);
    };
    let length = fournisseurData.fournisseursP.length
    useEffect(() => {
        dispatch(getFournisseursP(query))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
       if (length > 0) {
           setTimeout(() => {
                window.close()
            }, 500)
        }
    }, [length])

    useEffect(() => {
        if (length > 0) {
            document.getElementById("excelexport").click()
        }
    }, [length])
    return (
        <Fragment>
            {fournisseurData && fournisseurData.loading ? <div className="text-center">
                <img src={Spinner} style={{width: "10%"}} alt=""/>
            </div> : ""
            }
            <div hidden>
                <ReactHTMLTableToExcel
                    table="example"
                    buttonText="EXCEL"
                    className="btn  m-2 btn-excel text-white"
                    filename={`Beezit - Fournisseurs`}
                    sheet={"Fournisseurs"}
                    id="excelexport"
                />
                <Table striped id="example">
                    <thead>
                    <tr>
                        <th>{"Raison"}</th>
                        <th>{'Responsable'}</th>
                        <th>{"Téléphone"}</th>
                        <th>{"RIB"}</th>
                        <th>{"Adresse"}</th>
                        <th>{"Frais de livraison"}</th>
                        <th>{"Frais de retour"}</th>
                    </tr>
                    </thead>
                    {fournisseurData &&
                        fournisseurData.fournisseursP.map((b) => (
                            <tbody key={b.id}>
                            <tr>
                                <td>{b.name}</td>
                                <td>{b.responsable}</td>
                                <td>{b.tel}</td>
                                <td>{b.rib}</td>
                                <td>{b.absolute_address}</td>
                                <td>{b.petit_livraison=== null ? "0" : b.petit_livraison}--{b.grand_livraison === null ? "0" :b.grand_livraison}</td>
                                <td>{b.petit_retour=== null ? "0" : b.petit_retour}--{b.grand_retour === null ? "0" :b.grand_retour}</td>
                            </tr>
                            </tbody>
                        ))}
                </Table>
            </div>
        </Fragment>
    )
}
Exportfournisseur.propTypes = {
    getColisP: PropTypes.func.isRequired,
    fournisseurData: PropTypes.object.isRequired,
}
const mapstatetoprops = (state) => ({
    fournisseurData: state.reducerf,
});

export default connect(mapstatetoprops, {getFournisseursP})(Exportfournisseur)