import React, {Fragment, useState, useMemo, useEffect} from "react";
import {connect, useDispatch} from "react-redux";
import {Link, useLocation, withRouter} from "react-router-dom";
import {deleteAdresse, getAdresses} from "./actions";
import Spinner from "../colis/spinner.gif";
import PropTypes from "prop-types";
import Breadcrumb from "../../layout/breadcrumb";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    InputGroupAddon,
    Row,
    Table,
} from "reactstrap";
import Alert from "../../redux/Alert";
import PaginationWrapper from "../pagination/pagination";
import Error404 from "../../pages/errors/error404";

const Adresse = ({adresseData, deleteAdresse}) => {
    const [alldata, searchData] = useState([]);
    const dispatch = useDispatch();
    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };

    let query = useQuery();

    useEffect(() => {
        dispatch(getAdresses(query));
    }, [dispatch,query]);

    useEffect(() => {
        if (adresseData.adresses) {
            searchData(adresseData.adresses);
        }
    }, [adresseData.adresses,adresseData.loading,alldata]);
    let pagenumber = query.get("page") ? query.get("page") : 1;
    const Navigate = (page) => {
        query.set("page", Number(page));

        window.location.replace(`/adresse?${query}`);
    };

    let user_groups_name = localStorage.getItem("user_groups_name");
    let count = adresseData.count;
    const getValSearch = () => {
        let params = new URLSearchParams(document.location.search);
        return params.get("search");
    };
    const onSearchQuery = (e) => {
        query.set("search", e.target.value)
        dispatch(getAdresses(query));
    };
    return (
        <Fragment>
            <Breadcrumb parent="Adresses" title={`Adresses`}/>
            { user_groups_name.indexOf("admin") !== -1 ||
                user_groups_name.indexOf("fournisseur") !== -1
                ? (
                <div>
                <Container fluid={true}>
                    <Alert/>
                    <Row>
                        <Col sm="12">
                            <Card>
                                {/* {datacolis.colis.length > 0 ? () : ()} */}

                                <CardBody>
                                    <Col sm="12">
                                        <Row>
                                            <Col sm="4"></Col>
                                        </Row>
                                        <br/>
                                        <br/>
                                        <Row>
                                                <Col>
                                                    <Form className="theme-form">
                                                        <InputGroupAddon addonType="append">
                                                            <Col>
                                                                <div>
                                                                    <Link
                                                                        className="btn  m-2 "
                                                                        style={{backgroundColor: "#FDF203"}}
                                                                        to="/adresse/new"
                                                                    >
                                                                        {"Ajouter"}
                                                                    </Link>
                                                                </div>
                                                            </Col>
                                                        </InputGroupAddon>
                                                    </Form>
                                                </Col>
                                                <div className="search-5">
                                                    <h6>Rechercher:</h6>
                                                </div>

                                                <input
                                                    className=" border search "
                                                    style={{borderRadius: "7px"}}
                                                    type="search"
                                                    placeholder="Search..."
                                                    name="search"
                                                    defaultValue={getValSearch()}
                                                    onChange={(e) => onSearchQuery(e)}
                                                />
                                            </Row>
                                        <Card>
                                            <div className="card-block row">
                                                <Col sm="12">

                                                    <div className="table-responsive">
                                                        {adresseData && adresseData.loading ? (
                                                            <div className="text-center">
                                                                <img src={Spinner} style={{width: "10%"}} alt=""/>
                                                            </div>
                                                        ) : (
                                                            <Table striped>
                                                                <thead>
                                                                <tr>
                                                                    <th scope="col"></th>
                                                                    <th>{"Id"}</th>
                                                                    <th>{"Email"}</th>
                                                                    <th>{"Téléphone 1"}</th>
                                                                    <th>{"Nom"}</th>
                                                                    <th>{"Prénom"}</th>
                                                                    <th>{"Adresse"}</th>
                                                                    <th>{"Action"} </th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {adresseData &&
                                                                    adresseData.adresses.map((b) => (
                                                                        <tr key={b.id}>
                                                                            <td></td>
                                                                            <td>
                                                                                <Link
                                                                                    to={`/adresse/edit/${b.id}`}
                                                                                >
                                                                                    {b.id}
                                                                                </Link>
                                                                            </td>
                                                                            <td>{b.email}</td>
                                                                            <td>{b.telephone_1}</td>
                                                                            <td>{b.first_name}</td>
                                                                            <td>{b.last_name}</td>
                                                                            <td>{b.full_address}</td>
                                                                            <td>
                                                                                <button className="btn btn-danger"
                                                                                        onClick={() => {
                                                                                            deleteAdresse(b.id);
                                                                                            dispatch(getAdresses(query));
                                                                                            dispatch(getAdresses(query))
                                                                                        }}>
                                                                                    <i className="fa fa-trash"></i>
                                                                                </button>
                                                                            </td>

                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                        )}
                                                    </div>
                                                </Col>
                                            </div>
                                        </Card>
                                    </Col>{" "}
                                    <Row>
                                        <Col sm="10"></Col>
                                        <Col>
                                            <PaginationWrapper
                                                name="Adresses"
                                                length={adresseData.adresses.length}
                                                count={count}
                                                currentPage={pagenumber}
                                                onNavigate={Navigate}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                </div>
            ) : (
                <Error404 page="Adresses"/>
            )}
        </Fragment>
    );
};

Adresse.propTypes = {
    deleteAdresse: PropTypes.func.isRequired,
    getAdresses: PropTypes.func.isRequired,
    adresseData: PropTypes.object.isRequired,
};

const mapstatetoprops = (state) => ({
    adresseData: state.reduceraddr,
});

export default connect(mapstatetoprops, {
    getAdresses,
    deleteAdresse,
})(withRouter(Adresse));
