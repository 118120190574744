import React from 'react'
import '../css/testimonials.css'
import test1 from '../images/3ala9ni-logo.png'
import test2 from '../images/el-birou-logo.png'
import test3 from '../images/art-side-logo.png'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader  
import {Carousel} from 'react-responsive-carousel';
import {Row, Col} from 'reactstrap'

export default function Testimonials() {
    return (
        <Row>
            <Col ms={2}></Col>
            <Col ms={8} style={{width: "350px"}}>
                <Carousel infiniteLoop>
                    <div>
                        <figure className="snip1157 ">
                            <blockquote>Birou: Fastest,<br/> easiest and client oriented<br/> advises are on top ! <br/>bonne
                                continuation
                                <div className="arrow"/>
                            </blockquote>
                            <img src={test2} alt="sq-sample3" style={{width: "auto", height: "120px"}}/>
                            <div className="author">

                            </div>
                        </figure>
                    </div>
                    <div>
                        <figure className="snip1157 ">
                            <blockquote>Art Side:Des gens honnêtes et professionnels et à l'écoute.

                                la livraison se fait en 24h et dans les meilleures conditions (0 casse )

                                Bref les meilleurs

                                <div className="arrow"/>
                            </blockquote>
                            <div className="author">

                            </div>
                            <img src={test3} alt="sq-sample3" style={{width: "auto", height: "120px"}}/>

                        </figure>
                    </div>
                    <div>
                        <figure className="snip1157 ">
                            <blockquote>علقني:
                                ميخليوك ناقص حتى شي, بلحق قد ما شفت و ما ريتش شركة توصيل كيما


                                على الخدمة لي قاعدين تخدمو فيها, نحبكم برشا و
                                bonne continuation
                                beezit.Bravo beezit 5 étoiles

                                <div className="arrow"/>
                            </blockquote>
                            <img src={test1} alt="sq-sample3" style={{width: "auto", height: "120px"}}/>
                            <div className="author">

                            </div>
                        </figure>
                    </div>
                </Carousel>
            </Col>
            <Col ms={2}></Col>

        </Row>

    )
}

// eslint-disable-next-line no-lone-blocks
{/* <Carousel cols={3} rows={1} gap={2} loop>
<Carousel.Item>
<figure className="snip1157 " >
<blockquote>علقني:        
   ميخليوك ناقص حتى شي, بلحق قد ما شفت و ما ريتش شركة توصيل كيما






على الخدمة لي قاعدين تخدمو فيها, نحبكم برشا و
bonne continuation
beezit.Bravo beezit 5 étoiles

  <div className="arrow" />
</blockquote>
<img src={test1} alt="sq-sample3" />
<div className="author">
  <h5 style={{color: "black"}}>علقني</h5>
</div>
</figure>
</Carousel.Item>
<Carousel.Item>
<figure className="snip1157 " >
<blockquote>Birou: Fastest,<br/> easiest and client oriented<br/> advises are on top ! <br/>bonne continuation

<div className="arrow" />
</blockquote>
<img src={test2} alt="sq-sample3" />
<div className="author">
<h5 style={{color: "black"}}>Birou</h5>
</div>
</figure>
</Carousel.Item>
<Carousel.Item>
<figure className="snip1157 " >
<blockquote>Art Side:Des gens honnêtes et professionnels et à l'écoute.

la livraison se fait en 24h et dans les meilleures conditions (0 casse )

Bref les meilleurs

<div className="arrow" />
</blockquote>
<img src={test3} alt="sq-sample3" />
<div className="author">
<h5 style={{color: "black"}}>Art Side</h5>
</div>
</figure>
</Carousel.Item>

</Carousel> */
}
