import React, {Fragment, useContext, useEffect} from "react";
import PropTypes from "prop-types";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
} from "reactstrap";
import Alert from "../../redux/Alert"
import Breadcrumb from "../../layout/breadcrumb";
import {useParams, withRouter} from "react-router-dom";
import {connect} from "react-redux";

import {getCurrentDemande, updateDemande} from "./action";
import Error404 from "../../pages/errors/error404";
import AppContext from "../../context/Context";

const DetailDemande = ({
                           demande: {demande},
                           getCurrentDemande,
                           updateDemande,
                       }) => {
    const {id} = useParams();
    const {
        config: {group}
    } = useContext(AppContext)

    useEffect(() => {
        getCurrentDemande(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let user_groups_name = group;
    return (
        <Fragment>
            <Breadcrumb parent="Demande" title="Détail demande"/>
            {user_groups_name && user_groups_name.indexOf("admin") !== -1 ? (
                <Container fluid={true}>
                    <Alert/>
                    <Row>
                        <Col>
                            <button disabled={demande && demande.valid === true} onClick={() =>
                                updateDemande({valid: true}, id)
                            } className="btn m-2" style={{backgroundColor: "#FDF203"}}>Approuver
                            </button>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                            <Card>
                                <CardHeader>
                                    <h4>Détail partenaire</h4>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <h6>
                                                Nom :{" "}
                                                {demande && demande.last_name ? demande.last_name : ""}
                                            </h6>
                                        </Col>
                                        <Col>
                                            <h6>
                                                Prénom :{" "}
                                                {demande && demande.first_name
                                                    ? demande.first_name
                                                    : ""}
                                            </h6>
                                        </Col>
                                    </Row>

                                    <hr/>
                                    <Row>
                                        <Col>
                                            <h6>
                                                Email : {demande && demande.email ? demande.email : ""}
                                            </h6>
                                        </Col>
                                        <Col>
                                            <h6>
                                                Téléphone :{" "}
                                                {demande && demande.number ? demande.number : ""}
                                            </h6>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="6">
                            <Card>
                                <CardHeader>
                                    <h4>Autre détail</h4>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <h6>
                                                Entreprise :{" "}
                                                {demande && demande.entreprise
                                                    ? demande.entreprise
                                                    : ""}
                                            </h6>
                                        </Col>
                                        <Col>
                                            <h6>
                                                Nombre de colis :{" "}
                                                {demande && demande.commande ? demande.commande : ""}
                                            </h6>
                                        </Col>
                                    </Row>
                                    <hr/>
                                    <Row>
                                        <Col>
                                            <h6>
                                                Date :{" "}
                                                {demande && demande.created_at ? demande.created_at : ""}
                                            </h6>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            ) : (
                <Error404 page="Détail demande"/>
            )}
        </Fragment>
    );
};

DetailDemande.propTypes = {
    updateDemande: PropTypes.func.isRequired,
    getCurrentDemande: PropTypes.func.isRequired,
    demande: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    demande: state.reducerde,
});

export default connect(mapStateToProps, {updateDemande, getCurrentDemande})(
    withRouter(DetailDemande)
);
