import React, {Fragment, useEffect, useMemo} from 'react';
import {ExportCSV} from "./action";
import {connect, useDispatch} from "react-redux";
import PropTypes from "prop-types";
import {useLocation} from "react-router-dom";
import Spinner from "./spinner.gif";


const Downloadcsv = ({exportData, auth}) => {
    const dispatch = useDispatch()
    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };

let access = auth.access
    let query = useQuery();
    let params = new URLSearchParams(document.location.search)
    let type = params.get("type")
    let status = params.get("status")
    let service = params.get('service')
    let created_at = params.get('created_at')
    const Filename = (type) => {
        if (!status && !service && !created_at && type === 'csv') {
            return 'GestionColis.csv'
        }else if(service === "echange" && !type){
            return "ColisEchange.xls"
        }else if(created_at==="today" && !type){
            return "ColisDuJour.xls"
        }else if(status === "retour" && !type){
            return 'ColisRetour.xls'
        }else if(status === "supprimer" && !type) {
            return "ColisSupprimés.xls"
        }else if (status === "modifier" && !type) {
            return "ColisModifiés.xls"
        }else if (status === "payer" && !type){
            return "ColisPayés.xls"
        }else if (service === "echange" && type === "csv") {
            return 'ColisEchange.csv'
        }else if(created_at==="today" && type === "csv"){
            return "ColisDuJour.csv"
        }else if(status === "retour" && type === "csv"){
            return 'ColisRetour.csv'
        }else if(status === "supprimer" && type === "csv") {
            return "ColisSupprimés.csv"
        }else if (status === "modifier" && type === "csv") {
            return "ColisModifiés.csv"
        }else if (status === "payer" && type === "csv"){
            return "ColisPayés.csv"
        }else {
            return "GestionColis.xls"
        }
    }
    useEffect(() => {
        query.set("token", access)
        dispatch(ExportCSV(query, Filename(type)))

        //eslint-disable-next-line
    }, [query])

    useEffect(() => {
        if (exportData.link) {
            window.close()
        }
    })
    return (
        <Fragment>
            <div className="text-center">
                <img src={Spinner} style={{width: "10%"}} alt=""/>
            </div>
        </Fragment>
    )
}


Downloadcsv.propTypes = {
    ExportCSV: PropTypes.func.isRequired,
    exportData: PropTypes.object.isRequired,

}
const mapStateToProps = (state) => ({
    exportData: state.reducerc,
    auth: state.auth,
})


export default connect(mapStateToProps, {ExportCSV})(Downloadcsv)
