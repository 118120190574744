import React, {
    Fragment,
    useState,
    useEffect,
    useMemo, useContext,
} from "react";
import Breadcrumb from "../../layout/breadcrumb";
import Spinner from "../colis/spinner.gif";
import PaginationWrapper from "../pagination/pagination";
import Alert from "../../redux/Alert";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter, useLocation, useHistory} from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Table,
    Card,
    CardBody,
} from "reactstrap";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {getHistory} from "./action";
import {day2digits} from "./gestiondescolis";
import Select, {components} from "react-select";
import {getFournisseurs} from "../Fournisseurs/action";
import AppContext from "../../context/Context";

const History = ({historyData, fournisseurData}) => {
    const [timer, setTimer] = useState(null)
    const [selectedfournisseur, setSelectedFournisseur] = useState([]);
    const [statefournisseur, setFournisseur] = useState([
        {
            id: "",
            activiter: "",
        },
    ]);
    const [currentFournisseurPage, setCurrentFournisseurPage] = useState(1)
    const {
        config: {group}
    } = useContext(AppContext);
    const dispatch = useDispatch();

    const useQuery = () => {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    };
    let query = useQuery();

    let pagenumber = query.get("page") ? query.get("page") : 1;
    const Navigate = (page) => {
        query.set("page", `${Number(page)}`)

        window.location.replace(`/colis/history/?${query}`);
    };
    const getDate = () => {
        let date = query.get("created_at");
        if (date === "today") {
            let today = new Date();
            let m = today.getMonth() + 1;
            if (m < 10) {
                m = "0" + m;
            }
            return today.getFullYear() + "-" + m + "-" + day2digits(today.getDate());
        } else {
            return date;
        }
    };
    useEffect(() => {
        setFournisseur(fournisseurData.fournisseurs);
    }, [fournisseurData.fournisseurs, fournisseurData.loading, statefournisseur]);
    useEffect(() => {
        dispatch(getHistory(query))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        query.set("page_size", "50")
        query.set("page", currentFournisseurPage)
        dispatch(getFournisseurs(query))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFournisseurPage]);

    let count = historyData.counth;
    const history = useHistory()
    const getValSearch = () => {
        let params = new URLSearchParams(document.location.search);
        return params.get("q");
    };
    const inputChanged = (e) => {
        clearTimeout(timer)
        query.set("search", e)
        const newTimer = setTimeout(() => {
            dispatch(getHistory(query));
        }, 1500)
        setTimer(newTimer)
    }
    const onChangeFournisseur = (e) => {
        setSelectedFournisseur(e);
        const fournisseurid = e.value;

        query.set("fournisseur_id", fournisseurid)
        dispatch(getHistory(query))
    };
    const data = [];
    fournisseurData &&
    fournisseurData.fournisseurs.map((F) =>
        data.push({
            value: parseInt(F.id),
            label: F.name,
        })
    );
    const NoOptionsMessage = props => {
        return (
            <components.NoOptionsMessage {...props}>
                <div className="text-center">
                    <img src={Spinner} style={{width: "10%"}} alt=""/>
                </div>
            </components.NoOptionsMessage>
        );
    };
    return (
        <Fragment>
            <Breadcrumb parent="Colis" title={"Historique"}/>
            <div>
                <Container fluid={true}>
                    <Alert/>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardBody>
                                    <Col sm="12">
                                        <Row className={"m-1"}>
                                            <Col></Col>
                                            {group.indexOf('fournisseur') === -1 &&
                                                <Col sm={4}>
                                                    <Select
                                                        value={selectedfournisseur}
                                                        options={data}
                                                        placeholder={"Choisir Un Fournisseur"}
                                                        components={{NoOptionsMessage}}
                                                        onChange={(e) => {
                                                            onChangeFournisseur(e);
                                                            history.push({search: query.toString()})
                                                        }}
                                                        onInputChange={(e) => {
                                                            query.set('search', e);
                                                            dispatch(getFournisseurs(query))
                                                        }}
                                                        onMenuScrollToBottom={(e) => {
                                                            console.log(e)
                                                            setCurrentFournisseurPage(page => page + 1)
                                                        }}
                                                    />
                                                </Col>
                                            }


                                            <input
                                                className="btn btn-light"
                                                type="date"
                                                name="created_at"
                                                defaultValue={getDate()}
                                                onChange={(e) => {
                                                    query.set("created_at", e.target.value)
                                                }}
                                            />
                                            <button
                                                type="button"
                                                className="btn text-white  ml-2"
                                                style={{backgroundColor: "#27B3B0"}}
                                                onClick={(e) => {
                                                    history.push({search: query.toString()})
                                                    dispatch(getHistory(query))
                                                }}
                                            >
                                                Valider
                                            </button>
                                            <div className="search-5">
                                                <h6>Rechercher:</h6>
                                            </div>
                                            <input
                                                className=" border search "
                                                style={{borderRadius: "7px"}}
                                                type="search"
                                                placeholder="Search..."
                                                name="search"
                                                defaultValue={getValSearch()}
                                                onChange={(e) => {
                                                    inputChanged(e.target.value)
                                                    history.push({search: query.toString()})
                                                }}
                                            />
                                        </Row>
                                        <Card>
                                            <div className="card-block row">
                                                <Col sm="12" lg="12" xl="12">
                                                    <div className="table-responsive">
                                                        {historyData && historyData.loading ? (
                                                            <div className="text-center">
                                                                <img src={Spinner} style={{width: "10%"}} alt=""/>
                                                            </div>
                                                        ) : (
                                                            <Table striped>
                                                                <thead>
                                                                <tr>
                                                                    <th>ID</th>
                                                                    <th>Fournisseur</th>
                                                                    <th>Nom d'utilisateur</th>
                                                                    <th>Date création</th>
                                                                    <th>{"Action"}</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {historyData &&
                                                                    historyData.history.map((b) => (
                                                                        <tr key={b.id}>
                                                                            <td>
                                                                                <Link to={`/colis/history/${b.id}`}>
                                                                                    {b.id}
                                                                                </Link>
                                                                            </td>
                                                                            <td>
                                                                                {b.fournisseur_name}
                                                                            </td>
                                                                            <td>
                                                                                {b.username}
                                                                            </td>
                                                                            <td>
                                                                                {b.created_at}
                                                                            </td>
                                                                            <td>
                                                                                <Link
                                                                                    to={`/colis/history/${b.id}`}
                                                                                    className="btn  btn-primary m-1"
                                                                                >
                                                                                    Details
                                                                                </Link>
                                                                                <Link
                                                                                    to={`/colis/decharge?related_decharge=${b.id}`}
                                                                                    className={"btn btn-info"}
                                                                                    target={"_blank"}
                                                                                >
                                                                                    Imprimer
                                                                                </Link>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                <tr>
                                                                    <td></td>
                                                                </tr>
                                                                </tbody>
                                                            </Table>
                                                        )}
                                                    </div>
                                                </Col>
                                            </div>
                                        </Card>
                                    </Col>{" "}
                                    <Row>
                                        <Col sm="10"></Col>
                                        <Col>
                                            <PaginationWrapper
                                                name="Historique"
                                                length={historyData.history.length}
                                                count={count}
                                                currentPage={pagenumber}
                                                onNavigate={Navigate}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
};
History.propTypes = {
    getHistory: PropTypes.func.isRequired,
    getFournisseurs: PropTypes.func.isRequired,
    historyData: PropTypes.object.isRequired,
};
const mapstatetoprops = (state) => ({
    historyData: state.reducerc,
    fournisseurData: state.reducerf,
});

export default connect(mapstatetoprops, {
    getHistory,
    getFournisseurs,
})(withRouter(History));
