import { setAlert, setAlertD } from "../../redux/alert/action";
import { setAuthToken, api } from "../../utils/backend.instance";
import {
  GET_ADRESSE,
  GET_ADRESSES,
  ADRESSE_ERROR,
  ADRESSE_LOADING,
} from "../../redux/actionTypes";

export const addAdresse = (formData, history) => async (dispatch) => {
  if (localStorage.access) {
    setAuthToken(localStorage.access);
  }
  try {
    const res = await api.post("/colis/address/", formData);
    dispatch({
      type: GET_ADRESSE,
      payload: res.data,
    });
    dispatch(setAlert("Adresse Successfully Created", "success"));
    history.push("/adresse");
  } catch (err) {
    const errors = err.response.data;
    if (errors) {
      dispatch(
        setAlertD(
          (errors.telephone_1 ? "Téléphone 1: " + errors.telephone_1 + " " : "") +
            (errors.zipcode ? "Code postale: " + errors.zipcode + " " : "") +
            (errors.address ? " Adresse: " + errors.address + " " : ""),
          "danger"
        )
      );
    }
    dispatch({
      type: ADRESSE_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
  }
}

export const updateAdresse = (formData, id, history) => async (dispatch) => {
  if (localStorage.access) {
    setAuthToken(localStorage.access);
  }
  try {
    const res = await api.put(`/colis/address/${id}/`, formData);
    dispatch({
      type: GET_ADRESSE,
      payload: res.data,
    });
    dispatch(setAlert("Adresse Successfully Updated", "success"));
    history.push('/adresse')
  } catch (err) {
    const errors = err.response.data;
    if (errors) {
      dispatch(
        setAlertD(
          (errors.telephone_1 ? "Téléphone 1: " + errors.telephone_1 + " " : "") +
            (errors.zipcode ? "Code postale: " + errors.zipcode + " " : "") +
            (errors.address ? " Adresse: " + errors.address + " " : ""),
          "danger"
        )
      );
    }
    dispatch({
      type: ADRESSE_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
  }
};

export const getCurrentadresse = (id) => async (dispatch) => {
  dispatch({ type: ADRESSE_LOADING });
  if (localStorage.access) {
    setAuthToken(localStorage.access);
  }

  try {
    const res = await api.get(`/colis/address/${id}/`);

    dispatch({
      type: GET_ADRESSE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ADRESSE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const getAdresses = (params) => async (dispatch) => {
  dispatch({ type: ADRESSE_LOADING });
  if (localStorage.access) {
    setAuthToken(localStorage.access);
  }

  try {
    const res = await api.get(`/colis/address/?${params.toString()}`);

    dispatch({
      type: GET_ADRESSES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ADRESSE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const deleteAdresse = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/colis/address/${id}/`);

    dispatch({
      type: GET_ADRESSE,
      payload: res.data,
    });

    dispatch(setAlertD("Adresse Successfully Deleted", "success"));
  } catch (err) {
    dispatch({
      type: ADRESSE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
