import React, {Fragment, useContext, useEffect, useState,} from "react";
import PropTypes from "prop-types";
import Alert from "../../redux/Alert";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {addNew,} from "./actions";
import Breadcrumb from "../../layout/breadcrumb";

import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Row,} from "reactstrap";
import {Textarea} from "../../constant";
import AppContext from "../../context/Context";

const NewNews = ({
                     addNew,
                     history,
                 }) => {
    const {
        config: {group, user_id}
    } = useContext(AppContext)

    const [formData, setFormData] = useState({
        user: user_id,
        title: "",
        description: "",
    });

    const onChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const onSubmit = (e) => {
        e.preventDefault();
        addNew(formData, history);
    };
    const [taptopStyle, setTapTopStyle] = useState("none");
    const executeScroll = () => {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    };

    const handleScroll = () => {
        if (window.scrollY > 600) {
            setTapTopStyle("block");
        } else {
            setTapTopStyle("none");
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        handleScroll();
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [taptopStyle]);


    return (
        <Fragment>
            <Breadcrumb parent="News" title="Ajouter une nouvelle"/>
            <Container fluid={true}>
                <Form className="theme-form" onSubmit={(e) => onSubmit(e)}>
                    <Alert/>
                    <Row>
                        <Col sm="12">
                            {group.indexOf("admin") !== -1 ? (
                                <Card>
                                    <CardHeader>
                                        <h5> Ajouter Nouvelle</h5>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Titre</Label>
                                                    <Input
                                                        className="form-control digits"
                                                        name="title"
                                                        placeholder={"Titre"}
                                                        id="title"
                                                        type="text"
                                                        value={formData.title}
                                                        onChange={(e) => onChange(e)}
                                                    />

                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Description</Label>
                                                    <Textarea
                                                        className="form-control digits"
                                                        name="description"
                                                        id="description"
                                                        placeholder={"Description"}
                                                        value={formData.description}
                                                        onChange={(e) => onChange(e)}
                                                        rows={5}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            ) : (
                                ""
                            )}
                        </Col>


                        <Row>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <FormGroup className="mb-0">
                                <button
                                    type="submit"
                                    onClick={() => executeScroll()}
                                    className="btn btn-success mr-3"
                                >
                                    Enregistrer
                                </button>
                                <Link to={`/news`}>
                                    <Button color="danger">Annuler</Button>
                                </Link>
                                <br/>
                                <br/>
                            </FormGroup>
                        </Row>
                    </Row>
                </Form>
            </Container>
        </Fragment>
    );
};
NewNews.propTypes = {
    addNew: PropTypes.func.isRequired,
};

const mapstatetoprops = (state) => ({});

export default connect(mapstatetoprops, {
    addNew,
})(withRouter(NewNews));
